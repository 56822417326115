import { Button, Col, Form, Input, Row, Select, Space, message } from "antd";
import React, { useState, useEffect } from "react";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  actual_description: Yup.string()
    .required("Actual deviation is required")
    .label("Actual Deviation"),
  mitigates: Yup.string()
    .required("Mitigates is required")
    .label("Mitigates"),
  deviation: Yup.string()
    .required("Deviation is required")
    .label("Deviation"),
});

const yupSync = {
  async validator({ field }, value) {
    await validationSchema.validateSyncAt(field, { [field]: value });
  },
};

const CommonManualDeviationForm = ({
  selectedManual,
  deviationList,
  initialValues,
  onClose,
  handleEditManualDeviation,
  handleAddManualDeviation,
}) => {
  const [form] = Form.useForm();
  const [loading] = useState(false);

  useEffect(() => {
    if (selectedManual && initialValues) {
      // Find the deviation ID if we have a name
      let deviationId = initialValues.deviation;
      if (typeof deviationId === 'string' && deviationList?.length) {
        const deviationItem = deviationList.find(
          (item) => item.name === initialValues.deviation
        );
        if (deviationItem) {
          deviationId = deviationItem.id;
        }
      }

      form.setFieldsValue({
        ...initialValues,
        deviation: deviationId, 
      });
    } else {
      form.resetFields();
    }
  }, [selectedManual, initialValues, form, deviationList]);

  const closeForm = () => {
    form.resetFields(); 
    onClose();
  };

  const handleSubmit = (values) => { 

    const deviationData = {
      actual_description: values.actual_description,
      mitigates: values.mitigates,
      deviation: values.deviation, 
    };

     

    if (initialValues) {
      handleEditManualDeviation(deviationData, initialValues.id);
      // message.success("Manual Deviation Updated Successfully");
    } else {
      handleAddManualDeviation(deviationData);
      message.success("Manual Deviation Created Successfully");
    }

    form.resetFields();
    onClose();
  };

  return (
    <Form
      form={form}
      onFinish={handleSubmit}
      layout="vertical"
      initialValues={{
        actual_description: "",
        mitigates: "",
        deviation: undefined,
      }}
    >
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name="actual_description"
            label="Description"
            rules={[yupSync]}
            required
          >
            <Input
              placeholder="Please Enter Description"
            />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item name="mitigates" label="Mitigates" rules={[yupSync]} required>
            <Input
              placeholder="Please Enter Mitigates"
            />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item name="deviation" label="Deviation" rules={[yupSync]} required>
            <Select placeholder="Please Select" allowClear>
              {deviationList?.map((type) => (
                <Select.Option key={type.id} value={type.id}>
                  {type.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Space
        direction="horizontal"
        align="center"
        style={{ display: "flex", flexDirection: "row-reverse" }}
      >
        <Button type="primary" htmlType="submit" loading={loading}>
          Save
        </Button>
        <Button onClick={closeForm}>Cancel</Button>
      </Space>
    </Form>
  );
};

export default CommonManualDeviationForm;
