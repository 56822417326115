import { axiosRequest } from "../../../../../utils/api/axiosRequest";

export const getTeleverificationDetailsByIdService = (loan_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/customer/media-detail/televerification-detail/${loan_id}`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}


export const getCustomerMobileByIdService = (customer_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/customer/customer-detail/${customer_id}`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const createCallService = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.post("/api/v1/customer/televerification/initiate-call", data);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const getApplicantListByIdService = (loan_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(
        `api/v1/customer/customer-detail/personal-detail-list/${loan_id}`
      );
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const getCustomerMobileNumberByIdService = (customer_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(
        `api/v1/customer/customer-detail/get-mobile-number/${customer_id}`
      );
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};
