import { axiosRequest } from "../../../utils/api/axiosRequest";

export const getPropertyDedupe = (loan_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.post(
        `/api/v1/customer/dedupe/property-dedupe?loan_detail_id=${loan_id}`
      );
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const getCustomerDedupe = (loan_id, customer_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.post(
        `/api/v1/customer/dedupe/customer-dedupe?loan_detail_id=${loan_id}&customer_id=${customer_id}`
      );
      // console.log(response);
      return resolve(response?.data);
    } catch (error) {
      return reject(error);
    }
  });
};
export const getLoanDetailsByLoanIdDedupe = (loan_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(
        `/api/v1/loan/loan-detail/loan-id/${loan_id}`
      );
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const getLoanDetailsByIdDedupe = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/loan/loan-detail/${id}`);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const DedupeLinkService = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.post(
        "/api/v1/customer/customer-detail/link-lan-customer/",
        data
      ); // Remove the comma
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const getCustomersByLoanId = (loanId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(
        `/api/v1/customer/customer-detail/personal-detail-list/${loanId}`
      );
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};
