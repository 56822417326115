import React, { useState, useEffect } from "react";
import "../styles/documentDetails.css";
// import group from "../assets/Group.png";
import "../../style/customerStyle.css";
import { Drawer, message, Modal } from "antd";
import Header from "../../../../layout/views/Header";
import DocumentDetailsForm from "./DocumentDetailsForm";
import { getDocumentDetailsByIdService, deleteDocumentServiceById } from "../services/documentDetailsServices";
import { useParams } from "react-router-dom";
import { FaFile } from "react-icons/fa";
import RViewerJS from "viewerjs-react";
import "viewerjs-react/dist/index.css";
import Loading from "../../../../../utils/loading/Loading";
import ErrorMessage from "../../../../../utils/errorHandling/ErrorMessage";
import "../../../../commonCss/commonStyle.css";
import { decrypt } from "../../../../../utils/cryptoUtils/cryptoUtils";
import pdf_image from "../assets/pdf.png";
import csvimage from "../assets/csv.png";
import dummy_img from "../assets/dummy.png";
import Editbutton from '../assets/Form.png'
import shear from '../assets/share 1.png';
import ConditionalRender from '../../../../../utils/authorization/AuthorizeComponent';
import deletebutton from '../assets/DeleteOutlined.png'
import CustomNoData from "../../../../../utils/noDataIcon/CustomNoData";
import { AiOutlineMore } from "react-icons/ai";
import { useSelector } from 'react-redux';
import { Menu, Dropdown } from "antd";
import add from "../assets/Add.png";

const DocumentDetails = () => {
  const { user_data } = useSelector((state) => state.user);
  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false)
  const { encrypted_loan_id } = useParams();
  const [documentData, setDocumentData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [refreshData, setRefreshData] = useState(false);
  const [selectedCustomerId ,setSelectedCustomerId] = useState("");
  const [selectedDocumentId, setSelectedDocumentId] = useState(null);
  const [payload, setPayload] = useState({});

  const toggleRefreshData = () => {
    setRefreshData((prev) => !prev);
  };

  let loan_id = null;
  try {
    loan_id = decrypt(encrypted_loan_id);
  } catch (error) {
    message.error("Invalid Loan ID");
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getDocumentDetailsByIdService(loan_id);
        setDocumentData(response.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };

    fetchData();
  }, [loan_id, refreshData]);

  const Headings = [
    "Key Parameter's",
    "KYC",
    "Financial",
    "Income",
    "Property",
  ];

  const onClose = () => {
    setOpen(false);
  };

  const getTableColumnClass = (entryCount) => {
    switch (entryCount) {
      case 2:
        return "tab_form_main_data_two_entries";
      case 3:
        return "tab_form_main_data_three_entries";
      default:
        return "";
    }
  };

  const handleImageError = (e) => {
    e.target.src = dummy_img;
  };

  const showDrawer = () => {
    setOpen(true);
  };

  const handleDelete = async (values) => {
    let payload = {
      document_detail_id: values.document_detail_id,
      document_category: values.document_category,
      document_type: values.document_type,
    };

    try {
      const response = await deleteDocumentServiceById(payload);

      if (response?.status === 200 && response?.success) {
        message.success("Document deleted successfully");
        toggleRefreshData();
      } else {
        message.error("Failed to delete document");
      }
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message) ||
        "An unexpected error occurred."
      );
    } finally {
      setLoading(false); 
    }
  };

  const handleMenuClick = ({ key, properties }) => { 
    if (key === "edit") { 
      setOpen(true)
      setSelectedDocumentId(properties.id);
      setSelectedCustomerId(properties.customer_detail.id)
    } else if (key === "share") {
      console.log("Share action triggered"); 
    } else if (key === "delete") { 
      
      setPayload ({
        document_detail_id: properties.id,
        document_category: properties.document_category[0].id,
        document_type: properties.document_type
      });
      setOpenModal(true)
      
    }
  };

  return (
    <>
      <div className="tab_form_container">
        <Drawer
          title={
            <Header title="Document"
              onClose={onClose} />}
          width={970}
          onClose={onClose}
          open={open}
          closable={false}
          refreshData={toggleRefreshData}
        >
          <DocumentDetailsForm
            open={open}
            closeForm={onClose}
            refreshData={toggleRefreshData}
            loan_id={loan_id}
            customer_id={selectedCustomerId} 
            document_id={selectedDocumentId}
          />
        </Drawer>
        <div className="tab_form_main_container fade-in">
        {loading ? (
            <Loading loading={loading} />
          ) : documentData?.some(
            (person) => person?.document_details?.length > 0
          ) ? (
          <table className="tab_form_main_table">
            <thead>
              <tr>
                <th className="tab_form_key_parameters">Key Parameter's</th>
                {documentData &&
                  documentData?.map((person, index) => (
                    <th key={person.id || index} className="tab_form_top_heading">
                      <div className="add_button_alignment">
                        <div>
                          <span>{`${person?.customer_name}`} </span>
                          <span>
                            <span>{`(${person?.customer_type})`} </span>
                          </span>
                        </div>
                        <ConditionalRender
                          userPermissions={user_data?.permissions}
                          requiredPermissions={["POSTCUST"]}
                        >
                          <div
                            className="tab_form_sub_edit"
                            onClick={() => {
                              showDrawer();
                              setSelectedCustomerId(person.customer_id);
                              setSelectedDocumentId(null)
                            }}
                          >
                            <img src={add} alt="Add Button" />
                          </div>
                        </ConditionalRender>
                      </div>
                    </th>
                  ))}
              </tr>
            </thead>
            <tbody>
              <tr className="tab_form_gray_highlighted_row">
                <td className="tab_form_left_heading" style={{ height: "100px" }}>KYC Documents</td>
                {documentData &&
                  documentData?.map((person, index) => (
                    <td
                      key={index}
                      className={`tab_form_main_data_table ${getTableColumnClass(
                        documentData?.length
                      )}`}
                    >
                      {Headings.includes("KYC") && (
                        <div className="main_card_first_box">
                          {person?.document_details?.length > 0
                            ? person?.document_details?.map(
                              (properties, propIndex) =>
                                properties.document_category?.map((category, catIndex) => {
                                  const hasKYC = category.display_name;
                                  if (
                                    hasKYC === "KYC" &&
                                    properties.file_type === "application/pdf"
                                  ) {
                                    return (
                                      <div key={propIndex} className="customer_document_main_container">
                                        <div className="customer_document_main_content">
                                          <div className="customer_document_image">
                                            <img
                                              className="customer_document_file_image"
                                              src={pdf_image}
                                              alt="PDF"
                                              height={65}
                                              width={65}
                                              onClick={() =>
                                                window.open(
                                                  properties.document_path,
                                                  "_blank"
                                                )
                                              }
                                            />
                                          </div>
                                          <div className="customer_file_documnet_type_name">
                                            <div>{
                                              category.document_type
                                                ?.display_name
                                            }</div>
                                            <div className="customer_document_file_size">
                                              <div className="customer_document_file_number">{
                                                properties?.document_number
                                              }
                                              </div>
                                              <div className="dot"></div>
                                              {
                                                (properties?.file_size ? (properties.file_size / (1024 * 1024)).toFixed(2) : 0)
                                              } MB</div>
                                          </div>
                                        </div>

                                        <div
                                          className={`customer_document_file_extention ${
                                            properties?.file_type === "image/jpeg"
                                              ? "jpeg"
                                              : properties?.file_type === "application/pdf"
                                                ? "pdf"
                                                : ""
                                          }`}
                                        >
                                           {properties?.file_type === "image/jpeg"
                                              ? "JPEG" : 
                                              properties?.file_type === "image/jpg"
                                              ? "JPG" :
                                               properties?.file_type === "image/png"
                                              ? "PNG"
                                              : properties?.file_type === "application/pdf"
                                                ? "PDF"
                                                : "-"}

                                    
                                          {(() => {
                                            const documentProperties = properties; // Here we assign properties to a variable

                                            return (
                                              <Dropdown
                                                overlay={
                                                  <Menu onClick={(e) => handleMenuClick({ ...e, properties: documentProperties })}>
                                                    <Menu.Item key="edit">
                                                      <div className="icon_content">
                                                        <div>
                                                          <img
                                                            src={Editbutton}
                                                            alt="Edit"
                                                            className="action_icons_button"
                                                          />
                                                        </div>
                                                        <div className="action_button_name">Edit</div>
                                                      </div>
                                                    </Menu.Item>
                                                    <Menu.Item key="shear">
                                                      <div className="icon_content">
                                                        <div>
                                                          <img
                                                            src={shear}
                                                            alt="Share"
                                                            className="action_icons_button"
                                                          />
                                                        </div>
                                                        <div className="action_button_name">Share</div>
                                                      </div>
                                                    </Menu.Item>
                                                    <Menu.Item key="delete">
                                                      <div className="icon_content">
                                                        <div>
                                                          <img
                                                            src={deletebutton}
                                                            alt="Delete"
                                                            className="action_icons_button"
                                                          />
                                                        </div>
                                                        <div className="action_button_name">Delete</div>
                                                      </div>
                                                    </Menu.Item>
                                                  </Menu>
                                                }
                                                trigger={["click"]}
                                                placement="bottomLeft"
                                              >
                                                <AiOutlineMore className={
            properties?.file_type === "image/jpeg" ? "jpeg-more-icon" : "more-icon"
          }  />
                                              </Dropdown>
                                            );
                                          })()}
                                        </div>
                                      </div>
                                    );
                                  } else if (
                                    hasKYC === "KYC" &&
                                    (properties.file_type ===
                                      "application/vnd.ms-excel" ||
                                      properties.file_type ===
                                      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
                                      properties.file_type ===
                                      "application/octet-stream" ||
                                      properties.file_type === "text/csv")
                                  ) {
                                    return (
                                      <div key={propIndex} className="customer_document_main_container">
                                        <div className="customer_document_main_content">
                                          <div className="customer_document_image">
                                            <img
                                              className="customer_document_file_image"
                                              src={csvimage}
                                              alt="CSV"
                                              height={64}
                                              width={64}
                                              onClick={() =>
                                                window.open(
                                                  properties.document_path,
                                                  "_blank"
                                                )
                                              }
                                            />
                                          </div>
                                          <div className="customer_file_documnet_type_name">
                                            <div>{
                                              category.document_type
                                                ?.display_name
                                            }</div>
                                            <div className="customer_document_file_size">
                                              <div className="customer_document_file_number">{
                                                properties?.document_number
                                              }
                                              </div>
                                              <div className="dot"></div>
                                              {
                                                (properties?.file_size ? (properties.file_size / (1024 * 1024)).toFixed(2) : 0)
                                              } MB</div>
                                          </div>

                                        </div>
                                        <div
                                       className={`customer_document_file_extention ${(properties?.file_type === "image/jpeg" || properties?.file_type === "image/jpg"  || properties?.file_type === "image/png")
                                            ? "jpeg"
                                            : properties?.file_type === "application/pdf"
                                              ? "pdf"
                                              : ""
                                            }`}
                                        >
                                           {properties?.file_type === "image/jpeg"
                                              ? "JPEG" : 
                                              properties?.file_type === "image/jpg"
                                              ? "JPG" :
                                               properties?.file_type === "image/png"
                                              ? "PNG"
                                              : properties?.file_type === "application/pdf"
                                                ? "PDF"
                                                : "-"}

{(() => {
                                            const documentProperties = properties;  

                                            return (
                                              <Dropdown
                                                overlay={
                                                  <Menu onClick={(e) => handleMenuClick({ ...e, properties: documentProperties })}>
                                                    <Menu.Item key="edit">
                                                      <div className="icon_content">
                                                        <div>
                                                          <img
                                                            src={Editbutton}
                                                            alt="Edit"
                                                            className="action_icons_button"
                                                          />
                                                        </div>
                                                        <div className="action_button_name">Edit</div>
                                                      </div>
                                                    </Menu.Item>
                                                    <Menu.Item key="shear">
                                                      <div className="icon_content">
                                                        <div>
                                                          <img
                                                            src={shear}
                                                            alt="Share"
                                                            className="action_icons_button"
                                                          />
                                                        </div>
                                                        <div className="action_button_name">Share</div>
                                                      </div>
                                                    </Menu.Item>
                                                    <Menu.Item key="delete">
                                                      <div className="icon_content">
                                                        <div>
                                                          <img
                                                            src={deletebutton}
                                                            alt="Delete"
                                                            className="action_icons_button"
                                                          />
                                                        </div>
                                                        <div className="action_button_name">Delete</div>
                                                      </div>
                                                    </Menu.Item>
                                                  </Menu>
                                                }
                                                trigger={["click"]}
                                                placement="bottomLeft"
                                              >
                                                <AiOutlineMore className={
            properties?.file_type === "image/jpeg" ? "jpeg-more-icon" : "more-icon"
          }  />
                                              </Dropdown>
                                            );
                                          })()}
                                        </div>
                                      </div>
                                    );
                                  } else if (
                                    hasKYC === "KYC" &&
                                    (properties.file_type === "image/jpeg" ||
                                      properties.file_type === "image/jpg" ||
                                      properties.file_type === "image/png")
                                  ) {
                                    return (
                                      <div key={propIndex} className="customer_document_main_container">
                                        <div className="customer_document_main_content">
                                          <div className="customer_document_image">
                                            <RViewerJS
                                              options={{
                                                url: (image) => image.getAttribute("data-original"),
                                              }}
                                            >
                                              <img
                                                className="customer_document_file_image"
                                                src={
                                                  properties.thumb_document_path ||
                                                  dummy_img
                                                }
                                                data-original={
                                                  properties.document_path ||
                                                  dummy_img
                                                }
                                                alt="Document"
                                                height={64}
                                                width={64}
                                                onError={handleImageError}
                                              />
                                            </RViewerJS></div>
                                          <div className="customer_file_documnet_type_name">
                                            <div>{
                                              category.document_type
                                                ?.display_name
                                            }</div>
                                            <div className="customer_document_file_size">
                                              <div className="customer_document_file_number">{
                                                properties?.document_number
                                              }
                                              </div>
                                              <div className="dot"></div>
                                              {
                                                (properties?.file_size ? (properties.file_size / (1024 * 1024)).toFixed(2) : 0)
                                              } MB</div>
                                          </div>
                                        </div>
                                        <div
                                          className={`customer_document_file_extention ${(properties?.file_type === "image/jpeg" || properties?.file_type === "image/jpg"  || properties?.file_type === "image/png")
                                            ? "jpeg"
                                            : properties?.file_type === "application/pdf"
                                              ? "pdf"
                                              : ""
                                            }`}
                                        >
                                            {properties?.file_type === "image/jpeg"
                                              ? "JPEG" : 
                                              properties?.file_type === "image/jpg"
                                              ? "JPG" :
                                               properties?.file_type === "image/png"
                                              ? "PNG"
                                              : properties?.file_type === "application/pdf"
                                                ? "PDF"
                                                : "-"}
{(() => {
                                            const documentProperties = properties; // Here we assign properties to a variable

                                            return (
                                              <Dropdown
                                                overlay={
                                                  <Menu onClick={(e) => handleMenuClick({ ...e, properties: documentProperties })}>
                                                    <Menu.Item key="edit">
                                                      <div className="icon_content">
                                                        <div>
                                                          <img
                                                            src={Editbutton}
                                                            alt="Edit"
                                                            className="action_icons_button"
                                                          />
                                                        </div>
                                                        <div className="action_button_name">Edit</div>
                                                      </div>
                                                    </Menu.Item>
                                                    <Menu.Item key="shear">
                                                      <div className="icon_content">
                                                        <div>
                                                          <img
                                                            src={shear}
                                                            alt="Share"
                                                            className="action_icons_button"
                                                          />
                                                        </div>
                                                        <div className="action_button_name">Share</div>
                                                      </div>
                                                    </Menu.Item>
                                                    <Menu.Item key="delete">
                                                      <div className="icon_content">
                                                        <div>
                                                          <img
                                                            src={deletebutton}
                                                            alt="Delete"
                                                            className="action_icons_button"
                                                          />
                                                        </div>
                                                        <div className="action_button_name">Delete</div>
                                                      </div>
                                                    </Menu.Item>
                                                  </Menu>
                                                }
                                                trigger={["click"]}
                                                placement="bottomLeft"
                                              >
                                                <AiOutlineMore className={
            properties?.file_type === "image/jpeg" ? "jpeg-more-icon" : "more-icon"
          }  />
                                              </Dropdown>
                                            );
                                          })()}
                                        </div>
                                      </div>
                                    );
                                  }  else if (
                                    hasKYC === "KYC" &&
                                    properties.file_type === "text/plain"
                                  ) {
                                    return (
                                      <div key={propIndex} className="customer_document_main_container">
                                        <div className="customer_document_main_content">
                                          <div className="customer_document_image">
                                            <FaFile
                                              className="customer_document_file_image"
                                              size={64}
                                              color="red"
                                              onClick={() =>
                                                window.open(
                                                  properties.document_path,
                                                  "_blank"
                                                )
                                              }
                                            />
                                          </div>
                                          <div className="customer_file_documnet_type_name">
                                            <div>{
                                              category.document_type
                                                ?.display_name
                                            }</div>
                                            <div className="customer_document_file_size">
                                              <div className="customer_document_file_number">{
                                                properties?.document_number
                                              }
                                              </div>
                                              <div className="dot"></div>
                                              {
                                                (properties?.file_size ? (properties.file_size / (1024 * 1024)).toFixed(2) : 0)
                                              } MB</div>
                                          </div>
                                        </div>
                                        <div
                                       className={`customer_document_file_extention ${(properties?.file_type === "image/jpeg" || properties?.file_type === "image/jpg"  || properties?.file_type === "image/png")
                                            ? "jpeg"
                                            : properties?.file_type === "application/pdf"
                                              ? "pdf"
                                              : ""
                                            }`}
                                        >
                                          {properties?.file_type === "image/jpeg"
                                              ? "JPEG" : 
                                              properties?.file_type === "image/jpg"
                                              ? "JPG" :
                                               properties?.file_type === "image/png"
                                              ? "PNG"
                                              : properties?.file_type === "application/pdf"
                                                ? "PDF"
                                                : "-"}
                                      {(() => {
                                            const documentProperties = properties; // Here we assign properties to a variable

                                            return (
                                              <Dropdown
                                                overlay={
                                                  <Menu onClick={(e) => handleMenuClick({ ...e, properties: documentProperties })}>
                                                    <Menu.Item key="edit">
                                                      <div className="icon_content">
                                                        <div>
                                                          <img
                                                            src={Editbutton}
                                                            alt="Edit"
                                                            className="action_icons_button"
                                                          />
                                                        </div>
                                                        <div className="action_button_name">Edit</div>
                                                      </div>
                                                    </Menu.Item>
                                                    <Menu.Item key="shear">
                                                      <div className="icon_content">
                                                        <div>
                                                          <img
                                                            src={shear}
                                                            alt="Share"
                                                            className="action_icons_button"
                                                          />
                                                        </div>
                                                        <div className="action_button_name">Share</div>
                                                      </div>
                                                    </Menu.Item>
                                                    <Menu.Item key="delete">
                                                      <div className="icon_content">
                                                        <div>
                                                          <img
                                                            src={deletebutton}
                                                            alt="Delete"
                                                            className="action_icons_button"
                                                          />
                                                        </div>
                                                        <div className="action_button_name">Delete</div>
                                                      </div>
                                                    </Menu.Item>
                                                  </Menu>
                                                }
                                                trigger={["click"]}
                                                placement="bottomLeft"
                                              >
                                                <AiOutlineMore className={
            properties?.file_type === "image/jpeg" ? "jpeg-more-icon" : "more-icon"
          }  />
                                              </Dropdown>
                                            );
                                          })()}
                                        </div>
                                      </div>
                                    );
                                  }  else {
                                    return "";
                                  }
                                })
                            )
                            : "-"}
                        </div>
                      )}
                    </td>
                  ))}
              </tr>

              <tr className="tab_form_gray_highlighted_row">
                  <td className="tab_form_left_heading" style={{ height: "100px" }}>Financial</td>
                  {documentData &&
                    documentData?.map((person, index) => (
                      <td key={index} className="tab_form_main_data_table">
                        {Headings.includes("Financial") && (
                          <div className="main_card_first_box">
                            {person?.document_details?.length > 0
                              ? person?.document_details?.map(
                                (properties, propIndex) => properties.document_category?.map((category, catIndex) => {
                                  const hasFinancial = category.display_name;
                                  if (
                                    hasFinancial ===
                                    "Financial" &&
                                    properties.file_type === "application/pdf"
                                  ) {
                                    return (
                                      <div className="customer_document_main_container">
                                        <div className="customer_document_main_content">
                                          <div className="customer_document_image">
                                            <img
                                              className="customer_document_file_image"
                                              src={pdf_image}
                                              alt="PDF"
                                              height={65}
                                              width={65}
                                              onClick={() =>
                                                window.open(
                                                  properties.document_path,
                                                  "_blank"
                                                )
                                              }
                                            />
                                          </div>
                                          <div className="customer_file_documnet_type_name">
                                            <div>{
                                              category.document_type
                                                ?.display_name
                                            }</div>
                                            <div className="customer_document_file_size">
                                              <div className="customer_document_file_number">{
                                                properties?.document_number
                                              }
                                              </div>
                                              <div className="dot"></div>
                                              {
                                                (properties?.file_size ? (properties.file_size / (1024 * 1024)).toFixed(2) : 0)
                                              } MB</div>
                                          </div>

                                        </div>
                                        <div
                                       className={`customer_document_file_extention ${(properties?.file_type === "image/jpeg" || properties?.file_type === "image/jpg"  || properties?.file_type === "image/png")
                                            ? "jpeg"
                                            : properties?.file_type === "application/pdf"
                                              ? "pdf"
                                              : ""
                                            }`}
                                        >
                                            {properties?.file_type === "image/jpeg"
                                              ? "JPEG" : 
                                              properties?.file_type === "image/jpg"
                                              ? "JPG" :
                                               properties?.file_type === "image/png"
                                              ? "PNG"
                                              : properties?.file_type === "application/pdf"
                                                ? "PDF"
                                                : "-"}
                                            {(() => {
                                            const documentProperties = properties; // Here we assign properties to a variable

                                            return (
                                              <Dropdown
                                                overlay={
                                                  <Menu onClick={(e) => handleMenuClick({ ...e, properties: documentProperties })}>
                                                    <Menu.Item key="edit">
                                                      <div className="icon_content">
                                                        <div>
                                                          <img
                                                            src={Editbutton}
                                                            alt="Edit"
                                                            className="action_icons_button"
                                                          />
                                                        </div>
                                                        <div className="action_button_name">Edit</div>
                                                      </div>
                                                    </Menu.Item>
                                                    <Menu.Item key="shear">
                                                      <div className="icon_content">
                                                        <div>
                                                          <img
                                                            src={shear}
                                                            alt="Share"
                                                            className="action_icons_button"
                                                          />
                                                        </div>
                                                        <div className="action_button_name">Share</div>
                                                      </div>
                                                    </Menu.Item>
                                                    <Menu.Item key="delete">
                                                      <div className="icon_content">
                                                        <div>
                                                          <img
                                                            src={deletebutton}
                                                            alt="Delete"
                                                            className="action_icons_button"
                                                          />
                                                        </div>
                                                        <div className="action_button_name">Delete</div>
                                                      </div>
                                                    </Menu.Item>
                                                  </Menu>
                                                }
                                                trigger={["click"]}
                                                placement="bottomLeft"
                                              >
                                                <AiOutlineMore className={
            properties?.file_type === "image/jpeg" ? "jpeg-more-icon" : "more-icon"
          }  />
                                              </Dropdown>
                                            );
                                          })()}
                                        </div>
                                      </div>
                                    );
                                  } else if (
                                    hasFinancial ===
                                    "Financial" &&
                                    (properties.file_type ===
                                      "application/vnd.ms-excel" ||
                                      properties.file_type ===
                                      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
                                      properties.file_type ===
                                      "application/octet-stream" ||
                                      properties.file_type === "text/csv")
                                  ) {
                                    return (
                                      <div key={propIndex} className="customer_document_main_container">
                                        <div className="customer_document_main_content">
                                          <div className="customer_document_image">
                                            <img
                                              className="customer_document_file_image"
                                              src={csvimage}
                                              alt="CSV"
                                              height={64}
                                              width={64}
                                              onClick={() =>
                                                window.open(
                                                  properties.document_path,
                                                  "_blank"
                                                )
                                              }
                                            />
                                          </div>
                                          <div className="customer_file_documnet_type_name">
                                            <div>{
                                              category.document_type
                                                ?.display_name
                                            }</div>
                                            <div className="customer_document_file_size">
                                              <div className="customer_document_file_number">{
                                                properties?.document_number
                                              }
                                              </div>
                                              <div className="dot"></div>
                                              {
                                                (properties?.file_size ? (properties.file_size / (1024 * 1024)).toFixed(2) : 0)
                                              } MB</div>
                                          </div>

                                        </div>
                                        <div
                                       className={`customer_document_file_extention ${(properties?.file_type === "image/jpeg" || properties?.file_type === "image/jpg"  || properties?.file_type === "image/png")
                                            ? "jpeg"
                                            : properties?.file_type === "application/pdf"
                                              ? "pdf"
                                              : ""
                                            }`}
                                        >
                                           {properties?.file_type === "image/jpeg"
                                              ? "JPEG" : 
                                              properties?.file_type === "image/jpg"
                                              ? "JPG" :
                                               properties?.file_type === "image/png"
                                              ? "PNG"
                                              : properties?.file_type === "application/pdf"
                                                ? "PDF"
                                                : "-"}
                                        {(() => {
                                            const documentProperties = properties; // Here we assign properties to a variable

                                            return (
                                              <Dropdown
                                                overlay={
                                                  <Menu onClick={(e) => handleMenuClick({ ...e, properties: documentProperties })}>
                                                    <Menu.Item key="edit">
                                                      <div className="icon_content">
                                                        <div>
                                                          <img
                                                            src={Editbutton}
                                                            alt="Edit"
                                                            className="action_icons_button"
                                                          />
                                                        </div>
                                                        <div className="action_button_name">Edit</div>
                                                      </div>
                                                    </Menu.Item>
                                                    <Menu.Item key="shear">
                                                      <div className="icon_content">
                                                        <div>
                                                          <img
                                                            src={shear}
                                                            alt="Share"
                                                            className="action_icons_button"
                                                          />
                                                        </div>
                                                        <div className="action_button_name">Share</div>
                                                      </div>
                                                    </Menu.Item>
                                                    <Menu.Item key="delete">
                                                      <div className="icon_content">
                                                        <div>
                                                          <img
                                                            src={deletebutton}
                                                            alt="Delete"
                                                            className="action_icons_button"
                                                          />
                                                        </div>
                                                        <div className="action_button_name">Delete</div>
                                                      </div>
                                                    </Menu.Item>
                                                  </Menu>
                                                }
                                                trigger={["click"]}
                                                placement="bottomLeft"
                                              >
                                                <AiOutlineMore  className={
            properties?.file_type === "image/jpeg" ? "jpeg-more-icon" : "more-icon"
          } />
                                              </Dropdown>
                                            );
                                          })()}
                                        </div>
                                      </div>
                                    );
                                  } else if (
                                    hasFinancial ===
                                    "Financial" &&
                                    (properties.file_type === "image/jpeg" ||
                                      properties.file_type === "image/jpg" ||
                                      properties.file_type === "image/png")
                                  ) {
                                    return (
                                      <div key={propIndex} className="customer_document_main_container">
                                        <div className="customer_document_main_content">
                                          <div className="customer_document_image">
                                            <RViewerJS
                                              options={{
                                                url: (image) => image.getAttribute("data-original"),
                                              }}
                                            >
                                              <img
                                                className="customer_document_file_image"
                                                src={
                                                  properties.thumb_document_path ||
                                                  dummy_img
                                                }
                                                data-original={
                                                  properties.document_path ||
                                                  dummy_img
                                                }
                                                alt="Document"
                                                height={64}
                                                width={64}
                                                onError={handleImageError}
                                              />
                                            </RViewerJS></div>
                                          <div className="customer_file_documnet_type_name">
                                            <div>{
                                              category.document_type
                                                ?.display_name
                                            }</div>
                                            <div className="customer_document_file_size">
                                              <div className="customer_document_file_number">{
                                                properties?.document_number
                                              }
                                              </div>
                                              <div className="dot"></div>
                                              {
                                                (properties?.file_size ? (properties.file_size / (1024 * 1024)).toFixed(2) : 0)
                                              } MB</div>
                                          </div>

                                        </div>
                                        <div
                                       className={`customer_document_file_extention ${(properties?.file_type === "image/jpeg" || properties?.file_type === "image/jpg"  || properties?.file_type === "image/png")
                                            ? "jpeg"
                                            : properties?.file_type === "application/pdf"
                                              ? "pdf"
                                              : ""
                                            }`}
                                        >
                                          {properties?.file_type === "image/jpeg"
                                              ? "JPEG" : 
                                              properties?.file_type === "image/jpg"
                                              ? "JPG" :
                                               properties?.file_type === "image/png"
                                              ? "PNG"
                                              : properties?.file_type === "application/pdf"
                                                ? "PDF"
                                                : "-"}
                                        {(() => {
                                            const documentProperties = properties; // Here we assign properties to a variable

                                            return (
                                              <Dropdown
                                                overlay={
                                                  <Menu onClick={(e) => handleMenuClick({ ...e, properties: documentProperties })}>
                                                    <Menu.Item key="edit">
                                                      <div className="icon_content">
                                                        <div>
                                                          <img
                                                            src={Editbutton}
                                                            alt="Edit"
                                                            className="action_icons_button"
                                                          />
                                                        </div>
                                                        <div className="action_button_name">Edit</div>
                                                      </div>
                                                    </Menu.Item>
                                                    <Menu.Item key="shear">
                                                      <div className="icon_content">
                                                        <div>
                                                          <img
                                                            src={shear}
                                                            alt="Share"
                                                            className="action_icons_button"
                                                          />
                                                        </div>
                                                        <div className="action_button_name">Share</div>
                                                      </div>
                                                    </Menu.Item>
                                                    <Menu.Item key="delete">
                                                      <div className="icon_content">
                                                        <div>
                                                          <img
                                                            src={deletebutton}
                                                            alt="Delete"
                                                            className="action_icons_button"
                                                          />
                                                        </div>
                                                        <div className="action_button_name">Delete</div>
                                                      </div>
                                                    </Menu.Item>
                                                  </Menu>
                                                }
                                                trigger={["click"]}
                                                placement="bottomLeft"
                                              >
                                                <AiOutlineMore className={
            properties?.file_type === "image/jpeg" ? "jpeg-more-icon" : "more-icon"
          }  />
                                              </Dropdown>
                                            );
                                          })()}
                                        </div>
                                      </div>
                                    );
                                  } else if (
                                    hasFinancial ===
                                    "Financial" &&
                                    properties.file_type === "text/plain"
                                  ) {
                                    return (
                                      <div key={propIndex} className="customer_document_main_container">
                                        <div className="customer_document_main_content">
                                          <div className="customer_document_image">
                                            <FaFile
                                              size={64}
                                              color="red"
                                              onClick={() =>
                                                window.open(
                                                  properties.document_path,
                                                  "_blank"
                                                )
                                              }
                                              className="customer_document_file_image"

                                            />
                                          </div>
                                          <div className="customer_file_documnet_type_name">
                                            <div>{
                                              category.document_type
                                                ?.display_name
                                            }</div>
                                            <div className="customer_document_file_size">
                                              <div className="customer_document_file_number">{
                                                properties?.document_number
                                              }
                                              </div>
                                              <div className="dot"></div>
                                              {
                                                (properties?.file_size ? (properties.file_size / (1024 * 1024)).toFixed(2) : 0)
                                              } MB</div>
                                          </div>
                                        </div>
                                        <div
                                       className={`customer_document_file_extention ${(properties?.file_type === "image/jpeg" || properties?.file_type === "image/jpg"  || properties?.file_type === "image/png")
                                            ? "jpeg"
                                            : properties?.file_type === "application/pdf"
                                              ? "pdf"
                                              : ""
                                            }`}
                                        >
                                        {properties?.file_type === "image/jpeg"
                                              ? "JPEG" : 
                                              properties?.file_type === "image/jpg"
                                              ? "JPG" :
                                               properties?.file_type === "image/png"
                                              ? "PNG"
                                              : properties?.file_type === "application/pdf"
                                                ? "PDF"
                                                : "-"}
  {(() => {
                                            const documentProperties = properties; // Here we assign properties to a variable

                                            return (
                                              <Dropdown
                                                overlay={
                                                  <Menu onClick={(e) => handleMenuClick({ ...e, properties: documentProperties })}>
                                                    <Menu.Item key="edit">
                                                      <div className="icon_content">
                                                        <div>
                                                          <img
                                                            src={Editbutton}
                                                            alt="Edit"
                                                            className="action_icons_button"
                                                          />
                                                        </div>
                                                        <div className="action_button_name">Edit</div>
                                                      </div>
                                                    </Menu.Item>
                                                    <Menu.Item key="shear">
                                                      <div className="icon_content">
                                                        <div>
                                                          <img
                                                            src={shear}
                                                            alt="Share"
                                                            className="action_icons_button"
                                                          />
                                                        </div>
                                                        <div className="action_button_name">Share</div>
                                                      </div>
                                                    </Menu.Item>
                                                    <Menu.Item key="delete">
                                                      <div className="icon_content">
                                                        <div>
                                                          <img
                                                            src={deletebutton}
                                                            alt="Delete"
                                                            className="action_icons_button"
                                                          />
                                                        </div>
                                                        <div className="action_button_name">Delete</div>
                                                      </div>
                                                    </Menu.Item>
                                                  </Menu>
                                                }
                                                trigger={["click"]}
                                                placement="bottomLeft"
                                              >
                                                <AiOutlineMore className={
            properties?.file_type === "image/jpeg" ? "jpeg-more-icon" : "more-icon"
          }  />
                                              </Dropdown>
                                            );
                                          })()}

                                        </div>
                                      </div>
                                    );
                                  } else {
                                    return "";
                                  }
                                })
                              )
                              : "-"}
                          </div>
                        )}
                      </td>
                    ))}
                </tr>

                <tr className="tab_form_gray_highlighted_row">
                  <td className="tab_form_left_heading" style={{ height: "100px" }}>Income</td>
                  {documentData &&
                    documentData?.map((person, index) => (
                      <td key={index} className="tab_form_main_data_table">
                        {Headings.includes("Property") && (
                          <div className="main_card_first_box">
                            {person?.document_details?.length > 0
                              ? person?.document_details?.map(
                                (properties, propIndex) =>
                                  properties.document_category?.map((category, catIndex) => {
                                    const hasIncome = category.display_name;
                                    if (
                                      hasIncome === "Income" &&
                                      properties.file_type === "application/pdf"
                                    ) {
                                      return (
                                        <div className="customer_document_main_container">
                                          <div className="customer_document_main_content">
                                            <div className="customer_document_image">
                                              <img
                                                className="customer_document_file_image"
                                                src={pdf_image}
                                                alt="PDF"
                                                height={65}
                                                width={65}
                                                onClick={() =>
                                                  window.open(
                                                    properties.document_path,
                                                    "_blank"
                                                  )
                                                }
                                              />
                                            </div>
                                            <div className="customer_file_documnet_type_name">
                                              <div>{
                                                category.document_type
                                                  ?.display_name
                                              }</div>
                                              <div className="customer_document_file_size">
                                                <div className="customer_document_file_number">{
                                                  properties?.document_number
                                                }
                                                </div>
                                                <div className="dot"></div>
                                                {
                                                  (properties?.file_size ? (properties.file_size / (1024 * 1024)).toFixed(2) : 0)
                                                } MB</div>
                                            </div>
                                          </div>
                                          <div
                                         className={`customer_document_file_extention ${(properties?.file_type === "image/jpeg" || properties?.file_type === "image/jpg"  || properties?.file_type === "image/png")
                                              ? "jpeg"
                                              : properties?.file_type === "application/pdf"
                                                ? "pdf"
                                                : ""
                                              }`}
                                          >
                                           {properties?.file_type === "image/jpeg"
                                              ? "JPEG" : 
                                              properties?.file_type === "image/jpg"
                                              ? "JPG" :
                                               properties?.file_type === "image/png"
                                              ? "PNG"
                                              : properties?.file_type === "application/pdf"
                                                ? "PDF"
                                                : "-"}
{(() => {
                                            const documentProperties = properties; 

                                            return (
                                              <Dropdown
                                                overlay={
                                                  <Menu onClick={(e) => handleMenuClick({ ...e, properties: documentProperties })}>
                                                    <Menu.Item key="edit">
                                                      <div className="icon_content">
                                                        <div>
                                                          <img
                                                            src={Editbutton}
                                                            alt="Edit"
                                                            className="action_icons_button"
                                                          />
                                                        </div>
                                                        <div className="action_button_name">Edit</div>
                                                      </div>
                                                    </Menu.Item>
                                                    <Menu.Item key="shear">
                                                      <div className="icon_content">
                                                        <div>
                                                          <img
                                                            src={shear}
                                                            alt="Share"
                                                            className="action_icons_button"
                                                          />
                                                        </div>
                                                        <div className="action_button_name">Share</div>
                                                      </div>
                                                    </Menu.Item>
                                                    <Menu.Item key="delete">
                                                      <div className="icon_content">
                                                        <div>
                                                          <img
                                                            src={deletebutton}
                                                            alt="Delete"
                                                            className="action_icons_button"
                                                          />
                                                        </div>
                                                        <div className="action_button_name">Delete</div>
                                                      </div>
                                                    </Menu.Item>
                                                  </Menu>
                                                }
                                                trigger={["click"]}
                                                placement="bottomLeft"
                                              >
                                                <AiOutlineMore className={
            properties?.file_type === "image/jpeg" ? "jpeg-more-icon" : "more-icon"
          }  />
                                              </Dropdown>
                                            );
                                          })()}

                                          </div>
                                        </div>
                                      );
                                    } else if (
                                      hasIncome === "Income" &&
                                      (properties.file_type ===
                                        "application/vnd.ms-excel" ||
                                        properties.file_type ===
                                        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
                                        properties.file_type ===
                                        "application/octet-stream" ||
                                        properties.file_type === "text/csv")
                                    ) {
                                      return (
                                        <div key={propIndex} className="customer_document_main_container" >
                                          <div className="customer_document_main_content">
                                            <div className="customer_document_image">
                                              <img
                                                className="customer_document_file_image"
                                                src={csvimage}
                                                alt="CSV"
                                                height={64}
                                                width={64}
                                                onClick={() =>
                                                  window.open(
                                                    properties.document_path,
                                                    "_blank"
                                                  )
                                                }

                                              />
                                            </div>
                                            <div className="customer_file_documnet_type_name">
                                              <div>{
                                                category.document_type
                                                  ?.display_name
                                              }</div>
                                              <div className="customer_document_file_size">
                                                <div className="customer_document_file_number">{
                                                  properties?.document_number
                                                }
                                                </div>
                                                <div className="dot"></div>
                                                {
                                                  (properties?.file_size ? (properties.file_size / (1024 * 1024)).toFixed(2) : 0)
                                                } MB</div>
                                            </div>
                                          </div>
                                          <div
                                         className={`customer_document_file_extention ${(properties?.file_type === "image/jpeg" || properties?.file_type === "image/jpg"  || properties?.file_type === "image/png")
                                              ? "jpeg"
                                              : properties?.file_type === "application/pdf"
                                                ? "pdf"
                                                : ""
                                              }`}
                                          >
                                            {properties?.file_type === "image/jpeg"
                                              ? "JPEG" : 
                                              properties?.file_type === "image/jpg"
                                              ? "JPG" :
                                               properties?.file_type === "image/png"
                                              ? "PNG"
                                              : properties?.file_type === "application/pdf"
                                                ? "PDF"
                                                : "-"}
                                   {(() => {
                                            const documentProperties = properties;  

                                            return (
                                              <Dropdown
                                                overlay={
                                                  <Menu onClick={(e) => handleMenuClick({ ...e, properties: documentProperties })}>
                                                    <Menu.Item key="edit">
                                                      <div className="icon_content">
                                                        <div>
                                                          <img
                                                            src={Editbutton}
                                                            alt="Edit"
                                                            className="action_icons_button"
                                                          />
                                                        </div>
                                                        <div className="action_button_name">Edit</div>
                                                      </div>
                                                    </Menu.Item>
                                                    <Menu.Item key="shear">
                                                      <div className="icon_content">
                                                        <div>
                                                          <img
                                                            src={shear}
                                                            alt="Share"
                                                            className="action_icons_button"
                                                          />
                                                        </div>
                                                        <div className="action_button_name">Share</div>
                                                      </div>
                                                    </Menu.Item>
                                                    <Menu.Item key="delete">
                                                      <div className="icon_content">
                                                        <div>
                                                          <img
                                                            src={deletebutton}
                                                            alt="Delete"
                                                            className="action_icons_button"
                                                          />
                                                        </div>
                                                        <div className="action_button_name">Delete</div>
                                                      </div>
                                                    </Menu.Item>
                                                  </Menu>
                                                }
                                                trigger={["click"]}
                                                placement="bottomLeft"
                                              >
                                                <AiOutlineMore className={
            properties?.file_type === "image/jpeg" ? "jpeg-more-icon" : "more-icon"
          }  />
                                              </Dropdown>
                                            );
                                          })()}

                                          </div>
                                        </div>
                                      );
                                    } else if (
                                      hasIncome === "Income" &&
                                      (properties.file_type === "image/jpeg" ||
                                        properties.file_type === "image/jpg" ||
                                        properties.file_type === "image/png")
                                    ) {
                                      return (
                                        <div className="customer_document_main_container">
                                          <div className="customer_document_main_content">
                                            <div className="customer_document_image">
                                              <RViewerJS
                                                options={{
                                                  url: (image) => image.getAttribute("data-original"),
                                                }}
                                              >
                                                <img
                                                  className="customer_document_file_image"
                                                  src={
                                                    properties.thumb_document_path ||
                                                    dummy_img
                                                  }
                                                  data-original={
                                                    properties.document_path ||
                                                    dummy_img
                                                  }
                                                  alt="Document"
                                                  onError={handleImageError}
                                                />
                                              </RViewerJS></div>
                                            <div className="customer_file_documnet_type_name">
                                              <div>
                                                {
                                                  category.document_type
                                                    ?.display_name
                                                }</div>
                                              <div className="customer_document_file_size">
                                                <div className="customer_document_file_number">{
                                                  properties?.document_number
                                                }
                                                </div>
                                                <div className="dot"></div>
                                                {
                                                  (properties?.file_size ? (properties.file_size / (1024 * 1024)).toFixed(2) : 0)
                                                } MB</div>
                                            </div>

                                          </div>
                                          <div
                                         className={`customer_document_file_extention ${(properties?.file_type === "image/jpeg" || properties?.file_type === "image/jpg"  || properties?.file_type === "image/png")
                                              ? "jpeg"
                                              : properties?.file_type === "application/pdf"
                                                ? "pdf"
                                                : ""
                                              }`}
                                          >
                                           {properties?.file_type === "image/jpeg"
                                              ? "JPEG" : 
                                              properties?.file_type === "image/jpg"
                                              ? "JPG" :
                                               properties?.file_type === "image/png"
                                              ? "PNG"
                                              : properties?.file_type === "application/pdf"
                                                ? "PDF"
                                                : "-"}
                                         {(() => {
                                            const documentProperties = properties;  

                                            return (
                                              <Dropdown
                                                overlay={
                                                  <Menu onClick={(e) => handleMenuClick({ ...e, properties: documentProperties })}>
                                                    <Menu.Item key="edit">
                                                      <div className="icon_content">
                                                        <div>
                                                          <img
                                                            src={Editbutton}
                                                            alt="Edit"
                                                            className="action_icons_button"
                                                          />
                                                        </div>
                                                        <div className="action_button_name">Edit</div>
                                                      </div>
                                                    </Menu.Item>
                                                    <Menu.Item key="shear">
                                                      <div className="icon_content">
                                                        <div>
                                                          <img
                                                            src={shear}
                                                            alt="Share"
                                                            className="action_icons_button"
                                                          />
                                                        </div>
                                                        <div className="action_button_name">Share</div>
                                                      </div>
                                                    </Menu.Item>
                                                    <Menu.Item key="delete">
                                                      <div className="icon_content">
                                                        <div>
                                                          <img
                                                            src={deletebutton}
                                                            alt="Delete"
                                                            className="action_icons_button"
                                                          />
                                                        </div>
                                                        <div className="action_button_name">Delete</div>
                                                      </div>
                                                    </Menu.Item>
                                                  </Menu>
                                                }
                                                trigger={["click"]}
                                                placement="bottomLeft"
                                              >
                                                <AiOutlineMore className={
            properties?.file_type === "image/jpeg" ? "jpeg-more-icon" : "more-icon"
          }  />
                                              </Dropdown>
                                            );
                                          })()}
                                          </div>
                                        </div>
                                      );
                                    } else if (
                                      hasIncome === "Income" &&
                                      properties.file_type === "text/plain"
                                    ) {
                                      return (
                                        <div className="customer_document_main_container">
                                          <div className="customer_document_main_content">
                                            <div className="customer_document_image">
                                              <FaFile
                                                size={64}
                                                color="red"
                                                onClick={() =>
                                                  window.open(
                                                    properties.document_path,
                                                    "_blank"
                                                  )
                                                }
                                                className="customer_document_file_image"
                                              />
                                            </div>
                                            <div className="customer_file_documnet_type_name">
                                              <div>{
                                                category.document_type
                                                  ?.display_name
                                              }</div>
                                              <div className="customer_document_file_size">
                                                <div className="customer_document_file_number">{
                                                  properties?.document_number
                                                }
                                                </div>
                                                <div className="dot"></div>
                                                {
                                                  (properties?.file_size ? (properties.file_size / (1024 * 1024)).toFixed(2) : 0)
                                                } MB</div>
                                            </div>

                                          </div>
                                          <div
                                         className={`customer_document_file_extention ${(properties?.file_type === "image/jpeg" || properties?.file_type === "image/jpg"  || properties?.file_type === "image/png")
                                              ? "jpeg"
                                              : properties?.file_type === "application/pdf"
                                                ? "pdf"
                                                : ""
                                              }`}
                                          >
                                          
                                            {properties?.file_type === "image/jpeg"
                                              ? "JPEG" : 
                                              properties?.file_type === "image/jpg"
                                              ? "JPG" :
                                               properties?.file_type === "image/png"
                                              ? "PNG"
                                              : properties?.file_type === "application/pdf"
                                                ? "PDFS"
                                                : "-"}
                                       {(() => {
                                            const documentProperties = properties; // Here we assign properties to a variable

                                            return (
                                              <Dropdown
                                                overlay={
                                                  <Menu onClick={(e) => handleMenuClick({ ...e, properties: documentProperties })}>
                                                    <Menu.Item key="edit">
                                                      <div className="icon_content">
                                                        <div>
                                                          <img
                                                            src={Editbutton}
                                                            alt="Edit"
                                                            className="action_icons_button"
                                                          />
                                                        </div>
                                                        <div className="action_button_name">Edit</div>
                                                      </div>
                                                    </Menu.Item>
                                                    <Menu.Item key="shear">
                                                      <div className="icon_content">
                                                        <div>
                                                          <img
                                                            src={shear}
                                                            alt="Share"
                                                            className="action_icons_button"
                                                          />
                                                        </div>
                                                        <div className="action_button_name">Share</div>
                                                      </div>
                                                    </Menu.Item>
                                                    <Menu.Item key="delete">
                                                      <div className="icon_content">
                                                        <div>
                                                          <img
                                                            src={deletebutton}
                                                            alt="Delete"
                                                            className="action_icons_button"
                                                          />
                                                        </div>
                                                        <div className="action_button_name">Delete</div>
                                                      </div>
                                                    </Menu.Item>
                                                  </Menu>
                                                }
                                                trigger={["click"]}
                                                placement="bottomLeft"
                                              >
                                                <AiOutlineMore className={
            properties?.file_type === "image/jpeg" ? "jpeg-more-icon" : "more-icon"
          }  />
                                              </Dropdown>
                                            );
                                          })()}
                                          </div>
                                        </div>
                                      );
                                    } else {
                                      return "";
                                    }
                                  })
                              )
                              : "-"}
                          </div>
                        )}
                      </td>
                    ))}
                </tr>

                <tr className="tab_form_gray_highlighted_row">
                  <td className="tab_form_left_heading"  style={{ height: "100px" }}>Other Documents</td>
                  {documentData &&
                    documentData?.map((person, index) => (
                      <td key={index} className="tab_form_main_data_table">
                        {Headings.includes("Property") && (
                          <div className="main_card_first_box">
                            {person?.document_details?.length > 0
                              ? person?.document_details?.map(
                                (properties, propIndex) =>
                                  properties.document_category?.map((category, catIndex) => {
                                    const hasUnother = category.display_name;
                                    if (
                                      hasUnother !== "Income" &&
                                      hasUnother !==
                                      "Financial" &&
                                      hasUnother !== "KYC" &&
                                      properties.file_type === "application/pdf"
                                    ) {
                                      return (
                                        <div className="customer_document_main_container">
                                          <div className="customer_document_main_content">
                                            <div className="customer_document_image">
                                              <img
                                                className="customer_document_file_image"
                                                src={pdf_image}
                                                alt="PDF"
                                                height={65}
                                                width={65}
                                                onClick={() =>
                                                  window.open(
                                                    properties.document_path,
                                                    "_blank"
                                                  )
                                                }
                                              />
                                            </div>
                                            <div className="customer_file_documnet_type_name">
                                              <div>{
                                                category.document_type
                                                  ?.display_name
                                              }</div>
                                              <div className="customer_document_file_size">
                                                <div className="customer_document_file_number">{
                                                  properties?.document_number
                                                }
                                                </div>
                                                <div className="dot"></div>
                                                {
                                                  (properties?.file_size ? (properties.file_size / (1024 * 1024)).toFixed(2) : 0)
                                                } MB</div>
                                            </div>

                                          </div>
                                          <div
                                         className={`customer_document_file_extention ${(properties?.file_type === "image/jpeg" || properties?.file_type === "image/jpg"  || properties?.file_type === "image/png")
                                              ? "jpeg"
                                              : properties?.file_type === "application/pdf"
                                                ? "pdf"
                                                : ""
                                              }`}
                                          >
                                            {properties?.file_type === "image/jpeg"
                                              ? "JPEG" : 
                                              properties?.file_type === "image/jpg"
                                              ? "JPG" :
                                               properties?.file_type === "image/png"
                                              ? "PNG"
                                              : properties?.file_type === "application/pdf"
                                                ? "PDF"
                                                : "-"}
                                       {(() => {
                                            const documentProperties = properties;  

                                            return (
                                              <Dropdown
                                                overlay={
                                                  <Menu onClick={(e) => handleMenuClick({ ...e, properties: documentProperties })}>
                                                    <Menu.Item key="edit">
                                                      <div className="icon_content">
                                                        <div>
                                                          <img
                                                            src={Editbutton}
                                                            alt="Edit"
                                                            className="action_icons_button"
                                                          />
                                                        </div>
                                                        <div className="action_button_name">Edit</div>
                                                      </div>
                                                    </Menu.Item>
                                                    <Menu.Item key="shear">
                                                      <div className="icon_content">
                                                        <div>
                                                          <img
                                                            src={shear}
                                                            alt="Share"
                                                            className="action_icons_button"
                                                          />
                                                        </div>
                                                        <div className="action_button_name">Share</div>
                                                      </div>
                                                    </Menu.Item>
                                                    <Menu.Item key="delete">
                                                      <div className="icon_content">
                                                        <div>
                                                          <img
                                                            src={deletebutton}
                                                            alt="Delete"
                                                            className="action_icons_button"
                                                          />
                                                        </div>
                                                        <div className="action_button_name">Delete</div>
                                                      </div>
                                                    </Menu.Item>
                                                  </Menu>
                                                }
                                                trigger={["click"]}
                                                placement="bottomLeft"
                                              >
                                                <AiOutlineMore className={
            properties?.file_type === "image/jpeg" ? "jpeg-more-icon" : "more-icon"
          }  />
                                              </Dropdown>
                                            );
                                          })()}
                                          </div>
                                        </div>
                                      );
                                    } else if (
                                      hasUnother !== "Income" &&
                                      hasUnother !==
                                      "Financial" &&
                                      hasUnother !== "KYC" &&
                                      (properties.file_type ===
                                        "application/vnd.ms-excel" ||
                                        properties.file_type ===
                                        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
                                        properties.file_type ===
                                        "application/octet-stream" ||
                                        properties.file_type === "text/csv")
                                    ) {
                                      return (
                                        <div className="customer_document_main_container">
                                          <div className="customer_document_main_content">
                                            <div className="customer_document_image">
                                              <img
                                                className="customer_document_file_image"
                                                src={csvimage}
                                                alt="CSV"
                                                height={64}
                                                width={64}
                                                onClick={() =>
                                                  window.open(
                                                    properties.document_path,
                                                    "_blank"
                                                  )
                                                }
                                              />
                                            </div>
                                            <div className="customer_file_documnet_type_name">
                                              <div>{
                                                category.document_type
                                                  ?.display_name
                                              }</div>
                                              <div className="customer_document_file_size">
                                                <div className="customer_document_file_number">{
                                                  properties?.document_number
                                                }
                                                </div>
                                                <div className="dot"></div>
                                                {
                                                  (properties?.file_size ? (properties.file_size / (1024 * 1024)).toFixed(2) : 0)
                                                } MB</div>
                                            </div>

                                          </div>
                                          <div
                                         className={`customer_document_file_extention ${(properties?.file_type === "image/jpeg" || properties?.file_type === "image/jpg"  || properties?.file_type === "image/png")
                                              ? "jpeg"
                                              : properties?.file_type === "application/pdf"
                                                ? "pdf"
                                                : ""
                                              }`}
                                          >
                                             {properties?.file_type === "image/jpeg"
                                              ? "JPEG" : 
                                              properties?.file_type === "image/jpg"
                                              ? "JPG" :
                                               properties?.file_type === "image/png"
                                              ? "PNG"
                                              : properties?.file_type === "application/pdf"
                                                ? "PDF"
                                                : "-"}
                                                                            {(() => {
                                            const documentProperties = properties; 

                                            return (
                                              <Dropdown
                                                overlay={
                                                  <Menu onClick={(e) => handleMenuClick({ ...e, properties: documentProperties })}>
                                                    <Menu.Item key="edit">
                                                      <div className="icon_content">
                                                        <div>
                                                          <img
                                                            src={Editbutton}
                                                            alt="Edit"
                                                            className="action_icons_button"
                                                          />
                                                        </div>
                                                        <div className="action_button_name">Edit</div>
                                                      </div>
                                                    </Menu.Item>
                                                    <Menu.Item key="shear">
                                                      <div className="icon_content">
                                                        <div>
                                                          <img
                                                            src={shear}
                                                            alt="Share"
                                                            className="action_icons_button"
                                                          />
                                                        </div>
                                                        <div className="action_button_name">Share</div>
                                                      </div>
                                                    </Menu.Item>
                                                    <Menu.Item key="delete">
                                                      <div className="icon_content">
                                                        <div>
                                                          <img
                                                            src={deletebutton}
                                                            alt="Delete"
                                                            className="action_icons_button"
                                                          />
                                                        </div>
                                                        <div className="action_button_name">Delete</div>
                                                      </div>
                                                    </Menu.Item>
                                                  </Menu>
                                                }
                                                trigger={["click"]}
                                                placement="bottomLeft"
                                              >
                                                <AiOutlineMore className={
            properties?.file_type === "image/jpeg" ? "jpeg-more-icon" : "more-icon"
          }  />
                                              </Dropdown>
                                            );
                                          })()}
                                          </div>
                                        </div>
                                      );
                                    } else if (
                                      hasUnother !== "Income" &&
                                      hasUnother !==
                                      "Financial" &&
                                      hasUnother !== "KYC" &&
                                      (properties.file_type === "image/jpeg" ||
                                        properties.file_type === "image/jpg" ||
                                        properties.file_type === "image/png")
                                    ) {
                                      return (
                                        <div key={propIndex}
                                          className="customer_document_main_container">
                                          <div className="customer_document_main_content">
                                            <div className="customer_document_image">
                                              <RViewerJS
                                                options={{
                                                  url: (image) => image.getAttribute("data-original"),
                                                }}
                                              >
                                                <img
                                                  className="customer_document_file_image"
                                                  src={
                                                    properties.thumb_document_path ||
                                                    dummy_img
                                                  }
                                                  data-original={
                                                    properties.document_path ||
                                                    dummy_img
                                                  }
                                                  alt="Document"
                                                  height={64}
                                                  width={64}
                                                  onError={handleImageError}
                                                />

                                              </RViewerJS></div>
                                            <div className="customer_file_documnet_type_name">
                                              <div>{
                                                category.document_type
                                                  ?.display_name
                                              }</div>
                                              <div className="customer_document_file_size">
                                                <div className="customer_document_file_number">{
                                                  properties?.document_number
                                                }
                                                </div>
                                                <div className="dot"></div>
                                                {
                                                  (properties?.file_size ? (properties.file_size / (1024 * 1024)).toFixed(2) : 0)
                                                } MB</div>
                                            </div>

                                          </div>
                                          <div
                                         className={`customer_document_file_extention ${(properties?.file_type === "image/jpeg" || properties?.file_type === "image/jpg"  || properties?.file_type === "image/png")
                                              ? "jpeg"
                                              : properties?.file_type === "application/pdf"
                                                ? "pdf"
                                                : ""
                                              }`}
                                          >
                                          {properties?.file_type === "image/jpeg"
                                              ? "JPEG" : 
                                              properties?.file_type === "image/jpg"
                                              ? "JPG" :
                                               properties?.file_type === "image/png"
                                              ? "PNG"
                                              : properties?.file_type === "application/pdf"
                                                ? "PDF"
                                                : "-"}
                                       {(() => {
                                            const documentProperties = properties; // Here we assign properties to a variable

                                            return (
                                              <Dropdown
                                                overlay={
                                                  <Menu onClick={(e) => handleMenuClick({ ...e, properties: documentProperties })}>
                                                    <Menu.Item key="edit">
                                                      <div className="icon_content">
                                                        <div>
                                                          <img
                                                            src={Editbutton}
                                                            alt="Edit"
                                                            className="action_icons_button"
                                                          />
                                                        </div>
                                                        <div className="action_button_name">Edit</div>
                                                      </div>
                                                    </Menu.Item>
                                                    <Menu.Item key="shear">
                                                      <div className="icon_content">
                                                        <div>
                                                          <img
                                                            src={shear}
                                                            alt="Share"
                                                            className="action_icons_button"
                                                          />
                                                        </div>
                                                        <div className="action_button_name">Share</div>
                                                      </div>
                                                    </Menu.Item>
                                                    <Menu.Item key="delete">
                                                      <div className="icon_content">
                                                        <div>
                                                          <img
                                                            src={deletebutton}
                                                            alt="Delete"
                                                            className="action_icons_button"
                                                          />
                                                        </div>
                                                        <div className="action_button_name">Delete</div>
                                                      </div>
                                                    </Menu.Item>
                                                  </Menu>
                                                }
                                                trigger={["click"]}
                                                placement="bottomLeft"
                                              >
                                                <AiOutlineMore className={
            properties?.file_type === "image/jpeg" ? "jpeg-more-icon" : "more-icon"
          }  />
                                              </Dropdown>
                                            );
                                          })()}
                                          </div>
                                        </div>
                                      );
                                    } else if (
                                      hasUnother !== "Income" &&
                                      hasUnother !==
                                      "Financial" &&
                                      hasUnother !== "KYC" &&
                                      properties.file_type === "text/plain"
                                    ) {
                                      return (
                                        <div className="customer_document_main_container">
                                          <div className="customer_document_main_content">
                                            <div className="customer_document_image">
                                              <FaFile
                                                size={64}
                                                color="red"
                                                onClick={() =>
                                                  window.open(
                                                    properties.document_path,
                                                    "_blank"
                                                  )
                                                }
                                                className="customer_document_file_image"
                                              />
                                            </div>
                                            <div className="customer_file_documnet_type_name">
                                              <div>{
                                                category.document_type
                                                  ?.display_name
                                              }</div>
                                              <div className="customer_document_file_size">
                                                <div className="customer_document_file_number">{
                                                  properties?.document_number
                                                }
                                                </div>
                                                <div className="dot"></div>
                                                {
                                                  (properties?.file_size ? (properties.file_size / (1024 * 1024)).toFixed(2) : 0)
                                                } MB</div>
                                            </div>

                                          </div>
                                          <div
                                         className={`customer_document_file_extention ${(properties?.file_type === "image/jpeg" || properties?.file_type === "image/jpg"  || properties?.file_type === "image/png")
                                              ? "jpeg"
                                              : properties?.file_type === "application/pdf"
                                                ? "pdf"
                                                : ""
                                              }`}
                                          >
                                            {properties?.file_type === "image/jpeg"
                                              ? "JPEG" : 
                                              properties?.file_type === "image/jpg"
                                              ? "JPG" :
                                               properties?.file_type === "image/png"
                                              ? "PNG"
                                              : properties?.file_type === "application/pdf"
                                                ? "PDF"
                                                : "-"}
                                                                               {(() => {
                                            const documentProperties = properties; // Here we assign properties to a variable

                                            return (
                                              <Dropdown
                                                overlay={
                                                  <Menu onClick={(e) => handleMenuClick({ ...e, properties: documentProperties })}>
                                                    <Menu.Item key="edit">
                                                      <div className="icon_content">
                                                        <div>
                                                          <img
                                                            src={Editbutton}
                                                            alt="Edit"
                                                            className="action_icons_button"
                                                          />
                                                        </div>
                                                        <div className="action_button_name">Edit</div>
                                                      </div>
                                                    </Menu.Item>
                                                    <Menu.Item key="shear">
                                                      <div className="icon_content">
                                                        <div>
                                                          <img
                                                            src={shear}
                                                            alt="Share"
                                                            className="action_icons_button"
                                                          />
                                                        </div>
                                                        <div className="action_button_name">Share</div>
                                                      </div>
                                                    </Menu.Item>
                                                    <Menu.Item key="delete">
                                                      <div className="icon_content">
                                                        <div>
                                                          <img
                                                            src={deletebutton}
                                                            alt="Delete"
                                                            className="action_icons_button"
                                                          />
                                                        </div>
                                                        <div className="action_button_name">Delete</div>
                                                      </div>
                                                    </Menu.Item>
                                                  </Menu>
                                                }
                                                trigger={["click"]}
                                                placement="bottomLeft"
                                              >
                                                <AiOutlineMore className={
            properties?.file_type === "image/jpeg" ? "jpeg-more-icon" : "more-icon"
          }  />
                                              </Dropdown>
                                            );
                                          })()}
                                          </div>
                                        </div>
                                      );
                                    } else {
                                      return "";
                                    }
                                  })
                              )
                              : "-"}
                          </div>
                        )}
                      </td>
                    ))}
                </tr>
            </tbody>
            </table>) : (
              (
            <div className='fade-in'>
              <div>
                <table className='tab_form_main_table no_data_table_height'>
                  <thead>
                    <tr>
                      <th
                        className="tab_form_key_parameters"
                      >
                        Key Parameter's
                      </th>
                      {documentData?.map((person, index) => (
                        <th
                          key={index}
                          className="tab_form_top_heading no_data_heading_bottom_border"
                        >
                          <span className='no_data_heading_content'>
                            <div>
                              <span>{`${person?.customer_name}`} </span>
                              <span>
                                <span>{`(${person?.customer_type})`} </span>

                              </span>
                            </div>

                          </span>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                 
                    {documentData?.length ? (
                      <tr>
                        <td colSpan={documentData?.length + 1}> 
                        </td>
                      </tr>
                    ) : (
                      <tr>
                        <td
                          colSpan={documentData?.length + 1}
                        ></td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              <div
                className='no_data_icon'
              >
                <CustomNoData />
              </div>
            </div>
          ))} 
        </div>
        <Modal
        title="Confirm Delete"
        open={openModal}
        onOk={() => {
          handleDelete(payload);
          setOpenModal(false); 
        }}
        onCancel={() => {
          setOpenModal(false); 
        }}
        okType="danger"
      >
        <p>{`Are you sure you want to delete this file?`}</p>
      </Modal>
      </div>
    </>
  );
};

export default DocumentDetails;
