import { axiosRequest } from "../../../utils/api/axiosRequest";

export const listAllDocumentCategoryService = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.get(`/api/v1/master/document-category/list`);
        return resolve(response.data);
      } catch (error) {
        return reject(error);
      }
    });
  };

  export const listDocumentType  = (params = {}) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.get(`/api/v1/master/document-type/list`, {
          params: {
            property_doc_cat_code: true, // Adding the query param
            ...params,
          },
        });
        return resolve(response.data);
      } catch (error) {
        return reject(error);
      }
    });
  };

  export const listKycCategory  = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.get(`/api/v1/master/document-category/kyc-category/`);
        return resolve(response.data);
      } catch (error) {
        return reject(error);
      }
    });
  };

  export const listKycCategoryType  = (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.get(`/api/v1/master/document-category/kyc-category-type/${id}`);
        return resolve(response.data);
      } catch (error) {
        return reject(error);
      }
    });
  };

  export const postDocumentCheck  = (data,instance) => {
    return new Promise(async (resolve, reject) => {
      const newPayload = {
        ...data,
        document_category: "KYC91208",
      }
      try {
        const response = await axiosRequest.post(`/api/v1/customer/combine-sales-form/sales-form-document-upload/?ocr=true&new_instance=${instance}`, newPayload);
        return resolve(response.data);
      } catch (error) {
        return reject(error);
      }
    });
  };

  export const postDocumentSubmit  = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.post(`/api/v1/customer/combine-sales-form/sales-form-document-upload/?ocr-response=true`, data);
        return resolve(response.data);
      } catch (error) {
        return reject(error);
      }
    });
  };

  export const patchDocumentSubmit  = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.patch(`/api/v1/customer/combine-sales-form/`, data);
        return resolve(response.data);
      } catch (error) {
        return reject(error);
      }
    });
  };

  export const postOtherDocumentSubmit  = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.post(`/api/v1/customer/combine-sales-form/sales-form-document-upload/`, data);
        return resolve(response.data);
      } catch (error) {
        return reject(error);
      }
    });
  };

  

  export const getSubmittedDocuments  = (customer_detail) => {
    return new Promise(async (resolve, reject) => {
      try {
        // const response = await axiosRequest.get(`/api/v1/customer/combine-sales-form/get-all/${customer_detail}`);
        const response = await axiosRequest.get(`api/v1/customer/combine-sales-form/get-sales-documents/${customer_detail}`); 
        return resolve(response.data);
      } catch (error) {
        return reject(error);
      }
    });
  };

  export const getVerifiedDocuments  = (customer_detail) => {
    return new Promise(async (resolve, reject) => {
      try {
        // const response = await axiosRequest.get(`/api/v1/customer/combine-sales-form/get-all/${customer_detail}`);
        const response = await axiosRequest.get(`api/v1/customer/combine-sales-form/sales-documents-verify/${customer_detail}`); 
        return resolve(response.data);
      } catch (error) {
        return reject(error);
      }
    });
  };


  export const submitVerifiedDocuments  = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        // const response = await axiosRequest.get(`/api/v1/customer/combine-sales-form/get-all/${customer_detail}`);
        const response = await axiosRequest.post(`api/v1/customer/combine-sales-form/document-verification/`, data); 
        return resolve(response.data);
      } catch (error) {
        return reject(error);
      }
    });
  };

  export const getPersonalDetails  = (customerid) =>  {
    return new Promise(async (resolve, reject) => {
      try {
        // const response = await axiosRequest.get(`/api/v1/customer/combine-sales-form/get-all/${customer_detail}`);
        const response = await axiosRequest.get(`api/v1/customer/customer-detail/${customerid}`); 
        return resolve(response.data);
      } catch (error) {
        return reject(error);
      }
    });
  };
 
  export const getCibilDetails  = (data) =>  {
    return new Promise(async (resolve, reject) => {
      try { 
        const response = await axiosRequest.post(`/api/v1/bureau/hit-bureau-report-by-customer-id`, data); 
        return resolve(response.data);
      } catch (error) {
        return reject(error);
      }
    });
  };

  export const getHighmarkDetails  = (data) =>  {
    return new Promise(async (resolve, reject) => {
      try { 
        const response = await axiosRequest.post(`api/v1/bureau/hit-bureau-report-by-customer-id`, data); 
        return resolve(response.data);
      } catch (error) {
        return reject(error);
      }
    });
  };


  export const getSalesCustomerData  = (loan_id) =>  {
    return new Promise(async (resolve, reject) => {
      try { 
        const response = await axiosRequest.get(`/api/v1/customer/customer-detail/personal-detail-list/${loan_id}?get_inactive=True`); 
        return resolve(response.data);
      } catch (error) {
        return reject(error);
      }
    });
  };
  
  export const getAddressTabsData  = (customer_id) =>  {
    return new Promise(async (resolve, reject) => {
      try { 
        const response = await axiosRequest.get(`/api/v1/customer/customer-address-detail/address-detail/${customer_id}`); 
        return resolve(response.data);
      } catch (error) {
        return reject(error);
      }
    });
  };
  
  export const getEmploymentsTabsData  = (customer_id) =>  {
    return new Promise(async (resolve, reject) => {
      try { 
        const response = await axiosRequest.get(`/api/v1/customer/employment-detail/employment-detail-customer/${customer_id}`); 
        return resolve(response.data);
      } catch (error) {
        return reject(error);
      }
    });
  };

  export const getDependentsTabsData   = (customer_id) =>  {
    return new Promise(async (resolve, reject) => {
      try { 
        const response = await axiosRequest.get(`/api/v1/customer/dependent-details/sales-form/${customer_id}`); 
        return resolve(response.data);
      } catch (error) {
        return reject(error);
      }
    });
  };

  export const deleteDependentsTabsData  = (customer_id) =>  {
   return new Promise(async (resolve, reject) => {
      try { 
        const response = await axiosRequest.delete(`/api/v1/customer/dependent-details/${customer_id}`); 
        return resolve(response.data);
      } catch (error) {
        return reject(error);
      }
    });
  };

  
  export const getReferencesTabsData  = (customer_id) =>  {
    return new Promise(async (resolve, reject) => {
      try { 
        const response = await axiosRequest.get(`/api/v1/customer/reference-detail/sales-form/${customer_id}`); 
        return resolve(response.data);
      } catch (error) {
        return reject(error);
      }
    });
  };

  export const deleteReferencesTabsData  = (customer_id) =>  {
    return new Promise(async (resolve, reject) => {
      try { 
        const response = await axiosRequest.delete(`/api/v1/customer/reference-detail/${customer_id}`); 
        return resolve(response.data);
      } catch (error) {
        return reject(error);
      }
    });
  }; 
  
  export const getBankAccountTabsData  = (customer_id) =>  {
    return new Promise(async (resolve, reject) => {
      try { 
        const response = await axiosRequest.get(`/api/v1/customer/bank-account-detail/bank-detail-sales-form/${customer_id}`); 
        return resolve(response.data);
      } catch (error) {
        return reject(error);
      }
    });
  };

  export const deleteBankDetailsTabsData  = (customer_id) =>  {
    return new Promise(async (resolve, reject) => {
      try { 
        const response = await axiosRequest.delete(`/api/v1/customer/bank-account-detail/${customer_id}`); 
        return resolve(response.data);
      } catch (error) {
        return reject(error);
      }
    });
  };
 
  export const getInvestmentTabsData  = (customer_id) =>  {
    return new Promise(async (resolve, reject) => {
      try { 
        const response = await axiosRequest.get(`/api/v1/customer/investment-detail/sales-form/${customer_id}`); 
        return resolve(response.data);
      } catch (error) {
        return reject(error);
      }
    });
  };

  export const deleteInvestmentTabsData  = (customer_id) =>  {
    return new Promise(async (resolve, reject) => {
       try { 
         const response = await axiosRequest.delete(`/api/v1/customer/investment-detail/${customer_id}`); 
         return resolve(response.data);
       } catch (error) {
         return reject(error);
       }
     });
   };

  export const getEmployeementTabsData  = (customer_id) =>  {
    return new Promise(async (resolve, reject) => {
      try { 
        const response = await axiosRequest.get(`/api/v1/customer/existing-loan-detail/sales-from/${customer_id}`); 
        return resolve(response.data);
      } catch (error) {
        return reject(error);
      }
    });
  };

  export const deletePensionerDetailsTabsData  = (customer_id) =>  {
    return new Promise(async (resolve, reject) => {
      try { 
        const response = await axiosRequest.delete(`/api/v1/customer/pensioner/${customer_id}`); 
        return resolve(response.data);
      } catch (error) {
        return reject(error);
      }
    });
  };

  export const deleteSalariedTabsData  = (customer_id) =>  {
    return new Promise(async (resolve, reject) => {
      try { 
        const response = await axiosRequest.delete(`/api/v1/customer/salaried/${customer_id}`); 
        return resolve(response.data);
      } catch (error) {
        return reject(error);
      }
    });
  };

  export const deleteRentalTabsData  = (customer_id) =>  {
    return new Promise(async (resolve, reject) => {
      try { 
        const response = await axiosRequest.delete(`/api/v1/customer/rental/${customer_id}`); 
        return resolve(response.data);
      } catch (error) {
        return reject(error);
      }
    });
  };

  export const deleteSelfEmployedTabsData  = (customer_id) =>  {
    return new Promise(async (resolve, reject) => {
      try { 
        const response = await axiosRequest.delete(`/api/v1/customer/self-employed/${customer_id}`); 
        return resolve(response.data);
      } catch (error) {
        return reject(error);
      }
    });
  };

  export const deleteAddressTabsData  = (customer_id) =>  {
    return new Promise(async (resolve, reject) => {
      try { 
        const response = await axiosRequest.delete(`/api/v1/customer/customer-address-detail/${customer_id}`); 
        return resolve(response.data);
      } catch (error) {
        return reject(error);
      }
    });
  };

  export const deleteExistingTabsData  = (customer_id) =>  {
    return new Promise(async (resolve, reject) => {
       try { 
         const response = await axiosRequest.delete(`/api/v1/customer/existing-loan-detail/${customer_id}`); 
         return resolve(response.data);
       } catch (error) {
         return reject(error);
       }
     });
   };

   export const deletePartnerTabsData  = (customer_id) =>  {
    return new Promise(async (resolve, reject) => {
       try { 
         const response = await axiosRequest.delete(`/api/v1/customer/business-partner-detail/${customer_id}`); 
         return resolve(response.data);
       } catch (error) {
         return reject(error);
       }
     });
   };

   export const deleteCustomerData  = (customer_id) =>  {
    return new Promise(async (resolve, reject) => {
       try { 
         const response = await axiosRequest.delete(`/api/v1/customer/customer-detail/${customer_id}`); 
         return resolve(response.data);
       } catch (error) {
         return reject(error);
       }
     });
   };

   

  export const postConvertSalesForm  = (loan_id) =>  {
    return new Promise(async (resolve, reject) => {
      try { 
        const response = await axiosRequest.get(`/api/v1/loan/sales-form/${loan_id}`); 
        return resolve(response.data);
      } catch (error) {
        return reject(error);
      }
    });
  };

  export const listAllDocuments = () =>  {
    return new Promise(async (resolve, reject) => {
      try { 
        const response = await axiosRequest.get(`/api/v1/master/document-category/list`); 
        return resolve(response.data);
      } catch (error) {
        return reject(error);
      }
    });
  };


  export const listGenderService = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.get(`/api/v1/customer/choices/gender`);
        return resolve(response.data);
      } catch (error) {
        return reject(error);
      }
    });
  };


  export const postDocumentCheckSubmit  = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.post(`/api/v1/customer/combine-sales-form/sale-form-dummy-document-upload/`, data);
        return resolve(response.data);
      } catch (error) {
        return reject(error);
      }
    });
  };

  export const listRelationType = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.get(`/api/v1/customer/choices/relationship`);
        return resolve(response.data);
      } catch (error) {
        return reject(error);
      }
    });
  };

  export const listAddressType = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.get(`/api/v1/customer/choices/office-type`);
        return resolve(response.data);
      } catch (error) {
        return reject(error);
      }
    });
  };


  export const listBusinessContructionType = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.get(`/api/v1/customer/choices/business-constitution-type`);
        return resolve(response.data);
      } catch (error) {
        return reject(error);
      }
    });
  };

  export const listCustomerType  = (ids) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.get(`api/v1/master/customer-type/list?customer_id=${ids}`);
        return resolve(response.data);
      } catch (error) {
        return reject(error);
      }
    });
  };

  export const getPartnersTabsData   = (customer_id) =>  {
    return new Promise(async (resolve, reject) => {
      try { 
        const response = await axiosRequest.get(`/api/v1/customer/business-partner-detail/customer/${customer_id}`); 
        return resolve(response.data);
      } catch (error) {
        return reject(error);
      }
    });
  };