import React, { useState, useEffect } from "react";
import "../styles/loanInsurance.css";
import { Drawer, Modal, message } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import Header from "../../../../layout/views/Header";
import ICICIInsuranceForm from "./ICICIInsuranceForm";
import {
  getInsuranceDetailsByIdService,
  deleteICICIPrudential,
} from "../services/loanInsuranceService";
import pdf_image from "../assets/pdf.png";
import EditButton from "../../../../../utils/editButton/EditButton";
import RViewerJS from "viewerjs-react";
import "viewerjs-react/dist/index.css";
import { useSelector } from "react-redux";
import ConditionalRender from "../../../../../utils/authorization/AuthorizeComponent";

const ICICIInsuranceview = ({
  insurance_id,
  toggleRefreshInsurance,
  setAddressType,
  addressType,
}) => {
  const [openModal, setOpenModal] = useState(false);
  const [, setRefreshTableData] = useState(false);
  const [selectedLoanId, setSelectedLoanId] = useState(null);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [, setSelectedUser] = useState(null);
  const [data, setData] = useState([]);
  const [customer_id, setCustomer_id] = useState("");
  const [nominee_id, setNominee_id] = useState("");
  const [insurancecompanydetail, setInsurancecompanydetail] = useState("");
  const [imageData, setImagedata] = useState(null);

  const { user_data } = useSelector((state) => state.user);

  const showDrawer = () => {
    setOpenDrawer(true);
  };
  const onClose = () => {
    setOpenDrawer(false);
    setSelectedUser(null);
    setSelectedLoanId(null);
    toggleRefreshTableData();
  };

  const toggleRefreshTableData = () => {
    setRefreshTableData((prev) => !prev);
  };

  // const closeDrawers = () => {
  //   setOpen(false);
  // };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getInsuranceDetailsByIdService(insurance_id);
        setData(data.data);
        setImagedata(data.data.document);
        setCustomer_id(data.data.insured_customer_detail[0].customer_detail.id);
        setSelectedLoanId(data?.data?.loan_detail);
        setNominee_id(data.data.nominee_detail[0].id);
        setInsurancecompanydetail(data.data.insurance_company_detail.id);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [insurance_id, selectedLoanId, toggleRefreshInsurance]);

  const deleteUserById = async () => {
    try {
      const response = await deleteICICIPrudential(insurance_id);
      if (response.status === 200 && response.success === true) {
        message.success("ICICI deleted successfully");
        setOpenModal(false);
        toggleRefreshInsurance();
      }
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };

  const IndianNumberFormat = (value) => {
    return new Intl.NumberFormat("en-IN").format(value);
  };

  return (
    <div>
      <div className="icici_view_header">
        <div className="insurance_view_heading"></div>
        <div className="insurance_button_container">
          <ConditionalRender
            userPermissions={user_data?.permissions}
            requiredPermissions={["EDITINSR"]}
          >
            <div
              className="insurance-edit-button"
              onClick={() => {
                showDrawer();
                // setSelectedUserId(customer_id);
              }}
            >
              <EditButton />
            </div>
          </ConditionalRender>
          <Drawer
            title={
              <Header
                title={insurance_id ? "Edit" : "Add"}
                onClose={onClose}
                name="ICICI Prudential Insurance"
              />
            }
            width={1000}
            onClose={onClose}
            open={openDrawer}
            styles={{
              body: { paddingBottom: 80, paddingLeft: 0, paddingRight: 0 },
            }}
            closable={false}
          >
            <ICICIInsuranceForm
              toggleRefreshInsurance={toggleRefreshInsurance}
              id={customer_id}
              loan_id={selectedLoanId}
              open={openDrawer}
              // closeDrawers={closeDrawers}
              // setOpenDrawer={setOpenDrawer}
              closeForm={onClose}
              onCancel={onClose}
              setId={setSelectedLoanId}
              insurance_id={insurance_id}
              customer_id={customer_id}
              nominee_id={nominee_id}
              insurancecompanydetail={insurancecompanydetail}
              setAddressType={setAddressType}
              addressType={addressType}
            />
          </Drawer>
          <ConditionalRender
            userPermissions={user_data?.permissions}
            requiredPermissions={["DELTINSR"]}
          >
            <div
              className="insurance-delete-button"
              onClick={() => {
                setOpenModal(true);
              }}
            >
              <DeleteOutlined />
            </div>
          </ConditionalRender>
        </div>
      </div>

      <div className="icici_view_header">
        <div className="insurance_view_heading">Details Of Coverage</div>
      </div>

      <div className="loan_and_insurance_container">
        <div className="laon_insurance_Basic_card">
          <div className="loan_insurance_basic_card_content">
            <div className="loan_inusurance_label">Loan Amount</div>
            <div className="loan_inusurance_data">
              {data?.insurance_coverage_data?.loan_amount
                ? `₹ ${IndianNumberFormat(
                    data?.insurance_coverage_data?.loan_amount
                  )}`
                : "-"}
            </div>
          </div>
          <div className="loan_insurance_basic_card_content">
            <div className="loan_inusurance_label">Monthly Income</div>
            <div className="loan_inusurance_data">
              {/* {data?.insurance_coverage_data?.monthly_income
                ? `₹${new Intl.NumberFormat().format(data.insurance_coverage_data.monthly_income)}`
                : "-"} */}
              {data?.insurance_coverage_data?.monthly_income
                ? `₹ ${IndianNumberFormat(
                    data?.insurance_coverage_data?.monthly_income
                  )}`
                : "-"}
            </div>
          </div>
        </div>

        <div className="laon_insurance_Basic_card">
          <div className="loan_insurance_basic_card_content">
            <div className="loan_inusurance_label">Tenure Of Loan</div>
            <div className="loan_inusurance_data">
              {data?.insurance_coverage_data?.tenure ?? "-"}
            </div>
          </div>
          <div className="loan_insurance_basic_card_content">
            <div className="loan_inusurance_label">
              Premium Amount (Including GST)
            </div>
            <div className="loan_inusurance_data">
              {/* {data?.insurance_coverage_data?.total_premium_incl_gst
                ? `₹${new Intl.NumberFormat().format(data.insurance_coverage_data.total_premium_incl_gst)}`
                : "-"} */}

              {data?.insurance_coverage_data?.total_premium_incl_gst
                ? `₹ ${IndianNumberFormat(
                    data?.insurance_coverage_data?.total_premium_incl_gst
                  )}`
                : "-"}
            </div>
          </div>
        </div>

        <div className="laon_insurance_Basic_card">
          <div className="loan_insurance_basic_card_content">
            <div className="loan_inusurance_label">Policy Term(In Months)</div>
            <div className="loan_inusurance_data">
              {data?.insurance_coverage_data?.policy_term ?? "-"}
            </div>
          </div>
        </div>

        <div className="laon_insurance_Basic_card">
          <div className="loan_insurance_basic_card_content">
            <div className="loan_inusurance_label">Sum Assured</div>
            <div className="loan_inusurance_data">
              {/* {data?.insurance_coverage_data?.sum_assured
                ? `₹${new Intl.NumberFormat().format(data.insurance_coverage_data.sum_assured)}`
                : "-"} */}
              {data?.insurance_coverage_data?.sum_assured
                ? `₹ ${IndianNumberFormat(
                    data?.insurance_coverage_data?.sum_assured
                  )}`
                : "-"}
            </div>
          </div>
        </div>
      </div>

      <div className="icici_view_header">
        <div className="insurance_view_heading">Life To Be Assured</div>
      </div>

      <div className="loan_and_insurance_container">
        <div className="laon_insurance_Basic_card">
          <div className="loan_insurance_basic_card_content">
            <div className="loan_inusurance_label">Name</div>
            <div className="loan_inusurance_data">
              {data?.insured_customer_detail?.length > 0 ? (
                <>
                  {data?.insured_customer_detail[0]?.first_name ?? "-"}{" "}
                  {data?.insured_customer_detail[0]?.middle_name ?? "-"}{" "}
                  {data?.insured_customer_detail[0]?.last_name ?? "-"}
                </>
              ) : (
                "-"
              )}
            </div>
          </div>
          <div className="loan_insurance_basic_card_content">
            <div className="loan_inusurance_label">Nationality</div>
            <div className="loan_inusurance_data">
              {data?.insured_customer_detail?.length > 0
                ? data?.insured_customer_detail[0]?.nationality ?? "-"
                : "-"}
            </div>
          </div>
          <div className="loan_insurance_basic_card_content">
            <div className="loan_inusurance_label">Address 2</div>
            <div className="loan_inusurance_data">
              {data?.insured_customer_detail?.length > 0
                ? data?.insured_customer_detail[0]?.address_line_2 ?? "-"
                : "-"}
            </div>
          </div>

          <div className="loan_insurance_basic_card_content">
            <div className="loan_inusurance_label">Height</div>
            <div className="loan_inusurance_data">
              {data?.insured_customer_detail?.length > 0
                ? data?.insured_customer_detail[0]?.height ?? "-"
                : "-"}
            </div>
          </div>
        </div>

        <div className="laon_insurance_Basic_card">
          <div className="loan_insurance_basic_card_content">
            <div className="loan_inusurance_label">Date Of Birth</div>
            <div className="loan_inusurance_data">
              {data?.insured_customer_detail?.length > 0
                ? data?.insured_customer_detail[0]?.date_of_birth ?? "-"
                : "-"}
            </div>
          </div>
          <div className="loan_insurance_basic_card_content">
            <div className="loan_inusurance_label">Contact Number</div>
            <div className="loan_inusurance_data">
              {data?.insured_customer_detail?.length > 0
                ? data?.insured_customer_detail[0]?.mobile ?? "-"
                : "-"}
            </div>
          </div>
          <div className="loan_insurance_basic_card_content">
            <div className="loan_inusurance_label">City</div>
            <div className="loan_inusurance_data">
              {data?.insured_customer_detail?.length > 0
                ? data?.insured_customer_detail[0]?.city?.name ?? "-"
                : "-"}
            </div>
          </div>

          <div className="loan_insurance_basic_card_content">
            <div className="loan_inusurance_label">Weight</div>
            <div className="loan_inusurance_data">
              {data?.insured_customer_detail?.length > 0
                ? data?.insured_customer_detail[0]?.weight ?? "-"
                : "-"}
            </div>
          </div>
        </div>

        <div className="laon_insurance_Basic_card">
          <div className="loan_insurance_basic_card_content">
            <div className="loan_inusurance_label">Gender</div>
            <div className="loan_inusurance_data">
              {data?.insured_customer_detail?.length > 0
                ? data?.insured_customer_detail[0]?.gender ?? "-"
                : "-"}
            </div>
          </div>
          <div className="loan_insurance_basic_card_content">
            <div className="loan_inusurance_label">Email</div>
            <div className="loan_inusurance_data">
              {data?.insured_customer_detail?.length > 0
                ? data?.insured_customer_detail[0]?.email ?? "-"
                : "-"}
            </div>
          </div>
          <div className="loan_insurance_basic_card_content">
            <div className="loan_inusurance_label">State</div>
            <div className="loan_inusurance_data">
              {data?.insured_customer_detail?.length > 0
                ? data?.insured_customer_detail[0]?.state?.name ?? "-"
                : "-"}
            </div>
          </div>
        </div>

        <div className="laon_insurance_Basic_card">
          <div className="loan_insurance_basic_card_content">
            <div className="loan_inusurance_label">Occupation</div>
            <div className="loan_inusurance_data">
              {data?.insured_customer_detail?.length > 0
                ? data?.insured_customer_detail[0]?.ocupation?.name ?? "-"
                : "-"}
            </div>
          </div>

          <div className="loan_insurance_basic_card_content">
            <div className="loan_inusurance_label">Address 1</div>
            <div className="loan_inusurance_data">
              {data?.insured_customer_detail?.length > 0
                ? data?.insured_customer_detail[0]?.address_line_1 ?? "-"
                : "-"}
            </div>
          </div>
          <div className="loan_insurance_basic_card_content">
            <div className="loan_inusurance_label">Pincode</div>
            <div className="loan_inusurance_data">
              {data?.insured_customer_detail?.length > 0
                ? data?.insured_customer_detail[0]?.pincode?.name ?? "-"
                : "-"}
            </div>
          </div>
        </div>
      </div>

      <div className="second_heading_details_container">Nominee Details</div>
      <div className="loan_and_insurance_container">
        <div className="laon_insurance_Basic_card">
          <div className="loan_insurance_basic_card_content">
            <div className="loan_inusurance_label">Name</div>
            <div className="loan_inusurance_data">
              {data?.nominee_detail?.[0]?.name ?? "-"}
            </div>
          </div>
          <div className="loan_insurance_basic_card_content">
            <div className="loan_inusurance_label">Nationality</div>
            <div className="loan_inusurance_data">
              {data?.nominee_detail?.[0]?.nationality ?? "-"}
            </div>
          </div>
          <div className="loan_insurance_basic_card_content">
            <div className="loan_inusurance_label">City</div>
            <div className="loan_inusurance_data">
              {data?.nominee_detail?.[0]?.city?.name || "-"}
            </div>
          </div>
        </div>

        <div className="laon_insurance_Basic_card">
          <div className="loan_insurance_basic_card_content">
            <div className="loan_inusurance_label">
              Relation With The Life To Be Assured
            </div>
            <div className="loan_inusurance_data">
              {data?.nominee_detail?.[0]?.relation?.name ?? "-"}
            </div>
          </div>
          <div className="loan_insurance_basic_card_content">
            <div className="loan_inusurance_label">
              Nominee's Contact Number
            </div>
            <div className="loan_inusurance_data">
              {data?.nominee_detail?.[0]?.mobile ?? "-"}
            </div>

            <div className="loan_insurance_basic_card_content">
              <div className="loan_inusurance_label">State</div>
              <div className="loan_inusurance_data">
                {data?.nominee_detail?.[0]?.state?.name || "-"}
              </div>
            </div>
          </div>
        </div>

        <div className="laon_insurance_Basic_card">
          <div className="loan_insurance_basic_card_content">
            <div className="loan_inusurance_label">Nominee's Date Of Birth</div>
            <div className="loan_inusurance_data">
              {data?.nominee_detail?.[0]?.date_of_birth ?? "-"}
            </div>
            <div className="loan_insurance_basic_card_content">
              <div className="loan_inusurance_label">Address Line 1</div>
              <div className="loan_inusurance_data">
                {data?.nominee_detail?.[0]?.address_line_1 || "-"}
              </div>
            </div>
            <div className="loan_insurance_basic_card_content">
              <div className="loan_inusurance_label">Pincode</div>
              <div className="loan_inusurance_data">
                {data?.nominee_detail?.[0]?.pincode?.name || "-"}
              </div>
            </div>
          </div>
        </div>

        <div className="laon_insurance_Basic_card">
          <div className="loan_insurance_basic_card_content">
            <div className="loan_inusurance_label">Nominee's Gender</div>
            <div className="loan_inusurance_data">
              {data?.nominee_detail?.[0]?.gender ?? "-"}
            </div>
          </div>

          <div className="loan_insurance_basic_card_content">
            <div className="loan_inusurance_label">Address Line 2</div>
            <div className="loan_inusurance_data">
              {data?.nominee_detail?.[0]?.address_line_2 || "-"}
            </div>
          </div>
        </div>
      </div>

      {data?.nominee_detail?.[0]?.appointee && (
        <>
          <div className="second_heading_details_container">
            Appointee Details
          </div>
          <div className="loan_and_insurance_container">
            <div className="laon_insurance_Basic_card">
              <div className="loan_insurance_basic_card_content">
                <div className="loan_inusurance_label">Appointee Name</div>
                <div className="loan_inusurance_data">
                  {data?.nominee_detail?.[0]?.appointee?.appointee_name || "-"}
                </div>
              </div>

              <div className="loan_insurance_basic_card_content">
                <div className="loan_inusurance_label">
                  Appointee's Contact Number
                </div>
                <div className="loan_inusurance_data">
                  {data?.nominee_detail?.[0]?.appointee?.mobile || "-"}
                </div>
              </div>
            </div>

            <div className="laon_insurance_Basic_card">
              <div className="loan_insurance_basic_card_content">
                <div className="loan_inusurance_label">
                  Appointee's Date Of Birth
                </div>
                <div className="loan_inusurance_data">
                  {data?.nominee_detail?.[0]?.appointee?.date_of_birth || "-"}
                </div>
              </div>
            </div>

            <div className="laon_insurance_Basic_card">
              <div className="basic_card-content">
                <div className="loan_inusurance_label">Appointee's Gender</div>
                <div className="loan_inusurance_data">
                  {data?.nominee_detail?.[0]?.appointee?.gender || "-"}
                </div>
              </div>
            </div>

            <div className="laon_insurance_Basic_card">
              <div className="loan_insurance_basic_card_content">
                <div className="loan_inusurance_label">
                  Relationship With Nominee
                </div>
                <div className="loan_inusurance_data">
                  {data?.nominee_detail?.[0]?.appointee?.relation?.name || "-"}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      <div className="second_heading_details_container">
        Personal Details of Member
      </div>

      <div className="details_coverage_hdfc">Personal Detail</div>

      <div className="drawer-insurance-space">
        {/* Mapping through the 'Personal' section */}
        {data?.icici_question_data?.["Personal Detail"]
          ?.slice(0, -2)
          .map((item, index, arr) => (
            <div
              key={item.id || index}
              className="icici-insurance-personal-detail"
            >
              <p className="icici-questions">
                {item?.question || "No question available"}
              </p>
              <div className="icici-insurance-personal-detail-other-question">
                {/* Check if there are any sub-questions */}
                {item?.sub_questions && item.sub_questions.length > 0 ? (
                  <div className="icici-question-list">
                    <ul className="sub-questions-list">
                      {item.sub_questions.map((subItem, subIndex) => (
                        <li
                          key={subItem.id || `${index}-${subIndex}`}
                          className="sub-question-item"
                        >
                          <div className="insurance-sub-question-wrapper">
                            <p className="insurance-sub-question">
                              {subItem?.question || "No sub-question available"}
                            </p>
                          </div>
                          {subItem.answer && (
                            <p className="insurance-answer">
                              {subItem?.answer === "yes" ? "Yes" : "No"}
                              {subItem?.remark && `,${subItem.remark}`}
                            </p>
                          )}
                        </li>
                      ))}
                    </ul>
                  </div>
                ) : (
                  <>
                    {/* Skip answer for the last question */}
                    {index !== arr.length - 1 && (
                      <p className="insurance-answer">
                        {item?.answer === "yes" ? "Yes" : "No"}
                      </p>
                    )}
                    {item?.remark && (
                      <p className="insurance-remark">{item.remark}</p>
                    )}
                  </>
                )}
              </div>
            </div>
          )) || <p>No personal questions available.</p>}
      </div>

      <div className="details_coverage_hdfc">Female Insured Only</div>
      <div className="drawer-insurance-space">
        {/* Mapping through the 'Personal' section */}
        {data?.icici_question_data?.["Personal Detail"]
        ?.slice(-2)
          .map((item, index) => (
            <div key={item.id || index}>
              <p className="female-insured-only">
                {item?.question || "No question available"}
              </p>
              <div>
                {/* Check if there are any sub-questions */}
                {item?.sub_questions && item.sub_questions.length > 0 ? (
                  <div>
                    <ul className="sub-questions-list">
                      {item.sub_questions.map((subItem, subIndex) => (
                        <li
                          key={subItem.id || `${index}-${subIndex}`}
                          className="sub-question-items"
                        >
                          <div className="insurance-sub-question-wrapper">
                            <p className="insurance-sub-question">
                              {subItem?.question || "No sub-question available"}
                            </p>
                          </div>
                          <p className="insurance-answer">
                            {subItem?.answer === "yes" ? "Yes" : "No"}
                          </p>
                        </li>
                      ))}
                    </ul>
                  </div>
                ) : (
                  <>
                    <p className="insurance-answer">
                      {item?.answer === "yes" ? "Yes" : "No"}
                    </p>
                    {item?.remark && (
                      <p className="insurance-remark">{item.remark}</p>
                    )}
                  </>
                )}
              </div>
            </div>
          )) || <p>No personal questions available.</p>}
      </div>

      <div className="details_coverage_hdfc">COVID-19 DETAILS</div>

      <div className="drawer-insurance-space">
        {/* Mapping through the 'Covid-19' section */}
        {data?.icici_question_data?.["Covid-19"]?.map((item, index) => (
          <div key={item.id || index} className="insurance-item">
            <p className="covid-details">
              {item?.question || "No question available"}
            </p>
            <p className="insurance-answer">
              {item?.answer === "yes" ? "Yes" : "No"}
            </p>
          </div>
        )) || <p>No COVID-19 questions available.</p>}
      </div>

      <div className="details_coverage_hdfc">CCO</div>

      <div className="drawer-insurance-space">
        {data?.icici_question_data?.["Cover Continuace"]?.map((item, index) => (
          <div key={item.id || index} className="insurance-item">
            <p className="covid-details">
              {item?.question || "No question available"}
            </p>
            <p className="insurance-answer">
              {item?.answer === "yes" ? "Yes" : "No"}
            </p>
          </div>
        )) || <p>No Cover Continuance questions available.</p>}
      </div>

      <div className="icici-image-gallery">
        {imageData?.map((picture, index) => (
          <div key={index} className="image-container">
            {picture.file_type === "application/pdf" ? (
              <img
                src={pdf_image}
                className="icici-image"
                alt="PDF Preview"
                onClick={() => window.open(picture.document, "_blank")} // Opens the PDF in a new tab
              />
            ) : (
              <RViewerJS
                options={{
                  url: (image) => image.getAttribute("data-original"),
                }}
              >
                <img
                  src={picture.thumb_document}
                  data-original={picture.document}
                  alt="Preview"
                  className="icici-image"
                />
              </RViewerJS>
            )}
          </div>
        ))}
      </div>

      <Modal
        title="Confirm Delete"
        open={openModal}
        onOk={() => {
          setOpenModal(false);
          //deleteUser();
          deleteUserById(insurance_id);
        }}
        onCancel={() => setOpenModal(false)}
        okType="danger"
      >
        <p>{`Are you sure you want to delete this insurance`}</p>
      </Modal>
    </div>
  );
};

export default ICICIInsuranceview;
