import { loanTabComponents } from "./moduleConfig";

// Define common tabs for Sales
const commonSalesTabs = [
  { label: "Basic Details", component: loanTabComponents.BasicDetails },
  { label: "Property Details", component: loanTabComponents.PropertyDetails },
  { label: "Video Audio Gallery Details", component: loanTabComponents.VideoAudioGallary },
  { label: "Fee Details", component: loanTabComponents.FeeDetails },
];

// Define common tabs for underwriting
const commonUnderwritingTabs = [
  { label: "Income Evaluation", component: loanTabComponents.IncomeEvalution },
  { label: "Credit Analysis", component: loanTabComponents.CreaditAnalysis },
  { label: "Loan & Insurance", component: loanTabComponents.LoanInsurance },
  { label: "Disbursement Tranche", component: loanTabComponents.DisbursementTranche },
  { label: "Miscellaneous Details", component: loanTabComponents.MiscellaneousDetails },
];

// Define common tabs for operations
const commonOperationsTabs = [
  { label: "OTC/PDD", component: loanTabComponents.OTCPDD },
  { label: "Cheques/NEFT", component: loanTabComponents.ChequeAndNEFT },
  { label: "Payment Details", component: loanTabComponents.PaymentDetail },
  { label: "PDC", component: loanTabComponents.PDC },
  { label: "Cersai", component: loanTabComponents.CersaiEditForm },
  { label: "NACH", component: loanTabComponents.Nach },

];

export const schema = {
  sales: {
    "Home Loan": commonSalesTabs,
    "Loan Against Property": commonSalesTabs,
    "Micro Home Loan": commonSalesTabs,
    "MLAP": commonSalesTabs,
    "Vehicle Loan": [
      { label: "Basic Details", component: loanTabComponents.BasicDetails },
      { label: "Vehicle Details", component: loanTabComponents.VehicleDetails },
      { label: "Video Audio Gallery Details", component: loanTabComponents.VideoAudioGallary },
      { label: "Fee Details", component: loanTabComponents.FeeDetails },
    ],
  },
  underwriting: {
    "Home Loan": commonUnderwritingTabs,
    "Loan Against Property": commonUnderwritingTabs,
    "Micro Home Loan": commonUnderwritingTabs,
    "MLAP": commonUnderwritingTabs,
    "Vehicle Loan": commonUnderwritingTabs,
  },
  operations: {
    "Home Loan": commonOperationsTabs,
    "Loan Against Property": commonOperationsTabs,
    "Micro Home Loan": commonOperationsTabs,
    "MLAP": commonOperationsTabs,
    "Vehicle Loan": commonOperationsTabs,
  },
};

// common customer tabs
const commonTabs = [
  { label: "Personal Details", component: loanTabComponents.PersonalDetails },
  { label: "Address Details", component: loanTabComponents.AddressDetails },
  { label: "Document Details", component: loanTabComponents.DocumentDetails },
  { label: "Bureau Details", component: loanTabComponents.BureauDetails },
  { label: "Fraud & Crime Check", component: loanTabComponents.FraudCrimeCheckDetails },
  { label: "Employment Details", component: loanTabComponents.EmployementDetails },
  { label: "Bank Account Details", component: loanTabComponents.BankAccountDetails },
  { label: "Investment Details", component: loanTabComponents.InvestmentDetails },
  { label: "Existing Loan Details", component: loanTabComponents.ExistingLoanDetails },
  { label: "Reference Details", component: loanTabComponents.ReferenceDetails },
  { label: "Dependent Details", component: loanTabComponents.DependentDetails },
  { label: "Tele-Verification Calls", component: loanTabComponents.TeleVerificationCallDetails },
  { label: "Karza", component: loanTabComponents.KarzaDetails },
];

// Define specific tabs for each loan type
const vehicleLoanTabs = [
  ...commonTabs,
  { label: "Existing Vehicle Details", component: loanTabComponents.ExistingVehicleDetails },
];

export const customerDetailsTabs = {
  "Home Loan": commonTabs,
  "Loan Against Property": commonTabs,
  "Micro Home Loan": commonTabs,
  "MLAP": commonTabs,
  "Vehicle Loan": vehicleLoanTabs,
};

export const formConfig = [
  {
    schemeDisplayName: 'NORMALI5',
    component: loanTabComponents.CashNonCashForm
  },
  {
    schemeDisplayName: 'NORMALIN',
    component: loanTabComponents.CashNonCashForm
  },
  {
    schemeDisplayName: 'INCOMEMU',
    component: loanTabComponents.IncomeMultiplierForm
  },
  {
    schemeDisplayName: 'INCOMEES',
    component: loanTabComponents.NormalIncomeAndIncomeEstimateForm
  },
  {
    schemeDisplayName: 'EMIEQUAL',
    component: loanTabComponents.EmiEuiliserForm
  },
  {
    schemeDisplayName: 'RENTALIN',
    component: loanTabComponents.PureRentalForm
  }
];


export const viewConfig = [
  {
    scheme_name: 'NORMALI5',
    component: loanTabComponents.IncomeNonCashView,
  },
  {
    scheme_name: 'NORMALIN',
    component: loanTabComponents.IncomeCashView,
  },
  {
    scheme_name: 'INCOMEMU',
    component: loanTabComponents.IncomeMultiplierView,
  },
  {
    scheme_name: 'INCOMEES',
    component: loanTabComponents.IncomeEstimateView,
  },
  {
    scheme_name: 'RENTALIN',
    component: loanTabComponents.PureRentalView,
  }
];
