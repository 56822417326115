/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import "../styles/react-mentions.css";
import {
  Avatar,
  Button,
  Card,
  Badge,
  Space,
  Alert,
  Tooltip,
  Upload,
  Modal,
  message,
} from "antd";
import {
  SendOutlined,
  PaperClipOutlined,
  CloseOutlined
} from "@ant-design/icons";
import {
  getAllChatListByLoanId,
  getAllUserHaveAccess,
  getLastMessageId,
  updateMessageSeen,
} from "../../loanApplication/loanSummary/services/loanapplicationService";
import "../styles/chatbotScreen.css"
import ActionBox from "./ActionBox";
import { cleanText } from "../helperFunctions/trimString";
import ChatInputBox from "./ChatInputBox";
import ChatScreen from "./ChatScreen";
import { connectionStatus } from "../../../constant/socketConnectState";
import { ReadyState } from "react-use-websocket";
import ErrorMessage from "../../../utils/errorHandling/ErrorMessage";

const ChatBot = ({
  sendJsonMessage,
  lastJsonMessage,
  readyState,
  loan_id,
  conversationId,
  showChatDrawer
}) => {
  const [activeTabKey, setActiveTabKey] = useState("1");
  const [isActionBoxVisible, setIsActionBoxVisible] = useState(false);
  const { user_data } = useSelector((state) => state.user);
  const [messages, setMessages] = useState([]);
  const [userNameList, setUserNameList] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [fileList, setFileList] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [imageSrc, setImageSrc] = useState("");
  const [messageCount, setMessageCount] = useState(0);
  const [isAtBottom, setIsAtBottom] = useState(true);
  // const [newMessagesCount, setNewMessagesCount] = useState(0);
  const [newFileType, setNewFileType] = useState(null);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const messagesEndRef = useRef(null);
  const chatContainerRef = useRef(null);
  const loadingRef = useRef(false);
  const scrollPositionRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const checkIfAtBottom = () => {
    const container = chatContainerRef.current;
    if (container) {
      const isAtBottom =
        Math.abs(
          container.scrollHeight - container.scrollTop - container.clientHeight
        ) < 10;
      setIsAtBottom(isAtBottom);
    }
  };

  // Initial load effect
  useEffect(() => {
    fetchChats(1, true);
    fetchUserList();
    scrollToBottom();
    setIsInitialLoad(false);
  }, []);

  // WebSocket connection effect
  useEffect(() => {
    if (readyState === ReadyState.OPEN) {
      sendJsonMessage({
        event: "chat_message",
        data: {
          channel: "general-chatroom",
        },
      });
    }
  }, [readyState, sendJsonMessage]);

  // Scroll position check effect
  useEffect(() => {
    checkIfAtBottom();
    if (!isAtBottom) {
      // setNewMessagesCount((prevCount) => prevCount + 1);
    } else {
      // setNewMessagesCount(0);
      scrollToBottom();
    }
  }, [messages,lastJsonMessage]);

  // Restore scroll position after messages update
  useEffect(() => {
    if (
      !isInitialLoad &&
      scrollPositionRef.current &&
      chatContainerRef.current
    ) {
      const newScrollTop =
        chatContainerRef.current.scrollHeight -
        scrollPositionRef.current.scrollHeight +
        scrollPositionRef.current.scrollTop;

      chatContainerRef.current.scrollTop = newScrollTop;
      scrollPositionRef.current = null;
    }
  }, [messages, isInitialLoad]);

  // Handle incoming messages effect
  useEffect(() => {
    if (lastJsonMessage?.message) {
      const { message } = lastJsonMessage;
      const newMessage = {
        id: message.id || Date.now(),
        event: "chat_message",
        content: message.content || "",
        from_user: message.from_user,
        media_file: message.media_file ? message?.media_file : imageSrc,
        file_type: message.file_type,
        time_stamp: message.time_stamp,
        message_type: message.message_type,
        created_by: {
          id: message.from_user,
          name: message.from_user_name,
        },
        modified_by: {
          id: message.from_user,
          name: message.from_user_name,
        },
        created_at: new Date(message.time_stamp).toLocaleString(),
        modified_at: new Date(message.time_stamp).toLocaleString(),
        loan_detail: {
          id: loan_id,
        },
        from_user_name: message.from_user_name,
      };

      setMessages((prev) => [...prev, newMessage]);
      // setImageSrc("");
      if (isAtBottom) {
        scrollToBottom();
      }
    }
  }, [lastJsonMessage, loan_id]);

  const fetchUserList = async () => {
    try {
      const response = await getAllUserHaveAccess(loan_id);
      const userNameList = response?.data
        ?.filter((user) => user?.name)
        ?.map((user) => ({
          id: user.id,
          name: String(user.name).toLowerCase(),
          profile_img: user.profile_img || "https://via.placeholder.com/30",
        }));
      setUserNameList(userNameList);
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  const fetchChats = async (pageNumber = 1, isInitial = true) => {
    if ((!hasMore && pageNumber > 1) || loadingRef.current) return;

    try {
      setLoading(true);
      loadingRef.current = true;

      // Store scroll position before fetching new messages
      if (!isInitial && chatContainerRef.current) {
        scrollPositionRef.current = {
          scrollHeight: chatContainerRef.current.scrollHeight,
          scrollTop: chatContainerRef.current.scrollTop,
        };
      }

      const response = await getAllChatListByLoanId(loan_id, pageNumber);
      const allMessages = response?.data || [];

      if (allMessages.length === 0) {
        setHasMore(false);
        return;
      }

      setMessageCount(response?.count || 0);
      setHasMore(allMessages.length * pageNumber < response.count);

      const processedMessages = allMessages?.map(processMessage);

      setMessages((prev) => {
        if (isInitial) return processedMessages;

        const uniqueMessages = new Map([
          ...processedMessages.map((msg) => [msg.id, msg]),
          ...prev.map((msg) => [msg.id, msg]),
        ]);

        return Array.from(uniqueMessages.values()).sort((a, b) => a.id - b.id);
      });

      // Update message seen status
      if (allMessages.length > 0) {
        const lastMessageResponse = await getLastMessageId();
        if (allMessages[0]?.id !== lastMessageResponse?.last_message_id) {
          await updateMessageSeen({
            conversation_id: conversationId,
            message_id: allMessages[allMessages.length - 1].id,
          });
        }
      }
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    } finally {
      setLoading(false);
      loadingRef.current = false;
    }
  };

  const processMessage = (message) => {
    if (!message?.content) return message;

    let processedContent = message.content;
    const mentions = processedContent.match(/@(\d+)/g) || [];

    mentions.forEach((mention) => {
      const userId = mention.slice(1);
      const user = userNameList.find((u) => u.id === Number(userId));
      if (user) {
        processedContent = processedContent.replace(
          mention,
          `@${user.name} ${userId}@`
        );
      }
    });

    return { ...message, content: processedContent };
  };

  const handleSendMessage = () => {
    const cleanedText = cleanText(inputValue).trim();

    if (!cleanedText && !imageSrc) return;

    const messagePayload = {
      event: "chat_message",
      content: cleanedText,
      from_user: user_data?.id || "Unknown User",
      loan_detail: loan_id,
    };

    if (imageSrc) {
      messagePayload.media_file = imageSrc;
      messagePayload.type = newFileType;
    }
    sendJsonMessage(messagePayload);
    resetUploadStates();
    scrollToBottom();
  };

  const resetUploadStates = () => {
    setImageSrc("");
    setInputValue("");
    setIsModalVisible(false);
    setFileList([]);
  };

  const handleChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
    if (newFileList.length > 0 && newFileList[0].originFileObj) {
      const reader = new FileReader();
      const file = newFileList[0].originFileObj;
      const fileType = file.type;
      reader.onloadend = () => {
        setImageSrc(reader.result);
        setNewFileType(fileType);
        setIsModalVisible(true);
      };
      reader.readAsDataURL(newFileList[0].originFileObj);
    }
  };

  const handleScroll = () => {
    if (!chatContainerRef.current || loading) return;

    checkIfAtBottom();
    const { scrollTop } = chatContainerRef.current;
    const scrollThreshold = 100;

    if (
      scrollTop <= scrollThreshold &&
      hasMore &&
      messageCount > messages.length
    ) {
      setPage((prev) => prev + 1);
      fetchChats(page + 1, false);
    }
  };

  // const handleUnreadClick = () => {
  //   scrollToBottom();
  //   setNewMessagesCount(0);
  // };

  const uniqueUserNames = [
    ...new Set(messages?.map((item) => item?.created_by?.name)),
  ];

  return (
    <div className="main_screen">
      <Card
        className="flex flex-col chat-header"
        title={
          <div className="chat_title flex justify-between items-center">
          <Space>
            <Avatar.Group>
              <div>
                {uniqueUserNames?.map((userName) => (
                  <Tooltip key={userName} title={userName}>
                    <Avatar style={{ backgroundColor: "#f56a00" }}>
                      {userName?.charAt(0).toUpperCase()}
                    </Avatar>
                  </Tooltip>
                ))}
              </div>
            </Avatar.Group>
            <div>
              <Badge
                status={connectionStatus[readyState]?.color}
                text={connectionStatus[readyState]?.text}
                style={{color:"snow"}}
              />
            </div>
          </Space>
          <Button 
            icon={<CloseOutlined />} 
            type="text" 
            onClick={showChatDrawer} 
            style={{ 
              color: 'snow', 
              hover: { backgroundColor: 'lightgray' } 
            }}
          />
        </div>
        }
        bodyStyle={{
          flex: 1,
          overflow: "hidden",
          padding: "10px",
          background: "white",
        }}
      >
        <div
          ref={chatContainerRef}
          onScroll={handleScroll}
            className="custom-chat-scrollbar"
            style={{ 
              height: "calc(100vh - 250px)", // Responsive height calculation
              minHeight: "300px", // Minimum height
              maxHeight: "600px", // Maximum height
              overflowY: "auto",
              scrollbarWidth: "thin", // Firefox
              scrollbarColor: "grey #f1f1f1", // Firefox
            }}
        >
          <ChatScreen
            messages={messages}
            user_data={user_data}
            userNameList={userNameList}
          />
          <div ref={messagesEndRef} />
        </div>

        {readyState !== ReadyState.OPEN && (
          <Alert
            message="Connection lost. Reconnecting..."
            type="warning"
            showIcon
            className="mb-4"
          />
        )}
      </Card>

      {isActionBoxVisible && (
        <ActionBox
          activeTabKey={activeTabKey}
          handleTabChange={setActiveTabKey}
          setIsActionBoxVisible={setIsActionBoxVisible}
        />
      )}

      <div>
        <Space.Compact
          style={{
            width: "100%",
            backgroundColor: "rgba(98,116,137,255)",
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "center",
          }}
        >
          <Upload
            customRequest={() => {}}
            fileList={fileList}
            onChange={handleChange}
            showUploadList={false}
            accept="*/*"
          >
            <Button
              icon={
                <PaperClipOutlined
                  style={{
                    fontSize: "25px",
                    marginLeft: "10px",
                  }}
                />
              }
              style={{
                marginRight: 10,
                margin: "8px",
                border: "none",
                backgroundColor: "rgba(98,116,137,255)",
              }}
            />
          </Upload>

          <ChatInputBox
            handleSendMessage={handleSendMessage}
            userNameList={userNameList}
            setInputValue={setInputValue}
            inputValue={inputValue}
          />
          <Button
            onClick={handleSendMessage}
            type="primary"
            icon={<SendOutlined />}
            style={{ marginLeft: 8, border: "none" }}
          />
          {/* <Button
            icon={<AppstoreAddOutlined />}
            onClick={() => {
              setIsActionBoxVisible(true);
              setActiveTabKey("2");
            }}
            style={{ marginLeft: 8 }}
          /> */}
        </Space.Compact>
      </div>

      <Modal
        title="Uploaded Image"
        open={isModalVisible}
        footer={[
          <Button key="send" type="primary" onClick={handleSendMessage}>
            Send
          </Button>,
          <Button key="cancel" onClick={resetUploadStates}>
            Cancel
          </Button>,
        ]}
        onCancel={resetUploadStates}
      >
        <img src={imageSrc} alt="Uploaded" style={{ width: "100%" }} />
      </Modal>
    </div>
  );
};

export default ChatBot;
