import React, { useEffect, useRef } from "react";
import baseConfig from "../../config/baseConfig";

const GoogleMapLocation = ({
  latitude,
  longitude,
  branchLatitude,
  branchLongitude,
}) => {
  const mapRef = useRef(null);
  const scriptRef = useRef(null);

  useEffect(() => {
    const loadScript = (url) => {
      const script = document.createElement("script");
      script.src = url;
      script.async = true;
      script.defer = true;
      document.body.appendChild(script);
      script.onload = () => {
        if (!window.google) {
          console.error("Google Maps JavaScript API script failed to load");
          return;
        }

        const map = new window.google.maps.Map(mapRef.current, {
          center: { lat: latitude, lng: longitude },
          zoom: 15,
        });

        // Add a red marker for the main location
        new window.google.maps.Marker({
          position: { lat: latitude, lng: longitude },
          map: map,
          title: "Main Location",
          icon: {
            url: "http://maps.google.com/mapfiles/ms/icons/red-dot.png",
          },
        });

        // Add a blue marker for the branch location (hardcoded)
        new window.google.maps.Marker({
          position: { lat: branchLatitude, lng: branchLongitude },
          map: map,
          title: "Branch Location",
          icon: {
            url: "http://maps.google.com/mapfiles/ms/icons/blue-dot.png",
          },
        });
      };

      script.onerror = () => {
        console.error("Error loading Google Maps JavaScript API script");
      };

      scriptRef.current = script;
    };

    loadScript(
      `https://maps.googleapis.com/maps/api/js?key=${baseConfig.googleMapApiKey}`
    );

    // Cleanup script tag on component unmount
    return () => {
      if (scriptRef.current && document.body.contains(scriptRef.current)) {
        document.body.removeChild(scriptRef.current);
      }
    };
  }, [latitude, longitude, branchLatitude, branchLongitude]);

  return (
    <div>
      <div
        id="map"
        ref={mapRef}
        style={{ height: "65vh", width: "100%" }}
      ></div>
    </div>
  );
};

export default GoogleMapLocation;
