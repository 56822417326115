/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
// import {
//   MenuFoldOutlined,
//   MenuUnfoldOutlined,
// } from "@ant-design/icons";
import {
  UserOutlined,
  LoginOutlined,
  BellOutlined,
} from "@ant-design/icons";
import { Layout, Menu, theme, Dropdown, Badge, Drawer, message, Empty } from "antd";
import { Outlet, Link, useLocation, useNavigate } from "react-router-dom";
// import sibebarlogo from "../assets/sidebarlogo.png";
import vastologo from "../assets/vastulogo.png";
import DashboardIcon from "../assets/Dashboard Icon.svg";
import AdminIcon from "../assets/Admin Icon.svg";
import HRIcon from "../assets/HR Icon.svg";
import "../styles/myLayout.css";
import { useDispatch, useSelector } from "react-redux";
import CreditIcon from "../assets/Credit.svg";
import AccountsIcon from "../assets/account.svg";
import OperationsIcons from "../assets/Operations.svg";
import SubMenuIcon from "../assets/Sub menu icon.svg";
import { encrypt } from "../../../utils/cryptoUtils/cryptoUtils";
import Notification from "../../notification/views/Notification";
import useWebSocket from "react-use-websocket";
import baseConfig from "../../../config/baseConfig";
import {
  setNotification,
} from "../../../redux/notification";
import NotificationFeed from "./NotificationFeed";
import { getLoanAllNotification } from "../../notification/services/notificationServices";
import { Footer } from "antd/es/layout/layout";
// import { useParams } from 'react-router-dom';

function getItem(
  label,
  path,
  key,
  icon,
  children,
  requiredPermissions,
  requiredRoles
) {
  return {
    key,
    icon,
    children,
    label,
    path,
    requiredPermissions,
    requiredRoles,
  };
}

const { Header, Content } = Layout;

const notification = (
  showDrawer = () => {},
  notifications = [],
  newNotificationData = [],
  removeNotification = () => {},
  setDropdownOpen,
  user_data
) => {
  // const isMobile = useMediaQuery({ maxWidth: 767 });
  // const getContent = extractNumbers(notifications?.map((item) => item.content))
  const getLatestFiveEntries = (array) => {
    return array
      .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
      .slice(0, 5);
  };

  const earlierNotification = getLatestFiveEntries(newNotificationData);

  const processNotification = (not) => {
    const chat_tagged_user = not.message ? not.message?.tagged_users : not.tagged_users
    let updatedContent = not?.message?.content ? not?.message?.content : not?.notification_content;
    const userIds = Array.from(
      new Set(
        (updatedContent?.match(/@\d+/g) || [])?.map((match) =>
          match.replace("@", "")
        )
      )
    );

    userIds.forEach((id) => {
      const taggedUser = chat_tagged_user?.find(
        (user) => user.tagged_user_id === parseInt(id)
      );
      if (taggedUser) {
        const regex = new RegExp(`@${id}`, "g");
        updatedContent = updatedContent.replace(
          regex,
          `@${taggedUser.tagged_user_name}`
        );
      }
    });

    return {
      ...not,
      content: updatedContent,
    };
  };

  const updatedNotifications = [
    ...earlierNotification,
    ...(notifications || []),
  ].map(processNotification);
  return (
    <Menu className="menu_notification_container">
          {updatedNotifications.length ? (
        <NotificationFeed
          updatedNotifications={updatedNotifications}
          showDrawer={showDrawer}
          setDropdownOpen={setDropdownOpen}
        />
      ) : (
        <div className="empty-state">
          <Empty description="No notifications yet" />
        </div>
      )}
    </Menu>
  );
};

const MyLayout = () => {
  const navigate = useNavigate();
  const currentYear = new Date().getFullYear();
  const location = useLocation();
  const dispatch = useDispatch();
  const [notificationSocketData, setNotificationSocketData] = useState([]);
  const [newNotificationData, setNewNotificationData] = useState([]);
  const [webSocketUrl, setWebSocketUrl] = useState(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [hasAttemptedConnection, setHasAttemptedConnection] = useState(false);
  const notifications = useSelector(
    (state) => state.notification.notification_data
  );

  const fetchNotification = async () => {
    try {
      const response = await getLoanAllNotification(1);
      setNewNotificationData(response.data);
    } catch (error) {
      message.error(error.message || "Error fetching notifications");
    }
  };

  useEffect(() => {
    fetchNotification();
  }, []);

  const handleOpenChange = (open) => {
    setDropdownOpen(open);
  };

  const { lastJsonMessage, sendJsonMessage } = useWebSocket(webSocketUrl, {
    share: false,
    shouldReconnect: () => true,
    retryOnError: true,
    reconnectAttempts: 5,
    reconnectInterval: 3000,
    onOpen: () => {
      // Send the notification message only when connection is established
      sendJsonMessage({
        event: "notification_message",
        data: {
          channel: "notification_dashboard",
        },
      });
    },
    onError: () => {
      console.log("WebSocket connection error");
    },
  });

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  // const removeNotification = () => {
  //   dispatch(clearNotification());
  // };

  useEffect(() => {
    const getToken = localStorage.getItem("access");
    if (getToken && !hasAttemptedConnection) {
      setWebSocketUrl(
        `${baseConfig.socketurlDashboard}dashboard/?Authorization=${getToken}`
      );
      setHasAttemptedConnection(true);
    }
  }, [hasAttemptedConnection]);

  // Handle incoming messages
  useEffect(() => {
    if (lastJsonMessage?.message.content) {
      const matches = lastJsonMessage?.message?.content.match(/@\d+/g);
      const numbers = matches ? matches.map(num => num.slice(1)) : [];
      if(numbers.includes(user_data?.id.toString())){
        setNotificationSocketData((prev) => [...prev, lastJsonMessage?.message]);
        dispatch(setNotification(lastJsonMessage));
      }
    }
  }, [lastJsonMessage, dispatch]);


  const showDrawer = () => {
    setIsDrawerOpen(true);
  };

  const closeDrawer = () => {
    setIsDrawerOpen(false);
  };

  const menu = (
    <Menu className="menu_list_container">
      <Menu.Item
        key="2"
        className="menu_item"
        onClick={() => navigate("/user_profile")}
      >
        <UserOutlined /> <span>Profile</span>
      </Menu.Item>

      <Menu.Item
        key="1"
        className="menu_item"
        onClick={() => {
          localStorage.clear();
          window.location.reload();
          // removeNotification();
        }}
      >
        <LoginOutlined /> <span>LogOut</span>
      </Menu.Item>
    </Menu>
  );

  const items = [
    getItem(
      "Dashboard",
      "/",
      "dashboard",
      <span>
        <img src={DashboardIcon} alt="Dashboard" className="layout-icons" />
      </span>,
      [
        getItem(
          "Dashboard",
          "/",
          "subDashboard1",
          <img src={SubMenuIcon} alt="Submenu" className="layout-icons" />
        ),
        getItem(
          "Business Dashboard",
          "/maintenance",
          "subDashboard2",
          <img src={SubMenuIcon} alt="Submenu" className="layout-icons" />
        ),
      ]
    ),
    getItem(
      "Admin",
      "/",
      "admin1",
      // <img src={AdminIcon} alt="Admin" className="layout-icons" />,
      <span>
        <img src={AdminIcon} alt="Dashboard" className="layout-icons" />
      </span>,

      [
        getItem(
          "Master",
          "/master",
          "subadmin1",
          <img src={SubMenuIcon} alt="Submenu" className="layout-icons" />,
          null,
          ["VWMASTER"]
        ),
        getItem(
          "User",
          "/users",
          "subadmin2",
          <img src={SubMenuIcon} alt="Submenu" className="layout-icons" />,
          null,
          ["VIEWUSER"]
        ),
      ],
      ["VWMASTER", "VIEWUSER"]
    ),
    getItem(
      "Sales",
      "/",
      "sales",
      // <img src={AdminIcon} alt="Admin" className="layout-icons" />,
      <span>
        <img src={CreditIcon} alt="Credit" className="layout-icons" />
      </span>,

      [
        getItem(
          "Lead",
          "/listsalesapplication",
          "salesLeads1",
          <img src={SubMenuIcon} alt="Submenu" className="layout-icons" />,
          null,
          ["LSLOANS1"]
        ),
      ],
      ["LSLOANS1"]
    ),
    getItem(
      "Credit",
      "/",
      "credit",
      // <img src={CreditIcon} alt="Credit" className="layout-icons" />,
      <span>
        <img src={CreditIcon} alt="Credit" className="layout-icons" />
      </span>,

      [
        getItem(
          "Loan Detail",
          "/applications",
          "creditApplications1",
          <img src={SubMenuIcon} alt="Submenu" className="layout-icons" />,
          null,
          ["LSLOANS1"]
        ),
        getItem(
          "Part Disbursal",
          "/part_disbursed",
          "creditPartDisbursal",
          <img src={SubMenuIcon} alt="Submenu" className="layout-icons" />,
          null,
          ["VIEWPD01"]
        ),
        getItem(
          "Adhoc",
          "/adhoc",
          "creditAdhoc",
          <img src={SubMenuIcon} alt="Submenu" className="layout-icons" />,
          null,
          ["ADHOCBUR"]
        ),
      ],
      ["LSLOANS1", "VIEWPD01", "ADHOCBUR"]
    ),
    getItem(
      "Operations",
      "/",
      "operations",
      // <img src={OperationsIcons} alt="Operations" className="layout-icons" />,
      <span>
        <img src={OperationsIcons} alt="Operations" className="layout-icons" />
      </span>,
      [
        getItem(
          "OTC/PDD",
          "/otc_pdd",
          "otc",
          <img src={SubMenuIcon} alt="Submenu" className="layout-icons" />,
          null,
          ["NAVOPS01"]
        ),
        getItem(
          "NACH",
          "/maintenance",
          "16",
          <img src={SubMenuIcon} alt="Submenu" className="layout-icons" />,
          [
            getItem(
              "NACH",
              "/nach",
              "17",
              <img src={SubMenuIcon} alt="Submenu" className="layout-icons" />
            ),
            getItem(
              "Not Checked",
              "/not_checked",
              "18",
              <img src={SubMenuIcon} alt="Submenu" className="layout-icons" />
            ),
            getItem(
              "In Progress",
              "/in_progress",
              "19",
              <img src={SubMenuIcon} alt="Submenu" className="layout-icons" />
            ),
            getItem(
              "Approved",
              "/approved",
              "20",
              <img src={SubMenuIcon} alt="Submenu" className="layout-icons" />
            ),
            getItem(
              "Rejected",
              "/rejected",
              "21",
              <img src={SubMenuIcon} alt="Submenu" className="layout-icons" />
            ),
          ]
        ),
        getItem(
          "CERSAI",
          "/cersai",
          "Cersai",
          <img src={SubMenuIcon} alt="Submenu" className="layout-icons" />,
          [
            getItem(
              "Security Interest",
              "/cersai/security_interest",
              "si",
              <img src={SubMenuIcon} alt="Submenu" className="layout-icons" />,
              [
                getItem(
                  "Pending List",
                  "/securityinterestpendinglist",
                  "24",
                  <img
                    src={SubMenuIcon}
                    alt="Submenu"
                    className="layout-icons"
                  />
                ),
                getItem(
                  "Completed List",
                  "/cersai/security_interest/completed",
                  "34",
                  <img
                    src={SubMenuIcon}
                    alt="Submenu"
                    className="layout-icons"
                  />
                ),
              ]
            ),
            getItem(
              "Security Satisfaction",
              "/cersai/security_satisfaction",
              "ss",
              <img src={SubMenuIcon} alt="Submenu" className="layout-icons" />,
              [
                getItem(
                  "Pending List",
                  "/securitysatisfactionpendinglist",
                  "22",
                  <img
                    src={SubMenuIcon}
                    alt="Submenu"
                    className="layout-icons"
                  />
                ),
                getItem(
                  "Completed List",
                  "/cersai/security_interest/completed",
                  "23",
                  <img
                    src={SubMenuIcon}
                    alt="Submenu"
                    className="layout-icons"
                  />
                ),
              ]
            ),
          ]
        ),
      ]
    ),
    getItem(
      "All Stages",
      "/all_stage",
      "all_stage",
      // <img src={OperationsIcons} alt="Operations" className="layout-icons" />,
      <span>
        <img src={OperationsIcons} alt="all" className="layout-icons" />
      </span>,
      [
        getItem(
          "Lead",
          `applications/${encrypt((1).toString())}`,
          "26",
          <img src={SubMenuIcon} alt="Submenu" className="layout-icons" />
        ),
        getItem(
          "QC",
          `applications/${encrypt((2).toString())}`,
          "27",
          <img src={SubMenuIcon} alt="Submenu" className="layout-icons" />
        ),
        getItem(
          "PD",
          `applications/${encrypt((3).toString())}`,
          "28",
          <img src={SubMenuIcon} alt="Submenu" className="layout-icons" />
        ),
        getItem(
          "legal",
          `applications/${encrypt((4).toString())}`,
          "29",
          <img src={SubMenuIcon} alt="Submenu" className="layout-icons" />
        ),
        getItem(
          "Technical",
          `applications/${encrypt((5).toString())}`,
          "30",
          <img src={SubMenuIcon} alt="Submenu" className="layout-icons" />
        ),
        getItem(
          "RCU",
          `applications/${encrypt((6).toString())}`,
          "31",
          <img src={SubMenuIcon} alt="Submenu" className="layout-icons" />
        ),
        getItem(
          "FI",
          `applications/${encrypt((7).toString())}`,
          "25",
          <img src={SubMenuIcon} alt="Submenu" className="layout-icons" />
        ),

        getItem(
          "CAM",
          `applications/${encrypt((9).toString())}`,
          "33",
          <img src={SubMenuIcon} alt="Submenu" className="layout-icons" />
        ),

        getItem(
          "Rejected",
          `applications/${encrypt((9).toString())}`,
          "32",
          <img src={SubMenuIcon} alt="Submenu" className="layout-icons" />
        ),
      ],
      ["LSLOANS1"]
    ),
    getItem(
      "Account and Finance",
      "/",
      "Account",
      // <img src={AccountsIcon} alt="Accounts" className="layout-icons" />,
      <span>
        <img src={AccountsIcon} alt="Account" className="layout-icons" />
      </span>,
      [
        getItem(
          "Receipt",
          "/receipt",
          "rcp",
          <img src={SubMenuIcon} alt="Submenu" className="layout-icons" />,
          null,
          ["VWRECIPT"]
        ),
      ],
      ["VWRECIPT"]
    ),
    getItem(
      "HR",
      "/",
      "sub4",
      // <img src={HRIcon} alt="HR" className="layout-icons" />,
      <span>
        <img src={HRIcon} alt="Dashboard" className="layout-icons" />
      </span>,
      [
        getItem(
          "Digital ID",
          "/id-card",
          "id-card",
          <img src={SubMenuIcon} alt="Submenu" className="layout-icons" />
        ),
        getItem(
          "Time Sheet",
          "/calendar",
          "calendar",
          <img src={SubMenuIcon} alt="Submenu" className="layout-icons" />
        ),
      ]
    ),
  ];

  const generateMenuItems = (menuData, permissions, roles) => {
    const permissionCodes = permissions?.map((p) => p.code) || [];
    const roleCodes = roles?.map((r) => r.code) || [];
    // const isPermissionAdmin = permissionCodes.includes("MPADMIN1");
    const isRoleAdmin =
      roleCodes.includes("SUPADMIN") || roleCodes.includes("MPADMIN1");

    return menuData
      .filter((item) => {
        // Check admin access for all items
        if (isRoleAdmin) {
          return true;
        }
        if (
          !item.requiredPermissions ||
          item.requiredPermissions.length === 0
        ) {
          return true; // Render if no permissions are required
        }
        // Check permissions and roles for each item
        const hasPermission = item.requiredPermissions?.some((perm) =>
          permissionCodes.includes(perm)
        );

        const hasRole = item.requiredRoles?.some((role) =>
          roleCodes.includes(role)
        );

        return hasPermission || hasRole;
      })
      .map((item) => {
        if (item.children) {
          return (
            <Menu.SubMenu key={item.key} icon={item.icon} title={item.label}>
              {generateMenuItems(item.children, permissions, roles)}
            </Menu.SubMenu>
          );
        }
        return (
          <Menu.Item key={item.key} icon={item.icon}>
            <Link to={item.path}>{item.label}</Link>
          </Menu.Item>
        );
      });
  };

  const getDefaultSelectedKey = () => {
    const storedKey = localStorage.getItem("selectedKey");
    if (storedKey) {
      return storedKey;
    }
    return "1";
  };
  const [selectedKey, setSelectedKey] = useState(getDefaultSelectedKey());

  const { user_data } = useSelector((state) => state.user);

  useEffect(() => {
    const pathname = location.pathname;
    const data = location.state;
    const matchingItem = findMenuItemByPathname(pathname, items);
    if (!matchingItem && data?.key) {
      setSelectedKey(data?.key);
    }
    if (matchingItem) {
      setSelectedKey(matchingItem.key);
      if (matchingItem.label === "Dashboard") {
        document.title = `Dashboard | LMS`;
      } else {
        document.title = `Manage ${matchingItem.label}s | LMS`;
      }
    }
    if (!data?.key && !matchingItem) {
      document.title = "Vastu | LMS";
    }

    localStorage.setItem("selectedKey", selectedKey);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const handleMenuItemSelect = ({ key }) => {
    const matchingItem = findMenuItemByKey(key, items);
    if (matchingItem) {
      setSelectedKey(matchingItem.key);
    }
  };
  function findMenuItemByKey(key, menuData) {
    for (const item of menuData) {
      if (item.key === key) {
        return item;
      } else if (item.children) {
        const subItem = findMenuItemByKey(key, item.children);
        if (subItem) {
          return subItem;
        }
      }
    }
    return null;
  }

  const findMenuItemByPathname = (pathname, menuData) => {
    for (const item of menuData) {
      if (item.path === pathname) {
        return item;
      } else if (item.children) {
        const subItem = findMenuItemByPathname(pathname, item.children);
        if (subItem) {
          return subItem;
        }
      }
    }
    return null;
  };

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  useEffect(() => {
    const handleBeforeUnload = () => {
      // Clear local storage or perform any other necessary actions
      // localStorage.clear();
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      // Remove the event listener when the component is unmounted
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  // const handleBeforeUnload = (event) => {

  //   const userLoggedIn = localStorage.getItem("userLoggedIn");
  //   if (!userLoggedIn) {
  //     // Clear local storage or perform any other necessary actions
  //     localStorage.clear();

  //     // Optionally, you can display a warning message to the user
  //     // event.returnValue = "You have unsaved changes. Are you sure you want to leave?";
  //     window.location.href = "/login";
  //   }
  // };

  return (
    <Layout style={{ height: "100vh" }}>
      <Header
        className="header_container"
        style={{
          padding: 0,
          background: colorBgContainer,
          height: "48px",
          borderBottom: "1px solid hsla(0, 0%, 0%, 0.06)",
          display: "flex",
          justifyContent: "space-between",
          lineHeight: "48px",
        }}
      >
        <div className="header_first_div">
          <Link to="/">
            <div>
              <img
                className="header_first_div_img"
                src={vastologo}
                alt="homelogo"
              />
            </div>
          </Link>
          {user_data && (
            <Menu
              className="header_first_div_menu"
              theme="light"
              defaultSelectedKeys={["credit"]}
              selectedKeys={[selectedKey]}
              mode="horizontal"
              onSelect={handleMenuItemSelect}
            >
              {generateMenuItems(
                items,
                user_data?.permissions,
                user_data?.roles
              )}
            </Menu>
          )}
        </div>

        {!isDrawerOpen && (
          <div className="drawer_div">
            <Dropdown
              overlay={() =>
                notification(
                  showDrawer,
                  notifications,
                  newNotificationData,
                  notificationSocketData,
                  // removeNotification,
                  setDropdownOpen,
                  user_data
                )
              }
              trigger={["click"]}
              open={dropdownOpen}
              onOpenChange={handleOpenChange}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <Badge
                  count={notifications?.length}
                  overflowCount={10}
                  size="small"
                  style={{ marginRight: "10px" }}
                >
                  <BellOutlined
                    style={{
                      fontSize: "1rem",
                      marginRight: "1rem",
                    }}
                  />
                </Badge>
              </div>
            </Dropdown>
            <Dropdown overlay={menu} trigger={["click"]}>
              <div className="imf_div" style={{ display: "flex", alignItems: "center" }}>
                {user_data?.profile_img ? (
                  <img
                    src={user_data?.profile_img}
                    alt="user"
                    className="dropdown_img"
                  />
                ) : (
                  <div className="dropdown_div">
                    <span className="dropdown_span_first">
                      {user_data?.name
                        ? user_data.name.charAt(0).toUpperCase()
                        : ""}
                    </span>
                  </div>
                )}
                <span className="dropdown_span_second">{user_data?.name}</span>
              </div>
            </Dropdown>
          </div>
        )}
      </Header>
      <Drawer
        title="Notifications"
        width="24vw"
        placement="right"
        onClose={closeDrawer}
        open={isDrawerOpen}
        className="layout_scroll" 
      >
        <Notification newNotificationData={newNotificationData} />
      </Drawer>
      <Layout>
        <Content
          className="layout_scroll" 
          style={{
            padding: "20px",
            minHeight: 280,
            background: colorBgContainer,
            overflow: "auto",
          }}
        >
          <Outlet />
        </Content>
        <Footer
          style={{
            textAlign: "right",
            padding: "5px",
            background: "#fff",
          }}
        >
          © {currentYear} Vastu Housing Finance Corporation ltd.
        </Footer>
      </Layout>
    </Layout>
  );
};

export default MyLayout;
