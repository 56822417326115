import React from 'react';
import { Row, Col } from 'antd';
import DashboardTaskAssignUI from './DashboardTaskAssignUI';
import DashBoardLiteEmployeeDetail from './DashBoardLiteEmployeeDetail';

const DashboardLiteLayout = ({ assignedTask }) => {
  return (
    <div style={{ backgroundColor: "#f0f5ff", padding: '10px' }}>
      <Row gutter={[16, 16]}>
        <Col 
          xs={24}    
          sm={24}    
          md={24}    
          lg={18}  
          xl={18}  
        >
          <DashboardTaskAssignUI assignedTask={assignedTask} />
        </Col>
        <Col
          xs={24} 
          sm={24}
          md={24}
          lg={6}
          xl={6}
        >
          <DashBoardLiteEmployeeDetail />
        </Col>
      </Row>
    </div>
  );
};

export default DashboardLiteLayout;