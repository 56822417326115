// config/baseConfig.js
const baseConfig = {
  serverurl: process.env.REACT_APP_SERVER_URL,
  googleClentId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
  socketurlchat : process.env.REACT_APP_SOCKET_URL_CHAT,
  socketurlDashboard : process.env.REACT_APP_SOCKET_URL_DASHBOARD,
  googleMapApiKey:
    process.env.REACT_APP_ENVIRONMENT === "TESTING"
      ? process.env.REACT_APP_TESTING_GOOGLE_MAP_KEY
      : process.env.REACT_APP_LOCAL_GOOGLE_MAP_KEY,
};

export default baseConfig;