import { DeleteOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Form, Select, Input, Button, Table, Space, message, Row, Col, Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import "../styles/incomeDetails.css";
import { decrypt } from '../../../../../utils/cryptoUtils/cryptoUtils';
import ErrorMessage from '../../../../../utils/errorHandling/ErrorMessage';
import { createIncomeMultiplierFormCam, deleteIncomeDetailsByIdService, getCustomersByLoanId, getIncomeMultiplierCustomerDataByCustomerId, listFinancialYear, updateIncomeMultiplierByCustomerId } from '../services/incomeDetailsServices';
// const { Text } = Typography;

const IncomeMultiplierForm = ({ closeForm, open, refreshData, id }) => {
    const [form] = Form.useForm();
    const [customerList, setCustomerList] = useState([]);
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const [allMonthYear, setAllMonthYear] = useState([]);
    const [getCustomerId, setCustomerId] = useState(null);
    const [openModal, setOpenModal] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState(null);

    const handleChange = (namePath, value) => {
        form.setFieldsValue({ [namePath]: value });
    };
    const { encrypted_loan_id } = useParams();

    let loan_id = null;
    try {
        loan_id = decrypt(encrypted_loan_id);
    } catch (error) {
        message.error("Invalid Loan ID");
    }

    useEffect(() => {
        form.resetFields();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    useEffect(() => {
        const fetchCustomersByLoanId = async () => {
            try {
                const response = await getCustomersByLoanId(loan_id);
                const customers = response?.data?.customer_list?.map((customer) => {
                    return {
                        id: customer.id,
                        name: `${customer.name} (${customer.customer_type})`,
                    };
                });
                setCustomerList(customers);

                // setLoading(false);
            } catch (error) {
                // setLoading(false);
                message.error(
                    ErrorMessage(error?.response?.status, error?.response?.data?.message)
                );
            }
        };
        const getMonthYear = async () => {
            try {
                const response = await listFinancialYear();
                setAllMonthYear(response.data);
            } catch (error) {
                message.error(
                    ErrorMessage(error?.response?.status, error?.response?.data?.message)
                );
            }
        };
        fetchCustomersByLoanId();
        getMonthYear();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    const handleCustomerchange = (value) => {
        setSelectedCustomer(value);
    };
    const getIncomeData = async () => {
        if (id) {
            try {
                const response = await getIncomeMultiplierCustomerDataByCustomerId(id);
                let { customer_detail, data } = response?.data;
                setCustomerId(customer_detail?.customer_id)
                // const customerName = getCustomerNameById(customer_detail);
                form.setFieldsValue({
                    customer: customer_detail?.customer_id,
                    salaryDetails: data.map(item => ({
                        id: item.id,
                        financial_year: item.financial_year,
                        turnover: item.turnover,
                        profit_before_tax: item.profit_before_tax,
                        tax_paid: item.tax_paid,
                        depreciation: item.depreciation,
                        interest_paid_cc: item.interest_paid_cc,
                        interest_on_loans: item.interest_on_loans,
                        rental_income_cash: item.rental_income_cash,
                        rental_income_non_cash: item.rental_income_non_cash,
                        dividend_or_interest: item.dividend_or_interest,
                        agricultural_income: item.agricultural_income
                    }))
                });
            } catch (error) {
                message.error(
                    ErrorMessage(error?.response?.status, error?.response?.data?.message)
                );
            }
        }
    };

    useEffect(() => {
        if (open) {
            getIncomeData();

        } else {
            form.resetFields();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open, id]);

    const handleSubmit = async (values) => {
        try {

            if (id) {
                const payload = {
                    customer_detail: getCustomerId,
                    loan_detail: loan_id,
                    data: values.salaryDetails.map((item) => ({
                        id: item.id,
                        financial_year: item.financial_year,
                        turnover: item.turnover,
                        profit_before_tax: item.profit_before_tax,
                        tax_paid: item.tax_paid,
                        depreciation: item.depreciation,
                        interest_paid_cc: item.interest_paid_cc,
                        interest_on_loans: item.interest_on_loans,
                        rental_income_cash: item.rental_income_cash,
                        rental_income_non_cash: item.rental_income_non_cash,
                        dividend_or_interest: item.dividend_or_interest,
                        agricultural_income: item.agricultural_income,
                    })),
                };
                const response = await updateIncomeMultiplierByCustomerId(payload);
                if ((response.status = 200 && response.success)) {
                    message.success("Income multiplier details successfully updated");
                    form.resetFields();
                    refreshData();
                    closeForm();
                }
            } else {
                const payload = {
                    loan_detail: loan_id,
                    customer_detail: selectedCustomer,
                    data: values.salaryDetails,         // Use the salaryDetails from the form values
                };

                const response = await createIncomeMultiplierFormCam(payload);
                if ((response.status = 200 && response.success)) {
                    message.success("Income multiplier details successfully created");
                    form.resetFields();
                    closeForm();
                    refreshData();
                }
            }


        }
        catch (error) {
            message.error(
                ErrorMessage(error?.response?.status, error?.response?.data?.message)
            );
        }
    };

    const handleDelete = async () => {
        try {
            // Call the service to delete the record from the backend
            const response = await deleteIncomeDetailsByIdService(selectedRecord.id);
            if (response.status === 200 && response.success === true) {
                message.success("Record deleted successfully");
                setOpenModal(false);
                getIncomeData();
                refreshData();
            }
        } catch (error) {
            setOpenModal(false);
            message.error(error.response.data.message);
        }
    };

    return (
        <>
            <Form
                form={form}
                name="salary-details-form"
                layout="vertical"
                initialValues={{
                    salaryDetails: [{
                        financial_year: null,
                        turnover: null,
                        profit_before_tax: null,
                        tax_paid: null,
                        depreciation: null,
                        interest_paid_cc: null,
                        interest_on_loans: null,
                        rental_income_cash: null,
                        rental_income_non_cash: null,
                        dividend_or_interest: null,
                        agricultural_income: null
                    }],
                }}
                onFinish={handleSubmit}
            >
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item name="customer" label="Customer" required
                            rules={[{ required: true, message: 'Please select a customer' }]} >
                            <Select
                                value={selectedCustomer}
                                onChange={handleCustomerchange}
                                style={{ width: "300px" }}
                                placeholder="Please Select Customer"
                                disabled={!!id}
                            >
                                {customerList.length > 0 &&
                                    customerList.map((customer) => {
                                        return (
                                            <Select.Option value={customer.id}>{customer.name}</Select.Option>
                                        );
                                    })}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Form.List name="salaryDetails">
                    {(fields, { add, remove }) => (
                        <>
                            <Table
                                columns={[
                                    {
                                        title: 'SR.NO',
                                        dataIndex: 'key',
                                        key: 'key',
                                        fixed: "left",
                                        render: (text, record, index) => <span>{index + 1}</span>,
                                    },
                                    {
                                        title: 'F.Y',
                                        dataIndex: 'financial_year',
                                        key: 'financial_year',
                                        width: 150,
                                        render: (_, record, index) => (
                                            <Form.Item
                                                name={[index, 'financial_year']}
                                                rules={[{ required: true, message: 'Please select' }]}
                                            >
                                                <Select
                                                    placeholder="Please Select"
                                                    onChange={(value) => handleChange(['salaryDetails', index, 'financial_year'], value)}
                                                    style={{ width: '120px' }}
                                                    allowClear
                                                    showSearch
                                                >
                                                    {allMonthYear?.map((branch) => (
                                                        <Select.Option key={branch} value={branch}>
                                                            {branch}
                                                        </Select.Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                        ),
                                    },
                                    {
                                        title: 'Turnover',
                                        dataIndex: 'turnover',
                                        key: 'turnover',
                                        width: 150,
                                        render: (_, record, index) => (
                                            <Form.Item

                                                name={[index, 'turnover']}
                                                rules={[{ required: true, message: 'Please Enter Turnover' }]}
                                            >
                                                <Input
                                                    style={{ width: '120px' }}
                                                    placeholder="Enter Turnover"

                                                    onChange={(e) => handleChange(['salaryDetails', index, 'monthly_net_salary_non_cash'], e.target.value)}
                                                />
                                            </Form.Item>
                                        ),
                                    },
                                    {
                                        title: 'PBT',
                                        dataIndex: 'profit_before_tax',
                                        key: 'profit_before_tax',
                                        width: 150,
                                        render: (_, record, index) => (
                                            <Form.Item
                                                name={[index, 'profit_before_tax']}
                                                rules={[{ required: true, message: 'Enter profit before tax' }]}
                                            >
                                                <Input
                                                    style={{ width: '120px' }}
                                                    placeholder="Enter PBT"
                                                    onChange={(e) => handleChange(['salaryDetails', index, 'over_time_pay'], e.target.value)}
                                                />
                                            </Form.Item>
                                        ),
                                    },
                                    {
                                        title: 'Tax Paid',
                                        dataIndex: 'tax_paid',
                                        key: 'tax_paid',
                                        width: 150,
                                        render: (_, record, index) => (
                                            <Form.Item
                                                name={[index, 'tax_paid']}
                                                rules={[{ required: true, message: 'Enter tax paid' }]}
                                            >
                                                <Input
                                                    placeholder="Enter Tax Paid"
                                                    style={{ width: '120px' }}
                                                    onChange={(e) => handleChange(['salaryDetails', index, 'over_time_pay'], e.target.value)}
                                                />
                                            </Form.Item>
                                        ),
                                    },
                                    {
                                        title: 'Depreciation ',
                                        dataIndex: 'depreciation',
                                        key: 'depreciation',
                                        width: 150,
                                        render: (_, record, index) => (
                                            <Form.Item
                                                name={[index, 'depreciation']}
                                                rules={[{ required: true, message: 'Enter Depreciation' }]}
                                            >
                                                <Input
                                                    placeholder="Enter Depreciation"
                                                    style={{ width: '120px' }}
                                                    onChange={(e) => handleChange(['salaryDetails', index, 'over_time_pay'], e.target.value)}
                                                />
                                            </Form.Item>
                                        ),
                                    },
                                    {
                                        title: 'Interest Paid on CC ',
                                        dataIndex: 'interest_paid_cc',
                                        key: 'interest_paid_cc',
                                        render: (_, record, index) => (
                                            <Form.Item
                                                name={[index, 'interest_paid_cc']}
                                                rules={[{ required: true, message: 'Enter Interst Paid on CC' }]}
                                            >
                                                <Input
                                                    placeholder="Enter Interst Paid on CC"

                                                    onChange={(e) => handleChange(['salaryDetails', index, 'over_time_pay'], e.target.value)}
                                                />
                                            </Form.Item>
                                        ),
                                    },
                                    {
                                        title: 'Interest Paid on Loans ',
                                        dataIndex: 'interest_on_loans',
                                        key: 'interest_on_loans',
                                        render: (_, record, index) => (
                                            <Form.Item
                                                name={[index, 'interest_on_loans']}
                                                rules={[{ required: true, message: 'Enter Interst Paid on Loans' }]}
                                            >
                                                <Input
                                                    placeholder="Enter Interst Paid on Loans"

                                                    onChange={(e) => handleChange(['salaryDetails', index, 'over_time_pay'], e.target.value)}
                                                />
                                            </Form.Item>
                                        ),
                                    },
                                    {
                                        title: 'Rental Income - Cash',
                                        dataIndex: 'rental_income_cash',
                                        key: 'rental_income_cash',
                                        render: (_, record, index) => (
                                            <Form.Item
                                                name={[index, 'rental_income_cash']}
                                                rules={[{ required: true, message: 'Enter rental income - cash' }]}
                                            >
                                                <Input
                                                    placeholder="Enter rental income - cash"

                                                    onChange={(e) => handleChange(['salaryDetails', index, 'over_time_pay'], e.target.value)}
                                                />
                                            </Form.Item>
                                        ),
                                    },
                                    {
                                        title: 'Rental Income - Non Cash',
                                        dataIndex: 'rental_income_non_cash',
                                        key: 'rental_income_non_cash',
                                        render: (_, record, index) => (
                                            <Form.Item
                                                name={[index, 'rental_income_non_cash']}
                                                rules={[{ required: true, message: 'Enter renta income - Non cash' }]}
                                            >
                                                <Input
                                                    placeholder="Enter renta income - Non cash"

                                                    onChange={(e) => handleChange(['salaryDetails', index, 'over_time_pay'], e.target.value)}
                                                />
                                            </Form.Item>
                                        ),
                                    },
                                    {
                                        title: 'Dividend / Interest',
                                        dataIndex: 'dividend_or_interest',
                                        key: 'dividend_or_interest',
                                        render: (_, record, index) => (
                                            <Form.Item
                                                name={[index, 'dividend_or_interest']}
                                                rules={[{ required: true, message: 'Enter dividend / interest' }]}
                                            >
                                                <Input
                                                    placeholder="Enter dividend / interest"

                                                    onChange={(e) => handleChange(['salaryDetails', index, 'over_time_pay'], e.target.value)}
                                                />
                                            </Form.Item>
                                        ),
                                    },
                                    {
                                        title: 'Agriculture Income',
                                        dataIndex: 'agricultural_income',
                                        key: 'agricultural_income',
                                        render: (_, record, index) => (
                                            <Form.Item
                                                name={[index, 'agricultural_income']}
                                                rules={[{ required: true, message: 'Enter agriculture income' }]}
                                            >
                                                <Input
                                                    placeholder="Enter agriculture income"

                                                    onChange={(e) => handleChange(['salaryDetails', index, 'over_time_pay'], e.target.value)}
                                                />
                                            </Form.Item>
                                        ),
                                    },
                                    {
                                        title: 'Action',
                                        key: 'action',
                                        fixed: 'right',
                                        render: (_, record, index) => {
                                            const salaryValues = form.getFieldValue('salaryDetails') || []; // Ensure there's a default value
                                            const currentDetail = salaryValues[index] || {}; // Get the current salary detail values

                                            return (
                                                <Form.Item>
                                                    <DeleteOutlined
                                                        style={{ color: 'red' }}
                                                        onClick={() => {
                                                            if (currentDetail?.id) {
                                                                setOpenModal(true);
                                                                setSelectedRecord(currentDetail)

                                                            } else {
                                                                remove(index);
                                                            }
                                                        }}
                                                    />
                                                </Form.Item>
                                            );
                                        },
                                    },
                                ]}
                                dataSource={fields}
                                rowKey="key"
                                pagination={false}
                                scroll={{ x: true }}
                            />

                            <Button type="primary"
                                onClick={() => add()}
                                icon={<PlusCircleOutlined />}
                                style={{ marginTop: "10px" }} >
                                Year
                            </Button>
                        </>
                    )}
                </Form.List>

                <Space
                    direction="horizontal"
                    align="center"
                    style={{ display: "flex", flexDirection: "row-reverse" }}
                >
                    <Button type="primary" htmlType="submit">
                        Submit
                    </Button>
                    <Button onClick={closeForm}>Cancel</Button>
                </Space>
            </Form>


            {/* <div className="income_calculations_container" style={{ marginTop: "10px" }}>
                <h3 className="income_title">Income Multiplier Calculations</h3>
                <div className="ltv-content">
                    <Row className="ltv-row">
                        <Col span={10}>
                            <Text>PAT(profit after tax)</Text>
                        </Col>
                        <Col >
                            <Text>: 0 days</Text>
                        </Col>
                    </Row>

                    <Row className="ltv-row">
                        <Col span={10}>
                            <Text>Income Multiplier - A ...Add other incomes to the derived income </Text>
                        </Col>
                        <Col >
                            <Text>: 0%</Text>
                        </Col>
                    </Row>

                    <Row className="ltv-row">
                        <Col span={10}>
                            <Text>Minimum of A or B</Text>
                        </Col>
                        <Col >
                            <Text>: ₹ 0.00</Text>
                        </Col>
                    </Row>

                </div>
            </div> */}
            <Modal
                title="Confirm Delete"
                open={openModal}
                onOk={() => {
                    setOpenModal(false);
                    handleDelete();
                }}
                onCancel={() => setOpenModal(false)}
                okType="danger"
            >
                <p>{`Are you sure you want to delete?`}</p>
            </Modal>

        </>

    );
};

export default IncomeMultiplierForm;
