import { Avatar, Card, Tooltip, Typography } from "antd";
import React from "react";
import TimeDisplay from "./TimeDisplay";
import { navigateWithEncryptedId } from "../../../utils/cryptoUtils/cryptoUtils";
import { useNavigate } from "react-router-dom";

const NotificationCard = ({ notification }) => {
  const navigate = useNavigate();
  const chatMessages = notification?.message
    ? notification?.message
    : notification;

  const application_number_new = chatMessages?.loan_detail?.application_number
    ? chatMessages?.loan_detail?.application_number
    : chatMessages?.loan_detail_id?.application_number;
  const loan_account_number_new = chatMessages?.loan_detail?.loan_account_number
    ? chatMessages?.loan_detail?.loan_account_number
    : chatMessages?.loan_detail_id?.loan_account_number;
  const loanId = chatMessages?.loan_detail?.id
    ? chatMessages?.loan_detail?.id
    : chatMessages?.loan_detail_id?.loan_detail_id;

  const stage = chatMessages?.stage;
  const stageStatus = chatMessages?.stage_status;
  const getInitials = (name) => {
    return name
      .split(" ")
      .map((n) => n[0])
      .join("")
      .toUpperCase();
  };

  const updatedMessageContent = notification.content
    ? notification.content
    : chatMessages.content || "";
  return (
    <Card bodyStyle={{ padding: 16 }}>
      <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
        {/* First Row: Avatar, Title, and TimeDisplay */}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
            <Avatar style={{ backgroundColor: "#1890ff" }}>
              {chatMessages.tagged_users?.[0]?.tagged_user_name
                ? getInitials(chatMessages.tagged_users[0].tagged_user_name)
                : "U"}
            </Avatar>

            {chatMessages?.message_type === "Action" ? (
              <Typography.Text strong style={{ textTransform: "uppercase" }}>
                {notification.content.split(" ").slice(0, 2).join(" ")}
              </Typography.Text>
            ) : (
              <div>
                <Typography.Text strong style={{ textTransform: "uppercase" }}>
                  {stage} {" "}
                  {stageStatus}
                </Typography.Text>
              </div>
            )}
          </div>

          <Typography.Text type="secondary" style={{ fontSize: 10 }}>
            <TimeDisplay
              timeStamp={chatMessages?.time_stamp}
              createdAt={chatMessages?.created_at}
            />
          </Typography.Text>
        </div>

        {/* Message Content with Hover on Specific Parts */}
        <div style={{ display: "contents" }}>
          <Typography.Text style={{ marginLeft: "35px" }}>
            {updatedMessageContent.split(" ").map((word, index) => {
              if (word === `#${application_number_new}`) {
                return (
                  <Tooltip
                    key={`app-${index}`}
                    title={`Application Number: ${application_number_new}`}
                  >
                    <span
                      style={{ cursor: "pointer",fontWeight:'bold' }}
                      onClick={() => {
                        const targetPath = "/sales";

                        const newData = { name: "sales", key: "salesLeads1" };
                        const passdata = newData;

                        navigateWithEncryptedId(
                          navigate,
                          targetPath,
                          loanId.toString(),
                          passdata
                        );
                      }}
                    >
                      {" "}
                      {word}
                    </span>
                  </Tooltip>
                );
              }

              if (word === `#${loan_account_number_new}`) {
                return (
                  <Tooltip
                    key={`loan-${index}`}
                    title={`Loan Account Number: ${loan_account_number_new}`}
                  >
                    <span
                      style={{ cursor: "pointer",fontWeight:'bold' }}
                      onClick={() => {
                        const targetPath = "/loandetails";

                        const data = {
                          name: "loan",
                          key: "creditApplications1",
                        };
                        const newData = { name: "sales", key: "salesLeads1" };
                        const passdata =
                          targetPath === "/loandetails" ? data : newData;

                        navigateWithEncryptedId(
                          navigate,
                          targetPath,
                          loanId.toString(),
                          passdata
                        );
                      }}
                    >
                      {" "}
                      {word}
                    </span>
                  </Tooltip>
                );
              }

              return <span key={index}> {word} </span>;
            })}
          </Typography.Text>
        </div>
      </div>
    </Card>
  );
};

export default NotificationCard;
