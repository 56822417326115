/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Input, Row, Space, message, Select } from "antd";
import * as Yup from "yup";
import {
  getLandAuthorityService,
  updatePropertyDetailsByIdService,
  getAllStatusLandHoldingChoiceService,
  getTypePropertySiteChoiceService,
  getZoingChoicesService,
  getMCGPLimitChoiceService,
  getapproachRoadTypeChoiceService,
  getGOVPVTRoadChoiceService,
  getLocalityTypeChoiceService,
  getMarkebilityChoiceService,
  getSesmicZoneChoiceService,
  getNoOfKitchenService,
  getPortionWitnessChoiceService,
  getHouseDeliveryAgencyChoiceService,
  getIdentifiedThroughChoiceService,
  getMaintainenceLevelChoiceService,
  getTypeOfStructureService,
  getPropertyDataService,
  getAllBeforeYearChoiceService,
  getRiskDemolitionChoiceService
} from "../services/propertyDetailsServices";
import "../styles/propertyDetails.css"
import ErrorMessage from "../../../../../utils/errorHandling/ErrorMessage";

const validationSchema = Yup.object().shape({
  pas_per_plan: Yup.string().required("This field is required"),
  pas_per_site: Yup.string().required("This field is required"),
  pdeviation: Yup.string().required("This field is required"),
  prate: Yup.string().required("This field is required"),
  ppercentage_completed: Yup.string().required("This field is required"),
  ppercentage_recommended: Yup.string().required("This field is required"),

  // Existing Construction Area
  eas_per_plan: Yup.string().required("This field is required"),
  eas_per_site: Yup.string().required("This field is required"),
  edeviation: Yup.string().required("This field is required"),
  erate: Yup.string().required("This field is required"),
  epercentage_completed: Yup.string().required("This field is required"),
  epercentage_recommended: Yup.string().required("This field is required"),

  // Proposed Construction Area
  pcas_per_plan: Yup.string().required("This field is required"),
  pcas_per_site: Yup.string().required("This field is required"),
  pcdeviation: Yup.string().required("This field is required"),
  pcrate: Yup.string().required("This field is required"),
  pcpercentage_completed: Yup.string().required("This field is required"),
  pcpercentage_recommended: Yup.string().required("This field is required"),

  // Amenities
  aas_per_plan: Yup.string().required("This field is required"),
  aas_per_site: Yup.string().required("This field is required"),
  adeviation: Yup.string().required("This field is required"),
  arate: Yup.string().required("This field is required"),
  apercentage_completed: Yup.string().required("This field is required"),
  apercentage_recommended: Yup.string().required("This field is required"),

  // Technical Data
  total_market_value: Yup.string().required("This field is required"),
  forced_sale_value: Yup.string().required("This field is required"),
  completed_value: Yup.string().required("This field is required"),

  // Additional fields
  status_land_holding: Yup.string().required("Status Land Holding is required"),
  developed_by: Yup.string().required("Developed By is required"),
  type_of_property_document: Yup.string().required("Type Of Property Document is required"),
  type_of_property_site: Yup.string().required("Type Of Property Site is required"),
  zoning: Yup.string().required("Zoning is required"),
  development_vicinity: Yup.string().required("Development Vicinity is required"),
  approch_road_width: Yup.string().required("Approach Road width is required"),
  govt_private_road: Yup.string().required("Governmemt Or Private Road is required"),
  mc_gp_limit: Yup.string().required("MC GP Limit is required"),
  identified_through: Yup.string().required("Identified Through is required"),
  type_of_structure: Yup.string().required("Type Of Structure is required"),
  met_person_name: Yup.string().required("Met Person Name is required"),
  occupied_since: Yup.string().required("Occuppied Since is required"),
  occupation_status: Yup.string().required("Occupation Status is required"),
  residual_life_of_property: Yup.string().required("Residual Life of Property is required"),
  age_of_property: Yup.string().required("Age Of Property is required"),
  basic_amenities: Yup.string().required("Basic Amenities is required"),
  toilet: Yup.string().required("Toilet is required"),
  maintenance_level: Yup.string().required("Maintenance Level is required"),
  structure_government: Yup.string().required("Structure Government is required"),
  situated_near_canal: Yup.string().required("Situated Near Canal is required"),
  distance_from_canal: Yup.string().required("Distance From Canal is required"),
  situated_near_ht_lines: Yup.string().required("Situated Near HT Lines is required"),
  distance_from_ht_line: Yup.string().required("Distance From HT Lines is required"),
  seismic_zone: Yup.string().required("Seismic Zone is required"),
  locality_type: Yup.string().required("Locality Type is required"),
  marketability: Yup.string().required("Marketability  is required"),
  any_hazard: Yup.string().required("Any Hazard is required"),
  market_feedback: Yup.string().required("Market Feedback is required"),
  commercial_portion_witnessed: Yup.string().required("Commericial Portion Witnessed is required"),
  no_of_kitchen: Yup.string().required("No Of Kitchen is required"),
  recent_demolition: Yup.string().required("Recent Demolition is required"),
  before_years: Yup.string().required("Before Years is required"),
  property_affected: Yup.string().required("Property Affected is required"),
  road_widening: Yup.string().required("Road Widening is required"),
  property_negative_area: Yup.string().required("Property Negavtive Area is required"),
  lift_available: Yup.string().required("Lift Availability is required"),
  distance_from_branch: Yup.string().required("Distance From Branch is required"),
  house_delivery_agency: Yup.string().required("House Delivery Agency is required"),
  approch_road_type: Yup.string().required("Approach Road Type is required"),
  risk_of_demolition: Yup.string().required("Risk Of Demolition is required"),
  demarcation: Yup.string().required("Demarcation is required"),
  // distance_from_canal: Yup.string().required("This field is required"),
});

const { Option } = Select;
const yupSync = {
  async validator({ field }, value) {
    await validationSchema.validateSyncAt(field, { [field]: value });
  },
};

const LocationPropertyDetails = ({ refreshData, closeForm, stage_id, open }) => {
  const [form] = Form.useForm();

  const [houseDelivery, setHouseDelivery] = useState([]);
  const [kitchen, setKitchen] = useState([]);
  const [markebility, setMarkebility] = useState([]);
  const [seismiczones, setSeismiczones] = useState([]);
  const [maintenance, setMaintenance] = useState([]);
  const [gov, setGov] = useState([]);
  const [approachroadtype, setApproachroadtype] = useState([]);
  const [zones, setZones] = useState([]);
  const [types, setTypes] = useState([]);
  const [landholding, setLandholding] = useState([]);
  const [identified, setIdentified] = useState([]);
  const [limitChoice, setLimitChoice] = useState([]);
  const [portion, setPortion] = useState([]);
  const [structures, setStructures] = useState([]);
  const [localityType, setLocalityType] = useState([]);
  const [authority, setAuthority] = useState([]);
  const [beforeYear, setBeforeYear] = useState();
  const [demolition, setDemolition] = useState([]);
  const [technicalid, setTechnicalid] = useState("");
  const [occupied, setOccupied] = useState("");
  const [roadWidening, setRoadWidening] = useState(null)


  useEffect(() => {
    if (roadWidening) {
      form.setFieldsValue({ road_widening: null });
    }
  }, [roadWidening]);

  const getPersonalDatas = async () => {

    try {
      const response = await getPropertyDataService(stage_id);
      setTechnicalid(response?.data.technical_detail[0].id);
      setOccupied(response?.data.technical_detail[0].occupation_status)
      let {
        status_land_holding,
        demarcation,
        developed_by,
        type_of_property_document,
        type_of_property_site,
        zoning,
        development_vicinity,
        approch_road_width,
        govt_private_road,
        mc_gp_limit,
        identified_through,
        type_of_structure,
        met_person_name,
        occupied_since,
        occupation_status,
        residual_life_of_property,
        age_of_property,
        basic_amenities,
        toilet,
        maintenance_level,
        structure_government,
        situated_near_canal,
        distance_from_canal,
        situated_near_ht_lines,
        distance_from_ht_line,
        seismic_zone,
        locality_type,
        marketability,
        any_hazard,
        market_feedback,
        commercial_portion_witnessed,
        no_of_kitchen,
        recent_demolition,
        before_years,
        property_affected,
        road_widening,
        approch_road_type,
        property_negative_area,
        lift_available,
        distance_from_branch,
        house_delivery_agency,
        risk_of_demolition
      } = response?.data?.technical_detail[0];
      const dcrNormsValue = situated_near_canal ? 'true' : 'false';
      const highValue = situated_near_ht_lines ? 'true' : 'false';
      const demolition = recent_demolition ? 'true' : 'false';
      const demercation = demarcation ? 'Yes' : 'No';
      form.setFieldsValue({
        status_land_holding,
        demarcation: demercation,
        developed_by: developed_by.id,
        type_of_property_document,
        type_of_property_site,
        zoning,
        development_vicinity,
        govt_private_road,
        approch_road_width,
        met_person_name,
        mc_gp_limit,
        identified_through,
        type_of_structure,
        occupied_since,
        occupation_status,
        residual_life_of_property,
        age_of_property,
        basic_amenities,
        toilet,
        maintenance_level,
        structure_government,
        situated_near_canal: dcrNormsValue,
        distance_from_canal,
        situated_near_ht_lines: highValue,
        approch_road_type,
        distance_from_ht_line,
        seismic_zone,
        locality_type,
        marketability,
        any_hazard,
        market_feedback,
        commercial_portion_witnessed,
        no_of_kitchen,
        recent_demolition: demolition,
        before_years,
        property_affected,
        road_widening,
        property_negative_area,
        lift_available,
        distance_from_branch,
        house_delivery_agency,
        risk_of_demolition
      });
    } catch (error) {
      message.error(error?.response?.data?.message);
    }

  };





  const handleSubmit = async (values) => {
    try {
      const response = await updatePropertyDetailsByIdService(
        technicalid,
        values
      );
      if (response?.status === 200 && response?.success) {
        message.success(
          "Location & Property Specific Details successfully updated"
        );
        refreshData();
        closeForm();
      }
    } catch (error) {
      closeForm();
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };



  const handleNumberFields = (e, field) => {
    const newValue = e.target.value.replace(/[^\d.]/g, "");
    form.setFieldsValue({ [field]: newValue });
  };

  const handleCharacterFields = (e, field) => {
    // Get the value from the input
    let newValue = e.target.value;

    // Remove all characters except digits, alphabetic characters, and decimal points
    newValue = newValue.replace(/[^0-9a-zA-Z.]/g, "");

    // Ensure that only one decimal point is allowed
    const decimalCount = (newValue.match(/\./g) || []).length;
    if (decimalCount > 1) {
      newValue = newValue.replace(/\.(?=.*\.)/g, ""); // Remove all but the last decimal point
    }

    // Set the formatted value in the form
    form.setFieldsValue({ [field]: newValue });
  };

  const getAllAuthoritylist = async () => {
    try {
      const response = await getLandAuthorityService();
      setAuthority(response?.data);
      if (response && response?.data) {
        if (response?.status === 200 && response?.data.success) {
          message.success("Personal details updated successfully");
        }
      }
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  const getHousingDeliverylist = async () => {
    try {
      const response = await getHouseDeliveryAgencyChoiceService();
      setHouseDelivery(response?.data.choices);
      if (response && response?.data) {
        if (response?.status === 200 && response?.data.success) {
          message.success("Personal details updated successfully");
        }
      }
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };


  const getKitchenlist = async () => {
    try {
      const response = await getNoOfKitchenService();
      setKitchen(response?.data.choices);
      if (response && response?.data) {
        if (response?.status === 200 && response?.data.success) {
          message.success("Personal details updated successfully");
        }
      }
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  const getMarketlibitylist = async () => {
    try {
      const response = await getMarkebilityChoiceService();
      setMarkebility(response?.data.choices);
      if (response && response?.data) {
        if (response?.status === 200 && response?.data.success) {
          message.success("Personal details updated successfully");
        }
      }
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  const getSeismicZoneslist = async () => {
    try {
      const response = await getSesmicZoneChoiceService();
      setSeismiczones(response?.data.choices);
      if (response && response?.data) {
        if (response?.status === 200 && response?.data.success) {
          message.success("Personal details updated successfully");
        }
      }
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  const getMaintenenceLevellist = async () => {
    try {
      const response = await getMaintainenceLevelChoiceService();
      setMaintenance(response?.data.choices);
      if (response && response?.data) {
        if (response?.status === 200 && response?.data.success) {
          message.success("Personal details updated successfully");
        }
      }
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  const getGOVPVTRoadChoiceServices = async () => {
    try {
      const response = await getGOVPVTRoadChoiceService();
      setGov(response?.data.choices);
      if (response && response?.data) {
        if (response?.status === 200 && response?.data.success) {
          message.success("Personal details updated successfully");
        }
      }
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  const getapproachRoadTypeChoiceServiceList = async () => {
    try {
      const response = await getapproachRoadTypeChoiceService();
      setApproachroadtype(response?.data.choices);
      if (response && response?.data) {
        if (response?.status === 200 && response?.data.success) {
          message.success("Personal details updated successfully");
        }
      }
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  const getZoningChoicesList = async () => {
    try {
      const response = await getZoingChoicesService();
      setZones(response?.data.choices);
      if (response && response?.data) {
        if (response?.status === 200 && response?.data.success) {
          message.success("Personal details updated successfully");
        }
      }
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  const getTypePropertyChoicesList = async () => {
    try {
      const response = await getTypePropertySiteChoiceService();
      setTypes(response?.data.choices);
      if (response && response?.data) {
        if (response?.status === 200 && response?.data.success) {
          message.success("Personal details updated successfully");
        }
      }
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  const getLandHoldingList = async () => {
    try {
      const response = await getAllStatusLandHoldingChoiceService();
      setLandholding(response?.data.choices);
      if (response && response?.data) {
        if (response?.status === 200 && response?.data.success) {
          message.success("Personal details updated successfully");
        }
      }
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  const getIdentifiedThroughList = async () => {
    try {
      const response = await getIdentifiedThroughChoiceService();
      setIdentified(response?.data.choices);
      if (response && response?.data) {
        if (response?.status === 200 && response?.data.success) {
          message.success("Personal details updated successfully");
        }
      }
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  const getMCGPLimitChoiceList = async () => {
    try {
      const response = await getMCGPLimitChoiceService();
      setLimitChoice(response?.data.choices);
      if (response && response?.data) {
        if (response?.status === 200 && response?.data.success) {
          message.success("Personal details updated successfully");
        }
      }
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  const getPortionWitnessChoiceList = async () => {
    try {
      const response = await getPortionWitnessChoiceService();
      setPortion(response?.data.choices);
      if (response && response?.data) {
        if (response?.status === 200 && response?.data.success) {
          message.success("Personal details updated successfully");
        }
      }
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  const getTypeOfStructureList = async () => {
    try {
      const response = await getTypeOfStructureService();
      setStructures(response?.data.choices);
      if (response && response?.data) {
        if (response?.status === 200 && response?.data.success) {
          message.success("Personal details updated successfully");
        }
      }
    } catch (error) {
      message.error(
        error.response?.data?.message ||
        "An error occurred while updating personal details"
      );
    }
  };

  const getBeforeYearList = async () => {
    try {
      const response = await getAllBeforeYearChoiceService();
      setBeforeYear(response?.data.choices);
      if (response && response?.data) {
        if (response?.status === 200 && response?.data.success) {
          message.success("Personal details updated successfully");
        }
      }
    } catch (error) {

      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };



  const getLocalityTypeChoiceList = async () => {
    try {
      const response = await getLocalityTypeChoiceService();
      setLocalityType(response?.data.choices);
      if (response && response?.data) {
        if (response?.status === 200 && response?.data.success) {
          message.success("Personal details updated successfully");
        }
      }
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  const getDemolitionChoiceslist = async () => {
    try {
      const response = await getRiskDemolitionChoiceService();
      setDemolition(response.data.choices);
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  useEffect(() => {
    getAllAuthoritylist();
    getHousingDeliverylist();
    getKitchenlist();
    getMarketlibitylist();
    getSeismicZoneslist();
    getMaintenenceLevellist();
    getGOVPVTRoadChoiceServices();
    getapproachRoadTypeChoiceService();
    getZoningChoicesList();
    getTypePropertyChoicesList();
    getLandHoldingList();
    getIdentifiedThroughList();
    getMCGPLimitChoiceList();
    getPortionWitnessChoiceList();
    getTypeOfStructureList();
    getapproachRoadTypeChoiceServiceList();
    getLocalityTypeChoiceList();
    getBeforeYearList();
    getDemolitionChoiceslist();
  }, [])

  useEffect(() => {
    if (open) {
      getPersonalDatas();
    } else {
      form?.resetFields();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, form]);

  return (
    <Form
      form={form}
      onFinish={handleSubmit}
      layout="vertical"
      initialValues={{
        application_number: "",
        loan_account_number: "",
        requested_amount: "",
        requested_tenure: "",
        scheme: "",
        loan_type: "",
        loan_purpose: "",
        location: "",
        approved_amount: "",
        approved_roi: "",
      }}
    >
      <Row gutter={16} >
        <Col span={8}>
          <Form.Item
            name="status_land_holding"
            label="Status Land Holding"
            required
            rules={[yupSync]}
          >
            <Select placeholder="Please Select" className="selct_field_observation" allowClear>
              {landholding?.map((landholdings, index) => (
                <Option key={landholdings.label} value={landholdings.label}>
                  {landholdings.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name="demarcation"
            label="Plot Demarcation Available"
            required
            rules={[yupSync]}
          >
            <Select
              placeholder="Please Select"
              className="selct_field_observation"
              allowClear
            >
              <Option value={true}>Yes</Option>
              <Option value={false}>No</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="developed_by" label="Layout developed by" required rules={[yupSync]}>
            <Select placeholder="Please Select" className="selct_field_observation" allowClear>
              {authority?.map((layout) => {
                return (
                  <Select.Option key={layout.id} value={layout.id} >
                    {layout.display_name}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>


      </Row>

      <Row gutter={16} >
        <Col span={8}>
          <Form.Item
            name="type_of_property_document"
            label="Type of property as per document"
            required
            rules={[yupSync]}
          >
            <Select placeholder="Please Select" className="selct_field_observation" allowClear>
              {types?.map((type, index) => (
                <Option key={type.id} value={type.value}>
                  {type.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name="type_of_property_site"
            label="Type of property at site"
            required
            rules={[yupSync]}
          >
            <Select placeholder="Please Select" className="selct_field_observation" allowClear>
              {types?.map((type, index) => (
                <Option key={type.label} value={type.value}>
                  {type.value}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            name="zoning"
            label="Location/Zoning as per master plan"
            required
            rules={[yupSync]}
          >
            <Select placeholder="Please Select" className="selct_field_observation" allowClear>
              {zones?.map((zone, index) => (
                <Option key={zone.label} value={zone.label}>
                  {zone.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>



      </Row>

      <Row gutter={16} >
        <Col span={8}>
          <Form.Item
            name="development_vicinity"
            label="Development of the vicinity in %"
            rules={[yupSync]}
            required
          >
            <Input
              placeholder="Please Enter Development of the vicinity in"
              onChange={(e) => handleNumberFields(e, "development_vicinity")}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name="approch_road_width"
            label="Approach road width(in feet): "
            rules={[yupSync]}
            required
          >
            <Input
              placeholder="Please Enter Approach road width(in feet)"
              onChange={(e) => handleNumberFields(e, "approch_road_width")}
            />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            name="approch_road_type"
            label="Approach road type"
            required
            rules={[yupSync]}
          >
            <Select placeholder="Please Select" className="selct_field_observation" allowClear>
              {approachroadtype?.map((approachroadtype, index) => (
                <Option
                  key={approachroadtype.id}
                  value={approachroadtype.value}
                >
                  {approachroadtype.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>


      </Row>

      <Row gutter={16} >
        <Col span={8}>
          <Form.Item
            name="govt_private_road"
            label="Govt road or Private passage"
            required
            rules={[yupSync]}
          >
            <Select placeholder="Please Select" className="selct_field_technical_roads" allowClear>
              {gov?.map((govs, index) => (
                <Option key={govs.label} value={govs.label}>
                  {govs.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="mc_gp_limit" label="Within Corporation / Urban Development / GP Limit" required rules={[yupSync]}>
            <Select
              placeholder="Please enter layout developed by"
              className="selct_field_observation" allowClear
            >
              {limitChoice?.map((limit, index) => (
                <Option key={limit.label} value={limit.value}>
                  {limit.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            name="identified_through"
            label="Identified through"
            required
            rules={[yupSync]}
          >
            <Select placeholder="Please Select" className="selct_field_technical_road" allowClear>
              {identified?.map((identify, index) => (
                <Option key={identify.label} value={identify.value}>
                  {identify.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>


      </Row>

      <Row gutter={16} >
        <Col span={8}>
          <Form.Item name="met_person_name" label="Person met at site" required rules={[yupSync]}>
            <Input placeholder="Please Enter Person Met At Site" onChange={(e) => handleCharacterFields(e, "met_person_name")} />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="type_of_structure" label="Type Of Roof" required rules={[yupSync]}>
            <Select placeholder="Please Select" className="selct_field_observation" allowClear>
              {structures?.map((structure, index) => (
                <Option key={structure.label} value={structure.value}>
                  {structure.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>


        <Col span={8}>
          <Form.Item
            name="occupation_status"
            label="Occupancy status "
            required
            rules={[yupSync]}
          >
            <Select placeholder="Please Select" className="selct_field_observation" allowClear onChange={(value) => {
              form.setFieldsValue({ occupation_status: value });
              if (value === "No") {
                form.setFieldsValue({ occupied_since: null });
                form.setFields([{ name: 'occupied_since', errors: [] }]); // Clear validation errors
              }
              setOccupied(value)
            }}>
              <Option value="Yes">Yes</Option>
              <Option value="No">No</Option>
            </Select>
          </Form.Item>
        </Col>


      </Row>

      <Row gutter={16} >
        <Col span={8}>

          <Form.Item
            name="occupied_since"
            label="Occupied since(in years)"
            required
            rules={occupied === "No" ? [] : [yupSync]}
          >

            <Input placeholder="Please Enter Occupied since(in years) " onChange={(e) => handleNumberFields(e, "occupied_since")} disabled={occupied === "No"} />

          </Form.Item>

        </Col>


        <Col span={8}>
          <Form.Item
            name="residual_life_of_property"
            label="Residual life of property (in years)"
            required
            rules={[yupSync]}
          >
            <Input
              placeholder="Please Enter Residual Life" onChange={(e) => handleNumberFields(e, "residual_life_of_property")}
            />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            name="age_of_property"
            label="Age of the property (in years)"
            required
            rules={[yupSync]}
          >
            <Input
              placeholder="Please Enter Age of the property" onChange={(e) => handleNumberFields(e, "age_of_property")}
            />
          </Form.Item>
        </Col>


      </Row>

      <Row gutter={16} >
        <Col span={8}>
          <Form.Item
            name="basic_amenities"
            label="Availability of basic amenities like - electricity, water etc."
            required
            rules={[yupSync]}
          >
            <Select placeholder="Please Select" className="selct_field_observation" allowClear>
              <Option value={true}>Yes</Option>
              <Option value={false}>No</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name="toilet"
            label="Whether toilet built in house/outside?"
            required
            rules={[yupSync]}
          >
            <Select
              placeholder="Please Select"
              allowClear
              className="selct_field_technical_road"
            >
              <Option value={true}>Yes</Option>
              <Option value={false}>No</Option>
            </Select>
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            name="maintenance_level"
            label="Maintenance level of building"
            required
            rules={[yupSync]}
          >
            <Select
              placeholder="Please Select"
              className="selct_field_technical_road"
              allowClear
            >
              {maintenance?.map((maintenances, index) => (
                <Option key={maintenances.label} value={maintenances.value}>
                  {maintenances.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>


      </Row>


      <Row gutter={16} >
        <Col span={8}>
          <Form.Item
            name="structure_government"
            label="Structure conforming to the guidelines as mentioned in NBC by Government of India:"
            required
            rules={[yupSync]}
          >
            <Select placeholder="Please Select" className="selct_field_observation" allowClear>
              <Option value={true}>Yes</Option>
              <Option value={false}>No</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name="situated_near_canal"
            label="Property situated near nala/open canal"
            rules={[{ validator: yupSync.validator, field: "situated_near_canal" }]}
            required
          >
            <Select
              placeholder="Select Option"
              className="technical-sitauated-canal"

              allowClear
              onChange={(value) => {
                form.setFieldsValue({ situated_near_canal: value });
                if (value === "false") {
                  form.setFieldsValue({ distance_from_canal: null });
                  form.validateFields(['distance_from_canal']);
                }
              }}
            >
              <Option value="true">Yes</Option>
              <Option value="false">No</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) => prevValues.situated_near_canal !== currentValues.situated_near_canal}
          >
            {({ getFieldValue }) => (
              <Form.Item
                name="distance_from_canal"
                label="Distance from nala/open canal (in feet)"

                initialValue=""
                rules={[
                  {
                    required: getFieldValue('situated_near_canal') === 'true',
                    message: 'Please enter the distance',
                  },
                ]}
              >
                <Input
                  className="technical-sitauated-canal"
                  disabled={getFieldValue('situated_near_canal') === 'false'}
                  placeholder="Please Enter Distance From Canal"
                  onChange={(e) => handleNumberFields(e, "distance_from_canal")}
                />
              </Form.Item>
            )}
          </Form.Item>
        </Col>




        <Col span={8}>
          <Form.Item
            name="situated_near_ht_lines"
            label="Property situated near high tension line "
            rules={[{ validator: yupSync.validator, field: "situated_near_ht_lines" }]}
            required
          >
            <Select
              placeholder="Please Select"
              className="technical-property-high-tension"
              onChange={(value) => {
                form.setFieldsValue({ situated_near_ht_lines: value });
                if (value === 'false') {
                  form.setFieldsValue({ distance_from_ht_line: "" });
                  form.validateFields(['distance_from_ht_line']);
                }
              }}
              allowClear

            >
              <Option value="true">Yes</Option>
              <Option value="false">No</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) => prevValues.situated_near_ht_lines !== currentValues.situated_near_ht_lines}
          >
            {({ getFieldValue }) => (
              <Form.Item
                name="distance_from_ht_line"
                label="Distance from high tension line if HT lines are witnessed (in feet)"
                initialValue=""
                rules={[
                  {
                    required: getFieldValue('situated_near_ht_lines') === 'true',
                    message: 'Please enter the distance',
                  },
                ]}
                required
              >
                <Input
                  placeholder="Please Enter Distance From HT Lines"
                  onChange={(e) => handleNumberFields(e, "distance_from_ht_line")}
                  disabled={getFieldValue('situated_near_ht_lines') === 'false'}
                />
              </Form.Item>
            )}
          </Form.Item>
        </Col>




        <Col span={8}>
          <Form.Item name="seismic_zone" label="Seismic zone classification " required rules={[yupSync]}
          >
            <Select placeholder="Please Select" className="risk_slect_filed" allowClear>
              {seismiczones?.map((seismiczone, index) => (
                <Option key={seismiczone.value} value={seismiczone.value}>
                  {seismiczone.value}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="locality_type" label="Locality Type" required rules={[yupSync]}
          >
            <Select placeholder="Please Select" className="technical-form-locality-type" allowClear>
              {localityType?.map((types, index) => (
                <Option key={types.value} value={types.value}>
                  {types.value}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>


        <Col span={8}>
          <Form.Item name="marketability" label="Marketability" required rules={[yupSync]}>
            <Select
              placeholder="Please Select"
              className="marketabilit_selcte"
              allowClear
            >
              {markebility?.map((markebilities, index) => (
                <Option key={markebilities.label} value={markebilities.label}>
                  {markebilities.value}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            name="any_hazard"
            label="Any hazard in The event of earthquakes/ land slide /cyclone /flood /chemical hazardous/ fire hazardous/tsunamis etc as per guideline of NDMA"
            required allowClear rules={[yupSync]}
          >
            <Select placeholder="Please Select" className="selct_field_observation" allowClear>
              <Option value={true}>Yes</Option>
              <Option value={false}>No</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name="market_feedback"
            label="Market feedback (broker name and Broker number or paste the link of similar properties from 99acres, magicbricks, proptiger)"
            required
            rules={[yupSync]}
          >
            <Input placeholder="Please Enter Market Feedback" />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            name="commercial_portion_witnessed"
            label="% of commercial portion witnessed at the property"
            required
            rules={[yupSync]}
          >
            <Select placeholder="Please Select" className="commercial_selcte" allowClear>
              {portion?.map((portions, index) => (
                <Option key={portions.label} value={portions.value}>
                  {portions.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            name="no_of_kitchen"
            label="Number of Kitchens available in area wise"
            required
            rules={[yupSync]}
          >
            <Select
              placeholder="Please Select"
              className="marketabilit_selcte" allowClear
            >
              {kitchen?.map((kitchens, index) => (
                <Option key={kitchens.label} value={kitchens.value}>
                  {kitchens.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name="risk_of_demolition"
            label="Risk of demolition"
            required
            rules={[yupSync]}
          >
            <Select
              placeholder="Please Select"
              className="risk_slect_filed"
              allowClear

            >
              {demolition?.map((demolitions, index) => (
                <Option key={index} value={demolitions.label}>
                  {demolitions.value}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name="recent_demolition"
            label="Any recent demolition done in this vicinity"
            rules={[{ validator: yupSync.validator, field: "recent_demolition" }]}
            required

          >
            <Select
              placeholder="Select Option"
              onChange={(value) => {
                form.setFieldsValue({ recent_demolition: value });
                if (value === 'false') {
                  form.setFieldsValue({ before_years: null });
                  form.setFields([{ name: 'before_years', errors: [] }]); // Clear validation errors
                }
              }}

              allowClear
              className="risk_slect_filed"
            >
              <Option value="true">Yes</Option>
              <Option value="false">No</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) => prevValues.recent_demolition !== currentValues.recent_demolition}
          >
            {({ getFieldValue }) => (
              <Form.Item
                name="before_years"
                label="Before how many years of recent demolition"

                initialValue=""
                rules={[
                  {
                    required: getFieldValue('recent_demolition') === 'true',
                    message: 'Please enter the years',
                  },
                ]}
                required

              >
                <Select placeholder="Please Select" className="selct_field_observation"
                  disabled={getFieldValue('recent_demolition') === 'false'}
                  allowClear
                >
                  {beforeYear?.map((beforeYears, index) => (
                    <Option key={beforeYears.label} value={beforeYears.label}>
                      {beforeYears.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            )}
          </Form.Item>
        </Col>


        <Col span={8}>
          <Form.Item
            name="property_affected"
            label="Will the property be affected for road widening"
            required
            rules={[yupSync]}
          >
            <Select placeholder="Please Select" className="selct_field_observation" allowClear onChange={(value) => {
              form.setFieldsValue({ road_widening: value });
              if (value === false) {
                // form.resetFields(["road_widening"]);
                form.setFieldsValue({ road_widening: null });
                form.setFields([{ name: 'road_widening', errors: [] }]); // Clear validation errors
              }
              setRoadWidening(value)
            }}>
              <Option value={true}>Yes</Option>
              <Option value={false}>No</Option>
            </Select>
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            name="road_widening"
            label="Road widening chances foreseen"
            required
            rules={roadWidening ? [yupSync] : []}
          >
            <Select placeholder="Please Select" className="slect_road_widening" allowClear disabled={roadWidening === false}>
              <Option value={true}>Yes</Option>
              <Option value={false}>No</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name="property_negative_area"
            label="Is the property in negative area"
            required
            rules={[yupSync]}
          >
            <Select placeholder="Please Select" className="slect_road_widening" allowClear>
              <Option value={true}>Yes</Option>
              <Option value={false}>No</Option>
            </Select>
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item name="lift_available" label="Is Lift Available?" required rules={[yupSync]}>
            <Select placeholder="Please Select" className="selct_field_observation" allowClear>
              <Option value={true}>Yes</Option>
              <Option value={false}>No</Option>
            </Select>
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            name="distance_from_branch"
            label="Distance from branch(in KM)"
            required
            rules={[yupSync]}
          >
            <Input placeholder="Please Enter Distance From Branch" onChange={(e) => handleNumberFields(e, "distance_from_branch")} />
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item
            name="house_delivery_agency"
            label="House delivery agency"
            required
            rules={[yupSync]}
          >
            <Select placeholder="Please Select" className="house_select_field_observation" allowClear>
              {houseDelivery?.map((houseDelivery, index) => (
                <Option key={houseDelivery.value} value={houseDelivery.value}>
                  {houseDelivery.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Space
        direction="horizontal"
        align="center"
        className="address_property_save_cancle"
      >
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
        <Button onClick={closeForm}>Cancel</Button>
      </Space>
    </Form>
  );
};

export default LocationPropertyDetails;
