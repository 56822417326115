import React, { useEffect, useState } from "react";
import { Button, Drawer, Flex, Form, message } from "antd";
import { Chart } from "react-google-charts";
import "../../IncomeEvaluation/styles/creditTransactionDetails.css";
import { Table } from "antd";
import add from "../../../customerTabDetails/addressDetails/assets/Add.png";
import MonthlyExpenses from "./MonthlyExpenses";
import { MonthlyInflowAndOutFlow } from "./MonthlyInflowAndOutFlow";
import { getCreditTransactionDetailsById } from "../services/creditTransactionDetailsService";
import { useParams } from "react-router-dom";
import "../../IncomeEvaluation/styles/incomeEvalution.css";
import BankBalance from "./BankBalance";
import Header from "../../../../layout/views/Header";
import { decrypt } from "../../../../../utils/cryptoUtils/cryptoUtils";
// import { decrypt } from '../../../../../utils/cryptoUtils/cryptoUtils';
import { getAllBankBalanceByLoanId } from "../services/monthlyExpensesService";
import ErrorMessage from "../../../../../utils/errorHandling/ErrorMessage";
import EditButton from "../../../../../utils/editButton/EditButton";
import Banking from "./Banking";
import { useSelector } from "react-redux";
import ConditionalRender from "../../../../../utils/authorization/AuthorizeComponent";
const { Column, ColumnGroup } = Table;

const columns = [
  {
    title: "Month",
    dataIndex: "month",
    key: "month",
    sorter: "true",
    align: "left",
  },
  {
    title: "Inward",
    dataIndex: "inward_bounce_charge_txns",
    key: "inward_bounce_charge_txns",
    align: "left",
  },
  {
    title: "Outward",
    dataIndex: "outward_bounce_charge_txns",
    key: "outward_bounce_charge_txns",
    align: "left",
  },
];

const CreditTransactionDetails = ({ customer_id, bank_id ,refreshTableIncomeData}) => {
  const { encrypted_loan_id } = useParams();
  const [form] = Form.useForm();
  const [activeButton, setActiveButton] = useState("creditTransaction");
  const [showCreditTransaction, setShowCreditTransaction] = useState(true);
  const [showMonthlyInflowOutflow, setShowMonthlyInflowOutflow] =
    useState(false);
  const [showMonthlyExpenses, setShowMonthlyExpenses] = useState(false);
  const [showBankBalance, setShowBankBalance] = useState(false);
  const [creditTransactions, setCreditTransactions] = useState([]);
  const [bouncedCheques, setBouncedCheques] = useState([]);
  const [creditChartData, setCreditChartData] = useState([]);
  const [debitChartData, setDebitChartData] = useState([]);
  const [open, setOpen] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [refreshTableData, setRefreshTableData] = useState(false);
  const [createdById, setCreatedById] = useState("");
  const [showFilterForm, setShowFilterForm] = useState(false);

  const { user_data } = useSelector((state) => state.user);

  // const { encrypted_loan_id } = useParams();

  let loan_id = null;
  try {
    loan_id = decrypt(encrypted_loan_id);
  } catch (error) {
    message.error("Invalid Loan ID");
  }

  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    form.resetFields();
    setOpen(false);
  };

  const closeFilterForm = () => {
    setShowFilterForm(false);
  };

  useEffect(() => {
    form.resetFields(); // Reset form fields when the drawer opens
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  useEffect(() => {
    const fetchBankBalnceData = async () => {
      try {
        const response = await getAllBankBalanceByLoanId(customer_id);
        if (response.status === 200 && response.success) {
          const transformedData = response.data.results.flatMap((customer) => {
            const balanceMap = {};

            customer.bank_balance_details.forEach((balance) => {
              const month = new Date(balance.bank_balance_date).toLocaleString(
                "default",
                { month: "long", year: "numeric" }
              );

              // Initialize the month entry if it doesn't exist
              if (!balanceMap[month]) {
                balanceMap[month] = {
                  month,
                  balance_5: null,
                  balance_15: null,
                  balance_25: null,
                };
              }

              // Assign balance amount based on the day extracted from bank_balance_date
              const day = new Date(balance.bank_balance_date).getDate();
              if (day === 5) {
                balanceMap[month].balance_5 = balance.balance_amount;
              } else if (day === 15) {
                balanceMap[month].balance_15 = balance.balance_amount;
              } else if (day === 25) {
                balanceMap[month].balance_25 = balance.balance_amount;
              }
            });

            // Convert the balanceMap object back to an array
            return Object.values(balanceMap);
          });

          setTableData(transformedData);
          setCreatedById(response.data.customer_id);
        }
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };

    fetchBankBalnceData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshTableData, customer_id]);

  useEffect(() => {
    form.resetFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch credit transaction data
        const creditData = await getCreditTransactionDetailsById(
          customer_id,
          loan_id,
          bank_id
        );
        const extractedCreditTransactions = creditData.data.monthly_txn.map(
          (data) => ({
            month: `${data.month} ${data.year}`,
            total_credit_txns: data?.total_credit_txns,
            total_debit_txns: data?.total_debit_txns,
            credit_amount: data?.credit_amount,
            debit_amount: data?.debit_amount,
            closing_balance: data?.closing_balance,
          })
        );
        const totalCredit = extractedCreditTransactions.reduce(
          (acc, curr) => acc + (curr.total_credit_txns || 0),
          0
        );
        const totalDebit = extractedCreditTransactions.reduce(
          (acc, curr) => acc + (curr.total_debit_txns || 0),
          0
        );

        const totalCreditRow = {
          month: "Total",
          total_credit_txns: totalCredit,
          total_debit_txns: totalDebit,
          credit_amount: "",
          debit_amount: "",
        };
        const transactionsWithTotal = [
          ...extractedCreditTransactions,
          totalCreditRow,
        ];
        setCreditTransactions(transactionsWithTotal);

        const extractedBouncedCheques = creditData.data.bounce_txn.map(
          (data) => ({
            month: `${data.month} ${data.year}`,
            inward_bounce_charge_txns: data.inward_bounce_charge_txns,
            outward_bounce_charge_txns: data.outward_bounce_charge_txns,
          })
        );
        const totalInward = extractedBouncedCheques.reduce(
          (acc, curr) => acc + (curr.inward_bounce_charge_txns || 0),
          0
        );
        const totalOutward = extractedBouncedCheques.reduce(
          (acc, curr) => acc + (curr.outward_bounce_charge_txns || 0),
          0
        );

        const totalDebitRow = {
          month: "Total",
          inward_bounce_charge_txns: totalInward,
          outward_bounce_charge_txns: totalOutward,
        };
        const TotalInwardOutward = [...extractedBouncedCheques, totalDebitRow];
        setBouncedCheques(TotalInwardOutward);

        const ChartDataOne = [
          ["Task", "Count"],
          ["Credit Transaction", totalCredit],
          ["Debit Transaction", totalDebit],
        ];
        setCreditChartData(ChartDataOne);

        const ChartDataTwo = [
          ["Task", "Count"],
          ["Inward Cheques Bounced", totalInward],
          ["Outward Cheques Bounced", totalOutward],
        ];
        setDebitChartData(ChartDataTwo);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [customer_id, loan_id, bank_id, refreshTableData]);

  const toggleRefreshTableData = () => {
    setRefreshTableData((prev) => !prev);
  };

  const options = {
    pieHole: 0.4,
    colors: ["#1890FF", "#2FC25B"],
    pieSliceText: "none",
  };

  const rowClassName = (record) => {
    if (record.month === "Total") {
      return "total-row";
    }
    return "";
  };

  const renderEditButton = (showDrawer, customer_id) => (
    <div
      className="tab_form_sub_edit"
      onClick={() => {
        setCreatedById(customer_id);

        showDrawer();
      }}
    >
      <EditButton />
    </div>
  );
  return (
    <div style={{ margin: "0" }}>
      <Flex gap="small" wrap="wrap">
        <Button
          style={{
            color: activeButton === "creditTransaction" ? "#FFF" : "#27658F",
            borderColor: "#27658F",
          }}
          type={activeButton === "creditTransaction" ? "primary" : "default"}
          onClick={() => {
            setActiveButton("creditTransaction");
            setShowCreditTransaction(true);
            setShowMonthlyInflowOutflow(false);
            setShowMonthlyExpenses(false);
            setShowBankBalance(false);
          }}
        >
          Credit Transaction Details
        </Button>
        <Button
          style={{
            color: activeButton === "monthlyInflowOutflow" ? "#FFF" : "#27658F",
            borderColor: "#27658F",
          }}
          type={activeButton === "monthlyInflowOutflow" ? "primary" : "default"}
          onClick={() => {
            setActiveButton("monthlyInflowOutflow");
            setShowCreditTransaction(false);
            setShowMonthlyInflowOutflow(true);
            setShowMonthlyExpenses(false);
            setShowBankBalance(false);
          }}
        >
          Monthly Inflow and Outflow
        </Button>
        <Button
          style={{
            color: activeButton === "monthlyExpenses" ? "#FFF" : "#27658F",
            borderColor: "#27658F",
          }}
          type={activeButton === "monthlyExpenses" ? "primary" : "default"}
          onClick={() => {
            setActiveButton("monthlyExpenses");
            setShowCreditTransaction(false);
            setShowMonthlyInflowOutflow(false);
            setShowMonthlyExpenses(true);
            setShowBankBalance(false);
          }}
        >
          Monthly Expenses
        </Button>

        <Button
          style={{
            color: activeButton === "bankbalance" ? "#FFF" : "#27658F",
            borderColor: "#27658F",
          }}
          type={activeButton === "bankbalance" ? "primary" : "default"}
          onClick={() => {
            setActiveButton("bankbalance");
            setShowCreditTransaction(false);
            setShowMonthlyInflowOutflow(false);
            setShowMonthlyExpenses(false);
            setShowBankBalance(true);
          }}
        >
          Bank Balance
        </Button>
      </Flex>

      {showCreditTransaction && (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              padding: "10px",
              gap: "10px",
            }}
          >
            {creditTransactions && creditTransactions.length === 1 ? (
              <ConditionalRender
                userPermissions={user_data?.permissions}
                requiredPermissions={["POSTCAM1"]}
              >
                <div
                  onClick={setShowFilterForm}
                  // style={{cursor:"pointer"}}
                  className="credit_transaction_details_add_img"
                >
                  <img src={add} alt="Add Button" />
                </div>
              </ConditionalRender>
            ) : (
              ""
            )}
          </div>
          <div className="">
            <div className="main_credit">
              <p className="credit_heading">Credit Transactions</p>
              <div className="credit_box">
                <div className="table-container" style={{ width: "600px" }}>
                  <Table
                    dataSource={creditTransactions}
                    pagination={false}
                    rowClassName={rowClassName}
                  >
                    <Column
                      title="Month"
                      sorter="true"
                      dataIndex="month"
                      key="month"
                      align="left"
                    />
                    <ColumnGroup title="Credit" align="center">
                      <Column
                        title="Count"
                        dataIndex="total_credit_txns"
                        key="total_credit_txns"
                        align="center"
                      />
                      <Column
                        title="Amount"
                        dataIndex="credit_amount"
                        key="credit_amount"
                        align="center"
                      />
                    </ColumnGroup>
                    <ColumnGroup title="Debit" align="center">
                      <Column
                        title="Count"
                        dataIndex="total_debit_txns"
                        key="cototal_debit_txnsunt"
                        align="center"
                      />
                      <Column
                        title="Amount"
                        dataIndex="debit_amount"
                        key="debit_amount"
                        align="center"
                      />
                    </ColumnGroup>
                    <Column
                      title="Closing Balance"
                      sorter="true"
                      dataIndex="closing_balance"
                      key="closing_balance"
                      align="center"
                    />
                  </Table>
                </div>
                <div
                  style={{
                    width: "600px",
                    margin: "0 auto",
                    background: "#FFF",
                    overflow: "auto",
                  }}
                >
                  <Chart
                    chartType="PieChart"
                    height="auto"
                    width={"100%"}
                    data={creditChartData}
                    options={options}
                  />
                </div>
              </div>
            </div>

            <div className="main_debit">
              <p>No. of Cheques Bounced</p>
              <div className="debit_box">
                <div className="">
                  <div className="cheque_div">No.of Cheques Bounced</div>
                  <div className="table-container" style={{ width: "600px" }}>
                    <Table
                      columns={columns}
                      dataSource={bouncedCheques}
                      pagination={false}
                      rowClassName={rowClassName}
                    />
                  </div>
                </div>

                <div
                  style={{
                    width: "600px",
                    margin: "0 auto",
                    background: "#FFF",
                    overflow: "auto",
                  }}
                >
                  <Chart
                    chartType="PieChart"
                    width={"100%"}
                    height="auto"
                    data={debitChartData}
                    options={options}
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {showMonthlyInflowOutflow && (
        <MonthlyInflowAndOutFlow customer_id={customer_id} bank_id={bank_id} />
      )}

      {showMonthlyExpenses && (
        <MonthlyExpenses customer_id={customer_id} bank_id={bank_id} />
      )}

      <Drawer
        title={
          <Header
            title={"Add"}
            onClose={closeFilterForm}
            name={"Credit Transction"}
          />
        }
        width={1000}
        onClose={closeFilterForm}
        open={showFilterForm}
        closable={false}
      >
        <Banking
          refreshData={toggleRefreshTableData}
          customer_id={customer_id}
          open={showFilterForm}
          bank_id={bank_id}
          closeForm={closeFilterForm}
        />
      </Drawer>
      <Drawer
        title={<Header title={"Add"} onClose={onClose} name={"Bank Balance"} />}
        width={1000}
        onClose={onClose}
        open={open}
        closable={false}
      >
        <BankBalance
          refreshData={toggleRefreshTableData}
          customer_id={customer_id}
          id={createdById}
          bank_id={bank_id}
          open={open}
          closeForm={onClose}
          refreshTableIncomeData={refreshTableIncomeData}
        />
      </Drawer>
      {showBankBalance && (
        <>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <ConditionalRender
              userPermissions={user_data?.permissions}
              requiredPermissions={["POSTCAM1"]}
            >
              <div className="tab_form_sub_edit" onClick={showDrawer}>
                <img src={add} alt="Add Button" />
              </div>
            </ConditionalRender>
            <ConditionalRender
              userPermissions={user_data?.permissions}
              requiredPermissions={["EDITCAM1"]}
            >
              <div>{renderEditButton(showDrawer, customer_id)}</div>
            </ConditionalRender>
          </div>

          <div className="table-responsive" style={{ marginTop: "20px" }}>
            <Table
              dataSource={tableData}
              columns={[
                {
                  title: "Month & Year",
                  dataIndex: "month",
                  key: "month",
                  render: (text) => <div>{text}</div>,
                },
                {
                  title: "Day 5",
                  dataIndex: "balance_5",
                  key: "balance_5",
                  render: (text) => <div>{text}</div>,
                },
                {
                  title: "Day 15",
                  dataIndex: "balance_15",
                  key: "balance_15",
                  render: (text) => <div>{text}</div>,
                },
                {
                  title: "Day 25",
                  dataIndex: "balance_25",
                  key: "balance_25",
                  render: (text) => <div>{text}</div>,
                },
              ]}
              rowKey="month" // Set a unique key for each row
              pagination={false}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default CreditTransactionDetails;
