
const IndianNumberFormat = (amount) => {
    // Ensure amount is a valid number
    const validAmount = isNaN(amount) || amount === null ? 0 : Number(amount);
    const formatter = new Intl.NumberFormat('en-IN');
    return formatter.format(validAmount);
  }
  
  export default IndianNumberFormat;
  


// const IndianNumberFormat = (number) => {
//    // Convert number to string
//    let strNumber = number.toString();
//    // Split the string into integer and decimal parts
//    const parts = strNumber.split(".");
//    // Format the integer part
//    let integerPart = parts[0];
//    // Separate the last three digits
//    let lastThree = integerPart.substring(integerPart.length - 3);
//    // Separate the other digits
//    let otherNumbers = integerPart.substring(0, integerPart.length - 3);
//    // Add comma if otherNumbers is not empty
//    if (otherNumbers !== '') {
//      lastThree = ',' + lastThree;
//    }
//    // Format otherNumbers with commas after every two digits
//    otherNumbers = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",");
//    // Join the parts back together with a comma
//    integerPart = otherNumbers + lastThree;
//    return integerPart + (parts[1] ? "." + parts[1] : "");
// }

// export default IndianNumberFormat

