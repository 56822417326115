import { Divider, Space, message, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import "../styles/dedupe.css";
import CustomerMatching from "./CustomerMatching";
import OverallStatus from "./OverAllStatus";
import PropertyMatching from "./PropertyMatching";
import { useParams } from "react-router-dom";
import "../../loanApplication/loanSummary/styles/loanApplication.css";
import { decrypt } from "../../../utils/cryptoUtils/cryptoUtils";
import LoanDetailMainCard from "../../loanApplication/loanSummary/views/LoanDetailMainCard";
import { getCustomersByLoanId } from "../services/dedupeService";
import Loading from "../../../utils/loading/Loading";
import ErrorMessage from "../../../utils/errorHandling/ErrorMessage";

const Dedupe = () => {
  const { encrypted_loan_id } = useParams();
  const [activeKey, setActiveKey] = useState("0");
  const [customers, setCustomers] = useState(null);
  const [loading, setLoading] = useState(false);

  const onChange = (key) => {
    setActiveKey(key);
  };

  let loan_id = null;
  try {
    loan_id = decrypt(encrypted_loan_id);
  } catch (error) {
    message.error("Invalid Loan ID");
  }

  const tabs = [
    {
      key: "PM",
      label: "Property Matching",
      children: <PropertyMatching loanId={loan_id} />,
    },
    {
      key: "OS",
      label: "Overall Status",
      children: <OverallStatus />,
    },
  ];

  useEffect(() => {
    setLoading(true);
    const getCustomersForLoan = async () => {
      try {
        const response = await getCustomersByLoanId(loan_id);
        const tabsData = response?.data?.customer_list?.map((customer) => {
          return {
            key: String(customer?.id),
            label: customer?.name,
            children: <CustomerMatching customer_id={customer?.id} />,
          };
        });
        setCustomers([...tabsData, ...tabs]);
        setActiveKey(tabsData[0].key);
        setLoading(false);
      } catch (error) {
        setLoading("false");
        setCustomers(tabs);
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };
    getCustomersForLoan();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      {loading ? (
        <div style={{ border: "1px solid #ddd" }}>
          <Loading style={{ width: "100%" }} loading={loading} />
        </div>
      ) : (
        <>
          <div className="loan_application_progress_dedupe">
            <div style={{ margin: "12px" }}>
              <LoanDetailMainCard />
            </div>
          </div>

          <div className="dedupe_main_container">
            <Space
              direction="horizontal"
              align="center"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <h2 className="dedupe_heading">Matched LAN’s</h2>
            </Space>

            <Divider style={{ margin: " 0px" }} />
            <Tabs
              style={{ margin: "20px" }}
              type="card"
              defaultActiveKey={activeKey}
              activeKey={activeKey}
              items={customers}
              onChange={onChange}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default Dedupe;
