import React, { useEffect, useState } from "react";
import { Button, DatePicker, Form, Input, Select, Space, Table, message } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import moment from "moment";
import { listDocumentTypeService } from "../services/documentDetailService";
import dayjs from "dayjs";
import ErrorMessage from "../../../utils/errorHandling/ErrorMessage";
const { TextArea } = Input;
const { Option } = Select;

const ScrutinyAndLegalOpinionInputForm = ({ closeForm, selectedScrutiny, editValue, setEnteredData, refreshData }) => {
  const [documentType, setDocumentType] = useState([]);
  const [form] = Form.useForm();
  const fetchDocumentType = async () => {
    try {
      const response = await listDocumentTypeService();
      setDocumentType(response.data.choices);
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };
  useEffect(() => {
    fetchDocumentType();
  }, []);

  useEffect(() => {
    if (!selectedScrutiny) {
      form.resetFields();
    }
    if (editValue) {
      form.setFieldsValue({
        documents: [
          {
            document: editValue.document,
            key: editValue.key,
            dated: editValue.dated ? dayjs(editValue.dated, 'YYYY-MM-DD') : null, // Ensure moment object
            document_type: editValue.document_type,
          },
        ],
      });
    }
  }, [selectedScrutiny, form, editValue]);


  const handleSubmit = (values) => {
    setEnteredData(values.documents); // Pass the entered data to the parent component
    form.resetFields();
    closeForm(); // Close the form after submitting

  };

  const customDateValidator = (_, value) => {
    const today = moment().startOf("day");

    if (!value) {
      return Promise.reject("");
    }

    if (value.isAfter(today, 'day')) {
      return Promise.reject("Date cannot be in the future");
    }
    return Promise.resolve();
  };

  return (
    <Form form={form} onFinish={handleSubmit}>
      <Form.List name="documents" initialValue={[{ document: "", dated: "" }]}>
        {(fields, { add, remove }) => {
          const columns = [
            {
              title: "Sr. No",
              dataIndex: "key",
              key: "sr_no",
              render: (text, record, index) => index + 1,
            },
            {
              title: "Document",
              dataIndex: ["document"],
              key: "document",
              width: 250,
              render: (text, record, index) => (
                <Form.Item
                  name={[fields[index].name, "document"]}
                  rules={[{ required: true, message: "Please enter document" }]}
                >
                  <TextArea rows={1} placeholder="Enter document"
                    className="legal_initiat_input_custom_textarea"
                  />
                </Form.Item>
              ),
            },
            {
              title: "Dated",
              dataIndex: ["dated"],
              key: "dated",
              width: 250,
              render: (text, record, index) => (
                <Form.Item
                  name={[fields[index].name, "dated"]}
                  rules={[
                    { required: true, message: "Please select date" },
                    { validator: customDateValidator },
                  ]}
                >
                  <DatePicker 
                                disabledDate={(current) => current && current > moment().endOf("day")}
                  className="legal_input_as_per_document_date"
                   format="DD-MM-YYYY" 
                   placeholder="DD-MM-YYYY"
                    />
                </Form.Item>
              ),
            },
            {
              title: "Type of Document",
              dataIndex: ["document_type"],
              key: "document_type",
              width: 300,
              render: (text, record, index) => (
                <Form.Item
                  name={[fields[index].name, "document_type"]}
                  rules={[{ required: true, message: "Please select document type" }]}
                >
                  <Select
                    mode="single"
                    placeholder="Please Select"
                    allowClear
                    showSearch
                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                  >
                    {documentType?.map((documenttype) => (
                      <Option key={documenttype.value} value={documenttype.value}>
                        {documenttype.label}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              ),
            },
            {
              title: "Actions",
              fixed: "right",
              render: (_, record, index) => (
                <Space size="middle">
                  <DeleteOutlined
                    className={
                      index === 0
                        ? "legal_initiat_input_delete_icon-disabled"
                        : "legal_initiat_input_delete_icon"
                    }
                    onClick={() => index !== 0 && remove(record.name)}
                    disabled={index === 0}
                    title={index === 0 ? "Cannot delete the first document" : ""}
                  />
                </Space>
              ),
            },
          ];

          return (
            <>
              <Table
                dataSource={fields.map((field) => ({ ...field, key: field.key }))}
                columns={columns}
                pagination={false}
                scroll={{ x: 1000 }}
              />
              {!selectedScrutiny && (
                <Button type="primary" onClick={() => add()} className="legal_input_as_per_document">
                  More Documents
                </Button>
              )}
            </>
          );
        }}
      </Form.List>
      <Space direction="horizontal" align="center"  className="conclusion_initiate_input_form_space">
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
        <Button onClick={closeForm}>Cancel</Button>
      </Space>
    </Form>
  );
};

export default ScrutinyAndLegalOpinionInputForm;
