import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  notification_data: [],
  status: false,
};

const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    setNotification: (state, action) => {
      state.notification_data.push(action.payload);
    },
    clearNotification: (state, action) => {
      state.notification_data = [];
    },
    setNotificationStatus: (state, action) => {
      state.status = !state.status;
    },
  },
});

export const { setNotification, clearNotification, setNotificationStatus } =
  notificationSlice.actions;

export default notificationSlice.reducer;
