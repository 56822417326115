/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from "react";
import {
  Col,
  Button,
  Form,
  Input,
  Row,
  Select,
  Space,
  message,
  DatePicker,
  Tabs,
  Upload,
  Modal,
} from "antd";
import "../styles/insuranceForm.css";
import {
  getInsuranceDetailsByIdService,
  getRelationByIdService,
  getAllCityService,
  getAllStateService,
  getallpincode,
  getAllOccuptationTypeService,
  getPersonalDetailsByIdService,
  postMagmaHDI,
  postEditMagma,
  deleteHDFCDocumentDetailsByIdService,
  getMagmaPlanningList,
  getAdditionalLoanDetailIdService,
  getInsuranceAddressTypeList,
  getAddressDetailByAddresType,
  getDocumentDetailByDocumentType,
  insurancePincodeCodeService,
} from "../services/loanInsuranceService";
import moment from "moment";
import dayjs from "dayjs";
import RViewerJS from "viewerjs-react";
import "viewerjs-react/dist/index.css";
import { UploadOutlined, DeleteOutlined } from "@ant-design/icons";
import * as Yup from "yup";
import pdf_image from "../assets/pdf.png";
import ErrorMessage from "../../../../../utils/errorHandling/ErrorMessage";
import MagmaInsuredCustomerForm from "./MagmaInsuredCustomerForm";
import MagmaHDIInsurance from "./MagmaHDIInsurance";
import { listNationality } from "../../../customerTabDetails/personalDetails/services/personalDetailsServices";
import { listLocationService } from "../../../listOfApplication/services/listOfApplicationService";
import getDocumentTypeList from "../../../../fi/services/getDocumentTypeList";
import { stringToDate } from "../../../../../utils/dateConvertor/DateConvertor";
import IndianNumberFormat from "../../../../../utils/indianNumberFormat/IndianNumberFormat";

const { Option } = Select;

const validationSchema = Yup.object().shape({
  insured_customer_name: Yup.string().required(
    "Insured Customer Name is required"
  ),
  branch_address: Yup.string().required("Branch Address is required"),
  property_type: Yup.string().required("Property Type is required"),
  ocupation: Yup.string().required("Occupation is required"),
  city: Yup.string().required("City is required"),
  state: Yup.string().required("State is required"),
  pincode: Yup.string().required("Pincode is required"),
  salutation: Yup.string().required("Salutation is required"),
  mobile: Yup.string().required("Mobile is required"),
  gender: Yup.string().required("Gender is required"),
  email: Yup.string().required("Email is required"),
  address_line_1: Yup.string()
    .required("Address Line 1 is required")
    .max(225, "Address Line 1 cannot exceed 225 characters"),
  address_line_2: Yup.string()
    .required("Address Line 2 is required")
    .max(225, "Address Line 2 cannot exceed 225 characters"),
  nationality: Yup.string().required("Nationality is required"),
  date_of_birth: Yup.date()
    .nullable()
    .transform((curr, orig) => (orig === "" ? null : curr))
    .required("Date Of Birth is required"),
  applicant_type: Yup.string().required("Applicant Type is required"),
  share_of_loan: Yup.string().required("Share Of Loan is required"),
  pan_no: Yup.string().required("PAN Number is required"),
  height: Yup.string().required("Height is required"),
  weight: Yup.string().required("Weight is required"),
  loan_amount: Yup.number().required("Loan Amount is required"),
  loan_tenure: Yup.number().required("Tenure of loan is required"),
  policy_term: Yup.number()
    .transform((value, originalValue) => (originalValue === "" ? null : value)) // Convert empty string to null
    .nullable() // Allows the field to be null
    .required("Policy Term is required")
    .max(360, "Policy Term cannot be more than 360 months"),
  sum_assured: Yup.string().required("Sum Assured is required"),
  total_premium_incl_gst: Yup.string().required(
    "Total Premium Inclusive Of GST is required"
  ),
  emi_amount: Yup.string().required("EMI Amount is required"),
  monthly_income: Yup.string().required("Monthly Income is required"),
  age_proof: Yup.string().required("Age Proof is required"),
  nominee_name: Yup.string().required("Nominee Name is required"),
  nominee_nationality: Yup.string().required("Nationality is required"),
  nominee_email: Yup.string().required("Email is required"),
  nominee_mobile: Yup.string()
    .required("Mobile Number is required")
    .matches(/^\d{10}$/, "Nominee Contact Number must be exactly 10 digits"),
  nominee_date_of_birth: Yup.date()
    .max(
      new Date(),
      "Nominee Date of Birth cannot be a today's date or future date"
    )
    .required("Nominee Date Of Birth is required"),
  nominee_gender: Yup.string().required("Gender is required"),
  nominee_address_line1: Yup.string()
    .required("Address Line 1 is required")
    .max(225, "Address Line 1 cannot exceed 225 characters"),
  nominee_address_line2: Yup.string()
    .required("Address Line 2 is required")
    .max(225, "Address Line 2 cannot exceed 225 characters"),
  relation_with_assured_person: Yup.string().required("Relation is required"),
  nominee_insured_customer_name: Yup.string().required("Name is required"),
  nominee_city: Yup.string().required("City is required"),
  nominee_state: Yup.string().required("State is required"),
  nominee_pincode: Yup.string().required("Pincode is required"),
  nominee_relation: Yup.string().required("Relation is required"),
  nominee_contact_number: Yup.string()
    .required("Nominee Contact Number is required")
    .matches(/^\d{10}$/, "Nominee Contact Number must be exactly 10 digits"),
  total_premium_amount: Yup.string().required(
    "Total Premium Amount is required"
  ),
  nominee_relationship_with_life_to_be_assured: Yup.string().required(
    "Relationship With Life To Be Assured is required"
  ),
  contact_number: Yup.string().required("Contact Number is required"),
  apointee_name: Yup.string().required("Appointee Name is required"),
  apointee_date_of_birth: Yup.date()
    .max(new Date(), "Date of Birth cannot be today's date or a future date")
    .test(
      "is-18-years-old",
      "Appointee is not yet 18 years old",
      function (value) {
        const today = new Date();
        const eighteenYearsAgo = new Date(
          today.getFullYear() - 18,
          today.getMonth(),
          today.getDate()
        );
        return value <= eighteenYearsAgo;
      }
    )
    .required("Appointee Date Of Birth is required"),

  apointee_gender: Yup.string().required("Appointee Gender is required"),
  apointee_contact_number: Yup.string()
    .required("Appointee Contact Number is required")
    .matches(/^\d{10}$/, "Appointee Contact Number must be exactly 10 digits"),
  tenure: Yup.string().required("Tenure is required"),
  apointee_relationship_with_life_to_be_assured: Yup.string().required(
    "Appointee Relationship With Life To Be Assured is required"
  ),
});

const yupSync = {
  async validator({ field }, value) {
    await validationSchema.validateSyncAt(field, { [field]: value });
  },
};

const MagmaHDIInsuranceForm = ({
  id,
  loan_id,
  insurance_id,
  customerId,
  customer_id,
  closeDrawer,
  closeForm,
  toggleRefreshTableData,
  toggleRefreshInsurance,
  setSelectedCustomer,
  setSelectedInsuranceType,
  disabledButton,
  setDisabledButton,
  monthlyIncome,
}) => {
  const [form] = Form.useForm();
  const [relation, setRelation] = useState([]);
  const [activeKey, setActiveKey] = React.useState("0"); // Initial activeKey
  const [city, setCity] = useState([]);
  const [states, setStates] = useState([]);
  const [nationality, setNationality] = useState([]);
  const [pincode, setPincode] = useState([]);
  const [datas, setDatas] = useState([]);
  const [planDetails, setPlanDetails] = useState({});
  const [count, setCount] = useState(0);
  const [occupationType, setOccupationTypes] = useState([]);
  const [personalData, setPersonalData] = useState([]);
  const [planningListData, setPlanningListData] = useState();
  const [, setMatchedCustomer] = useState(null);
  const [matchedNominee, setMatchedNominee] = useState(null);
  const [nominee, setSelectedNominee] = useState();
  const [isUnder18, setIsUnder18] = useState(false);
  const [, setLoanid] = useState("");
  const [branchLocations, setBranchLocations] = useState([]);
  const [documentTypes, setDocumentTypes] = useState([]);
  const [nomineeId, setNomineeId] = useState();
  const [editloanid, setEditloanid] = useState("");
  const [documentUrls, setDocumentUrls] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [loanData, setLoanData] = useState([]);
  const [image, setImage] = useState([]);
  const [existingImages, setExistingImages] = useState([]);
  const [deleteUploadPicture, setDeleteUploadPicture] = useState(null);
  const [openUploadModal, setOpenUploadModal] = useState(false);
  const [deletePicture, setDeletePicture] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [insurancePlanMagma, setInsurancePlanMagma] = useState();
  const [formValues, setFormValues] = useState({});
  const [appointeeId, setAppointeeId] = useState();
  const [nomineeData, setNomineeData] = useState([]);
  const [addressType, setAddressType] = useState([]);
  const [addressTypeList, setAddressTypeList] = useState([]);
  const [insuranceCoverageData, setInsuranceCoverageData] = useState();
  const [nomineeAddressType, setNomineeAddressType] = useState("");
  const [documentTypeDetail, setDocumentTypeDetail] = useState(null);
  const [documentName, setDocumentName] = useState("");
  const [checkCustomer, setCheckCustomer] = useState(null);

  const getAllRelationlist = async () => {
    try {
      const response = await getRelationByIdService();
      setRelation(response.data);
    } catch (error) {
      message.error(
        error?.response?.data?.message || "Failed to fetch relations."
      );
    }
  };

  const getAllCitylist = async () => {
    try {
      const response = await getAllCityService();
      setCity(response.data);
    } catch (error) {
      message.error(
        error?.response?.data?.message || "Failed to fetch cities."
      );
    }
  };

  const getAllState = async () => {
    try {
      const response = await getAllStateService();
      setStates(response.data);
    } catch (error) {
      message.error(
        error?.response?.data?.message || "Failed to fetch states."
      );
    }
  };

  const getAllNationality = async () => {
    try {
      const response = await listNationality();
      setNationality(response?.data);
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  const getAllPincodelist = async () => {
    try {
      const response = await getallpincode();
      setPincode(response.data);
    } catch (error) {
      message.error(
        error?.response?.data?.message || "Failed to fetch pincodes."
      );
    }
  };

  const getAllOccupationlist = async () => {
    try {
      const response = await getAllOccuptationTypeService();
      setOccupationTypes(response.data);
      if (response && response.data) {
        if (response.status === 200 && response.data.success) {
          message.success("Pincode details updated successfully");
        }
      }
    } catch (error) {
      message.error(error?.response?.status, error?.response?.data?.message);
    }
  };

  const getAllPlanninglist = async () => {
    try {
      const response = await getMagmaPlanningList();
      setPlanningListData(response.data);
    } catch (error) {
      message.error(error?.response?.status, error?.response?.data?.message);
    }
  };

  const getAddressTypeList = async () => {
    try {
      const response = await getInsuranceAddressTypeList();
      setAddressTypeList(response.data);
    } catch (error) {
      message.error(error?.response?.status, error?.response?.data?.message);
    }
  };

  const fetchData = async () => {
    try {
      const response = await getPersonalDetailsByIdService(loan_id);
      setPersonalData(response.data.customers);
      setLoanid(response.data.loan_detail);
    } catch (error) {
      message.error(
        `${error?.response?.status}: ${error?.response?.data?.message}`
      );
    }
  };

  const getLocationList = async () => {
    try {
      const response = await listLocationService();
      setBranchLocations(response.data);
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  const getDocumentType = async () => {
    try {
      const response = await getDocumentTypeList();
      setDocumentTypes(response.data);
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  const fetchLoanData = async () => {
    try {
      const response = await getAdditionalLoanDetailIdService(loan_id);
      setLoanData(response.data);
    } catch (error) {
      message.error(
        `${error?.response?.status}: ${error?.response?.data?.message}`
      );
    }
  };

  useEffect(() => {
    getAllRelationlist();
    getAllCitylist();
    getAllState();
    getAllPincodelist();
    getAllOccupationlist();
    getAllPlanninglist();
    getAllNationality();
    getLocationList();
    getDocumentType();
    getAddressTypeList();
  }, []);

  useEffect(() => {
    if (loan_id) {
      fetchData();
      fetchLoanData();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loan_id, customer_id]);

  useEffect(() => {
    if (formValues.length > 0 && !insurance_id) {
      formValues?.forEach((item, index) => {
        if (item) {
          form.setFieldsValue({
            [`insured_customer_name_${index}`]:
              item?.insured_customer_name || "",
            [`ocupation_${index}`]: item?.ocupation || undefined,
            [`gender_${index}`]: item?.gender || undefined,
            [`nationality_${index}`]: item?.nationality || undefined,
            [`mobile_${index}`]: item?.mobile || "",
            [`email_${index}`]: item?.email || "",
            [`address_line_1_${index}`]: item?.address_line_1 || "",
            [`address_line_2_${index}`]: item?.address_line_2 || "",
            [`city_${index}`]: item?.city || undefined,
            [`state_${index}`]: item?.state || undefined,
            [`pincode_${index}`]: item?.pincode || undefined,
            [`customer_address_type_${index}`]:
              item?.customer_address_type?.[0] || undefined, // Add this line
          });
        } else {
          form.setFieldsValue({
            loan_amount: "",
            tenure: "",
            policy_term: "",
            sum_assured: "",
            monthly_income: "",
            emi_amount: "",
            total_premium_amount: "",
            age_proof: undefined,
            branch_address: undefined,
          });
        }
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues, form, customerId]);

  const getUserData = useCallback(async () => {
    if (!insurance_id) return;

    try {
      const response = await getInsuranceDetailsByIdService(insurance_id);

      if (!response || !response.data) {
        throw new Error("No data in response");
      }

      const {
        nominee_detail,
        insurance_coverage_data,
        insured_customer_under_magma_plan,
        insurance_magma_plan,
      } = response?.data || {};

      setCount(insured_customer_under_magma_plan?.length || 0);

      const nominee_dob = nominee_detail[0]?.date_of_birth;
      const checkAppointee = nominee_detail[0]?.appointee;
      setAppointeeId(checkAppointee?.id || null);

      const parsedDate = moment(nominee_dob, "DD-MM-YYYY");
      const age = moment().diff(parsedDate, "years");
      setIsUnder18(age < 18);

      setNomineeId(nominee_detail[0]?.id || null);
      setNomineeData(nominee_detail[0]);
      setInsuranceCoverageData(insurance_coverage_data);
      setFileList(response?.data?.document || []);
      setExistingImages(response?.data?.document || []);
      setDatas(insured_customer_under_magma_plan || []);
      setPlanDetails(insurance_magma_plan || {});
      setEditloanid(response?.data?.loan_detail || null);
      setNomineeAddressType("")
      if (nominee_detail[0]?.customer_detail?.id) {
        setSelectedNominee(nominee_detail[0]?.customer_detail?.id);
      } else {
        setSelectedNominee(null);
      }

      if (nominee_detail && nominee_detail.length > 0) {
        const nominee = nominee_detail[0];
        form.setFieldsValue({
          nominee_name: nominee?.name || "",
          nominee_date_of_birth: dayjs(nominee?.date_of_birth, "DD-MM-YYYY"),
          nominee_gender: nominee?.gender || "",
          nominee_contact_number: nominee?.mobile || "",
          nominee_relationship_with_life_to_be_assured:
            nominee?.relation?.id || "",
          address_type: nominee?.address_type?.id,
          nominee_address_line1: nominee?.address_line_1 || "",
          nominee_address_line2: nominee?.address_line_2 || "",
          nominee_city: nominee?.city?.id || "",
          nominee_state: nominee?.state?.id || "",
          nominee_pincode: nominee?.pincode?.id || "",
        });
      }

      if (checkAppointee) {
        const appointeedateOfBirthValue = dayjs(
          checkAppointee?.date_of_birth,
          "DD-MM-YYYY"
        );
        form.setFieldsValue({
          apointee_name: checkAppointee?.appointee_name || "",
          apointee_gender: checkAppointee?.gender || "",
          apointee_date_of_birth: appointeedateOfBirthValue,
          apointee_relationship_with_life_to_be_assured:
            checkAppointee?.relation?.id || "",
          apointee_contact_number: checkAppointee?.mobile || "",
        });
      }

      if (insurance_coverage_data) {
        form.setFieldsValue({
          loan_amount: insurance_coverage_data?.loan_amount || "",
          tenure: insurance_coverage_data?.tenure || "",
          policy_term: insurance_coverage_data?.policy_term || "",
          sum_assured: insurance_coverage_data?.sum_assured || "",
          monthly_income: insurance_coverage_data?.monthly_income || "",
          emi_amount: insurance_coverage_data?.emi_amount || "",
          total_premium_amount:
            insurance_coverage_data?.total_premium_incl_gst || "",
          age_proof: insurance_coverage_data?.age_proof?.id || "",
          branch_address: insurance_coverage_data?.branch_address?.id || "",
        });
      }
    } catch (error) {
      console.error("Error fetching insurance details:", error);
      message.error("Failed to fetch insurance details.");
    }
  }, [insurance_id]);

  useEffect(() => {
    if (insurance_id) {
      getUserData();
    } else {
      form.resetFields();
      setFileList([]);
      setImage([]);
    }
  }, [insurance_id]); // Run useEffect when insurance_id changes

  useEffect(() => {
    if (nominee) {
      if (nominee === "other") {
        form.setFieldsValue({
          nominee_name: "",
          nominee_gender: null,
          nominee_date_of_birth: "",
          nominee_contact_number: "",
          nominee_relationship_with_life_to_be_assured: undefined,
          // nominee_address_type:undefined,
          nominee_address_line1: "",
          nominee_address_line2: "",
          nominee_city: undefined,
          nominee_state: undefined,
          nominee_pincode: undefined,
          apointee_name: "",
          apointee_gender:undefined,
          apointee_date_of_birth: "",
          apointee_relationship_with_life_to_be_assured: undefined,
          apointee_contact_number: "",
        });
        setIsUnder18(false)
      } else {
        const foundCustomer = personalData.find(
          (person) => person.id === nominee
        );

        if (foundCustomer) {
          setMatchedNominee(foundCustomer);
          const [day, month, year] = foundCustomer?.date_of_birth.split("-");
          const formattedDate = dayjs(`${year}-${month}-${day}`, "YYYY-MM-DD"); // Create a moment object
          const parsedDate = moment(formattedDate, "DD-MM-YYYY");

          // Calculate age
          const age = moment().diff(parsedDate, "years");
          if (age < 18) {
            setIsUnder18(true);
          } else {
            setIsUnder18(false);
          }

          // setNomineeAddressType(null)
          form.setFieldsValue({
            nominee_name: `${foundCustomer.first_name} ${foundCustomer.middle_name} ${foundCustomer.last_name}`,
            nominee_gender: foundCustomer.gender,
            nominee_contact_number: foundCustomer.mobile_number,
            nominee_date_of_birth: formattedDate,
            // nominee_relationship_with_life_to_be_assured: undefined,
            nominee_address_line1: "",
            // address_type:undefined,
            // nominee_address_type: undefined,
            nominee_address_line2: "",
            nominee_city: undefined,
            nominee_state: undefined,
            nominee_pincode: undefined,
            apointee_name: "",
            apointee_date_of_birth: "",
            apointee_relationship_with_life_to_be_assured: undefined,
            apointee_contact_number: "",
          });
        }
      }
    }
  }, [nominee, form]);

  useEffect(() => {
    const updateAddressDetail = async () => {
      try {
        const response = await getAddressDetailByAddresType(
          nominee,
          nomineeAddressType
        );
        if (response?.data) {
          const { address_line_1, address_line_2, city, state, pincode } =
            response.data;
          form.setFieldsValue({
            [`nominee_address_line1`]: address_line_1 || undefined,
            [`nominee_address_line2`]: address_line_2 || undefined,
            [`nominee_city`]: city.id || null,
            [`nominee_state`]: state.id || null,
            [`nominee_pincode`]: pincode.id || null,
          });
        }
      } catch (error) {
        console.error("Failed to fetch address details:", error);
      }
    };

    if (nominee !== "other" && nomineeAddressType) {
      updateAddressDetail();
    } else {

      form.resetFields([
        `nominee_address_line1`,
        `nominee_address_line2`,
        `nominee_city`,
        `nominee_state`,
        `nominee_pincode`,
      ]);
    }
  }, [nomineeAddressType]);
  const filterOption = (input, option) =>
    (option?.children ?? "").toLowerCase().indexOf(input.toLowerCase()) >= 0;

  const handleNomineeChange = (value) => {
    if (value === "other") {
      form.setFieldsValue({
        nominee_name: null,
        nominee_date_of_birth: null,
        nominee_gender: null,
        nominee_contact_number: null,
        nominee_nationality: null,
        nominee_relationship_with_life_to_be_assured: undefined,
        nominee_address_line1: "",
        nominee_address_line2: "",
        nominee_city: undefined,
        nominee_state: undefined,
        nominee_pincode: undefined,
        address_type: undefined,
        apointee_name: "",
        apointee_date_of_birth: "",
        apointee_relationship_with_life_to_be_assured: undefined,
        apointee_contact_number: "",
      });
    } else {
      form.setFieldsValue({
        nominee_relationship_with_life_to_be_assured: undefined,
        address_type: undefined,
      });
    }
    setSelectedNominee(value);
  };

  const fileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader?.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const handleUploadChange = async ({ file, fileList }) => {
    if (file.status === "removed") {
      // Filter out removed files
      const newDocumentUrls = documentUrls.filter((url) => url !== file.url);
      setDocumentUrls(newDocumentUrls);
    } else if (file.status === "done") {
      // Add newly uploaded file URL
      const base64 = await fileToBase64(file.originFileObj);
      setDocumentUrls([...documentUrls, base64]);
    }
    setFileList(fileList);
  };

  const handleDateChange = (date, dateString) => {
    if (date) {
      // Parse the given date string in DD-MM-YYYY format
      const parsedDate = moment(dateString, "DD-MM-YYYY");

      // Calculate age
      const age = moment().diff(parsedDate, "years");

      setIsUnder18(age < 18);
    } else {
      setIsUnder18(false);
    }
  };

  const handleBeforeUpload = (file) => {
    const isJpgOrPngOrPdf =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "application/pdf";
    if (!isJpgOrPngOrPdf) {
      message.error("You can only upload JPG/PNG/PDF file!");
      return Upload.LIST_IGNORE;
    }

    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";

    const newImage = {
      file_path: isJpgOrPng ? URL.createObjectURL(file) : pdf_image,
      uid: file.uid,
    };

    setImage((prevImages) => [...prevImages, newImage]);
    setFileList((prevFileList) => [...prevFileList, file]);
    return false; // Prevent automatic upload
  };
  const validateAddressFields = (formValues, customerId) => {
    if (customerId) {
      // Check if all address fields are present and not empty
      const { address_line_1_0, address_line_2_0, city_0, state_0, pincode_0 } =
        formValues;
      // Check if all fields are empty strings
      if (
        !address_line_1_0 &&
        !address_line_2_0 &&
        !city_0 &&
        !state_0 &&
        !pincode_0
      ) {
        message.error("All address fields cannot be empty.");
      }
    }
  };

  const handleSubmit = async (values) => {
    setLoading(true);
    validateAddressFields(values, customerId);

    const convertDate = (dateString) => {
      const date = new Date(dateString);
      const year = date.getUTCFullYear();
      const month = String(date.getUTCMonth() + 1).padStart(2, "0");
      const day = String(date.getUTCDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    };

    const insured_customers = [];
    const nominne = {
      id:insurance_id ? nomineeId : "",
      name: values.nominee_name,
      mobile: values.nominee_contact_number,
      mobile_country_code: "91",
      date_of_birth: convertDate(values?.nominee_date_of_birth),
      gender: values?.nominee_gender,
      address_type: values?.address_type,
      address_line_1: values?.nominee_address_line1,
      address_line_2: values?.nominee_address_line2,
      relation: values?.nominee_relationship_with_life_to_be_assured,
      city: values?.nominee_city,
      state: values?.nominee_state,
      pincode: values?.nominee_pincode,
      customer_detail: nominee === "other" ? null : nominee,
        appointee_detail: isUnder18 && {
          id: insurance_id ? appointeeId : "",
          appointee_name: values.apointee_name,
          mobile: values.apointee_contact_number,
          mobile_country_code: "91",
          date_of_birth: convertDate(values.apointee_date_of_birth),
          gender: values.apointee_gender,
          relation: values.apointee_relationship_with_life_to_be_assured,
        },
    };

    const numberOfInsured = formValues.length;
    for (let i = 0; i < numberOfInsured; i++) {
      const insuredCustomer = {
        ocupation: values[`ocupation_${i}`],
        city: values[`city_${i}`],
        state: values[`state_${i}`],
        pincode: values[`pincode_${i}`],
        address_line_1: values[`address_line_1_${i}`],
        address_line_2: values[`address_line_2_${i}`],
        customer_address_type: values[`customer_address_type_${i}`],
        customer_detail: values[`planCustomerId_${i}`],
        relationship_with_insured_customer: values[`relation_${i}`],
      };

      if (i > 0) {
        insuredCustomer.name = values[`insured_customer_name_${i}`];
        insuredCustomer.gender = values[`gender_${i}`];
        insuredCustomer.email = values[`email_${i}`];
        insuredCustomer.date_of_birth = convertDate(
          values[`date_of_birth_${i}`]
        );
        insuredCustomer.nationality = values[`nationality_${i}`];
        insuredCustomer.mobile = values[`mobile_${i}`];
        insuredCustomer.mobile_country_code = "91";
      }
      insured_customers.push(insuredCustomer);
    }

    // Handle propertyDocumentDetail
    const propertyDocumentDetail = insurance_id
      ? fileList
      : await Promise.all(
          fileList.map(async (file) => {
            const base64 = await fileToBase64(file.originFileObj);
            return base64;
          })
        );

    if (insurance_id) {
      const filterDocument = propertyDocumentDetail.filter(
        (item) => !item.hasOwnProperty("document")
      );
      const convertFilterDocumetToBase64 = await Promise.all(
        filterDocument.map(async (file) => {
          const base64 = await fileToBase64(file.originFileObj);
          return base64;
        })
      );
      const upated_insured_customers = [];
      for (let i = 0; i < count; i++) {
        upated_insured_customers.push({
          id: values[`id_${i}`] ? values[`id_${i}`] : datas[i][`id`],
          name: values[`insured_customer_name_${i}`]
            ? values[`insured_customer_name_${i}`]
            : datas[i][`insured_customer_name`],
          ocupation: values[`ocupation_${i}`]
            ? values[`ocupation_${i}`]
            : datas[i][`ocupation`][`id`],
          city: values[`city_${i}`]
            ? values[`city_${i}`]
            : datas[i][`city`][`id`],
          state: values[`state_${i}`]
            ? values[`state_${i}`]
            : datas[i][`state`][`id`],
          pincode: values[`pincode_${i}`]
            ? values[`pincode_${i}`]
            : datas[i][`pincode`][`id`],
          address_line_1: values[`address_line_1_${i}`]
            ? values[`address_line_1_${i}`]
            : datas[i][`address_line_1`],
          address_line_2: values[`address_line_2_${i}`]
            ? values[`address_line_2_${i}`]
            : datas[i][`address_line_2`],
          customer_address_type: values[`address_type_${i}`]
            ? values[`address_type_${i}`]
            : datas[i][`address_type`],
          relationship_with_insured_customer: values[
            `relationship_with_insured_customer_${i}`
          ]
            ? values[`relationship_with_insured_customer_${i}`]
            : datas[i][`relationship_with_insured_customer`][`id`],
          mobile: values[`mobile_${i}`]
            ? values[`mobile_${i}`]
            : datas[i][`mobile`],
          mobile_country_code: "91",
          email: values[`email_${i}`]
            ? values[`email_${i}`]
            : datas[i][`email`],
          gender: values[`gender_${i}`]
            ? values[`gender_${i}`]
            : datas[i][`gender`],
        });
      }

      const formattedEditValues = {
        loan_detail: loan_id || editloanid,
        insurance_detail: insurance_id,
        insured_customer_detail: {
          ...upated_insured_customers[0],
          customer_detail: customerId,
        },
        nominne: nominne,
        insurance_coverage: {
          policy_term: values.policy_term,
          sum_assured: values.sum_assured,
          monthly_income: monthlyIncome,
          total_premium_incl_gst: values.total_premium_amount,
          emi_amount: values.emi_amount,
          property_type: values.property_type,
          branch_address: values.branch_address,
          age_proof: values.age_proof,
        },
        insured_customer_under_magma_plan: upated_insured_customers.slice(1),
        document:documentTypeDetail ? [...convertFilterDocumetToBase64,documentTypeDetail] :convertFilterDocumetToBase64
      };

      const response = await postEditMagma(formattedEditValues);
      handleResponse(response);
    } else {
      const formattedPostValues = {
        loan_detail: loan_id || editloanid,
        insured_customer_detail: {
          ...insured_customers[0],
          customer_detail: customerId,
        },
        insurance_plan_magma: insurancePlanMagma,
        nominne: nominne,
        insurance_coverage: {
          policy_term: values.policy_term,
          sum_assured: values.sum_assured,
          monthly_income: monthlyIncome,
          total_premium_incl_gst: values.total_premium_amount,
          emi_amount: values.emi_amount,
          property_type: values.property_type,
          branch_address: values.branch_address,
          age_proof: values.age_proof,
        },
        insured_customer_under_magma_plan: insured_customers.slice(1),
        document:documentTypeDetail ? [...propertyDocumentDetail,documentTypeDetail] :propertyDocumentDetail,
      };

      try {
        const response = await postMagmaHDI(formattedPostValues);
        handleResponse(response);
      } catch (error) {
        console.error("Error occurred while submitting:", error);
        message.error(error?.response?.data?.message);
        setLoading(false);
      }
    }
  };

  const handleResponse = (response) => {
    if (response && response.success) {
      closeForm();
      form.resetFields();
      toggleRefreshInsurance();
      setLoading(false);
    } else {
      console.error("Error: Response did not indicate success", response);
      message.error("Failed to update or add insurance");
    }
  };

  const confirmDelete = () => {
    setImage(image.filter((image) => image !== deleteUploadPicture));
    const updatedFileList = fileList.filter(
      (item) => item.uid !== deleteUploadPicture.uid
    );
    setFileList(updatedFileList);
    setOpenUploadModal(false); // Close the modal
    setDeleteUploadPicture(null); // Reset the selected picture
  };

  const deletePropertyPictures = async () => {
    try {
      const response = await deleteHDFCDocumentDetailsByIdService(
        deletePicture.id
      );
      if (response?.status === 200 && response?.success === true) {
        message.success("Document deleted successfully");
        setOpenModal(false);
        toggleRefreshInsurance();
      } else {
        // Handle unexpected success response formats
        message.error("Failed to delete the document. Please try again.");
      }
    } catch (error) {
      message.error(
        ErrorMessage(
          error?.response?.status,
          error?.response?.data?.message || "An error occurred"
        )
      );
    }
  };

  const handleNumberFields = (e, field) => {
    let newValue = e.target.value.replace(/[^0-9.]/g, "");
    newValue = newValue.slice(0, 10);
    form.setFieldsValue({ [field]: newValue });
  };

  const handleInput = (e, field) => {
    // Allow letters, numbers, comma, forward slash, and empty spaces
    const newValue = e.target.value.replace(/[^A-Za-z0-9,/\s]/g, "");
    form.setFieldsValue({ [field]: newValue });
  };

  const { TabPane } = Tabs;

  const handleContactNumberFields = (e, field) => {
    // Get the value from the input
    let newValue = e.target.value.replace(/\D/g, "");
    newValue = newValue.slice(0, 10);
    form.setFieldsValue({ [field]: newValue });
  };

  const handlePlanCustomerChange = (value) => {
    // Create an object to store all field values
    setCheckCustomer(!checkCustomer);
    const newFieldValues = {
      insured_customer_name: "",
      ocupation: undefined,
      gender: undefined,
      nationality: undefined,
      mobile: "",
      email: "",
      address_line_1: "",
      address_line_2: "",
      city: undefined,
      state: undefined,
      pincode: undefined,
      customer_address_type: undefined,
      relation: undefined,
      planCustomerId: undefined,
    };

    // Only populate values if value is not "other" and customer is found
    if (value !== "others") {
      const foundCustomer = personalData?.find((person) => person.id === value);

      if (foundCustomer) {
        // Update with found customer data
        newFieldValues.insured_customer_name = `${
          foundCustomer.first_name || ""
        } ${foundCustomer.middle_name || ""} ${
          foundCustomer.last_name || ""
        }`.trim();
        newFieldValues.gender = foundCustomer.gender || undefined;
        newFieldValues.nationality = foundCustomer.nationality || "";
        newFieldValues.mobile = foundCustomer.mobile_number || "";
        newFieldValues.email = foundCustomer.email || "";
        newFieldValues.date_of_birth = stringToDate(
          foundCustomer.date_of_birth
        );
        newFieldValues.applicant_type =
          foundCustomer.customer_type?.display_name || "";
        newFieldValues.ocupation = undefined;
        newFieldValues.customer_address_type = undefined;
        newFieldValues.planCustomerId = value;
      }
    } else {
      newFieldValues.ocupation = undefined;
      newFieldValues.customer_address_type = undefined;
      newFieldValues.date_of_birth = undefined;
    }

    // Update formValues
    formValues[activeKey] = {
      id: value,
      ...newFieldValues,
    };

    // Create form fields object with proper keys
    const formFields = {};
    Object.entries(newFieldValues).forEach(([key, value]) => {
      formFields[`${key}_${activeKey}`] = value;
    });

    // Update form fields
    form.setFieldsValue(formFields);
  };

  const handleTabChange = (key) => {
    setActiveKey(key);
  };

  const handlePlanAddressType = async (value, formValue) => {
    try {
      const customerId = formValues[activeKey]?.id;
      // Fetch address details based on address type
      const response = await getAddressDetailByAddresType(customerId, value);
      const addressDetail = response?.data;

      if (addressDetail) {
        // Update formValues with the fetched address data
        formValues[activeKey] = {
          ...formValues[activeKey],
          address_line_1: addressDetail.address_line_1 || undefined,
          address_line_2: addressDetail.address_line_2 || "",
          city: addressDetail.city.id || "",
          state: addressDetail.state.id || "",
          pincode: addressDetail.pincode.id || "",
        };

        // Update the Ant Design form fields dynamically
        formValues.forEach((item, idx) => {
          if (idx > 0 && item) {
            form.setFieldsValue({
              [`address_line_1_${idx}`]: item.address_line_1 || "",
              [`address_line_2_${idx}`]: item.address_line_2 || "",
              [`city_${idx}`]: item.city || undefined,
              [`state_${idx}`]: item.state || undefined,
              [`pincode_${idx}`]: item.pincode || undefined,
            });
          }
        });
      } else {
        console.warn("Customer not found!");
      }
    } catch (error) {
      console.error("Error fetching address details:", error);
    }
  };

  const handleDocumentType = async (value) => {
    const response = await getDocumentDetailByDocumentType(customerId, value);
    if (response.success) {
      const getDocumentName = documentTypes?.find((doc) => doc.id === value);
      setDocumentName(getDocumentName.name);
      setDocumentTypeDetail(response?.data.document_path);
    }
  };

  const handleNomineePincodeChange = async (value, name) => {
    const selectedPincode = pincode.find((pin) => pin.id === value);

    const pincodeName = selectedPincode ? selectedPincode.name : "";
    try {
      const response = await insurancePincodeCodeService(pincodeName);
      if (response && response.data) {
        const { city, state } = response.data;
        if (name === "insuredCustomer") {
          form.setFieldsValue({
            [`city_${activeKey}`]: city?.id,
            [`state_${activeKey}`]: state?.id,
          });
        } else {
          form.setFieldsValue({
            [`nominee_city`]: city?.id,
            [`nominee_state`]: state?.id,
          });
        }
      }
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };
  return (
    <div>
      {!insurance_id && (
        <MagmaInsuredCustomerForm
          personalData={personalData}
          setMatchedCustomer={setMatchedCustomer}
          customerId={customerId}
          occupationType={occupationType}
          filterOption={filterOption}
          states={states}
          city={city}
          pincode={pincode}
          formValues={formValues}
          setFormValues={setFormValues}
          insurance_id={insurance_id}
          datas={datas}
          form={form}
          setAddressType={setAddressType}
          addressTypeList={addressTypeList}
          addressType={addressType}
        />
      )}

      {!insurance_id && customerId && (
        <div className="magma-hdi-insurance-detail">
          <MagmaHDIInsurance
            setCheckCustomer={setCheckCustomer}
            planningListData={planningListData}
            setCount={setCount}
            formValues={formValues}
            setFormValues={setFormValues}
            setInsurancePlanMagma={setInsurancePlanMagma}
          />
        </div>
      )}
      {insurance_id && (
        <div className="magma-hdi-insurance-detail">
          <MagmaHDIInsurance
            setCheckCustomer={setCheckCustomer}
            planningListData={planningListData}
            insurance_id={insurance_id}
            planDetails={planDetails}
            setCount={setCount}
            formValues={formValues}
            setFormValues={setFormValues}
            setInsurancePlanMagma={setInsurancePlanMagma}
          />
        </div>
      )}
      <Form
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
        onValuesChange={(changedValues, allValues) => {
          setDisabledButton(false);
          // You can add additional logic here to handle changes
        }}
      >
        {count > 0 && (
          <div>
            <Tabs
              activeKey={activeKey}
              onChange={handleTabChange}
              className="magma-hdi-insured-customer"
            >
              {insurance_id && datas.length
                ? datas.map((customer, index) => {
                    const formattedDate = stringToDate(customer?.date_of_birth);
                    return (
                      <TabPane
                        tab={`Insured Customer ${index + 1}`}
                        key={index}
                      >
                        <div className="drawer-insurance-space">
                          <Row gutter={16} className="row_magmainsurance_input">
                            <Col span={8}>
                              <Form.Item
                                name={`insured_customer_name_${index}`}
                                label="Insured Customer Name"
                                required
                                initialValue={`${customer.first_name} ${customer.middle_name} ${customer.last_name}`.trim()}
                                rules={[
                                  {
                                    required: true,
                                    message: `Insured Customer Name is required`,
                                  },
                                  {
                                    pattern: /^[a-zA-Z\s]*$/,
                                    message:
                                      "Only letters and spaces are allowed",
                                  },
                                ]}
                              >
                                <Input
                                  placeholder={
                                    `${customer.first_name} ${customer.middle_name} ${customer.last_name}`.trim() ||
                                    "Please Enter Customer Name"
                                  }
                                  onChange={(e) => {
                                    const value = e.target.value;
                                    // Allow only letters and spaces
                                    const filteredValue = value.replace(
                                      /[^a-zA-Z\s]/g,
                                      ""
                                    );

                                    // Update the input field only if filtered value is different
                                    if (value !== filteredValue) {
                                      e.target.value = filteredValue; // Update the input value to filtered value
                                    }

                                    // You can choose to handle valid input here if needed
                                    // For example, if you want to set valid values to state or perform other actions
                                    // handleAlphaFields({ target: { value: filteredValue } }, `insured_customer_name_${index}`);
                                  }}
                                  // disabled={index === 0} // Disable if index is 0 (or whatever condition you need)
                                />
                              </Form.Item>
                            </Col>

                            <Col span={8}>
                              <Form.Item
                                name={
                                  index === 0
                                    ? `date_of_birth`
                                    : `date_of_birth_${index}`
                                }
                                label="Date Of Birth"
                                initialValue={formattedDate}
                                rules={[
                                  {
                                    required: true,
                                    message: "Date Of Birth is required",
                                  },
                                ]}
                                required
                              >
                                <DatePicker
                                  format="DD-MM-YYYY"
                                  className="select_filed_datepicker_magma"
                                  placeholder="Select Date"
                                  onChange={handleDateChange}
                                />
                              </Form.Item>
                            </Col>

                            {/* Gender */}
                            <Col span={8}>
                              <Form.Item
                                name={`gender_${index}`}
                                label="Gender"
                                initialValue={customer.gender}
                                rules={[
                                  {
                                    required: true,
                                    message: "Gender is required",
                                  },
                                ]}
                              >
                                <Select
                                  placeholder="Select Gender"
                                  className="select_filed_datepicker_magma"
                                  allowClear
                                >
                                  <Option value="Male">Male</Option>
                                  <Option value="Female">Female</Option>
                                </Select>
                              </Form.Item>
                            </Col>

                            {/* Occupation */}
                            <Col span={8}>
                              <Form.Item
                                name={`ocupation_${index}`}
                                label="Occupation"
                                rules={[
                                  {
                                    required: true,
                                    message: "Occupation is required",
                                  },
                                ]}
                                initialValue={customer.ocupation.id}
                              >
                                <Select
                                  placeholder="Please Select"
                                  showSearch
                                  optionFilterProp="children"
                                  filterOption={filterOption}
                                  allowClear
                                >
                                  {occupationType?.map((loc) => (
                                    <Option key={loc.id} value={loc.id}>
                                      {loc?.name}
                                    </Option>
                                  ))}
                                </Select>
                              </Form.Item>
                            </Col>

                            {/* Contact Number */}
                            <Col span={8}>
                              <Form.Item label="Contact Number" required>
                                <Input.Group compact>
                                  <Form.Item noStyle>
                                    <Input
                                      className="magma_country_code_block"
                                      defaultValue="+91"
                                    />
                                  </Form.Item>
                                  <Form.Item
                                    name={`mobile_${index}`}
                                    noStyle
                                    initialValue={customer.mobile}
                                    rules={[
                                      {
                                        required: true,
                                        message: "Contact Number is Required",
                                      },
                                    ]}
                                  >
                                    <Input
                                      className="magma_mobile_number_field"
                                      placeholder={customer.mobile}
                                    />
                                  </Form.Item>
                                </Input.Group>
                              </Form.Item>
                            </Col>

                            {/* Nationality */}
                            <Col span={8}>
                              <Form.Item
                                name={`nationality_${index}`}
                                label="Nationality"
                                initialValue={customer.nationality}
                                rules={[
                                  {
                                    required: true,
                                    message: "Nationality is Required",
                                  },
                                ]}
                              >
                                <Select
                                  placeholder="Please Select"
                                  showSearch
                                  optionFilterProp="children"
                                  filterOption={filterOption}
                                  allowClear
                                >
                                  {nationality?.map((loc) => (
                                    <Option key={loc} value={loc}>
                                      {loc}
                                    </Option>
                                  ))}
                                </Select>
                              </Form.Item>
                            </Col>

                            <Col span={8}>
                              <Form.Item
                                name={`email_${index}`}
                                label="Email ID"
                                rules={[
                                  {
                                    required: true,
                                    message: "Email is Required",
                                  },
                                ]}
                                initialValue={customer.email}
                              >
                                <Input placeholder={customer.email} />
                              </Form.Item>
                            </Col>
                            <Col span={8}>
                              <Form.Item
                                name={`customer_address_type_${index}`}
                                label="Address Type"
                                required
                                rules={[
                                  {
                                    required: true,
                                    message: "Address Type is Required",
                                  },
                                ]}
                                initialValue={
                                  customer?.customer_address_type?.id
                                }
                              >
                                <Select
                                  placeholder="Please Select Address Type"
                                  showSearch
                                  optionFilterProp="children"
                                  filterOption={filterOption}
                                  onChange={(value) =>
                                    handlePlanAddressType(value, customer)
                                  }
                                  allowClear
                                >
                                  {addressTypeList?.map(
                                    (addressType, index) => (
                                      <Option
                                        key={addressType.id}
                                        value={addressType.id}
                                      >
                                        {addressType.name}
                                      </Option>
                                    )
                                  )}
                                </Select>
                              </Form.Item>
                            </Col>
                            <Col span={8}>
                              <Form.Item
                                name={`relationship_with_insured_customer_${index}`}
                                label="Relationship with Life to be Assured"
                                className={index === 0 ? "hidden" : ""}
                                initialValue={
                                  customer?.relationship_with_insured_customer
                                    ?.id
                                } // Ensure safe access to customer data
                                rules={[
                                  {
                                    required: true,
                                    message: "Relationship is Required",
                                  },
                                ]} // Add validation rule for required field
                              >
                                <Select
                                  placeholder="Please Select"
                                  showSearch
                                  value={
                                    customer?.relationship_with_insured_customer
                                      ?.name
                                  }
                                  optionFilterProp="children"
                                  filterOption={filterOption}
                                  allowClear
                                >
                                  {relation?.length > 0 ? ( // Safely check if relation exists and has options
                                    relation.map((loc) => (
                                      <Option key={loc.id} value={loc.id}>
                                        {loc.name}
                                      </Option>
                                    ))
                                  ) : (
                                    <Option disabled>
                                      No options available
                                    </Option> // Fallback when there are no options
                                  )}
                                </Select>
                              </Form.Item>
                            </Col>

                            {/* Email */}
                          </Row>
                          <Row gutter={16} className="row_input_form_magma">
                            {/* Address Line 1 */}
                            <Col span={12}>
                              <Form.Item
                                name={`address_line_1_${index}`}
                                label="Address Line 1"
                                rules={[
                                  {
                                    required: true,
                                    message: "Address Line 1 is Required",
                                  },
                                ]}
                                initialValue={customer.address_line_1} // Set initial value
                              >
                                <Input
                                  defaultValue={customer.address_line_1} // Use defaultValue instead of placeholder
                                />
                              </Form.Item>
                            </Col>

                            {/* Address Line 2 */}
                            <Col span={12}>
                              <Form.Item
                                name={`address_line_2_${index}`}
                                label="Address Line 2"
                                rules={[
                                  {
                                    required: true,
                                    message: "Address Line 2 is Required",
                                  },
                                ]}
                                initialValue={customer.address_line_2}
                              >
                                <Input
                                  placeholder={customer.address_line_2}
                                  //disabled
                                />
                              </Form.Item>
                            </Col>

                            {/* City */}
                            <Col span={8}>
                              <Form.Item
                                name={`city_${index}`}
                                label="City"
                                initialValue={customer.city?.id}
                                rules={[
                                  {
                                    required: true,
                                    message: "City is Required",
                                  },
                                ]}
                              >
                                <Select
                                  showSearch
                                  placeholder="Please Select"
                                  filterOption={filterOption}
                                  // disabled
                                  allowClear
                                >
                                  {city?.map((loc) => (
                                    <Option key={loc.id} value={loc.id}>
                                      {loc.name}
                                    </Option>
                                  ))}
                                </Select>
                              </Form.Item>
                            </Col>

                            {/* State */}
                            <Col span={8}>
                              <Form.Item
                                name={`state_${index}`}
                                label="State"
                                initialValue={customer.state.id}
                                rules={[
                                  {
                                    required: true,
                                    message: "State is Required",
                                  },
                                ]}
                              >
                                <Select
                                  showSearch
                                  placeholder="Please Select"
                                  filterOption={filterOption}
                                  //disabled
                                  allowClear
                                >
                                  {states?.map((loc) => (
                                    <Option key={loc.id} value={loc.id}>
                                      {loc.name}
                                    </Option>
                                  ))}
                                </Select>
                              </Form.Item>
                            </Col>

                            {/* Pincode */}
                            <Col span={8}>
                              <Form.Item
                                name={`pincode_${index}`}
                                label="Pincode"
                                initialValue={customer.pincode.id}
                                rules={[
                                  {
                                    required: true,
                                    message: "Pincode is Required",
                                  },
                                ]}
                              >
                                <Select
                                  showSearch
                                  placeholder="Please Select"
                                  filterOption={filterOption}
                                  //disabled
                                  allowClear
                                >
                                  {pincode?.map((loc) => (
                                    <Option key={loc.id} value={loc.id}>
                                      {loc.name}
                                    </Option>
                                  ))}
                                </Select>
                              </Form.Item>
                            </Col>
                          </Row>
                          {/* </Form> */}
                        </div>
                      </TabPane>
                    );
                  })
                : formValues &&
                  (Array.isArray(formValues) ? formValues : [formValues])?.map(
                    (formValue, index) => {
                      const newFormattedDate = formValue?.date_of_birth
                        ? moment(formValue.date_of_birth).format("DD-MM-YYYY")
                        : null;
                      return (
                        <TabPane
                          tab={`Insured Customer ${index + 1}`}
                          key={index.toString()}
                        >
                          <div className="drawer-insurance-space">
                            <Form
                              layout="vertical"
                              onFinish={(values) => handleSubmit(values)}
                              form={form} // Bind the form instance
                              onValuesChange={(changedValues, allValues) => {
                                setDisabledButton(false);
                                // You can add additional logic here to handle changes
                              }}
                              initialValues={{
                                ...formValue,
                                // Use dynamic field key based on the index
                                [`date_of_birth_${index}`]:
                                  index === 0 && newFormattedDate
                                    ? moment(newFormattedDate, "DD-MM-YYYY")
                                    : null,
                              }}
                            >
                              <Row>
                                <Form.Item name={`planCustomerId_${activeKey}`}>
                                  {/* Your form control here */}
                                </Form.Item>
                                <Col span={12}>
                                  <div
                                    className={index === 0 ? "hidden" : ""}
                                    style={{ marginBottom: "20px" }}
                                  >
                                    <label className="insurancetype-size">
                                      <span className="astrick">*</span>
                                      Select Insured Customer
                                    </label>
                                    <br />
                                    <Select
                                      placeholder="Please Select Customer"
                                      allowClear
                                      onChange={handlePlanCustomerChange}
                                      // value={selectedCustomerId}
                                      className="insurance_field"
                                    >
                                      {personalData
                                        ?.filter(
                                          (person) => person.id !== customerId
                                        )
                                        .map((person) => (
                                          <Option
                                            key={person.id}
                                            value={person.id}
                                          >
                                            {person.first_name}{" "}
                                            {person.middle_name}{" "}
                                            {person.last_name} (
                                            {person.customer_type.display_name})
                                          </Option>
                                        ))}

                                      <Option key="others" value="others">
                                        Others
                                      </Option>
                                    </Select>
                                  </div>
                                </Col>
                              </Row>

                              <Row gutter={16} className="row_input_form_magma">
                                <Col span={8}>
                                  <Form.Item
                                    name={`insured_customer_name_${index}`}
                                    label="Insured Customer Name"
                                    required
                                    rules={[
                                      {
                                        required: index !== 0, // Required only if it's not the first index
                                        message:
                                          "Insured Customer Name is required",
                                      },
                                      {
                                        pattern: /^[a-zA-Z\s]*$/,
                                        message:
                                          "Only letters and spaces are allowed",
                                      },
                                    ]}
                                  >
                                    <Input
                                      placeholder="Enter Customer Name"
                                      disabled={
                                        index === 0 ||
                                        form.getFieldValue(
                                          `insured_customer_name_${index}`
                                        ) !== ""
                                      }
                                      onChange={(e) => {
                                        const value = e.target.value;
                                        form.setFieldsValue({
                                          [`insured_customer_name_${index}`]:
                                            value,
                                        });
                                      }}
                                    />
                                  </Form.Item>
                                </Col>

                                {/* Date Of Birth */}
                                <Col span={8}>
                                  <Form.Item
                                    name={`date_of_birth_${index}`}
                                    label="Date Of Birth"
                                    required
                                    rules={
                                      index === 0
                                        ? []
                                        : [
                                            {
                                              required: true,
                                              message: `Date Of Birth is required`,
                                            },
                                          ]
                                    }
                                  >
                                    <DatePicker
                                      format="DD-MM-YYYY"
                                      className="select_filed_datepicker_magma"
                                      placeholder="Select Date"
                                      disabled={
                                        index === 0 ||
                                        form.getFieldValue(
                                          `date_of_birth_${index}`
                                        ) !== undefined
                                      }
                                    />
                                  </Form.Item>
                                </Col>

                                {/* Gender */}
                                <Col span={8}>
                                  <Form.Item
                                    name={`gender_${index}`}
                                    label="Gender"
                                    required
                                    rules={
                                      index === 0
                                        ? []
                                        : [
                                            {
                                              required: true,
                                              message: `Gender is required`,
                                            },
                                          ]
                                    }
                                  >
                                    <Select
                                      placeholder={"Select Gender" || undefined}
                                      className="select_filed_datepicker_magma"
                                      disabled={
                                        index === 0 ||
                                        form.getFieldValue(
                                          `gender_${index}`
                                        ) !== undefined
                                      }
                                      allowClear
                                    >
                                      <Option value="Male">Male</Option>
                                      <Option value="Female">Female</Option>
                                    </Select>
                                  </Form.Item>
                                </Col>

                                {/* Occupation */}
                                <Col span={8}>
                                  <Form.Item
                                    name={`ocupation_${index}`}
                                    label="Occupation"
                                    rules={[
                                      {
                                        required: true,
                                        message: `Occupation is required`,
                                      },
                                    ]}
                                    required
                                  >
                                    <Select
                                      placeholder="Please Select Occupation"
                                      showSearch
                                      optionFilterProp="children"
                                      filterOption={filterOption}
                                      disabled={index === 0}
                                      allowClear
                                    >
                                      {occupationType?.map((loc) => (
                                        <Option key={loc.id} value={loc.id}>
                                          {loc.name}
                                        </Option>
                                      ))}
                                    </Select>
                                  </Form.Item>
                                </Col>

                                {/* Contact Number */}
                                <Col span={8}>
                                  <Form.Item
                                    label="Contact Number"
                                    rules={
                                      index === 0
                                        ? []
                                        : [
                                            {
                                              required: true,
                                              message: `Contact Number is required`,
                                            },
                                            {
                                              pattern: /^\d+$/,
                                              message:
                                                "Only numbers are allowed",
                                            },
                                            {
                                              max: 10,
                                              message:
                                                "Contact Number cannot exceed 10 digits",
                                            },
                                          ]
                                    }
                                    required
                                  >
                                    <Input.Group compact>
                                      <Form.Item noStyle>
                                        <Input
                                          className="magma_country_code_block"
                                          defaultValue="+91"
                                          disabled
                                        />
                                      </Form.Item>
                                      <Form.Item
                                        name={`mobile_${index}`}
                                        required
                                        noStyle
                                        rules={
                                          index === 0
                                            ? []
                                            : [
                                                {
                                                  required: true,
                                                  message: `Contact Number is required`,
                                                },
                                                {
                                                  pattern: /^\d+$/,
                                                  message:
                                                    "Only numbers are allowed",
                                                },
                                                {
                                                  max: 10,
                                                  message:
                                                    "Contact Number cannot exceed 10 digits",
                                                },
                                              ]
                                        }
                                      >
                                        <Input
                                          className="magma_mobile_number_field"
                                          placeholder="Mobile Number"
                                          onChange={(e) =>
                                            handleContactNumberFields(
                                              e,
                                              `mobile_${index}`
                                            )
                                          }
                                          disabled={
                                            index === 0 ||
                                            form.getFieldValue(
                                              `mobile_${index}`
                                            ) !== ""
                                          }
                                        />
                                      </Form.Item>
                                    </Input.Group>
                                  </Form.Item>
                                </Col>

                                {/* Nationality */}
                                <Col span={8}>
                                  <Form.Item
                                    name={`nationality_${index}`}
                                    label="Nationality"
                                    required
                                    rules={
                                      index === 0
                                        ? []
                                        : [
                                            {
                                              required: true,
                                              message: `Nationality is required`,
                                            },
                                          ]
                                    }
                                  >
                                    <Select
                                      placeholder="Please Select Nationality"
                                      showSearch
                                      optionFilterProp="children"
                                      filterOption={filterOption}
                                      disabled={
                                        index === 0 ||
                                        form.getFieldValue(
                                          `nationality_${index}`
                                        ) !== undefined
                                      }
                                      allowClear
                                    >
                                      {nationality?.map((loc) => (
                                        <Option key={loc} value={loc}>
                                          {loc}
                                        </Option>
                                      ))}
                                    </Select>
                                  </Form.Item>
                                </Col>

                                {/* Email */}
                                <Col span={8}>
                                  <Form.Item
                                    name={`email_${index}`}
                                    label="Email ID"
                                    required
                                    rules={
                                      index === 0
                                        ? []
                                        : [
                                            {
                                              required: true,
                                              message: `Email ID is required`,
                                            },
                                            {
                                              type: "email",
                                              message:
                                                "The input is not valid E-mail!",
                                            },
                                          ]
                                    }
                                  >
                                    <Input
                                      placeholder="Email ID"
                                      disabled={
                                        index === 0 ||
                                        form.getFieldValue(`email_${index}`) !==
                                          ""
                                      }
                                    />
                                  </Form.Item>
                                </Col>

                                <Col span={8}>
                                  <Form.Item
                                    name={`customer_address_type_${index}`}
                                    label="Address Type"
                                    required
                                    rules={[
                                      {
                                        required: true,
                                        message: "Address Type is Required",
                                      },
                                    ]}
                                  >
                                    <Select
                                      placeholder="Please Select Address Type"
                                      showSearch
                                      optionFilterProp="children"
                                      filterOption={filterOption}
                                      onChange={(value) =>
                                        handlePlanAddressType(value, formValue)
                                      }
                                      allowClear
                                      disabled={index === 0}
                                    >
                                      {addressTypeList?.map(
                                        (addressType, index) => (
                                          <Option
                                            key={addressType.id}
                                            value={addressType.id}
                                          >
                                            {addressType.name}
                                          </Option>
                                        )
                                      )}
                                    </Select>
                                  </Form.Item>
                                </Col>
                                <Col span={8}>
                                  <Form.Item
                                    name={`relation_${index}`}
                                    label="Relationship with Life to be Assured"
                                    required
                                    className={index === 0 ? "hidden" : ""}
                                    rules={
                                      index === 0
                                        ? []
                                        : [
                                            {
                                              required: true,
                                              message: `Relationship with Life to be Assured is required`,
                                            },
                                          ]
                                    }
                                  >
                                    <Select
                                      placeholder="Please Select Relationship"
                                      showSearch
                                      optionFilterProp="children"
                                      filterOption={filterOption}
                                      disabled={index === 0}
                                      allowClear
                                    >
                                      {relation?.map((loc) => (
                                        <Option key={loc.id} value={loc.id}>
                                          {loc.name}
                                        </Option>
                                      ))}
                                    </Select>
                                  </Form.Item>
                                </Col>
                              </Row>

                              <Row gutter={16} className="row_input_form_magma">
                                {/* Address Line 1 */}
                                <Col span={12}>
                                  <Form.Item
                                    name={`address_line_1_${index}`}
                                    label="Address Line 1"
                                    required
                                    rules={[
                                      {
                                        required: true,
                                        message: `Address Line 1 is required`,
                                      },
                                    ]}
                                  >
                                    <Input
                                      placeholder="Please Enter Address Line 1"
                                      disabled={index === 0}
                                    />
                                  </Form.Item>
                                </Col>

                                {/* Address Line 2 */}
                                <Col span={12}>
                                  <Form.Item
                                    name={`address_line_2_${index}`}
                                    label="Address Line 2"
                                    rules={[
                                      {
                                        required: true,
                                        message: `Address Line 2 is required`,
                                      },
                                    ]}
                                  >
                                    <Input
                                      placeholder="Please Enter Address Line 2"
                                      disabled={index === 0}
                                    />
                                  </Form.Item>
                                </Col>

                                {/* City */}
                                <Col span={8}>
                                  <Form.Item
                                    name={`city_${index}`}
                                    label="City"
                                    rules={[
                                      {
                                        required: true,
                                        message: `City is required`,
                                      },
                                    ]}
                                  >
                                    <Select
                                      showSearch
                                      placeholder="Please Select City"
                                      filterOption={filterOption}
                                      disabled={index === 0}
                                      allowClear
                                    >
                                      {city?.map((loc) => (
                                        <Option key={loc.id} value={loc.id}>
                                          {loc.name}
                                        </Option>
                                      ))}
                                    </Select>
                                  </Form.Item>
                                </Col>

                                {/* State */}
                                <Col span={8}>
                                  <Form.Item
                                    name={`state_${index}`}
                                    label="State"
                                    rules={[
                                      {
                                        required: true,
                                        message: `State is required`,
                                      },
                                    ]}
                                  >
                                    <Select
                                      showSearch
                                      placeholder="Please Select State"
                                      filterOption={filterOption}
                                      disabled={index === 0}
                                      allowClear
                                    >
                                      {states?.map((loc) => (
                                        <Option key={loc.id} value={loc.id}>
                                          {loc.name}
                                        </Option>
                                      ))}
                                    </Select>
                                  </Form.Item>
                                </Col>

                                {/* Pincode */}
                                <Col span={8}>
                                  <Form.Item
                                    name={`pincode_${index}`}
                                    label="Pincode"
                                    rules={[
                                      {
                                        required: true,
                                        message: `Pincode is required`,
                                      },
                                    ]}
                                  >
                                    <Select
                                      showSearch
                                      placeholder="Please Select Pincode"
                                      filterOption={filterOption}
                                      disabled={index === 0}
                                      allowClear
                                      onChange={(value) =>
                                        handleNomineePincodeChange(
                                          value,
                                          "insuredCustomer"
                                        )
                                      }
                                    >
                                      {pincode?.map((loc) => (
                                        <Option key={loc.id} value={loc.id}>
                                          {loc.name}
                                        </Option>
                                      ))}
                                    </Select>
                                  </Form.Item>
                                </Col>
                              </Row>
                            </Form>
                          </div>
                        </TabPane>
                      );
                    }
                  )}
            </Tabs>
          </div>
        )}

        {count > 0 && (
          <div>
            <div className="details_coverage_hdfc">Nominee Details Magma:</div>
            <div className="drawer-insurance-space">
              <Row>
                <Col span={8}>
                  <Form.Item name="select_nominee" label="Select Nominee">
                    <Select
                      placeholder="Please Select Nominee"
                      showSearch
                      optionFilterProp="children"
                      onChange={handleNomineeChange}
                      filterOption={filterOption}
                      allowClear
                      required
                    >
                      {personalData
                        ?.filter((person) => person.id !== customerId)
                        .map((person, index) => (
                          <Option key={person.id} value={person.id}>
                            {`${person.first_name} ${person.middle_name} ${person?.last_name}`}
                          </Option>
                        ))}
                      <Option value="other">Other</Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16} className="row_input_form_magma">
                <Col span={8}>
                  <Form.Item
                    name="nominee_name"
                    label="Name"
                    required
                    initialValue={nomineeData?.name}
                    // rules={[yupSync]}
                    rules={nominee !== "other" ? [] : [yupSync]}
                  >
                    <Input
                      placeholder={
                        nominee === "other"
                          ? "Please Enter Your Name"
                          : `${matchedNominee?.first_name || ""} ${
                              matchedNominee?.middle_name || ""
                            } ${matchedNominee?.last_name || ""}`.trim() ||
                            "Please Enter Nominee Name"
                      }
                      value={
                        nominee === "other"
                          ? ""
                          : `${matchedNominee?.first_name || ""} ${
                              matchedNominee?.middle_name || ""
                            } ${matchedNominee?.last_name || ""}`.trim()
                      }
                      disabled={!insurance_id && nominee !== "other"}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name="nominee_date_of_birth"
                    label="Date Of Birth"
                    rules={[yupSync]}
                    initialValue={
                      nomineeData?.date_of_birth
                        ? stringToDate(nomineeData?.date_of_birth)
                        : null
                    }
                    required
                  >
                    <DatePicker
                      format="DD-MM-YYYY"
                      className="select_filed_datepicker_magma"
                      placeholder="Select Date"
                      onChange={handleDateChange}
                      disabled={!insurance_id && nominee !== "other"}
                    />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    name="nominee_gender"
                    label="Gender"
                    rules={[yupSync]}
                    initialValue={nomineeData?.gender}
                    required
                  >
                    <Select
                      placeholder="Select Gender"
                      className="select_filed_datepicker_magma"
                      disabled={!insurance_id && nominee !== "other"}
                      allowClear
                    >
                      <Option value="Male">Male</Option>
                      <Option value="Female">Female</Option>
                    </Select>
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    name="nominee_relationship_with_life_to_be_assured"
                    label="Relationship with Life to be Assured"
                    // rules={[yupSync, { required: true, message: 'Please select a relationship' }]}  // Added required validation here
                    rules={[yupSync]}
                    initialValue={nomineeData?.relation?.id}
                    required
                  >
                    <Select
                      placeholder="Please Select" // Placeholder should now display correctly
                      showSearch
                      optionFilterProp="children"
                      filterOption={filterOption}
                      allowClear
                    >
                      {relation?.map((relations) => (
                        <Option key={relations.id} value={relations.id}>
                          {relations.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item label="Contact Number" required>
                    <Input.Group compact>
                      <Input
                        className="magma_country_code_block"
                        disabled={!insurance_id}
                        defaultValue="+91"
                        initialValue={nomineeData?.mobile_country_code}
                      />
                      <Form.Item
                        name="nominee_contact_number"
                        rules={[yupSync]}
                        initialValue={nomineeData?.mobile}
                        noStyle
                       
                      >
                        <Input
                          className="magma_mobile_number_field"
                          placeholder={"Enter Contact Number" || undefined}
                          disabled={!insurance_id && nominee !== "other"}
                          onChange={(e) =>
                            handleContactNumberFields(e, "nominee_contact_number")
                          }
                        />
                      </Form.Item>
                    </Input.Group>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name={`address_type`}
                    label="Address Type"
                    required
                    rules={[
                      {
                        required: true,
                        message: "Nominee Address Type is required",
                      },
                    ]}
                    initialValue={nomineeData?.address_type?.id}
                  >
                    <Select
                      placeholder="Please Select Address Type"
                      showSearch
                      optionFilterProp="children"
                      filterOption={filterOption}
                      onChange={(value) => setNomineeAddressType(value)}
                      allowClear
                    >
                      {addressTypeList?.map((addressType, index) => (
                        <Option key={addressType.id} value={addressType.id}>
                          {addressType.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16} className="row_input_form_magma">
                <Col span={12}>
                  <Form.Item
                    name="nominee_address_line1"
                    label="Address Line 1"
                    required
                    rules={[yupSync]}
                    initialValue={nomineeData?.address_line_1}
                  >
                    <Input
                      placeholder="Please Enter Address Line 1"
                      onChange={(e) => handleInput(e, "nominee_address_line1")}
                      disabled={!insurance_id && nominee !== "other"}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="nominee_address_line2"
                    label="Address Line 2"
                    required
                    initialValue={nomineeData?.address_line_2}
                    rules={[yupSync]}
                  >
                    <Input
                      placeholder="Please Enter Address Line 2"
                      onChange={(e) => handleInput(e, "nominee_address_line2")}
                      disabled={!insurance_id && nominee !== "other"}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16} className="row_input_form_magma">
                <Col span={8}>
                  <Form.Item
                    name="nominee_city"
                    label="City"
                    required
                    rules={[yupSync]}
                    initialValue={nomineeData?.city?.id}
                  >
                    <Select
                      placeholder="Please Select"
                      showSearch
                      optionFilterProp="children"
                      filterOption={filterOption}
                      allowClear
                      disabled={!insurance_id && nominee !== "other"}
                    >
                      {city?.map((locations) => (
                        <Option key={locations.id} value={locations.id}>
                          {locations.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    name="nominee_state"
                    label="State"
                    required
                    rules={[yupSync]}
                    initialValue={nomineeData?.state?.id}
                  >
                    <Select
                      placeholder="Please Select"
                      showSearch
                      filterOption={filterOption}
                      allowClear
                      disabled={!insurance_id && nominee !== "other"}
                    >
                      {states?.map((state) => (
                        <Option key={state.id} value={state.id}>
                          {state.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    name="nominee_pincode"
                    label="Pincode"
                    required
                    rules={[yupSync]}
                    initialValue={nomineeData?.pincode?.id}
                  >
                    <Select
                      placeholder="Please Select"
                      showSearch
                      filterOption={filterOption}
                      allowClear
                      disabled={!insurance_id && nominee !== "other"}
                      onChange={(value) =>
                        handleNomineePincodeChange(value, "nominee")
                      }
                    >
                      {pincode?.map((pin) => (
                        <Option key={pin.id} value={pin.id}>
                          {pin.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              {isUnder18 && (
                <Row gutter={16} className="row_input_form_magma">
                  <Col span={8}>
                    <Form.Item
                      name="apointee_name"
                      label="Appointee Name"
                      required
                      rules={[yupSync]}
                    >
                      <Input placeholder="Enter Appointee Name" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      name="apointee_date_of_birth"
                      label="Date Of Birth"
                      rules={[yupSync]}
                      required
                    >
                      <DatePicker
                        format="DD-MM-YYYY"
                        className="select_filed_datepicker_magma"
                        placeholder="Select Date"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      name="apointee_gender"
                      label="Gender"
                      required
                      rules={[yupSync]}
                    >
                      <Select placeholder="Select Gender">
                        <Option value="Male">Male</Option>
                        <Option value="Female">Female</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      name="apointee_relationship_with_life_to_be_assured"
                      label="Relationship with the Nominee"
                      rules={[yupSync]}
                      required
                    >
                      <Select
                        placeholder="Please Select"
                        showSearch
                        optionFilterProp="children"
                        filterOption={filterOption}
                      >
                        {relation?.map((relations) => (
                          <Option key={relations.id} value={relations.id}>
                            {relations.display_name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      name="apointee_contact_number"
                      label="Contact Number"
                      required
                    >
                      <Input.Group compact>
                        <Input
                          className="magma_country_code_block"
                          disabled={!insurance_id}
                          defaultValue="+91"
                        />
                        <Form.Item
                          name="apointee_contact_number"
                          noStyle
                          rules={[yupSync]}
                       
                        >
                          <Input
                            className="magma_mobile_number_field"
                            placeholder="Enter Contact Number"
                            onChange={(e) =>
                              handleContactNumberFields(
                                e,
                                "apointee_contact_number"
                              )
                            }
                          />
                        </Form.Item>
                      </Input.Group>
                    </Form.Item>
                  </Col>
                </Row>
              )}
            </div>

            <div className="details_coverage_hdfc">
              Details Of The Coverage MAGMA HDI:
            </div>
            <div className="drawer-insurance-space">
              <Row gutter={16} className="row_input_form_magma">
                <Col span={8}>
                  <Form.Item
                    name="loan_amount"
                    label="Loan Amount"
                    initialValue={insuranceCoverageData?.loan_amount}
                    required
                  >
                    <Input
                      className="magma_mobile_number_field"
                      placeholder={`${loanData.approved_amount}`}
                      value={`${loanData.approved_amount}`}
                      disabled
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name="tenure"
                    label="Tenure Of Loan (In Months)"
                    required
                    initialValue={insuranceCoverageData?.tenure}
                  >
                    <Input
                      placeholder={`${loanData.approved_tenure}`}
                      disabled
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name="policy_term"
                    label="Policy Term (In Months)"
                    required
                    rules={[yupSync]}
                    initialValue={insuranceCoverageData?.policy_term}
                  >
                    <Input
                      placeholder="Please Enter Policy Term"
                      onChange={(e) => handleNumberFields(e, "policy_term")}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16} className="row_input_form_magma">
                <Col span={8}>
                  <Form.Item label="Sum Assured (₹)" required>
                    <Input.Group compact>
                      <Form.Item noStyle>
                        <Input
                          className="magma_country_code_block"
                          disabled
                          defaultValue="₹"
                        />
                      </Form.Item>
                      <Form.Item
                        name="sum_assured"
                        noStyle
                        rules={[yupSync]}
                        required
                        initialValue={insuranceCoverageData?.sum_assured}
                      >
                        <Input
                          className="magma_mobile_number_field"
                          placeholder="Please Enter Sum Assured"
                          onChange={(e) => handleNumberFields(e, "sum_assured")}
                        />
                      </Form.Item>
                    </Input.Group>
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item label="Monthly Income" required>
                    <Input.Group compact>
                      <Form.Item noStyle>
                        <Input
                          className="magma_country_code_block"
                          disabled
                          defaultValue="₹"
                        />
                      </Form.Item>
                      <Form.Item
                        name="monthly_income"
                        label="Monthly Income"
                        noStyle
                        required
                        rules={[yupSync]}
                        initialValue={IndianNumberFormat(monthlyIncome)}
                      >
                        <Input
                          className="magma_mobile_number_field"
                          placeholder="Please Enter Monthly Income"
                          disabled={true}
                        />
                      </Form.Item>
                    </Input.Group>
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    name="emi_amount"
                    label="EMI Amount"
                    rules={[yupSync]} // Assuming yupSync is properly defined elsewhere
                    required
                    initialValue={insuranceCoverageData?.emi_amount}
                  >
                    <Input
                      className="select_filed_datepicker_magma"
                      placeholder="Please Enter EMI Amount"
                      onChange={(e) => handleNumberFields(e, "emi_amount")} // Correct event handler
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16} className="row_input_form_magma">
                <Col span={8}>
                  <Form.Item
                    label="Total Premium Amount (Including GST)(₹)"
                    required
                    rules={[yupSync]}
                  >
                    <Input.Group compact>
                      <Form.Item noStyle>
                        <Input
                          className="magma_country_code_block"
                          disabled
                          defaultValue="₹"
                        />
                      </Form.Item>
                      <Form.Item
                        name="total_premium_amount"
                        rules={[yupSync]}
                        noStyle
                        required
                        initialValue={
                          insuranceCoverageData?.total_premium_incl_gst
                        }
                      >
                        <Input
                          className="magma_mobile_number_field"
                          placeholder="Please Enter Total Premium Amount"
                          onChange={(e) =>
                            handleNumberFields(e, "total_premium_amount")
                          }
                        />
                      </Form.Item>
                    </Input.Group>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label="Branch Address"
                    name="branch_address"
                    required
                    rules={[yupSync]}
                    initialValue={insuranceCoverageData?.branch_address?.id}
                  >
                    <Select
                      placeholder="Please Select"
                      className="select_filed_datepicker_magma"
                      // disabled
                      allowClear
                    >
                      {branchLocations?.map((item) => (
                        <Option value={item.id}>{item.name}</Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label="Age Proof"
                    name="age_proof"
                    required
                    rules={[yupSync]}
                    initialValue={insuranceCoverageData?.age_proof?.id}
                  >
                    <Select
                      placeholder="Please Select"
                      className="select_filed_datepicker_magma"
                      // disabled
                      allowClear
                      onChange={handleDocumentType}
                    >
                      {documentTypes?.map((item) => (
                        <Option value={item.id}>{item.name}</Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </div>

            <div className="details_coverage_hdfc">Documents:</div>
            <div className="drawer-insurance-space">
              {documentTypeDetail ? (
                <RViewerJS>
                  <img
                    src={documentTypeDetail}
                    alt=""
                    className="magma_picture_div_views"
                  />
                </RViewerJS>
              ) : (
                <div>
                  {" "}
                  <p>
                    {documentTypeDetail
                      ? documentName
                      :  ''}
                  </p>
                </div>
              )}

              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item name="documents" label="Documents">
                    <Upload
                      single
                      fileList={fileList}
                      onChange={handleUploadChange}
                      beforeUpload={handleBeforeUpload}
                      showUploadList={false}
                    >
                      <Button
                        type="primary"
                        icon={<UploadOutlined />}
                        className="select_uplad_documnet"
                      >
                        Upload Document
                      </Button>
                    </Upload>
                  </Form.Item>
                </Col>
              </Row>
              <div className="magma_picture_div">
                <div className="magma_picture_div_two">
                  {image?.map((picture, index) => (
                    <div key={index} className="magma_picture_div_third">
                      <RViewerJS>
                        <img
                          src={picture.file_path}
                          alt=""
                          className="magma_picture_div_views"
                        />
                      </RViewerJS>
                      <div
                        className="delete_icon_magma"
                        onClick={() => {
                          setDeleteUploadPicture(picture);
                          setOpenUploadModal(true);
                        }}
                      >
                        <DeleteOutlined />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="image-gallery">
                {existingImages?.map((picture, index) => (
                  <div key={index} className="image-container">
                    {picture.file_type === "application/pdf" ? (
                      <img
                        src={pdf_image}
                        width="100px"
                        height="100px"
                        className="Nominee-detail-whole-table"
                        alt="PDF Preview"
                      />
                    ) : (
                      <RViewerJS
                        options={{
                          url: (image) => image.getAttribute("data-original"),
                        }}
                      >
                        <img
                          src={picture.thumb_document? picture?.thumb_document : picture.document}
                          data-original={picture.document}
                          alt="Preview"
                          className="magma_picture_div_views"
                        />
                      </RViewerJS>
                    )}

                    <div
                      className="delete-icon"
                      onClick={() => {
                        setDeletePicture(picture);
                        setOpenModal(true);
                      }}
                    >
                      <DeleteOutlined />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}

        <Modal
          title="Confirm Delete"
          open={openUploadModal}
          onOk={confirmDelete}
          onCancel={() => {
            setOpenUploadModal(false);
            setDeleteUploadPicture(null);
          }}
          okType="danger"
        >
          <p>{`Are you sure you want to delete this upload image?`}</p>
        </Modal>
        <Modal
          title="Confirm Delete"
          open={openModal}
          onOk={deletePropertyPictures}
          onCancel={() => {
            setOpenModal(false);
            setDeletePicture(null);
          }}
          okType="danger"
        >
          <p>Are you sure you want to delete?</p>
        </Modal>

        <Space
          direction="horizontal"
          align="center"
          className="save_cancle_button_magma"
        >
          <Button type="primary" htmlType="submit" loading={loading}>
            Save
          </Button>
          <Button htmlType="button" onClick={closeForm}>
            Cancel
          </Button>
        </Space>
      </Form>
    </div>
  );
};

export default MagmaHDIInsuranceForm;
