import React, { useEffect, useState } from "react";
import {
  Space,
  Modal,
  Typography,
  Drawer,
  Button,
  message,
  Switch,
} from "antd";
import { FilterOutlined, EyeOutlined } from "@ant-design/icons";
import { Form } from "antd";
import ListOfApplicationFilterForm from "./ListOfApplicationFilterForm";
import DataTable from "../../../../utils/dataTable/DataTable";
import Header from "../../../layout/views/Header";
import { Link } from "react-router-dom";
import IndianNumberFormat from "../../../../utils/indianNumberFormat/IndianNumberFormat";
import { useSelector } from "react-redux";
import authorizeTableColumns from "../../../../utils/authorization/authorizeTableColumns";
import { encrypt } from "../../../../utils/cryptoUtils/cryptoUtils";
import { decrypt } from "../../../../utils/cryptoUtils/cryptoUtils";
import { useParams } from "react-router-dom";
import ConditionalRender from "../../../../utils/authorization/AuthorizeComponent";
import "../styles/listOfApplication.css";
import { dateToString } from "../../../../utils/dateConvertor/DateConvertor";

const { Title } = Typography;

const ListOfApplication = () => {
  const { encrypted_stage_id } = useParams();
  // const [stageId, setStageId] = useState(null);
  const [listOfApplications, setListOfApplications] = useState([]);
  const [refreshTableData, setRefreshTableData] = useState(false);
  const [selectedListOfApplication, setSelectedListOfApplication] =
    useState(null);
  const [filteropen, setFilteropen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [actionPerformed, setActionPerformed] = useState(false);
  const [toggleState, setToggleState] = useState(false);

  const [filterFormData, setFilterFormData] = useState({
    filter: false,
    location: null,
    loan_type: [],
    loan_status: [],
    sales_manager: [],
    amountType: null,
    created_by: [],
    modified_by: [],
  });

  // useEffect(() => {
  //   if (encrypted_stage_id !== null) {
  //     try {
  //       const stage_id = decrypt(encrypted_stage_id);
  //       setStageId(stage_id);
  //     } catch (error) {
  //       message.error("Invalid Loan or Stage ID");
  //     }
  //   }
  // }, [encrypted_stage_id]);

  let stageId;

  if (encrypted_stage_id !== undefined) {
    try {
      stageId = decrypt(encrypted_stage_id);
    } catch (error) {
      message.error("Invalid Loan or Stage ID");
    }
  }

  const [form] = Form.useForm();

  const { user_data } = useSelector((state) => state.user);

  const showDrawer1 = () => {
    setFilteropen(true);
  };

  const onClose1 = () => {
    setSelectedListOfApplication(null);
    setFilteropen(false);
  };

  const toggleRefreshTableData = () => {
    setRefreshTableData((prev) => !prev);
  };
  useEffect(() => {
    if (!filteropen && actionPerformed) {
      toggleRefreshTableData();
      setActionPerformed(false); // Reset the action performed state
    }
  }, [filteropen, actionPerformed]);
  const columns = [
    {
      title: "Loan Account Number",
      dataIndex: "loan_account_number",
      fixed: "left",
      render: (text) => text || "-",
    },
    {
      title: "Applicant Name",
      dataIndex: "applicant_name",
      render: (text) => text || "-",
    },
    {
      title: "Application Number",
      dataIndex: "application_number",
      render: (text) => text || "-",
    },
    {
      title: "Location",
      render: (record) => record?.location?.display_name || "-",
    },
    {
      title: "Loan Type",
      render: (record) => record?.loan_type?.display_name || "-",
    },
    {
      title: "Loan Amount",
      dataIndex: "requested_amount",
      render: (amount) => {
        return amount !== undefined
          ? formatCurrency(IndianNumberFormat(amount))
          : "-";
      },
    },
    {
      title: "Sales Manager",
      render: (record) => record?.sales_manager?.name || "-",
    },
    {
      title: "Date",
      render: (record) => dateToString(record?.bd_created_at) || "-",
    },
    {
      title: "Status",
      render: (record) => (
        <div className="status_column_loan_list">
          {record?.loan_stage || "-"}
        </div>
      ),
    },
  ];

  const formatCurrency = (value) => {
    return `₹ ${value.toLocaleString("en-IN")}`;
  };

  const authorizedColumns = [
    {
      title: "View",
      fixed: "right",
      render: (_, record) => (
        <Link
          to={record.id ? `/loandetails/${encrypt(record.id.toString())}` : "#"}
        >
          <EyeOutlined className="view_button_loan_list" />
        </Link>
      ),
    },
  ];

  const tableColumns = authorizeTableColumns(
    columns,
    authorizedColumns,
    user_data?.permissions,
    ["VWLOANID"]
  );

  return (
    <div>
      <Space
        direction="horizontal"
        align="center"
        className="loan_list_heading"
      >
        <Title level={2} align="center">
          Applications
        </Title>

        <div className="switch_toggle_button_container">
          <div className="switch_toggle_button">
            <Switch
              checked={toggleState}
              onChange={(checked) => setToggleState(checked)}
              checkedChildren="all"
              unCheckedChildren="me"
              className="switch_toggle_button_icon"
            />
          </div>
          <div>
            <ConditionalRender
              userPermissions={user_data?.permissions}
              requiredPermissions={["LSLOANS1"]}
            >
              <Button onClick={showDrawer1} icon={<FilterOutlined />}>
                More Filter
              </Button>
            </ConditionalRender>
          </div>
        </div>
      </Space>
      <div>
        <div>
          {stageId !== undefined ? (
            <DataTable
              apiPath={
                toggleState
                  ? `/api/v1/loan/loan-detail/?stage_id=${stageId}`
                  : `/api/v1/loan/loan-detail/?stage_id=${stageId}&created_by=${user_data?.id}`
              }
              tableData={listOfApplications}
              setTableData={setListOfApplications}
              tableColumns={tableColumns}
              refreshData={refreshTableData}
              searchPlaceholder={"Type to search "}
              filters={filterFormData}
            />
          ) : (
            <DataTable
              apiPath={
                toggleState
                  ? `/api/v1/loan/loan-detail/`
                  : `/api/v1/loan/loan-detail/?created_by=${user_data?.id}`
              }
              tableData={listOfApplications}
              setTableData={setListOfApplications}
              tableColumns={tableColumns}
              refreshData={refreshTableData}
              searchPlaceholder={"Type to search "}
              filters={filterFormData}
            />
          )}
        </div>
      </div>

      <Drawer
        title={<Header title="Filter" name="Applications" onClose={onClose1} />}
        width={720}
        onClose={onClose1}
        open={filteropen}
        closable={false}
      >
        <ListOfApplicationFilterForm
          refreshData={toggleRefreshTableData}
          filterFormData={filterFormData}
          setFilterFormData={setFilterFormData}
          open={showDrawer1}
          closeForm={onClose1}
          form={form}
          placement="right"
          setActionPerformed={setActionPerformed}
        />
      </Drawer>

      <Modal
        title="Confirm Delete"
        open={openModal}
        onOk={() => {
          setOpenModal(false);
        }}
        onCancel={() => setOpenModal(false)}
        okType="danger"
      >
        <p>{`Are you sure you want to delete ${selectedListOfApplication?.name}?`}</p>
      </Modal>
    </div>
  );
};

export default ListOfApplication;
