import React, { useEffect, useState } from "react";
import "../styles/customerMatching.css";
import { getCustomerDedupe } from "../services/dedupeService";
import ErrorMessage from "../../../utils/errorHandling/ErrorMessage";
import { message } from "antd";
import Loading from "../../../utils/loading/Loading";
import { useParams } from "react-router-dom";
import { decrypt } from "../../../utils/cryptoUtils/cryptoUtils";
import { DedupeLinkService } from "../services/dedupeService";
// import correct from "../assets/correct.png"
import link from "../assets/link.png";
import CustomNoData from "../../../utils/noDataIcon/CustomNoData";

const specialKeys = [
  "Pan Card",
  "Aadhar Card",
  "Voter Id",
  "Driving Licence",
  "Passport",
];

const CustomerMatching = ({ customer_id }) => {
  const [showNoData, setShowNoData] = useState(false);
  const [customersData, setCustomersData] = useState(null);
  const [loading, setLoading] = useState(false);
  const { encrypted_loan_id } = useParams();

  let loan_id = null;
  try {
    loan_id = decrypt(encrypted_loan_id);
  } catch (error) {
    message.error("Invalid Loan ID");
  }

  useEffect(() => {
    const fetchCustomerDedupeData = async () => {
      try {
        setLoading(true);
        const response = await getCustomerDedupe(loan_id, customer_id);
        const isEmpty = response?.every((obj) => obj?.response_code !== 200);

        if (isEmpty) {
          setShowNoData(true);
          setLoading(false);
        } else {
          const filteredData = response.filter(
            (item) => item.riskmatch !== null
          );

          const data = filteredData.map((customer) => {
            const dedupeRecords = [];

            if (customer?.riskmatch) {
              customer.riskmatch.forEach((match) => {
                const hasValidData =
                  match?.matched_application_no !== null &&
                  match?.matched_application_no !== "";

                if (hasValidData) {
                  dedupeRecords.push({
                    loan_account_no: match?.loan_account_no || "",
                    source: match?.source_name || "Direct",
                    customer_name:
                      `${match?.f_name} ${match?.m_name} ${match.l_name}` || "",
                    matching_data: match?.matching_data || [],
                    un_matching_data: match?.un_matching_data || [],
                    customer_type: match?.customer_type || "",
                    input_customer_id: customer?.input_customer_id || "", // Ensure input_customer_id is mapped from customer
                    customer_id: match?.customer_id || "", // Use customer_id from match
                  });
                }
              });
            }

            if (customer?.householdmatch) {
              customer.householdmatch.forEach((match) => {
                if (match.matched_loan_account_no === null) {
                }

                const hasValidData =
                  match?.matched_application_no != null &&
                  match?.matched_percentage != null &&
                  match?.matched_loan_account_no != null &&
                  match?.matched_application_no?.trim() !== "" &&
                  match?.matched_application_no !== "None" &&
                  match?.matched_percentage !== "None";

                if (hasValidData) {
                  dedupeRecords.push({
                    loan_account_no: `${match?.matched_loan_account_no} (Household)`,
                    source: match?.source_name || "Direct",
                    customer_name: `${match?.f_name} ${match?.m_name} ${match.l_name}`,
                    matching_data: [
                      {
                        "Matched Current Address":
                          match?.matched_current_address || "",
                      },
                      {
                        "Current Address Match Percentage":
                          match?.current_fulladdress_match_percentage?.toString() ||
                          "",
                      },
                      {
                        "Matched Permanent Address":
                          match?.matched_permanent_address || "",
                      },
                      {
                        "Permanent Address Match Percentage":
                          match?.permanent_fulladdress_match_percentage?.toString() ||
                          "",
                      },
                    ],
                    un_matching_data: [],
                    customer_type: match?.customer_type,
                  });
                }
              });
            }

            return { dedupe_records: dedupeRecords };
          });

          setCustomersData(data);
          setLoading(false);
        }
      } catch (error) {
        setShowNoData(true);
        setLoading(false);
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };
    fetchCustomerDedupeData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loan_id]);

  if (showNoData) {
    return (
      <div className="no_data_found_message">
        <CustomNoData />
      </div>
    );
  }

  const handleLinkClick = async (match) => {
    try {
      const data = {
        linked_from_customer_detail_id: match.input_customer_id,
        linked_to_customer_detail_id: match.customer_id,
      };

      const response = await DedupeLinkService(data);
      if (response && response.status === 200) {
        message.success("Customer linked successfully!");
      } else {
        message.error("Failed to link customer. Please try again.");
      }
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  return (
    <div className="customer-matching-container">
      {loading ? (
        <div style={{ border: "1px solid #ddd" }}>
          <Loading style={{ width: "100%" }} loading={loading} />
        </div>
      ) : showNoData ? (
        <div className="no_data_found_message">
          <CustomNoData />
        </div>
      ) : (
        <div className="custom-table">
          <div className="table_heading">
            <div className="source-column-dedupe">Source</div>
            <div className="source-column-dedupe">Customer Name</div>
            <div className="source-column-dedupe">Customer Type</div>
            <div className="source-column-dedupe">Matched Records</div>
            <div className="source-column-dedupe">Unmatched Records</div>
          </div>
          {customersData &&
            customersData.map((data, index) => (
              <div key={index} className="dedupe_grid_container">
                {data.dedupe_records.map((match, index) => (
                  <div key={index} >
                    <div
                      className="dedupe_first_row"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div className="dedupe_loan_number">
                        {match?.loan_account_no}
                      </div>

                      {/* <div className="dedup_link_lan_button" onClick={() => handleLinkClick(match)}>
                        <img src={correct} alt="" />
                        <span>Linked</span>
                      </div>  */}

                      <div
                        className="dedup_link_lan_button"
                        onClick={() => handleLinkClick(match)}
                      >
                        <img src={link} alt="" />
                        <span>Link</span>
                      </div>
                    </div>

                    <div className="dedupe_grid_row">
                      <div className="dedupe_table_data_body" style={{marginLeft:"10px"}}>
                        {data.source || "Direct"}
                      </div>
                      <div className="dedupe_table_data_body">
                        {match.customer_name}
                      </div>
                      <div className="dedupe_table_data_body">
                        {match.customer_type}
                      </div>
                      <div className="dedupe_table_data_body">
                        {match.matching_data.map((record, i) => (
                          <div key={i} style={{ marginBottom: "10px" }}>
                            {Object.entries(record)
                              .filter(
                                ([key, value]) =>
                                  value !== null &&
                                  value !== "None" &&
                                  value.trim() !== ""
                              )
                              .map(([key, value]) => (
                                <div
                                  className={`dedupe_match ${specialKeys.includes(key) ? "highlight_property" : ""}`}
                                  key={key}
                                >

                                  <div style={{ width: "40%", textAlign: "left" }}>
                                    <strong>{`${key}:`}</strong>
                                  </div>
                                  <div style={{ width: "65%", display: "flex", textAlign: "left",marginRight:"20px" }}>
                                    {`${value}`}
                                  </div>
                                </div>
                              ))}
                          </div>
                        ))}
                      </div>
                      <div className="dedupe_table_data_body">
                        {match.un_matching_data.map((record, i) => (
                          <div key={i} style={{ marginBottom: "10px" }}>
                            {Object.entries(record)
                              .filter(
                                ([key, value]) =>
                                  value !== null &&
                                  value !== "None" &&
                                  value.trim() !== ""
                              )
                              .map(([key, value]) => (
                                <div key={key} className="dedupe_match">
                                  <div style={{ width: "40%", textAlign: "left" }}>
                                    <strong>{`${key}:`}</strong>
                                  </div>
                                  <div style={{ width: "70%", display: "flex", textAlign: "left" }}>
                                    {`${value}`}
                                  </div>
                                </div>
                              ))}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ))}
        </div>
      )}
    </div>
  );
};

export default CustomerMatching;
