import React, { useEffect, useState } from "react";
import "../styles/loanDetails.css";
import { Button, Tabs, Space } from "antd";
import { AiOutlineUp, AiOutlineDown } from "react-icons/ai";
import "../../../styles/loanTabsApplication.css";
import ConditionalRender from "../../../../../utils/authorization/AuthorizeComponent";
import { useSelector } from "react-redux";
import { schema } from "../../../../../config/schemeConfig";
const { TabPane } = Tabs;

const LoanDetails = ({ loanDetails, onRefresh }) => {
  const [showTabs, setShowTabs] = useState(true);
  const [activeButton, setActiveButton] = useState("sales");
  const { user_data } = useSelector((state) => state.user);


  const generateTabKey = (section, label, index) => {
    const sanitizedLabel = label.toLowerCase().replace(/\s+/g, '-');
    return `${section}-${sanitizedLabel}-${index}`;
  };

  // Function to get the first available tab key for a section
  const getFirstTabKey = (section) => {
    const loanTypeTabs = schema[section]?.[loanDetails?.loan_type?.display_name] || [];
    if (loanTypeTabs.length > 0) {
      return generateTabKey(section, loanTypeTabs[0].label, 0);
    }
    return `${section}-0`;
  };

  const [tabStates, setTabStates] = useState({
    sales: "",
    underwriting: "",
    operations: "",
  });

  useEffect(() => {
    setTabStates({
      sales: getFirstTabKey("sales"),
      underwriting: getFirstTabKey("underwriting"),
      operations: getFirstTabKey("operations"),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loanDetails]); 

  const handleButtonClick = (type) => {
    setActiveButton(type);
    setShowTabs(true);
    setTabStates(prev => ({
      ...prev,
      [type]: getFirstTabKey(type)
    }));
  };

  const handleTabChange = (activeKey) => {
    setTabStates(prev => ({
      ...prev,
      [activeButton]: activeKey
    }));
  };

  const toggleTabContent = () => {
    setShowTabs((prev) => !prev);
  };


  const tabs = {
    sales: [],
    underwriting: [],
    operations: [],
  };

  const loanTypeTabs = schema[activeButton]?.[loanDetails?.loan_type?.display_name] || [];

  loanTypeTabs.forEach((item, index) => {
    let componentWithProps;
    if (item.label === "Income Evaluation") {
      componentWithProps = <item.component loanDetails={loanDetails} />;
    } else if (item.label === "Basic Details") {
      componentWithProps = <item.component onRefresh={onRefresh} />;
    } else {
      componentWithProps = <item.component />;
    }

    tabs[activeButton].push({
      label: item.label,
      component: componentWithProps,
      key: generateTabKey(activeButton, item.label, index)
    });
  });

  return (
    <div className="loan_details_main_container">
      <Space
        direction="horizontal"
        align="center"
        className="loan_block_heading"
      >
        <div>
          <h2 className="heading_loan_details">Loan Details</h2>
        </div>
        <div className="loan_block_heading_collapse_buttons_block">
          <div className="loan_block_heading_collapse_buttons_block_background">
            <Button
              className={`loan_block_buttons ${activeButton === "sales" ? "active_sales" : "inactive_sales"}`}
              onClick={() => handleButtonClick("sales")}
            >
              Sales
            </Button>
            <ConditionalRender
              userPermissions={user_data?.permissions}
              requiredPermissions={["NVUNDRWG"]}
            >
              <Button
                className={`loan_block_buttons ${activeButton === "underwriting" ? "active_sales" : "inactive_sales"}`}
                onClick={() => handleButtonClick("underwriting")}
              >
                Underwriting
              </Button>
            </ConditionalRender>
            <ConditionalRender
              userPermissions={user_data?.permissions}
              requiredPermissions={["NAVOPS01"]}
            >
              <Button
                className={`loan_block_buttons ${activeButton === "operations" ? "active_sales" : "inactive_sales"}`}
                onClick={() => handleButtonClick("operations")}
              >
                Operations
              </Button>
            </ConditionalRender>
          </div>
          {showTabs ? (
            <AiOutlineUp
              className="loan_block_collapse_button"
              onClick={toggleTabContent}
            />
          ) : (
            <AiOutlineDown
              className="loan_block_collapse_button"
              onClick={toggleTabContent}
            />
          )}
        </div>
      </Space>
      <div
        className={`customer_main_heading ${!showTabs ? "loan_block_border_collapse" : ""}`}
      ></div>
      {showTabs && (
        <Tabs
          activeKey={tabStates[activeButton]}
          onChange={handleTabChange}
          type="card"
          className="loan_block_content"
          destroyInactiveTabPane
        >
          {tabs[activeButton].map((tab) => (
            <TabPane tab={tab.label} key={tab.key}>
              {tab.component}
            </TabPane>
          ))}
        </Tabs>
      )}
    </div>
  );
};

export default LoanDetails;