import React, { useEffect, useState } from "react";
import { Button, Col, Form, Input, Row, Select, Space, message } from "antd";
import * as Yup from "yup";
import {
  createAgentService,
  getAgentByIdService,
  updateAgentByIdService,
  listLocationService,
  listAgencyTypeService,
  fetchAgenciesByType,
} from "../services/agentServices";
import ErrorMessage from "../../../../utils/errorHandling/ErrorMessage";
const { Option } = Select;

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .required("Name is required")
    .matches(/^[A-Za-z ]+$/, "Name can only contain letters and spaces")
    .min(2, "Name must be at least 2 characters")
    .max(128, "Name must be at max 128 characters")
    .label("Name"),
  personal_email: Yup.string()
    .required("Email is required")
    .min(2, "Email must be at least 2 characters")
    .max(128, "Email must be at max 128 characters")
    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z.-]+\.[a-zA-Z]{2,}$/,
      "Email address is not in a valid format "
    ),
  work_email: Yup.string()
    .required("Email is required")
    .min(2, "Email must be at least 2 characters")
    .max(128, "Email must be at max 128 characters")
    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z.-]+\.[a-zA-Z]{2,}$/,
      "Email address is not in a valid format "
    ),
  password: Yup.string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters")
    .max(16, "Password must be at max 16 characters")
    .matches(/[A-Z]/, "Password must contain at least one capital letter")
    .matches(/[0-9]/, "Password must contain at least one number")
    .matches(
      /[!@#$%^&*]/,
      "Password must contain at least one special character"
    )
    .label("Password"),
  mobile: Yup.string()
    .required("Mobile is required")
    .matches(/^\d+$/, "Mobile number must contain only numeric characters")
    .min(10, "Mobile number must be exact 10 digits")
    .max(10, "Mobile number must be exact 10 digits"),
  agency: Yup.array()
    .of(Yup.string())
    .required("Agency is required")
    .min(1, "At least one agency must be selected"),
  agency_type: Yup.string()
    .required("Agency is required")
    .min(1, "Agency is required"),
  user: Yup.string().required("User is required").min(1, "User is required"),
  location: Yup.string()
    .required("Location is required")
    .min(1, "Location is required"),
});

const yupSync = {
  async validator({ field }, value) {
    await validationSchema.validateSyncAt(field, { [field]: value });
  },
};

const AgentForm = ({
  refreshData,
  id,
  open,
  closeDrawer,
  onCancel,
  onNext,
  formCompleted,
  currentRoute,
  setId,
  onUserIdChange,
}) => {
  const [form] = Form.useForm();
  const [allAgency, setAllAgency] = useState([]);
  const [allAgencyType, setAllAgencyType] = useState([]);
  const [, setAgencyTypeCode] = useState([]);
  const [allLocations, setAllLocations] = useState([]);

  const getAgentData = async () => {
    if (id) {
      try {
        const response = await getAgentByIdService(id);
        const user_id = response.data.user;
  
        if (onUserIdChange) {
          onUserIdChange(user_id);
        }
        
        const {
          name,
          personal_email,
          work_email,
          password,
          mobile,
          location,
          agency,
          agency_type,
          user,
          is_active,
          is_verified,
        } = response?.data;
  
        // First set agency_type to trigger the agencies load
        form.setFieldsValue({
          agency_type: agency_type?.id
        });
  
        // Load agencies for the selected type
        await handleAgencyTypeChange(agency_type?.id);
  
        // Then set all other fields including agency IDs
        form.setFieldsValue({
          name,
          personal_email,
          work_email,
          password,
          mobile,
          location: location?.id,
          agency: agency?.map((ag) => ag?.id), // Use IDs instead of names
          user: user?.id,
          is_active,
          is_verified,
        });
        
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    }
  };
  

  const handleSubmit = async (values) => {
    try {
      if (id) {
        const { work_email, ...updatedValues } = values;
        const response = await updateAgentByIdService(id, updatedValues);
        if ((response.status = 200 && response.success)) {
          message.success("Agent successfully updated");
          onNext();
          // refreshData();
          // closeDrawer();
        }
      } else {
        const response = await createAgentService(values);
        if ((response.status = 201 && response.success)) {
          setId(response?.data?.id);
          message.success("Agent successfully created");
          formCompleted(true);
          onNext();
          // refreshData();
          // closeDrawer();
        }
      }
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  useEffect(() => {
    if (open) {
      getAgentData();
    } else {
      form.resetFields();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, id]);

  const handleInput = (e, field) => {
    const newValue = e.target.value.replace(/[^A-Za-z\s]/g, "");
    form.setFieldsValue({ [field]: newValue });
  };

  useEffect(() => {
    const getAllAgencyType = async () => {
      try {
        const response = await listAgencyTypeService();
        setAllAgencyType(response.data); // Set the full data as-is
        const codes = response.data.map((item) => item.code); // Extract only the codes
        setAgencyTypeCode(codes); // Set only the codes
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };
    const getAllLocations = async () => {
      try {
        const response = await listLocationService();
        setAllLocations(response.data);
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };

    getAllAgencyType();
    getAllLocations();
  }, [form, open]);

  const handleCancel = () => {
    onCancel();
    closeDrawer();
  };

  const handleNumberFields = (e, field) => {
    let newValue = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
    newValue = newValue.slice(0, 10); // Truncate to 10 digits
    form.setFieldsValue({ [field]: newValue });
  };
  const handleAgencyTypeChange = async (value) => {
    try {
      // Clear agency field when agency type changes
      form.setFieldsValue({ agency: undefined });
      setAllAgency([]);
  
      if (value) {
        // Find the selected agency type's code
        const selectedAgencyType = allAgencyType.find(
          (type) => type.id === value
        );
  
        if (selectedAgencyType) {
          const response = await fetchAgenciesByType(selectedAgencyType.code);
          if (response?.data) {
            setAllAgency(response.data);
          }
        }
      }
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
      setAllAgency([]);
    }
  };
  

  return (
    <Form
      form={form}
      onFinish={handleSubmit}
      layout="vertical"
      initialValues={{
        name: "",
        personal_email: "",
        work_email: "",
        password: "",
        mobile: "",
        is_active: true,
        is_verified: true,
      }}
    >
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item name="name" label="Name" rules={[yupSync]} required>
            <Input
              placeholder="Please Enter Name"
              onChange={(e) => handleInput(e, "name")}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="personal_email"
            label="Email"
            rules={[yupSync]}
            required
          >
            <Input
              placeholder="Please Enter Email"
              // onChange={(e) => handleInput(e, "personal_email")}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name="mobile"
            label="Mobile Number"
            rules={[yupSync]}
            required
          >
            <Input
              placeholder="Please Enter Mobile Number"
              onChange={(e) => handleNumberFields(e, "mobile")}
            />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            name="agency_type"
            label="Agency Type"
            rules={[yupSync]}
            required
          >
            <Select
              mode="single"
              placeholder="Please select"
              required={true}
              allowClear
              style={{ width: "100%" }}
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              onChange={handleAgencyTypeChange}
            >
              {allAgencyType?.map((agencytype) => {
                return (
                  <Select.Option key={agencytype.id} value={agencytype.id}>
                    {agencytype.display_name}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item name="agency" label="Agency" rules={[yupSync]} required>
            <Select
              mode="multiple"
              placeholder="Please select"
              required={true}
              allowClear
              style={{ width: "100%" }}
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {allAgency?.map((agency) => {
                return (
                  <Select.Option key={agency.id} value={agency.id}>
                    {agency.display_name}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            name="work_email"
            label="Work Email"
            rules={[yupSync]}
            required
          >
            <Input placeholder="Please Enter Email" disabled={!!id} />
          </Form.Item>
        </Col>
        {!id && (
          <>
            <Col span={12}>
              <Form.Item
                name="password"
                label="Password"
                required
                rules={[yupSync]}
              >
                <Input.Password placeholder="Please Enter Password" />
              </Form.Item>
            </Col>
          </>
        )}

        {/* <Col span={12}>
          <Form.Item name="user" label="User" rules={[yupSync]} required>
            <Select
              mode="single"
              placeholder="Please select"
              required={true}
              allowClear
              style={{ width: "100%" }}
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {allUsers?.map((user) => {
                return (
                  <Select.Option key={user.id} value={user.id}>
                    {user.name}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col> */}

        <Col span={12}>
          <Form.Item
            name="location"
            label="Location"
            rules={[yupSync]}
            required
          >
            <Select
              mode="single"
              placeholder="Please select"
              required={true}
              allowClear
              style={{ width: "100%" }}
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {allLocations?.map((location) => {
                return (
                  <Select.Option key={location.id} value={location.id}>
                    {location.name}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="is_active" label="Is Active" required>
            <Select placeholder="Please Select">
              <Option value={true}>True</Option>
              <Option value={false}>False</Option>
            </Select>
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item name="is_verified" label="Is Verified" required>
            <Select placeholder="Please Select">
              <Option value={true}>True</Option>
              <Option value={false}>False</Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Space
        direction="horizontal"
        align="center"
        style={{ display: "flex", flexDirection: "row-reverse" }}
      >
        <Button type="primary" htmlType="submit">
          Next
        </Button>
        <Button onClick={handleCancel}>Cancel</Button>
      </Space>
    </Form>
  );
};

export default AgentForm;
