import React, { useState, useEffect } from "react";
import "../styles/loanInsurance.css";
import { Drawer, Modal, message } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import {
  getInsuranceDetailsByIdService,
  deleteMagmaHdi,
} from "../services/loanInsuranceService";
import "../styles/magmaInsuranceView.css";
import Header from "../../../../layout/views/Header";
import pdf_image from "../assets/pdf.png";
import MagmaHDIInsuranceForm from "./MagmaHDIInsuranceForm";
import EditButton from "../../../../../utils/editButton/EditButton";
import MagmaPlanData from "./MagmaPlanData";
import RViewerJS from "viewerjs-react";
import { useSelector } from "react-redux";
import ConditionalRender from "../../../../../utils/authorization/AuthorizeComponent";

const MagmaHDIInsuranceview = ({
  insurance_id,
  loan_id,
  toggleRefreshInsurance,
  setDisabledButton,
  disabledButton,
  setAddressType,
  addressType
}) => {
  const [openModal, setOpenModal] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [open, setOpen] = useState(false);
  const [, setSelectedUser] = useState(null);
  const [refreshTableData, setRefreshTableData] = useState(false);
  const [data, setData] = useState([]);
  const [imageData, setImagedata] = useState(null);
  const [customer_id, setCustomer_id] = useState("");
  const [nominee_id, setNominee_id] = useState("");
  const [insurancecompanydetail, setInsurancecompanydetail] = useState("");

  const { user_data } = useSelector((state) => state.user);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setSelectedUser(null);
    setSelectedUserId(null);
    toggleRefreshTableData();
    setOpen(false);
  };

  const toggleRefreshTableData = () => {
    setRefreshTableData((prev) => !prev);
  };

  const closeDrawer = () => {
    setOpen(false);
    setSelectedUser(null);
    setSelectedUserId(null);
    toggleRefreshTableData();
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getInsuranceDetailsByIdService(insurance_id);
        setData(data?.data);
        setCustomer_id(data?.data?.insurance_detail?.customer_detail?.id);
        setImagedata(data?.data?.document);
        setNominee_id(data?.data?.nominee_detail[0]?.id);
        setInsurancecompanydetail(
          data?.data?.insurance_detail?.insurance_company_detail?.id
        );
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [insurance_id, refreshTableData]);

  const deleteUserById = async () => {
    try {
      const response = await deleteMagmaHdi(insurance_id);
      if (response.status === 200 && response.success === true) {
        toggleRefreshInsurance();
        message.success("Magma deleted successfully");
        setOpenModal(false);
      }
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };


  const IndianNumberFormat = (value) => {
    return new Intl.NumberFormat('en-IN').format(value);
  };

  return (
    <div>
      <div className="loan-view-header">
        <div className="loan-view-heading"></div>
        <div className="insurance-button-container" style={{ display: "flex" }}>
          {/* <div className="insurance-download-button">
            <VerticalAlignBottomOutlined />
            Download Form
          </div> */}
          <ConditionalRender
            userPermissions={user_data?.permissions}
            requiredPermissions={["EDITINSR"]}
          >
            <div className="insurance-edit-button" onClick={showDrawer}>
              <EditButton />
            </div>
          </ConditionalRender>
          <Drawer
            title={
              <Header
                title={insurance_id ? "Edit" : "Add"}
                onClose={onClose}
                name="Magma HDI Insurance"
              />
            }
            width={1000}
            onClose={onClose}
            open={open}
            closable={false}

          >
            <MagmaHDIInsuranceForm
              toggleRefreshTableData={toggleRefreshTableData}
              id={selectedUserId}
              open={open}
              closeDrawer={closeDrawer}
              closeForm={onClose}
              onCancel={onClose}
              setSelectedCustomer={setSelectedUserId}
              insurance_id={insurance_id}
              loan_id={loan_id}
              customer_id={customer_id}
              nominee_id={nominee_id}
              insurancecompanydetail={insurancecompanydetail}
              toggleRefreshInsurance={toggleRefreshInsurance}
              setDisabledButton={setDisabledButton}
              disabledButton={disabledButton}
              setAddressType={setAddressType}
              addressType={addressType}
            />
          </Drawer>
          <ConditionalRender
            userPermissions={user_data?.permissions}
            requiredPermissions={["DELTINSR"]}
          >
            <div
              className="insurance-delete-button"
              onClick={() => setOpenModal(true)}
            >
              <DeleteOutlined />
            </div>
          </ConditionalRender>
        </div>
      </div>

      <div className="icici_view_header">
        <div className="insurance_view_heading">Details Of Coverage</div>
      </div>

      <div className="loan_and_insurance_container">
        <div className="laon_insurance_Basic_card">
          <div className="laon_insurance_basic_card-content">
            <div className="loan_inusurance_label">Loan Amount</div>
            <div className="loan_inusurance_data">
              {data?.insurance_coverage_data?.loan_amount ? `₹ ${IndianNumberFormat(data?.insurance_coverage_data?.loan_amount)}` : '-'}
            </div>
          </div>
          <div className="laon_insurance_basic_card-content">
            <div className="loan_inusurance_label">Monthly Income</div>
            <div className="loan_inusurance_data">
              {data?.insurance_coverage_data?.monthly_income ? `₹${IndianNumberFormat(data?.insurance_coverage_data?.monthly_income)}` : '-'}
            </div>
          </div>
          <div className="laon_insurance_basic_card-content">
            <div className="loan_inusurance_label">Branch Address</div>
            <div className="loan_inusurance_data">
              {data?.insurance_coverage_data?.branch_address?.name || "-"}
            </div>
          </div>
        </div>
        <div className="laon_insurance_Basic_card">
          <div className="laon_insurance_basic_card-content">
            <div className="loan_inusurance_label">Tenure Of Loan</div>
            <div className="loan_inusurance_data">
              {data?.insurance_coverage_data?.tenure || "-"}
            </div>
          </div>
          <div className="laon_insurance_basic_card-content">
            <div className="loan_inusurance_label">
              Premium Amount (Including GST)
            </div>
            <div className="loan_inusurance_data">
              {data?.insurance_coverage_data?.total_premium_incl_gst ? `₹${IndianNumberFormat(data?.insurance_coverage_data?.total_premium_incl_gst)}` : '-'}
            </div>
          </div>
        </div>

        <div className="laon_insurance_Basic_card">
          <div className="laon_insurance_basic_card-content">
            <div className="loan_inusurance_label">Policy Term(In Months)</div>
            <div className="loan_inusurance_data">
              {data?.insurance_coverage_data?.policy_term || "-"}
            </div>
          </div>
          <div className="laon_insurance_basic_card-content">
            <div className="loan_inusurance_label">EMI Amount</div>
            <div className="loan_inusurance_data">
                 {data?.insurance_coverage_data?.emi_amount ? `₹${IndianNumberFormat(data?.insurance_coverage_data?.emi_amount)}` : '-'}
            </div>
          </div>
        </div>

        <div className="laon_insurance_Basic_card">
          <div className="laon_insurance_basic_card-content">
            <div className="loan_inusurance_label">Sum Assured</div>
            <div className="loan_inusurance_data">
                {data?.insurance_coverage_data?.sum_assured ? `₹${IndianNumberFormat(data?.insurance_coverage_data?.sum_assured)}` : '-'}
            </div>
          </div>
          <div className="laon_insurance_basic_card-content">
            <div className="loan_inusurance_label">Age Proof</div>
            <div className="loan_inusurance_data">
              {data?.insurance_coverage_data?.age_proof?.name || "-"}
            </div>
          </div>
        </div>
      </div>

      <div className="icici_view_header">
        <div className="insurance_view_heading">
          Group Member / Life To Be Assured
        </div>
      </div>
      {data?.insured_customer_detail?.map((datas) => (
        <div className="loan_and_insurance_container">
          <div className="laon_insurance_Basic_card">
            <div className="laon_insurance_basic_card-content">
              <div className="loan_inusurance_label">Name</div>
              <div className="loan_inusurance_data">
                {datas?.first_name || "-"} {datas?.middle_name || "-"}{" "}
                {datas?.last_name || "-"}
              </div>
            </div>
            <div className="laon_insurance_basic_card-content">
              <div className="loan_inusurance_label">Nationality</div>
              <div className="loan_inusurance_data">
                {datas?.nationality || "-"}
              </div>
            </div>
            <div className="laon_insurance_basic_card-content">
              <div className="loan_inusurance_label">Address 1</div>
              <div className="loan_inusurance_data">
                {datas?.address_line_1 || "-"}
              </div>
            </div>
            <div className="laon_insurance_basic_card-content">
              <div className="loan_inusurance_label">Pincode</div>
              <div className="loan_inusurance_data">
                {datas?.pincode?.name || "-"}
              </div>
            </div>
          </div>

          <div className="laon_insurance_Basic_card">
            <div className="laon_insurance_basic_card-content">
              <div className="loan_inusurance_label">Date Of Birth</div>
              <div className="loan_inusurance_data">
                {datas?.date_of_birth || "-"}
              </div>
            </div>
            <div className="laon_insurance_basic_card-content">
              <div className="loan_inusurance_label">Contact Number</div>
              <div className="loan_inusurance_data">{datas?.mobile || "-"}</div>
            </div>
            <div className="laon_insurance_basic_card-content">
              <div className="loan_inusurance_label">Address 2</div>
              <div className="loan_inusurance_data">
                {datas?.address_line_2 || "-"}
              </div>
            </div>
          </div>

          <div className="laon_insurance_Basic_card">
            <div className="laon_insurance_basic_card-content">
              <div className="loan_inusurance_label">Gender</div>
              <div className="loan_inusurance_data">{datas?.gender || "-"}</div>
            </div>
            <div className="laon_insurance_basic_card-content">
              <div className="loan_inusurance_label">Email</div>
              <div className="loan_inusurance_data">{datas?.email || "-"}</div>
            </div>
            <div className="laon_insurance_basic_card-content">
              <div className="loan_inusurance_label">City</div>
              <div className="loan_inusurance_data">
                {datas?.city?.name || "-"}
              </div>
            </div>
          </div>

          <div className="laon_insurance_Basic_card">
            <div className="laon_insurance_basic_card-content">
              <div className="loan_inusurance_label">Occupation</div>
              <div className="loan_inusurance_data">
                {datas?.ocupation?.name || "-"}
              </div>
            </div>
            <div className="laon_insurance_basic_card-content">
              <div className="loan_inusurance_label">Applicant Status</div>
              <div className="loan_inusurance_data">
                {datas?.customer_type?.name || "-"}
              </div>
            </div>
            <div className="laon_insurance_basic_card-content">
              <div className="loan_inusurance_label">State</div>
              <div className="loan_inusurance_data">
                {datas?.state?.name || "-"}
              </div>
            </div>
          </div>
        </div>
      ))}

      <div>
        <div className="second_heading_details_container">Nominee Details</div>
        <div className="loan_and_insurance_container">
          <div className="laon_insurance_Basic_card">
            <div className="laon_insurance_basic_card-content">
              <div className="loan_inusurance_label">Name</div>
              <div className="loan_inusurance_data">
                {data?.nominee_detail?.[0]?.name || "-"}
              </div>
            </div>
            <div className="laon_insurance_basic_card-content">
              <div className="loan_inusurance_label">
                Nominee's Contact Number
              </div>
              <div className="loan_inusurance_data">
                {data?.nominee_detail?.[0]?.mobile || "-"}
              </div>
            </div>
            <div className="laon_insurance_basic_card-content">
              <div className="loan_inusurance_label">State</div>
              <div className="loan_inusurance_data">
                {data?.nominee_detail?.[0]?.state.name || "-"}
              </div>
            </div>
          </div>

          <div className="laon_insurance_Basic_card">
            <div className="laon_insurance_basic_card-content">
              <div className="loan_inusurance_label">
                Relation With The Life To Be Assured
              </div>
              <div className="loan_inusurance_data">
                {data?.nominee_detail?.[0]?.relation?.name || "-"}
              </div>
            </div>
            <div className="laon_insurance_basic_card-content">
              <div className="loan_inusurance_label">Address Line 1</div>
              <div className="loan_inusurance_data">
                {(data?.nominee_detail?.[0]?.address_line_1 || "")}
              </div>
            </div>
            <div className="laon_insurance_basic_card-content">
              <div className="loan_inusurance_label">Pincode</div>
              <div className="loan_inusurance_data">
                {data?.nominee_detail?.[0]?.pincode.name || "-"}
              </div>
            </div>
          </div>

          <div className="laon_insurance_Basic_card">
            <div className="laon_insurance_basic_card-content">
              <div className="loan_inusurance_label">
                Nominee's Date Of Birth
              </div>
              <div className="loan_inusurance_data">
                {(data?.nominee_detail?.[0]?.date_of_birth)}
              </div>
            </div>
            <div className="laon_insurance_basic_card-content">
              <div className="loan_inusurance_label">
                Address Line 2
              </div>
              <div className="loan_inusurance_data">
                {data?.nominee_detail?.[0]?.address_line_2 || "-"}
              </div>
            </div>

          </div>

          <div className="laon_insurance_Basic_card">
            <div className="laon_insurance_basic_card-content">
              <div className="loan_inusurance_label">Nominee's Gender</div>
              <div className="loan_inusurance_data">
                {data?.nominee_detail?.[0]?.gender || "-"}
              </div>
            </div>
            <div className="laon_insurance_basic_card-content">
              <div className="loan_inusurance_label">
                City
              </div>
              <div className="loan_inusurance_data">
                {data?.nominee_detail?.[0]?.city.name || "-"}
              </div>
            </div>
          </div>
        </div>
      </div>

      {data?.nominee_detail?.[0]?.appointee && (
        <div>
          <div
            className="second_heading_details_container"
          >
            Appointee Details
          </div>

          <div className="nominee_details">
            <div className="laon_insurance_Basic_card">
              <div className="laon_insurance_basic_card-content">
                <div className="loan_inusurance_label">Name</div>
                <div className="loan_inusurance_data">
                  {data?.nominee_detail[0]?.appointee?.appointee_name || "-"}
                </div>
              </div>
              <div className="laon_insurance_basic_card-content">
                <div className="loan_inusurance_label">
                  Appointee's Contact Number
                </div>
                <div className="loan_inusurance_data">
                  {data?.nominee_detail[0]?.appointee?.mobile || "-"}
                </div>
              </div>
            </div>

            <div className="laon_insurance_Basic_card">
              <div className="laon_insurance_basic_card-content">
                <div className="loan_inusurance_label">
                  Relation With The Life To Be Assured
                </div>
                <div className="loan_inusurance_data">
                  {data?.nominee_detail?.[0]?.appointee?.relation?.name || "-"}
                </div>
              </div>
            </div>

            <div className="laon_insurance_Basic_card">
              <div className="laon_insurance_basic_card-content">
                <div className="loan_inusurance_label">
                  Appointee's Date Of Birth
                </div>
                <div className="loan_inusurance_data">
                  {data?.nominee_detail[0]?.appointee?.date_of_birth || "-"}
                </div>
              </div>
            </div>

            <div className="laon_insurance_Basic_card">
              <div className="laon_insurance_basic_card-content">
                <div className="loan_inusurance_label">Appointee's Gender</div>
                <div className="loan_inusurance_data">
                  {data?.nominee_detail[0]?.appointee?.gender || "-"}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <MagmaPlanData data={data} />

      <div
        className="magma-image-gallery"
      >
        {imageData?.map((picture, index) => (
          <div key={index} className="image-container">
            {picture.file_type === "application/pdf" ? (
              <a
                href={picture.document}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={pdf_image}
                  className="magma-image"
                  alt="PDF Preview"
                />
              </a>
            ) : (
              <RViewerJS
                options={{
                  url: (image) => image.getAttribute("data-original"),
                }}
              >
                <img
                  src={picture.thumb_document ? picture.thumb_document : picture.document}
                  data-original={picture.document}
                  alt="Preview"
                  className="magma-image"
                />
              </RViewerJS>
            )}
          </div>
        ))}
      </div>
      <Modal
        title="Confirm Delete"
        open={openModal}
        onOk={() => {
          setOpenModal(false);
          deleteUserById();
        }}
        onCancel={() => setOpenModal(false)}
        okType="danger"
      >
        <p>{`Are you sure you want to delete this insurance?`}</p>
      </Modal>
    </div>
  );
};

export default MagmaHDIInsuranceview;
