import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Space,
  message,
  Upload,
  DatePicker,
  Select,
} from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import debounce from "lodash/debounce";
import "react-loading-skeleton/dist/skeleton.css";
import * as Yup from "yup";
// import RViewerJS from "viewerjs-react";

import {
  createUserPersonalDetailsService,
  getUserPersonalDetailsService,
  updateUserPersonalDetailsService,
  checkMobileNumberService,
  checkMobileEmailService,
  listGenderService,
} from "../services/userService";
import ErrorMessage from "../../../utils/errorHandling/ErrorMessage";
import dayjs from "dayjs";
import Loading from "../../../utils/loading/Loading";
const { Option } = Select;

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .required("Name is required")
    .min(2, "Name must be at least 2 characters")
    .max(128, "Name must be at max 128 characters")
    .label("Name"),
  mobile: Yup.string()
    .required("Mobile is required")
    .matches(/^\d{10}.*$/, "Invalid mobile number")
    .label("Mobile"),
  employee_code: Yup.string()
    .required("Employee Code is required")
    .min(2, "Employee Code must be at least 2 characters")
    .max(8, "Employee Code must be at max 8 characters")
    .label("Employee Code"),
  code: Yup.string()
    .required("Code is required")
    .length(8, "Code must be exactly 8 characters")
    .matches(
      /^[A-Z0-9]*$/,
      "Code must contain only capital letters and numbers"
    )
    .label("Code"),
  country_code: Yup.string()
    .required("Code is required")
    .min(2, "Code must be at least 2 characters")
    .max(16, "Code must be at max 16 characters")
    .label("Code"),
  work_email: Yup.string()
    .required("Email is required!")
    .matches(
      /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
      "Invalid email address!"
    )
    .label("Email"),
  personal_email: Yup.string()
    .required("Email is required!")
    .matches(
      /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
      "Invalid email address!"
    )
    .label("Email"),
  password: Yup.string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters")
    .max(16, "Password must be at max 16 characters")
    .matches(/[A-Z]/, "Password must contain at least one capital letter")
    .matches(/[0-9]/, "Password must contain at least one number")
    .matches(
      /[!@#$%^&*]/,
      "Password must contain at least one special character"
    )
    .label("Password"),
  password2: Yup.string()
    .required("Confirm Password is required")
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .label("Confirm Password"),
  gender: Yup.string().required("Gender is required"),
  date_of_birth: Yup.date()
    .nullable() // Allows null values
    .transform((value, originalValue) => (originalValue === "" ? null : value)) // Converts "" to null
    .required("Date of birth is required")
    .max(new Date(), "Date of birth cannot be in the future"),
});

const yupSync = {
  async validator({ field }, value) {
    await validationSchema.validateSyncAt(field, { [field]: value });
  },
};

const CustomUploadButton = ({ loading, imageUrl, isEditMode, userImageUrl }) => {
  const displayImageUrl = imageUrl || (userImageUrl);

  return (
    <div>
      {loading ? (
        <LoadingOutlined />
      ) : displayImageUrl ? (
        // <RViewerJS>
          <img
            src={displayImageUrl}
            alt="avatar"
            style={{
              width: "100px",
              height: "100px",
              borderRadius: "50%",
              objectFit: "cover",
              cursor: "pointer", // Make the image clickable
            }}
          />
        // </RViewerJS>
      ) : (
        <>
          <PlusOutlined />
          <div>Upload Image</div>
        </>
      )}
    </div>
  );
};



const PersonalDetailsForm = ({
  id,
  open,
  onCancel,
  onNext,
  closeDrawer,
  setId,
  formCompleted,
  isEditMode,
  userData,
  currentRoute,
}) => {

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);
  const [userImageUrl, setUserImageUrl] = useState(userData?.profile_img || "");
  const [imageFile, setImageFile] = useState(null);
  const [genders, setGenders] = useState();

  useEffect(() => {
    if (userData?.profile_img) {
      setUserImageUrl(userData.profile_img);
    }
  }, [userData]);

  useEffect(() => {
    const getAllGenders = async () => {
      try {
        const response = await listGenderService();
        setGenders(response?.data);
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };
    getAllGenders();
  }, [form]);

  const beforeUpload = (file) => {
    setImageUrl(URL.createObjectURL(file));
    setImageFile(file);
    return false;
  };

  const handleChange = (info) => {
    if (info.file.status === "uploading") {
      setLoading(true);
    }
  };

  const handleCancel = () => {
    onCancel();
    closeDrawer();
  };

  const getUserData = async () => {
    if (id) {
      try {
        setLoading(true)
        const response = await getUserPersonalDetailsService(id);
        const {
          name,
          work_email,
          mobile,
          employee_code,
          code,
          country_code,
          date_of_birth,
          personal_email,
          gender,
          password,
          password2,
          profile_img,
        } = response?.data;
        // date_of_birth = dayjs(date_of_birth, "DD-MM-YYYY");


        form.setFieldsValue({
          name,
          work_email,
          mobile,
          employee_code,
          code,
          country_code,
          date_of_birth: date_of_birth ? dayjs(date_of_birth, "YYYY-MM-DD") : "",
          personal_email,
          gender,
          password,
          password2,
          profile_img,
        });
        if (profile_img) {
          setImageUrl(profile_img);
        }
        setLoading(false)
      } catch (error) {
        setLoading(false)
        message.error(error?.response?.data?.message);
      }
    }
  };

  const handleSubmit = async (values) => {
    setLoading(true);
    try {
      if (currentRoute === "/user_profile") {
        formCompleted(true);
        onNext();
        return
      }
      const responseEmail = await checkMobileEmailService(values.work_email, id);
      const responsePersonalEmail = await checkMobileEmailService(values.personal_email, id);
      const responseMobile = await checkMobileNumberService(values.mobile, id);

      if (!responseEmail.success || !responseMobile.success || !responsePersonalEmail.success) {
        if (!responseEmail.success) {
          form.setFields([
            {
              name: "work_email",
              errors: ["Email already taken"],
            },
          ]);
        }

        if (!responseMobile.success) {
          form.setFields([
            {
              name: "mobile",
              errors: ["Mobile Number already taken"],
            },
          ]);
        }
        if (!responsePersonalEmail.success) {
          form.setFields([
            {
              name: "personal_email",
              errors: ["Email already taken"],
            },
          ]);
        }
        return;
      }

      const formData = new FormData();
      formData.append("name", values.name);
      formData.append("mobile", values.mobile);
      // formData.append("password", values.password);
      // formData.append("password2", values.password2);
      formData.append("country_code", values.country_code);
      formData.append("employee_code", values.employee_code);
      formData.append("gender", values.gender);
      formData.append("personal_email", values.personal_email);
      formData.append(
        "date_of_birth",
        values.date_of_birth.format("YYYY-MM-DD")
      );

      formData.append("code", values.code);
      if (imageFile) {
        formData.append("profile_img", imageFile, values.profile_img);
      }
      if (!id) {
        // Append work_email, password, and confirm password only when creating a new user
        formData.append("work_email", values.work_email);
        formData.append("password", values.password);
        formData.append("password2", values.password2);
      }
      if (id) {
        const response = await updateUserPersonalDetailsService(id, formData);
        if (response.status === 200 && response.success) {
          message.success("User Personal Details Successfully Updated");
          onNext();
        }
      } else {
        const response = await createUserPersonalDetailsService(formData);
        if (response.status === 201 && response.success) {
          setId(response?.data?.id);
          message.success("User Successfully created");
          formCompleted(true);
          onNext();
        }
      }
    } catch (error) {
      message.error(error.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (open) {
      getUserData();
    } else {
      form.resetFields();
      setImageUrl(null);
      setImageFile(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, id, form]);

  const handleValuesChange = async (changedValues, allValues) => {
    const validationPromises = Object.keys(changedValues).map(async (field) => {
      try {
        await validationSchema.validateAt(field, {
          [field]: changedValues[field],
        });
        form.setFields([
          {
            name: field,
            errors: [],
          },
        ]);
      } catch (error) {
        form.setFields([
          {
            name: field,
            errors: [error.message],
          },
        ]);
      }
    });
    await Promise.all(validationPromises);
  };

  const handleMobileChange = async (e) => {
    const newMobileValue = e.target.value;
    const numericValue = newMobileValue.replace(/\D/g, "");
    const truncatedValue = numericValue.slice(0, 10);
    form.setFieldsValue({ mobile: truncatedValue });

    if (newMobileValue.length === 10) {
      try {
        await validationSchema.validateAt("mobile", { mobile: numericValue });
        const response = await checkMobileNumberService(numericValue, id);

        if (response.success) {
          form.setFields([
            {
              name: "mobile",
              errors: [],
            },
          ]);
        } else {
          form.setFields([
            {
              name: "mobile",
              errors: [response.message],
            },
          ]);
        }
      } catch (error) {
        form.setFields([
          {
            name: "mobile",
            errors: [error.message],
          },
        ]);
      }
    }
  };

  const handleEmailChange = async (e) => {
    const newEmailValue = e.target.value;

    try {
      await validationSchema.validateAt("work_email", { work_email: newEmailValue });
      const response = await checkMobileEmailService(newEmailValue, id);

      form.setFieldsValue({ work_email: newEmailValue, user_id: id });

      if (response.success) {
        form.setFields([
          {
            name: "work_email",
            errors: [],
          },
        ]);
      } else {
        form.setFields([
          {
            name: "work_email",
            errors: ["Email already taken"],
          },
        ]);
      }
    } catch (error) {
      form.setFields([
        {
          name: "work_email",
          errors: [error.message],
        },
      ]);
    }
  };

  const handlePersonalEmailChange = async (e) => {
    const newEmailValue = e.target.value;

    try {
      await validationSchema.validateAt("personal_email", { personal_email: newEmailValue });
      const response = await checkMobileEmailService(newEmailValue, id);

      form.setFieldsValue({ personal_email: newEmailValue, user_id: id });

      if (response.success) {
        form.setFields([
          {
            name: "personal_email",
            errors: [],
          },
        ]);
      } else {
        form.setFields([
          {
            name: "personal_email",
            errors: ["Email already taken"],
          },
        ]);
      }
    } catch (error) {
      form.setFields([
        {
          name: "personal_email",
          errors: [error.message],
        },
      ]);
    }
  };
  const handleEmailChangeDebounce = debounce(handleEmailChange, 500);
  const handlePersonalEmailChangeDebounce = debounce(handlePersonalEmailChange, 500);
  const handleAlphanumericChange = (fieldName) => (e) => {
    const inputValue = e.target.value;
    const alphanumericValue = inputValue.replace(/[^a-zA-Z0-9]/g, "");
    form.setFieldsValue({ [fieldName]: alphanumericValue });
  };

  const handleNameChange = (e) => {
    const inputValue = e.target.value;
    const alphabeticValue = inputValue.replace(/[^a-zA-Z\s]/g, "");
    form.setFieldsValue({ name: alphabeticValue });
  };

  return (
    <div>
       {loading ? (
          <Loading loading={loading} />
        ) : (
      <Form
        form={form}
        onFinish={handleSubmit}
        layout="vertical"
        onValuesChange={handleValuesChange}
        initialValues={{
          name: userData?.name || "",
          mobile: userData?.mobile || "",
          work_email: userData?.work_email || "",
          personal_email: userData?.personal_email || "",
          country_code: userData?.country_code || "+91",
          employee_code: userData?.employee_code || "",
          code: userData?.code || "",
          gender: userData?.gender || undefined,
          date_of_birth: userData?.date_of_birth ? dayjs(userData.date_of_birth, "YYYY-MM-DD") : null,
          profile_img: userData?.profile_img || "",
        }}
      >
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="Profile Image">
              <Upload
                name="profile_img"
                listType="picture-circle"
                className="avatar-uploader"
                showUploadList={false}
                beforeUpload={beforeUpload}
                onChange={handleChange}
              >
                { }
                <CustomUploadButton
                  loading={loading}
                  imageUrl={imageUrl}
                  userImageUrl={userImageUrl}
                  isEditMode={isEditMode}
                  disabled={currentRoute === '/user_profile'}
                />
              </Upload>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="name"
              label={<span>Name</span>}
              rules={[yupSync]}
              required
            >
              <Input
                placeholder="Please Enter Name"
                onChange={handleNameChange}
                disabled={currentRoute === "/user_profile"}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label={<span>Country Code and Mobile </span>} required>
              <Input.Group compact>
                <Form.Item name="country_code" noStyle>
                  <Input
                    disabled
                    style={{
                      width: "20%",
                      textAlign: "center",
                      background: "#f1f1f1",
                    }}
                  />
                </Form.Item>
                <Form.Item name="mobile" noStyle rules={[yupSync]}>
                  <Input
                    style={{ width: "80%" }}
                    placeholder="Enter Mobile Number"
                    onChange={handleMobileChange}
                    disabled={currentRoute === "/user_profile"}
                  />
                </Form.Item>
              </Input.Group>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="work_email"
              label={<span>Work Email</span>}
              rules={[yupSync]}
              required
            >
              <Input
                placeholder="Please Enter Email"
                onChange={handleEmailChangeDebounce}
                disabled={!!id || currentRoute === "/user_profile"}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="gender" label="Gender"
              rules={[yupSync]}
              required
            >
              <Select placeholder="Please select" allowClear showSearch disabled={currentRoute === '/user_profile'}>
                {genders?.map((gender) => (
                  <Option key={gender} value={gender}>
                    {gender}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="date_of_birth"
              label="Date of Birth"
              rules={[yupSync]}
              required
            >
              <DatePicker
                style={{ width: "100%" }}
                format="DD-MM-YYYY"
                placeholder="DD-MM-YYYY"
                disabled={currentRoute === '/user_profile'}
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="employee_code"
              label={<span>Employee Code</span>}
              required
              rules={[yupSync]}
            >
              <Input
                placeholder="Please Enter Employee Code"
                onChange={handleAlphanumericChange("employee_code")}
                disabled={currentRoute === '/user_profile'}
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="code"
              label={<span>Code</span>}
              required
              rules={[yupSync]}
            >
              <Input
                placeholder="Please Enter Code"
                onChange={handleAlphanumericChange("code")}
                disabled={currentRoute === '/user_profile'}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="personal_email"
              label={<span>Personal Email</span>}
              rules={[yupSync]}
              required
            >
              <Input
                placeholder="Please Enter Email"
                onChange={handlePersonalEmailChangeDebounce}
                disabled={!!id || currentRoute === "/user_profile"}
              />
            </Form.Item>
          </Col>
        </Row>
        {!id && !userData && (
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="password"
                label="Password"
                required
                rules={[yupSync]}
              >
                <Input.Password placeholder="Please Enter Password" disabled={currentRoute === '/user_profile'} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="password2"
                label="Confirm Password"
                required
                rules={[
                  { required: true, message: "Confirm Password is required!" },
                  {
                    validator: (_, value) =>
                      value === form.getFieldValue("password")
                        ? Promise.resolve()
                        : Promise.reject("Passwords must match"),
                  },
                ]}
              >
                <Input.Password placeholder="Please Enter Confirm Password" disabled={currentRoute === '/user_profile'} />
              </Form.Item>
            </Col>
          </Row>
        )}

        {currentRoute === "/users" && (
          <Space
            direction="horizontal"
            align="center"
            style={{ display: "flex", flexDirection: "row-reverse" }}
          >
            <Button type="primary" htmlType="submit" loading={loading}>
              Next
            </Button>
            <Button onClick={handleCancel}>Cancel</Button>
          </Space>
        )}
      </Form>)
}
    </div>
  );
};

export default PersonalDetailsForm;
