import React from 'react';

const TimeDisplay = ({ timeStamp, createdAt }) => {
  const parseDateTime = (dateStr) => {
    if (!dateStr) return null;
    
    // Handle ISO format (timestamp)
    if (dateStr.includes('T')) {
      return new Date(dateStr);
    }
    
    // Handle "DD-MM-YYYY HH:MM:SS AM/PM" format (created_at)
    const [datePart, timePart] = dateStr.split(' ');
    const [day, month, year] = datePart.split('-');
    const time = timePart.slice(0, -3); // Remove 'AM/PM'
    const period = timePart.slice(-2);
    
    let [hours, minutes] = time.split(':');
    hours = parseInt(hours);
    
    if (period === 'PM' && hours !== 12) hours += 12;
    if (period === 'AM' && hours === 12) hours = 0;
    
    return new Date(year, month - 1, day, hours, minutes);
  };

  const getRelativeTime = (timestamp) => {
    const now = new Date();
    const messageDate = parseDateTime(timestamp);
    if (!messageDate) return '';
    
    const diffInMilliseconds = now - messageDate;
    const minutes = Math.floor(diffInMilliseconds / (1000 * 60));
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (days > 0) return `${days}d`;
    if (hours > 0) return messageDate.toLocaleTimeString([], {
      hour: '2-digit',
      minute: '2-digit'
    });
    if (minutes > 0) return `${minutes}m`;
    return 'Just now';
  };

  return (
    <div className="text-xs text-gray-500">
      {getRelativeTime(timeStamp || createdAt)}
    </div>
  );
};

export default TimeDisplay;