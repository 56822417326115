import React, { useEffect, useState } from 'react'
import { listAgencyServiceInNach } from '../services/nachDetailService';
import { Button, Col, Form, Select, Space, message } from "antd";
import ErrorMessage from '../../../../utils/errorHandling/ErrorMessage';
import * as Yup from "yup";

const UploadDocumentForm = ({closeForm}) => {

    const validationSchema = Yup.object().shape({

        vendor: Yup.string()
          .required("Vendor is required")
          .min(1, "Vendor is required"),
      });
      
      const yupSync = {
        async validator({ field }, value) {
          await validationSchema.validateSyncAt(field, { [field]: value });
        },
      };
    const [form] = Form.useForm();
    const [allVendor,setAllVendor] =useState([]);

    useEffect(() => {
        const getAllState = async () => {
          try {
            const response = await listAgencyServiceInNach();
    
            setAllVendor(response.data);
          } catch (error) {
            message.error(
              ErrorMessage(error?.response?.status, error?.response?.data?.message)
            );
          }
        };
    
        getAllState();
      }, [form]);
  return (
    <Form
    form={form}
    // onFinish={handleSubmit}
    layout="vertical"
    initialValues={{
    }}
  >
        <Col span={12}>
          <Form.Item name="vendor" label="Vendor" rules={[yupSync]} required>
            <Select
              placeholder="Please select"
              required={true}
              allowClear
              style={{ width: "100%" }}
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {allVendor?.map((vendor) => {
                return (
                  <Select.Option key={vendor.id} value={vendor.id}>
                    {vendor.display_name}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>

    <Space
      direction="horizontal"
      align="center"
      style={{ display: "flex", flexDirection: "row-reverse" }}
    > 
      <Button type="primary" htmlType="submit" >
        Upload
      </Button>
      <Button onClick={closeForm}>Cancel</Button>
    </Space>
  </Form>
  )
}

export default UploadDocumentForm