import React from 'react';
import { Form, Input, Button, Space, Row, Col, message } from 'antd';
import { useParams } from 'react-router-dom';
import { decrypt } from '../../../../../../utils/cryptoUtils/cryptoUtils';
import ErrorMessage from '../../../../../../utils/errorHandling/ErrorMessage';
import { submitRejection } from '../services/rejectFormService';

const RejectForm = ({ closeForm, open, setSubmitStageBar }) => {
  const [form] = Form.useForm();
  const { encrypted_loan_id } = useParams();

  let loan_id = null;
  try {
    loan_id = decrypt(encrypted_loan_id);
  } catch (error) {
    message.error("Invalid Loan ID");
  }
  const handleSubmit = async (values) => {
    const payload = {
      ...values,
      loan_detail: loan_id,
      stage: "MSRT0RJ1",
      stage_status: "COMPLETE",
    };

    try {
      const response = await submitRejection(payload);
      if ((response.status = 201 && response.success)) {
        message.success("Loan rejected successfully");
        setSubmitStageBar();
        closeForm();
      }
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={handleSubmit}
    >
      <Row gutter={16}>
        <Col span={14}>
          <Form.Item
            label="Reason For Rejection"
            name="reason"
            rules={[{ required: true, message: 'Please provide a reason for rejection' }]}
          >
            <Input.TextArea rows={4} placeholder="Enter reason for rejection" />
          </Form.Item>
        </Col>
      </Row>
      <Space
        direction="horizontal"
        align="center"
        className='initiate_button'
      >
        <Button type="primary" htmlType="submit">
          Submit
        </Button>

        <Button onClick={closeForm}>Cancel</Button>
      </Space>
    </Form>
  );
};

export default RejectForm;
