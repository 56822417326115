import React, { useEffect, useState } from "react";
import {
  createDownloadFileService,
  getNachStatusChoice,
  listAgencyServiceInNach,
} from "../services/nachDetailService";
import { Button, Col, Form, Row, Select, Space, message } from "antd";
import ErrorMessage from "../../../../utils/errorHandling/ErrorMessage";
import * as Yup from "yup";

const VENDOR_CODES = {
  "Kotak Bank": "KOTAKBAN",
  "Worldline": "NACH7842",
  "Bank Of Baroda": "BANKOFBA",
  "Bill Desk": "BILLDESK",
  "Yes Bank": "YESBANK4",
};

const DownloadDocumentForm = ({
  closeForm,
  selectedData = [],
  refreshData,
}) => {
  const validationSchema = Yup.object().shape({
    vendor: Yup.string()
      .required("Vendor is required")
      .min(1, "Vendor is required"),
    status: Yup.string().required("Status is required"),
  });

  const yupSync = {
    async validator({ field }, value) {
      await validationSchema.validateSyncAt(field, { [field]: value });
    },
  };
  const [form] = Form.useForm();
  const [allVendor, setAllVendor] = useState([]);
  const [vendorCode, setVendorCode] = useState("");
  const [nachStatusChoice, setNachStatusChoice] = useState([]);

  const { Option } = Select;

  useEffect(() => {
    const fetchAllData = async (getData, setData) => {
      const response = await getData();
      setData(response.data);
    };
    fetchAllData(getNachStatusChoice, setNachStatusChoice);
  }, []);

  useEffect(() => {
    const getAllState = async () => {
      try {
        const response = await listAgencyServiceInNach();
        setAllVendor(response.data);
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };

    getAllState();
  }, [form]);

  useEffect(() => {
    form.resetFields();
    setVendorCode("");
  }, [form, selectedData, closeForm]);

  const handleVendorChange = (value) => {
    const selectedVendor = allVendor.find((vendor) => vendor.id === value);
    if (selectedVendor) {
      const code = VENDOR_CODES[selectedVendor.name] || "";
      setVendorCode(code);
    }
  };

  const handleSubmit = async () => {
    // if (selectedData.length === 0) {
    //   message.warning("Please select at least one record to download");
    //   return;
    // }

    // const filteredData = selectedData.map(
    //   ({
    //     id,
    //     account_number,
    //     holder_name,
    //     ifsc_number,
    //     bank_name,
    //     branch_name,
    //     micr_number,
    //     emi,
    //     status,
    //     mode,
    //   }) => ({
    //     id,
    //     account_number,
    //     holder_name,
    //     ifsc_number,
    //     bank_name,
    //     branch_name,
    //     micr_number,
    //     emi,
    //     status,
    //     mode,
    //   })
    // );

    // if (!vendorCode) {
    //   message.warning("Invalid vendor selection");
    //   return;
    // }

    try {
      const { status } = await form.validateFields();
      const response = await createDownloadFileService(
        // filteredData,
        vendorCode,
        status
      );

      if (response?.status === 200 && response?.success) {
        const fileURL = response?.data?.report;

        if (fileURL) {
          const link = document.createElement("a");
          link.href = fileURL;
          link.download = fileURL.split("/").pop();
          link.click();

          message.success("File successfully downloaded");

          closeForm();
          refreshData();
          form.resetFields();
        } else {
          message.error("File URL is missing in the response");
        }
      } else {
        message.error("Failed to download the file");
      }
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  return (
    <Form
      form={form}
      onFinish={handleSubmit}
      layout="vertical"
      initialValues={{}}
    >
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item name="vendor" label="Vendor" rules={[yupSync]} required>
            <Select
              placeholder="Please select"
              required={true}
              allowClear
              style={{ width: "100%" }}
              showSearch
              onChange={handleVendorChange}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {allVendor?.map((vendor) => {
                return (
                  <Select.Option key={vendor.id} value={vendor.id}>
                    {vendor.display_name}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="status" label="Status" rules={[yupSync]} required>
            <Select
              placeholder="Please Select"
              allowClear
              showSearch
              filterOption={(input, option) =>
                option.children?.toLowerCase().includes(input.toLowerCase())
              }
            >
              {nachStatusChoice?.map((status, index) => (
                <Option key={index} value={status}>
                  {status}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Space
        direction="horizontal"
        align="center"
        style={{ display: "flex", flexDirection: "row-reverse" }}
      >
        <Button type="primary" htmlType="submit">
          Download
        </Button>
        <Button onClick={closeForm}>Cancel</Button>
      </Space>
    </Form>
  );
};

export default DownloadDocumentForm;
