import React, { useState, useEffect } from "react";
import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Space,
  Checkbox,
  message,
  Select,
} from "antd";
import {
  getAddressDataByIdService,
  updateAddressByIdService,
  getAllStateService
} from "../services/propertyDetailsServices";
import * as Yup from "yup";
import { pincodeCodeService } from "../../technicalValuationReport/services/pincodeCodeService";
import { getallpincode } from "../../technicalValuationReport/services/TechnicalValuationReportservices";
import ErrorMessage from "../../../../../utils/errorHandling/ErrorMessage";

const validationSchema = Yup.object().shape({
  address1: Yup.string()
    .required("Address Line 1 is required")
    .matches(
      /^[a-zA-Z0-9/,.\s#()-]*$/,
      "Address Line 1 can only contain letters, numbers, and special characters: / , . # ( )"
    ),
  address2: Yup.string()
    .required("Address Line 2 is required")
    .matches(
      /^[a-zA-Z0-9/,.\s#()-]*$/,
      "Address Line 2 can only contain letters, numbers, and special characters: / , . # ( )"
    ),
  survey_number: Yup.string()
    .required("Survey No. is required")
    .matches(
      /^[a-zA-Z0-9/,.\s#()-]*$/,
      "Survey No can only contain letters, numbers, and special characters: / , . # ( )"
    ),
  plot_number: Yup.string().required("Plot No. is required"),
  state: Yup.string().required("State is required"),
  house_number: Yup.string().required("House No./Flat No. is required"),
  city: Yup.string().required("City is required"),
  pincode: Yup.string()
    .required("Pincode is required")
    .matches(/^[0-9]+$/, "Pincode should contain numbers only")
    .label("Pincode"),
  taluka: Yup.string()
    .required("Taluka is required")
    .matches(/^[a-zA-Z\s]*$/, "Taluka can only contain letters and spaces"),
  district: Yup.string()
    .required("District is required")
    .matches(/^[a-zA-Z\s]*$/, "Distric can only contain letters and spaces"),
  country: Yup.string().required("Country is required"),
  site_address1: Yup.string()
    .required("Address Line 1 is required")
    .matches(
      /^[a-zA-Z0-9/,.\s#()-]*$/,
      "Address Line 1 can only contain letters, numbers, and special characters: / , . # ( )"
    ),
  site_address2: Yup.string()
    .required("Address Line 2 is required")
    .matches(
      /^[a-zA-Z0-9/,.\s#()-]*$/,
      "Address Line 2 can only contain letters, numbers, and special characters: / , . # ( )"
    ),
  site_survey_number: Yup.string().required("Survey No. is required"),
  site_plot_number: Yup.string().required("Plot No. is required"),
  site_state: Yup.string().required("State is required"),
  site_house_number: Yup.string().required("House No./Flat No. is required"),
  site_city: Yup.string().required("City is required"),
  site_pincode: Yup.string()
    .required("Pincode is required")
    .matches(/^[0-9]+$/, "Pincode should contain numbers only")
    .label("Pincode"),
  site_taluka: Yup.string()
    .required("Taluka is required")
    .matches(/^[a-zA-Z\s]*$/, "Taluka can only contain letters and spaces"),
  site_district: Yup.string()
    .required("District is required")
    .matches(/^[a-zA-Z\s]*$/, "Distric can only contain letters and spaces"),
  site_country: Yup.string()
    .required("Country is required"),
  address_matching: Yup.string()
    .required("Address Matching is required"),
  latitude: Yup.string()
    .matches(/^-?\d*(\.\d+)?$/, "Latitude must be a number")
    .required("Latitude is required"), // To provide a custom error message if the value is not a number
  longitude: Yup.string()
    .matches(/^-?\d*(\.\d+)?$/, "Longitude must be a number")
    .required("Longitude is required"), // To provide a custom error message if the value is not a number
});

const yupSync = {
  async validator({ field }, value) {
    await validationSchema.validateSyncAt(field, { [field]: value });
  },
};

const { Option } = Select;

const AddressPropertyDetails = ({ refreshData, id, open, closeForm, stage_id }) => {
  const [form] = Form.useForm();
  const [technicalid, setTechnicalid] = useState("");
  const [data, setData] = useState([]);
  const [pincode, setPincode] = useState([]);
  const [sameAsDocument, setSameAsDocument] = useState(false);
  const [states, setStates] = useState();

  const getPersonalData = async () => {
    try {
      const response = await getAddressDataByIdService(stage_id);
      setData(response.data.technical_address_details);
      setTechnicalid(
        response.data.technical_address_details.document_site_address[0]
          .technical_detail
      );
      const { document_site_address, actual_site_address, technical_details } =
        response.data.technical_address_details;

      const documentSite = document_site_address[0];
      const addressSite = actual_site_address[0]; 

      const documentAddressDetails = {
        address1: documentSite?.address1,
        address2: documentSite?.address2,
        survey_number: documentSite?.survey_number,
        plot_number: documentSite?.plot_number,
        state: documentSite?.state,
        house_number: documentSite?.house_number,
        city: documentSite?.city,
        pincode: documentSite?.pincode,
        taluka: documentSite?.taluka,
        district: documentSite?.district,
        country: documentSite?.country
      };

      const actualAddressDetails = {
        address1: addressSite?.address1,
        address2: addressSite?.address2,
        survey_number: addressSite?.survey_number,
        plot_number: addressSite?.plot_number,
        state: addressSite?.state,
        house_number: addressSite?.house_number,
        city: addressSite?.city,
        pincode: addressSite?.pincode,
        taluka: addressSite?.taluka,
        district: addressSite?.district,
        country: addressSite?.country
      };

      let allMatch = true;  
      for (let key in documentAddressDetails) {
        if (documentAddressDetails[key] !== actualAddressDetails[key]) {
          allMatch = false; 
          break;  
        }
      } 
      
      setSameAsDocument(allMatch);

      form.setFieldsValue({
        address1: document_site_address[0]?.address1,
        address2: document_site_address[0]?.address2,
        survey_number: document_site_address[0]?.survey_number,
        plot_number: document_site_address[0]?.plot_number,
        state: document_site_address[0]?.state,
        house_number: document_site_address[0]?.house_number,
        city: document_site_address[0]?.city,
        pincode: document_site_address[0]?.pincode,
        taluka: document_site_address[0]?.taluka,
        district: document_site_address[0]?.district,
        country: document_site_address[0]?.country,
        site_address1: actual_site_address[0]?.address1,
        site_address2: actual_site_address[0]?.address2,
        site_survey_number: actual_site_address[0]?.survey_number,
        site_plot_number: actual_site_address[0]?.plot_number,
        site_state: actual_site_address[0]?.state,
        site_house_number: actual_site_address[0]?.house_number,
        site_city: actual_site_address[0]?.city,
        site_pincode: actual_site_address[0]?.pincode,
        site_taluka: actual_site_address[0]?.taluka,
        site_district: actual_site_address[0]?.district,
        site_country: actual_site_address[0]?.country,
        address_matching: technical_details[0]?.address_matching,
        latitude: technical_details[0]?.latitude,
        longitude: technical_details[0]?.longitude,
      });
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  const handlePincodeDocumentChange = async (value) => {
    const selectedPincode = pincode.find((pin) => pin.name === value);

    const pincodeName = selectedPincode ? selectedPincode.name : "";
    try {
      const response = await pincodeCodeService(pincodeName);
      if (response && response.data) {
        const { city, district, state, taluka, country } = response.data;
        form.setFieldsValue({
          city: city?.name || undefined,
          district: district?.name || undefined,
          state: state?.name || undefined,
          taluka: taluka?.name || undefined,
          country: country?.name || undefined,
          site_city: city?.name || undefined,
          site_district: district?.name || undefined,
          site_state: state?.name || undefined,
          site_taluka: taluka?.name || undefined,
          site_country: country?.name || undefined,
        });
      }
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  
  const handlePincodeDocumentChangeSecondary = async (value) => {
    const selectedPincode = pincode.find((pin) => pin.name === value);

    const pincodeName = selectedPincode ? selectedPincode.name : "";
    try {
      const response = await pincodeCodeService(pincodeName);
      if (response && response.data) {
        const { city, district, state, taluka, country } = response.data;
        form.setFieldsValue({
          site_city: city?.name || undefined,
          site_district: district?.name || undefined,
          site_state: state?.name || undefined,
          site_taluka: taluka?.name || undefined,
          site_country: country?.name || undefined,
        });
      }
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  const handleInputFields = (e, field) => {
    const newValue = e.target.value;
    form.setFieldsValue({ [field]: newValue });
  };

  const getPincodeList = async () => {
    try {
      const response = await getallpincode();
      setPincode(response.data);
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  const getAllState = async () => {
    try {
      const response = await getAllStateService();
      setStates(response.data); 
    } catch (error) {
      closeForm();
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  useEffect(() => {
    getPincodeList();
    getAllState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleInput = (e, field) => {
    const newValue = e.target.value.replace(/[^A-Za-z\s]/g, "");
    form.setFieldsValue({ [field]: newValue });
  };

  useEffect(() => {
    if (open) {
      getPersonalData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, form, id, stage_id]);

  useEffect(() => {
    if (sameAsDocument) {
      const values = form.getFieldsValue();
      form.setFieldsValue({
        site_address1: values.address1,
        site_address2: values.address2,
        site_survey_number: values.survey_number,
        site_plot_number: values.plot_number,
        site_state: values.state,
        site_house_number: values.house_number,
        site_city: values.city,
        site_pincode: values.pincode,
        site_taluka: values.taluka,
        site_district: values.district,
        site_country: values.country,
      });
    }
  }, [sameAsDocument, form]);

  const handleSubmit = async (values) => {
    const payload = {
      document_address: [
        {
          id: data.document_site_address[0].id,
          address1: values.address1,
          address2: values.address2,
          survey_number: values.survey_number,
          plot_number: values.plot_number,
          state: values.state,
          house_number: values.house_number,
          city: values.city,
          pincode: values.pincode,
          taluka: values.taluka,
          district: values.district,
          country: values.country,
        },
      ],
      site_address: [
        {
          id: data.actual_site_address[0].id,
          address1: sameAsDocument ? values.address1 : values.site_address1,
          address2: sameAsDocument ? values.address2 : values.site_address2,
          survey_number: sameAsDocument
            ? values.survey_number
            : values.site_survey_number,
          plot_number: sameAsDocument
            ? values.plot_number
            : values.site_plot_number,
          state: sameAsDocument ? values.state : values.site_state,
          house_number: sameAsDocument
            ? values.house_number
            : values.site_house_number,
          city: sameAsDocument ? values.city : values.site_city,
          pincode: sameAsDocument ? values.pincode : values.site_pincode,
          taluka: sameAsDocument ? values.taluka : values.site_taluka,
          district: sameAsDocument ? values.district : values.site_district,
          country: sameAsDocument ? values.country : values.site_country,
        },
      ],
      address_matching: values.address_matching,
      latitude: values.latitude,
      longitude: values.longitude,
    };
    try {
      const response = await updateAddressByIdService(technicalid, payload);
      if (response && response.data) {
        if (response.status === 200 && response.success) {
          message.success(
            "Address of Property being Appraised updated successfully"
          );
          refreshData();
          closeForm();
        }
      } else {
        message.error("Failed to update Address of Property being Appraised");
      }
    } catch (error) {
      closeForm();
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

    // Filter `option.label` match the user type `input`
    const filterOption = (input, option) =>
      (option?.children ?? "").toLowerCase().indexOf(input.toLowerCase()) >= 0;

  return (
    <Form
      form={form}
      onFinish={handleSubmit}
      layout="vertical"
      initialValues={{}}
    >
      <div

        className="address_property_technical"
      >
        <div className="remarks_addres_technical technical_report_boundries_box">
          Address as per Document: <span className="address_text_tech">*</span>
        </div>
        <div

          className="address_property_technical_text"
        >
          * Any textbox should contain only A-Z 0-9 / , - . # ( )
        </div>
      </div>

      <p

        className="plot_text_technical"
      >
        Plot No. and House No./Flat No. is used for cersai.
      </p>
      <Row gutter={16} className="adddres_technical_form_row">
        <Col span={8}>
          <Form.Item name="address1" label="Address Line 1" rules={[yupSync]} required>
            <Input
              placeholder="Please Enter Address Line 1"
              onChange={(e) => handleInputFields(e, "address1")}
            />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item name="address2" label="Address Line 2" rules={[yupSync]} required>
            <Input
              placeholder="Please Enter Address Line 2"
              onChange={(e) => handleInputFields(e, "address2")}
            />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item name="survey_number" label="Survey No." rules={[yupSync]} required>
            <Input
              placeholder="Please Enter Survey No."
              onChange={(e) => handleInputFields(e, "survey_number")}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16} className="adddres_technical_form_row">
        <Col span={8}>
          <Form.Item name="plot_number" label="Plot No" rules={[yupSync]} required>
            <Input
              placeholder="Please Enter Plot No"
              onChange={(e) => handleInputFields(e, "plot_number")}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name="house_number"
            label="House No./Flat No."
            rules={[yupSync]}
            required
          >
            <Input
              placeholder="Please Enter House No./Flat No."
              onChange={(e) => handleInputFields(e, "house_number")}
            />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            name="pincode"
            label="Pincode"
            rules={[yupSync]}
            required
          >
            <Select
              mode="single"
              placeholder="Please Select Pincode"
              required={true}
              className="width_select_pincode"
              allowClear
              showSearch
              filterOption={(input, option) =>
                option.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              onChange={handlePincodeDocumentChange}
            >
              {pincode?.map((pincode) => {
                return (
                  <Select.Option
                    key={pincode.id}
                    value={pincode.name}
                  >
                    {pincode.name}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>



      </Row>

      <Row gutter={16} className="adddres_technical_form_row">

        <Col span={8}>
          <Form.Item name="city" label="City" rules={[yupSync]} required>
            <Input
              placeholder="Please Enter City"
              onChange={(e) => handleInput(e, "city")}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="taluka" label="Taluka" rules={[yupSync]}  required>
            <Input
              placeholder="Please Enter Taluka"
              onChange={(e) => handleInput(e, "taluka")}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="district" label="District" rules={[yupSync]} required>
            <Input
              placeholder="Please Enter District"
              onChange={(e) => handleInput(e, "district")}
            />
          </Form.Item>
        </Col>

      </Row>

      <Row gutter={16} className="adddres_technical_form_row">
        <Col span={8}>
          <Form.Item name="state" label="State" rules={[yupSync]} required> 
            <Select
              placeholder="Please Select"
              showSearch
              optionFilterProp="children"
              filterOption={filterOption}
              // disabled
            >
              {states?.map((states, index) => (
                <Option key={states.id} value={states.id}>
                  {states.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>


        <Col span={8}>
          <Form.Item name="country" label="Country" rules={[yupSync]} required>
            <Input
              placeholder="Please Enter Country"
              onChange={(e) => handleInput(e, "country")}

            />
          </Form.Item>
        </Col>


      </Row>

      <div className="address">Address as per document on docket :</div>

      <div

        className="addres_technical_docket_text"
      >
        <div className="address_tech_actual_site" >
          Address As per Actual at site: <span className="address_text_tech">*</span>
          <Checkbox
            className="checkbox_address_technical"
            checked={sameAsDocument}
            onChange={(e) => setSameAsDocument(e.target.checked)}
          >
            Same as per document
          </Checkbox>
        </div>
        <div
          className="address_property_technical_text"
        >
          * Any textbox should contain only A-Z 0-9 / , - . # ( )
        </div>
      </div>

      <p
        className="plot_text_technical"
      >
        Plot No. and House No./Flat No. is used for cersai.
      </p>

      <Row gutter={16} className="adddres_technical_form_row">
        <Col span={8}>
          <Form.Item
            name="site_address1"
            label="Address Line 1"
            rules={[yupSync]}
            required
          >
            <Input
              placeholder="Please Enter Address Line 1"
              onChange={(e) => handleInputFields(e, "site_address1")}
              disabled={sameAsDocument}
            />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            name="site_address2"
            label="Address Line 2"
            rules={[yupSync]}
            required
          >
            <Input
              placeholder="Please Enter Address Line 2"
              onChange={(e) => handleInputFields(e, "site_address2")}
              disabled={sameAsDocument}
            />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            name="site_survey_number"
            label="Survey No."
            rules={[yupSync]}
            required
          >
            <Input
              placeholder="Please Enter Survey No."
              onChange={(e) => handleInputFields(e, "site_survey_number")}
              disabled={sameAsDocument}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16} className="adddres_technical_form_row">
        <Col span={8}>
          <Form.Item name="site_plot_number" label="Plot No" rules={[yupSync]}   required>
            <Input
              placeholder="Please Enter Plot No"
              onChange={(e) => handleInputFields(e, "site_plot_number")}
              disabled={sameAsDocument}
            />
          </Form.Item>
        </Col>



        <Col span={8}>
          <Form.Item
            name="site_house_number"
            label="House No./Flat No."
            rules={[yupSync]}
            required
          >
            <Input
              placeholder="Please Enter House No./Flat No."
              onChange={(e) => handleInputFields(e, "site_house_number")}
              disabled={sameAsDocument}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="site_pincode" label="Pincode" rules={[yupSync]}  required>
          <Select
              mode="single"
              placeholder="Please Select Pincode"
              required={true}
              className="width_select_pincode"
              allowClear
              showSearch
              filterOption={(input, option) =>
                option.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              onChange={handlePincodeDocumentChangeSecondary}
              disabled={sameAsDocument}
            >
              {pincode?.map((pincode) => {
                return (
                  <Select.Option
                    key={pincode.id}
                    value={pincode.name}
                  >
                    {pincode.name}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16} className="adddres_technical_form_row">

        <Col span={8}>
          <Form.Item name="site_city" label="City" rules={[yupSync]}  required>
            <Input
              placeholder="Please Enter City"
              onChange={(e) => handleInput(e, "site_city")}
              disabled={sameAsDocument}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="site_taluka" label="Taluka" rules={[yupSync]}  required>
            <Input
              placeholder="Please Enter Taluka"
              onChange={(e) => handleInput(e, "site_taluka")}
              disabled={sameAsDocument}
            />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item name="site_district" label="District" rules={[yupSync]}  required>
            <Input
              placeholder="Please Enter District"
              onChange={(e) => handleInput(e, "site_district")}
              disabled={sameAsDocument}
            />
          </Form.Item>
        </Col>






      </Row>

      <Row gutter={16} className="adddres_technical_form_row">

        <Col span={8}>
          <Form.Item name="site_state" label="State" rules={[yupSync]}   required> 
            <Select
              placeholder="Please Select"
              showSearch
              optionFilterProp="children"
              filterOption={filterOption} 
              disabled={sameAsDocument}
            >
              {states?.map((states, index) => (
                <Option key={states.id} value={states.id}>
                  {states.name}
                </Option>
              ))}
            </Select> 
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="site_country" label="Country" rules={[yupSync]}   required>
            <Input
              placeholder="Please Enter Country"
              onChange={(e) => handleInput(e, "site_country")}
              disabled={sameAsDocument}
            />
          </Form.Item>
        </Col>
      </Row>

      <div className="address">Actual address on docket</div>

      <div
        className="addres_technical_docket_text"
      >
        <div className="remarks_addres_technical" >
          Remarks: <span className="address_text_tech">*</span>
        </div>
      </div>

      <Row gutter={16} className="documents_provided_technical_table">
        <Col span={8}>
          <Form.Item
            name="address_matching"
            label="Address Matching"
            rules={[yupSync]}
            required
            allowClear
          >
            <Select placeholder="Search To Select" className="selct_field_observation" allowClear>
              <Option value={true}>Yes</Option>
              <Option value={false}>No</Option>
            </Select>
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            name="latitude"
            label="Latitude"
            rules={[yupSync]}
            required
          >
            <Input placeholder="Please Enter Latitude" />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            name="longitude"
            label="Longitude"
            rules={[yupSync]}
            required
          >
            <Input placeholder="Please Enter Longitude" />
          </Form.Item>
        </Col>
      </Row>

      <Space
        direction="horizontal"
        align="center"
        className="address_property_save_cancle"

      >
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
        <Button onClick={closeForm}>Cancel</Button>
      </Space>
    </Form>
  );
};

export default AddressPropertyDetails;
