import { axiosRequest } from "../../../../utils/api/axiosRequest";

export const getListOfLoan = (text) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/loan/loan-detail/list-loan?search=${text}`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}


export const listAgencyServiceInNach = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/master/agency/agency-list-by-agency-type`, {
        params: {
          code: 'NACH2986', 
        },
      });
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const getLoanNumberDetail = (loan_number) => {
  return new Promise(async (resolve, reject) => {
      try {
          const response = await axiosRequest.get(`/api/v1/loan/loan-detail/get-loan-account-detail?loan_account_number=${loan_number}`);
          return resolve(response.data)
      } catch (error) {
          return reject(error)
      }
  })
}


export const getNachByLoanIdService = (loan_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/customer/nach/get-data-by-loan-id/${loan_id}`);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};


export const updateNachDetailsFormByIdService = (loan_id, data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.patch(`/api/v1/customer/customer-detail/${loan_id}`, data);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}


export const updateNachAddDetailsFormByIdService = (loan_id, data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.patch(`/api/v1/customer/nach/?bank_id=${loan_id}`, data);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const getCustomersByLoanId = (loanId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(
        `/api/v1/customer/customer-detail/personal-detail-list/${loanId}`
      );
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};


export const getBankAccountDetailsByIdService = (loan_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/customer/bank-account-detail/bank-account-detail/${loan_id}`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const getBankAccountFilterDetailsByIdService = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/customer/nach/${id}`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const createNachAccountService = (data,bank_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.post(`/api/v1/customer/nach/?bank_id=${bank_id}`,data);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const getBankAccountByIdService = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/customer/bank-account-detail/${id}`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const getNachStatusChoice= () => {
  return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.get(`/api/v1/customer/choices/get-nach-status`);
        return resolve(response.data);
      } catch (error) {
        return reject(error);
      }
    });
}

export const listAgencyTypeService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/master/agency-type/list`);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const listModetypeService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/customer/choices/get-nach-mode`);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const getFilterByStatus= (status) => {
  return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.get(`/api/v1/customer/nach/nach-filter-by-status/?status=${status}`);
        return resolve(response.data);
      } catch (error) {
        return reject(error);
      }
    });
}


export const updateNachAccountService = (id, data) => {
  return new Promise(async (resolve, reject) => {
      try {
          const response = await axiosRequest.patch(`/api/v1/customer/nach/${id}`, data);
          return resolve(response.data)
      } catch (error) {
          return reject(error)
      }
  })
}

export const createDownloadFileService = (vendor_code,status) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.post(`/api/v1/customer/nach/export-to-excel/?vendor=${vendor_code}&status=${status}`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}