import { Avatar, Typography } from "antd";
import React from "react";
import RViewerJS from "viewerjs-react";
import { processMessages } from "../helperFunctions/processMessage";
import UserMentionProcessor from "./UserMentionProcessor";
import { fileTypeExt } from "../helperFunctions/fileTypeExtension";
import FileHandler from "./FIleHandler";
import MessageBubble from "./MessageBubble";
const { Text } = Typography;
const ChatScreen = ({
  messages,
  user_data,
  userNameList,
  isAtBottom,
  newMessagesCount,
  handleUnreadClick,
}) => {
  const processedMessages = processMessages(messages, userNameList);
  return (
    <>
      {processedMessages?.map((msg, index) => {
        const isCurrentUser = msg.created_by?.name === user_data?.name;
        const getUserImage = userNameList?.find(
          (item) => item?.id === msg?.from_user
        );

        if (!msg.content && !msg.media_file) {
          return null;
        }

        const isActionMessage = msg.message_type === "Action";

        return (
          <div
            key={index}
            style={{
              display: "flex",
              justifyContent: isActionMessage
                ? "center"
                : isCurrentUser
                ? "flex-end"
                : "flex-start",
              marginBottom: "16px",
            }}
          >
            {!isCurrentUser && !isActionMessage && (
              <Avatar
                src={getUserImage?.profile_img}
                style={{ marginTop: 8, marginRight: 8 }}
                size="large"
              />
            )}
         

            {!isActionMessage && isCurrentUser && (
              <Text
                // type="secondary"
                style={{
                  display: "block",
                  fontSize: "12px",
                  marginTop: "auto",
                  marginBottom:'10px',
                }}
              >
                {new Date(msg.time_stamp).toLocaleTimeString([], {
                  hour: "2-digit",
                  minute: "2-digit",
                })}
              </Text>
            )}
            <MessageBubble
              isActionMessage={isActionMessage}
              isCurrentUser={isCurrentUser}
              msg={msg}
            >
              <div>
                {msg.media_file &&
                  [
                    "image/png",
                    "image/png",
                    "image/jpeg",
                    "image/jpeg",
                    "image/jpg",
                    "image/jpg",
                  ].includes(msg.file_type) && (
                    <RViewerJS key={index}>
                      <img
                        src={msg.media_file}
                        alt="Uploaded"
                        style={{
                          display: "block",
                          width: "100%",
                          height: "auto",
                          marginBottom: msg?.content ? "8px" : "4px",
                        }}
                      />
                    </RViewerJS>
                  )}

                <FileHandler
                  fileType={msg.file_type}
                  mediaFile={msg.media_file}
                  index={index}
                />

                {msg.media_file && fileTypeExt[msg.file_type] && (
                  <div key={index} style={{ marginBottom: "8px" }}>
                    {msg.file_type === "application/pdf" && (
                      <iframe
                        src={msg.media_file}
                        title="PDF File"
                        style={{
                          width: "100%",
                          height: "500px",
                          border: "none",
                        }}
                      ></iframe>
                    )}
                    {(msg.file_type === "application/vnd.ms-excel" ||
                      msg.file_type ===
                        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
                      msg.file_type === "text/csv") && (
                      <a
                        href={msg.media_file}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Download {fileTypeExt[msg.file_type]}
                      </a>
                    )}
                    {msg.file_type === "audio/mpeg" && (
                      <audio controls>
                        <source src={msg.media_file} type={msg.file_type} />
                        Your browser does not support the audio element.
                      </audio>
                    )}
                    {msg.file_type === "video/mp4" && (
                      <video controls style={{ width: "100%", height: "auto" }}>
                        <source src={msg.media_file} type={msg.file_type} />
                        Your browser does not support the video element.
                      </video>
                    )}
                    {msg.file_type === "application/octet-stream" && (
                      <p>
                        Unable to preview this file type.{" "}
                        <a href={msg.media_file} download>
                          Download
                        </a>
                      </p>
                    )}
                  </div>
                )}

                {msg?.content && (
                  <div>
                    <UserMentionProcessor
                      content={msg?.content}
                      isCurrentUser={isCurrentUser}
                      isActionMessage={isActionMessage}
                    />
                  </div>
                )}
              </div>
            </MessageBubble>
            {isCurrentUser && !isActionMessage && (
              <Avatar
                src={getUserImage?.profile_img}
                style={{ marginTop: 8, marginRight: 0 }}
                size="large"
              />
            )}
            {!isActionMessage && !isCurrentUser && (
              <Text
                // type="secondary"
                style={{
                  display: "block",
                  fontSize: "12px",
                  marginTop: "auto",
                  marginBottom:'10px',
                  textAlign: isCurrentUser ? "left" : "right",
                }}
              >
                {new Date(msg.time_stamp).toLocaleTimeString([], {
                  hour: "2-digit",
                  minute: "2-digit",
                })}
              </Text>
            )}
          </div>
        );
      })}
    </>
  );
};

export default ChatScreen;
