import React from "react";
import { Space, Typography } from "antd";
import UserPopover from "./UserPopOver";
import { useSelector } from "react-redux";

const { Text } = Typography;

const UserMentionProcessor = ({ content, isCurrentUser, isActionMessage }) => {
  const { user_data } = useSelector((state) => state.user);

  const userId = user_data?.id
  // Match @username@ pattern where username can contain spaces
  const processContent = () => {
    const parts = [];
    let currentIndex = 0;
    const regex = /@([^@]+)@/g;
    let match;

    while ((match = regex.exec(content)) !== null) {
      // Add text before the match
      if (match.index > currentIndex) {
        parts.push({
          type: "text",
          content: content.slice(currentIndex, match.index),
        });
      }

      // Add the username
      parts.push({
        type: "mention",
        username: match[1].trim(),
        original: match[0],
      });

      currentIndex = match.index + match[0].length;
    }

    // Add remaining text after last match
    if (currentIndex < content.length) {
      parts.push({
        type: "text",
        content: content.slice(currentIndex),
      });
    }

    return parts;
  };

  return (
    <Space direction="horizontal" wrap size={0} style={{ marginBottom: "5px",display:'ruby' }}>
      {processContent().map((part, index) => {
        const checkedUserExist = part?.username?.includes(userId)
        if (part.type === "mention") {
          return checkedUserExist && isActionMessage ? (
            <Text
              key={`mention-${index}`}
              style={{
                color: isActionMessage ? "#5E5E5E" : !isCurrentUser ? "white" : "black",
                fontSize: isActionMessage ? "12px" : "15px",
                marginLeft: "3px",
                fontWeight: "bold"
              }}
            >
              you
            </Text>
          ) : (
            <UserPopover
              key={`mention-${index}`}
              username={part.username}
              part={part.original}
              isCurrentUser={isCurrentUser}
              isActionMessage={isActionMessage}
            />
          );
        }
        return (
          <Text
            key={`text-${index}`}
            style={{
              color: isActionMessage
                ? "#5E5E5E"
                : !isCurrentUser
                ? "white"
                : "black",
              fontSize: isActionMessage ? "12px" : "15px",
              marginLeft: "3px",
              overflow: "auto",
              width: "fit-content",
              whiteSpace: "normal", // Allow text to break
              wordWrap: "break-word", // Ensure long words break properly
              wordBreak: "break-word", // Handle breaking for long unbroken strings
            }}
          >
            {part.content}
          </Text>
        );
      })}
    </Space>
  );
};

export default UserMentionProcessor;
