/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef, useCallback } from "react";
import { Avatar, Card, Empty, Tooltip, Typography } from "antd";
import { useNavigate } from "react-router-dom";
import { navigateWithEncryptedId } from "../../../utils/cryptoUtils/cryptoUtils";
import { useDispatch } from "react-redux";
import { clearNotification } from "../../../redux/notification";
import { getLoanAllNotification } from "../services/notificationServices";
import TimeDisplay from "../../layout/views/TimeDisplay";

const { Title } = Typography;

const Notification = () => {
  const [newNotificationData, setNewNotificationData] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [notificationCount, setNotificationCount] = useState(null);
  const observer = useRef();
  const dispatch = useDispatch();
  const notificationContainerRef = useRef(null);

  useEffect(() => {
    dispatch(clearNotification());
  }, []);

  const lastNotificationRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPage((prevPage) => prevPage + 1);
        }
      });

      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  const fetchNotificationData = async (pageNumber) => {
    try {
      setLoading(true);
      const response = await getLoanAllNotification(pageNumber);
      setNotificationCount(response.count);
      setNewNotificationData((prev) => {
        if (pageNumber === 1) return response?.data;
        return [...prev, ...response?.data];
      });

      // Check if we have more data to load
      setHasMore(response?.data?.length > 0);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching notifications:", error);
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchNotificationData(page);
  }, [page]);

  // Rest of your existing helper functions (getInitials, groupNotifications, etc.)

  const updatedNotifications = newNotificationData?.map((not) => {
    const userIds = Array.from(
      new Set(
        (not.notification_content?.match(/@\d+/g) || [])?.map((match) =>
          match.replace("@", "")
        )
      )
    );
    let updatedContent = not.notification_content;
    userIds.forEach((id) => {
      const taggedUser = not.tagged_users?.find(
        (user) => user.tagged_user_id === parseInt(id)
      );
      if (taggedUser) {
        const regex = new RegExp(`@${id}`, "g");
        updatedContent = updatedContent.replace(
          regex,
          `@${taggedUser.tagged_user_name}`
        );
      }
    });

    return {
      ...not,
      content: updatedContent,
    };
  });

  const groupNotifications = (notifications) => {
    const twoHours = 2 * 60 * 60 * 1000;
    const now = new Date();
  
    return notifications?.reduce(
      (acc, notification) => {
        const [day, month, year, time, period] = notification.created_at.split(/[-\s:]+/);
        const hours = period === "PM" && parseInt(time, 10) !== 12 ? parseInt(time, 10) + 12 : parseInt(time, 10) % 12;
        const formattedDate = new Date(`${year}-${month}-${day}T${hours.toString().padStart(2, '0')}:${notification.created_at.split(":")[1]}:00`);
        
        if (formattedDate instanceof Date && !isNaN(formattedDate)) {
          const isRecent = now - formattedDate <= twoHours;
          if (isRecent) {
            acc.recent.push(notification);
          } else {
            acc.earlier.push(notification);
          }
        }
        
        return acc;
      },
      { recent: [], earlier: [] }
    );
  };
  

  const NotificationCard = ({ notification, ref }) => {
    const navigate = useNavigate();
  const chatMessages = notification?.message
    ? notification?.message
    : notification;

  const application_number_new = chatMessages?.loan_detail?.application_number
    ? chatMessages?.loan_detail?.application_number
    : chatMessages?.loan_detail_id?.application_number;
  const loan_account_number_new = chatMessages?.loan_detail?.loan_account_number
    ? chatMessages?.loan_detail?.loan_account_number
    : chatMessages?.loan_detail_id?.loan_account_number;
  const loanId = chatMessages?.loan_detail?.id
    ? chatMessages?.loan_detail?.id
    : chatMessages?.loan_detail_id?.loan_detail_id;
  const getInitials = (name) => {
    return name
      .split(" ")
      .map((n) => n[0])
      .join("")
      .toUpperCase();
  };

  const updatedMessageContent = notification.content ? notification.content : chatMessages.content || "";
  
    return (
      <Card bodyStyle={{ padding: 16 }} ref={ref}>
      <div style={{ display: "flex", flexDirection: "column", gap: "8px"}}>
        {/* First Row: Avatar, Title, and TimeDisplay */}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
            <Avatar style={{ backgroundColor: "#1890ff" }}>
              {chatMessages.tagged_users?.[0]?.tagged_user_name
                ? getInitials(chatMessages.tagged_users[0].tagged_user_name)
                : "U"}
            </Avatar>

            {chatMessages?.message_type === "Action" ? (
              <Typography.Text strong style={{ textTransform: "uppercase" }}>
                {notification.content.split(" ").slice(0, 2).join(" ")}
              </Typography.Text>
            ) : (
              <div>
                <Typography.Text strong style={{ textTransform: "uppercase" }}>
                  {updatedMessageContent.includes("Forward")
                    ? " Stage Forward"
                    : "initiate"}
                </Typography.Text>
              </div>
            )}
          </div>

          <Typography.Text type="secondary" style={{ fontSize: 10 }}>
            <TimeDisplay
              timeStamp={chatMessages?.time_stamp}
              createdAt={chatMessages?.created_at}
            />
          </Typography.Text>
        </div>

        {/* Message Content with Hover on Specific Parts */}
        <div style={{display:"contents"}}>
          <Typography.Text style={{ marginLeft: "35px" }}>
            {updatedMessageContent.split(" ").map((word, index) => {
              if (word === `#${application_number_new}`) {
                return (
                  <Tooltip
                    key={`app-${index}`}
                    title={`Application Number: ${application_number_new}`}
                  >
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        const targetPath = "/sales";

                        const newData = { name: "sales", key: "salesLeads1" };
                        const passdata = newData;

                        navigateWithEncryptedId(
                          navigate,
                          targetPath,
                          loanId.toString(),
                          passdata
                        );
                      }}
                    >
                      {" "}
                      {word}
                    </span>
                  </Tooltip>
                );
              }

              if (word === `#${loan_account_number_new}`) {
                return (
                  <Tooltip
                    key={`loan-${index}`}
                    title={`Loan Account Number: ${loan_account_number_new}`}
                  >
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        const targetPath = "/loandetails";

                        const data = {
                          name: "loan",
                          key: "creditApplications1",
                        };
                        const newData = { name: "sales", key: "salesLeads1" };
                        const passdata =
                          targetPath === "/loandetails" ? data : newData;

                        navigateWithEncryptedId(
                          navigate,
                          targetPath,
                          loanId.toString(),
                          passdata
                        );
                      }}
                    >
                      {" "}
                      {word}
                    </span>
                  </Tooltip>
                );
              }

              return <span key={index}> {word} </span>;
            })}
          </Typography.Text>
        </div>
      </div>
    </Card>
    );
  };

  const { recent, earlier } = groupNotifications(updatedNotifications);

  const handleScroll = (e) => {
    const scrollTop = e.target.scrollTop;
    const scrollHeight = e.target.scrollHeight;
    const clientHeight = e.target.clientHeight;

    if (scrollTop + clientHeight >= scrollHeight && notificationCount !== newNotificationData.length ) {
      setPage((prev) => prev + 1);
    }
  };

  return (
    <div
      className="notification-container"
      ref={notificationContainerRef}
      onScroll={handleScroll}
      style={{ overflowY: "auto", height: "100%" }}
    >
      <div className="scroll-container">
        <div className="list-container">
          {recent.length > 0 || earlier.length > 0 ? (
            <>
              {recent.length > 0 && (
                <div className="notification-section">
                  <Title level={5} className="section-title">
                    Recent
                  </Title>
                  {recent.map((notification, index) => (
                    <div
                      className="notification-card"
                      key={`${notification?.message?.loan_detail_id}-${index}`}
                    >
                      <NotificationCard
                        notification={notification}
                        ref={
                          index === recent.length - 1
                            ? lastNotificationRef
                            : null
                        }
                      />
                    </div>
                  ))}
                </div>
              )}
              {earlier.length > 0 && (
                <div className="notification-section">
                  <Title level={5} className="section-title">
                    Earlier
                  </Title>
                  {earlier.map((notification, index) => (
                    <div
                      className="notification-card"
                      key={`${notification.loan_detail.id}-${index}`}
                    >
                      <NotificationCard
                        notification={notification}
                        ref={
                          index === earlier.length - 1 && recent.length === 0
                            ? lastNotificationRef
                            : null
                        }
                      />
                    </div>
                  ))}
                </div>
              )}
              {loading && (
                <div style={{ textAlign: "center", padding: "20px" }}>
                  Loading...
                </div>
              )}
            </>
          ) : (
            <div className="empty-state">
              <Empty description="No notifications yet" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Notification;
