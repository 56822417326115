import React, { useEffect, useState } from "react";
import { Button, Col, Form, Input, Row, Divider, Space, message } from "antd";
import {
  createInitiatConsent,
  getCustomerMobileByIdService,
  accountAggregartorStatusService,
  sendAccountAggregatorConsentMessageService,
  getAccountAggregatorrequestStatusService,
  initiateDataFetchService,
} from "../services/bankAccountDetailsService";
import ErrorMessage from "../../../../../utils/errorHandling/ErrorMessage";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  customer_detail: Yup.string()
    .required("Mobile number is required")
    .matches(/^\d+$/, "Mobile number must contain only numeric characters")
    .min(10, "Mobile number must be exact 10 digits")
    .max(10, "Mobile number must be exact 10 digits")
    .label("Mobile number"),
  to_country_code: Yup.string()
    .max(2, "Country code must be exact 2 digits")
    .matches(/^[0-9 ]{2,}$|^$/, "Country code must contain only 2 digits"),
});

const yupSync = {
  async validator({ field }, value) {
    await validationSchema.validateSyncAt(field, { [field]: value });
  },
};

const AccountAggregatorForm = ({
  customer_id,
  loan_id,
  closeForm,
  refreshData,
  open,
}) => {
  const [form] = Form.useForm();
  const [trackingId, setTrackingId] = useState(null);
  const [referenceId, setReferenceId] = useState(null);
  // const [dataDetails, setDataDetails] = useState(null);
  const [aaRequestStatus, setaaRequestStatus] = useState(null);

  useEffect(() => {
    setaaRequestStatus(null);
    if (open) {
      const getaaStatus = async () => {
        try {
          const response = await getAccountAggregatorrequestStatusService(
            loan_id,
            customer_id
          );
          if (response?.data?.trackingId && response?.data?.referenceId) {
            setaaRequestStatus(
              `Consent already Raised for ${response?.data?.mobile}. Tracking ID: ${response?.data?.trackingId}, Reference ID: ${response?.data?.referenceId}.`
            );
            setTrackingId(response?.data?.trackingId);
            setReferenceId(response?.data?.referenceId);
          }
        } catch (error) {
          message.error(
            ErrorMessage(
              error?.response?.status,
              error?.response?.data?.message
            )
          );
        }
      };
      getaaStatus();
      form.resetFields();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, customer_id, open]);

  useEffect(() => {
    const getCustomerById = async () => {
      if (customer_id) {
        try {
          const response = await getCustomerMobileByIdService(customer_id);
          form.setFieldsValue({
            customer_detail: response?.data?.mobile_number,
          });
        } catch (error) {
          message.error(
            ErrorMessage(
              error?.response?.status,
              error?.response?.data?.message
            )
          );
        }
      }
    };
    getCustomerById();
  }, [customer_id, form]);

  const onFinish = async (values) => {
    const payload = {
      phoneNumber: values?.customer_detail,
      redirectionUrl: "http://google.com",
      customer_id: customer_id,
      loan_detail_id: loan_id,
    };
    try {
      const response = await createInitiatConsent(payload);
      const consentStatus = response?.status;

      setTrackingId(response?.data?.consents[0]?.trackingId);
      setReferenceId(response?.data?.consents[0]?.referenceId);

      const consentPayload = {
        customer_id: customer_id,
        loan_detail_id: loan_id,
        mobile: values?.customer_detail,
        referenceId: response?.data?.consents[0]?.referenceId,
        trackingId: response?.data?.consents[0]?.trackingId,
        redirectionUrl: response?.data?.redirectionUrl,
      };

      await sendAccountAggregatorConsentMessageService(consentPayload);

      if (consentStatus === 200) {
        message.success(
          "Consent initiated successfully, Please contact the customer to confirm their approval."
        );
      }

      refreshData();
      closeForm();
    } catch (error) {
      console.error(error);
      message.error("Failed to initiate consent. Please try again.");
    }
  };

  const handleRefresh = async () => {
    if (!trackingId || !referenceId) {
      message.warning("Tracking ID or Reference ID not available.");
      return;
    }
    try {
      const response = await accountAggregartorStatusService(
        trackingId,
        referenceId,
        customer_id,
        loan_id
      );

      if (response?.data?.status) {
        message.info(`Your data fetching status is ${response?.data?.status}`);
      }
      if (
        response?.data?.status === "COMPLETED" &&
        response?.data?.consentStatus === "ACTIVE" &&
        response?.data?.dataFetchStatus === "COMPLETED"
      ) {
        message.info(`Fetching your account data.`);
        const response = await initiateDataFetchService(
          trackingId,
          referenceId,
          customer_id,
          loan_id
        );
        if (response?.data?.status === 200) {
          message.success("Your data Fetched and saved successfully");
          closeForm();
        }
      }
    } catch (error) {
      message.error("Failed to refresh data status.");
    }
  };

  const handleNumberFields = (e, field) => {
    let newValue = e.target.value.replace(/\D/g, "").slice(0, 10);
    form.setFieldsValue({ [field]: newValue });
  };

  return (
    <div>
      <Form form={form} onFinish={onFinish} layout="vertical">
        <p className="manual_enter_block">Get Data from Account Aggregator</p>
        <Row gutter={16}>
          <Col span={9}>
            <Form.Item
              label={<span>Verify Customer’s mobile number for Consent </span>}
              required
            >
              <Input.Group compact>
                <Form.Item name="to_country_code" rules={[yupSync]} noStyle>
                  <Input
                    className="bank_country_code_block"
                    disabled
                    defaultValue="91"
                  />
                </Form.Item>
                <Form.Item name="customer_detail" rules={[yupSync]} noStyle>
                  <Input
                    className="bank_mobile_number_field"
                    placeholder="Please Enter Mobile Number"
                    onChange={(e) => handleNumberFields(e, "customer_detail")}
                  />
                </Form.Item>
              </Input.Group>
            </Form.Item>
          </Col>
        </Row>
        {aaRequestStatus && (
          <p className="manual_enter_block">{aaRequestStatus}</p>
        )}
        <Space
          direction="horizontal"
          align="center"
          className="customer_tabs_form_buttons"
        >
          <Button onClick={handleRefresh}>Refresh</Button>
          <Button type="primary" htmlType="submit">
            Initiate Consent
          </Button>
        </Space>

        <Divider plain>OR</Divider>

        {/* {dataDetails && (
          <div>
            <h3>Data Details</h3>
            {dataDetails.length > 0 ? (
              dataDetails.map((session, index) => (
                <div key={index}>
                  <p>
                    <strong>Session ID:</strong> {session.sessionId}
                  </p>
                  <p>
                    <strong>Data Range:</strong> {session.dataRange.fromDate} -{" "}
                    {session.dataRange.toDate}
                  </p>
                  <p>
                    <strong>Accounts:</strong>
                  </p>
                  <ul>
                    {session.accounts.map((account, accIndex) => (
                      <li key={accIndex}>
                        {account.fipName} - {account.accountNumber} (
                        {account.fiType})
                      </li>
                    ))}
                  </ul>
                </div>
              ))
            ) : (
              <p>No data available.</p>
            )}
          </div>
        )} */}
      </Form>
    </div>
  );
};

export default AccountAggregatorForm;
