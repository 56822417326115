import React, { useEffect, useState } from "react";
import { Button, Col, Form, Input, Row, Select, Space, message } from "antd";
import * as Yup from "yup";
import { getReferncetById, listRelationService, updateInvestmentDetailsByIdService, createReferenceService, listCityervice, listTalukaService, listdistrictService, listPincodeService, listStateService, listCountryService, listReferenceType, listAddressTypeService, getallpincode, pincodeCodeService, listSalutationType } from "../services/refrencesDetailsServices";
import ErrorMessage from "../../../../../utils/errorHandling/ErrorMessage";
import { useParams } from "react-router-dom";
import { decrypt } from "../../../../../utils/cryptoUtils/cryptoUtils";
import { useDispatch } from "react-redux";
import { addNewCustomerReference } from "../../../../../redux/salesFormSlice";
const { Option } = Select;

const validationSchema = Yup.object().shape({
  address_line_1: Yup.string()
    .required("Address line 1 is required")
    .min(2, "Address line 1 must be atleast 2 characters")
    .max(225, "Address line 1 must be at max 225 characters"),
  address_line_2: Yup.string()
    .required("Address line 2 is required")
    .min(2, "Address line 2 must be atleast 2 characters")
    .max(225, "Address line 2 must be at max 225 characters"),
  first_name: Yup.string()
    .required("First name is required")
    .min(2, "First name must be at least 2 characters")
    .max(128, "First name must be at max 128 characters")
    .matches(/^[^\d]+$/, "First name must not contain numeric characters")
    .label("First name"),
  middle_name: Yup.string()
    // .min(2, "Middle name must be at most 2 characters")
    .max(128, "Middle name must be at max 128 characters"),
  // .matches(/^[^\d]+$/, "Middle name must not contain numeric characters"),
  last_name: Yup.string()
    .required("Last name is required")
    .min(2, "Last name must be at most 2 characters")
    .max(128, "Last name must be at max 128 characters")
    .matches(/^[^\d]+$/, "Last name must not contain numeric characters"),
  salutation: Yup.string()
    .required("Salutation is required")
    .min(1, "Salutation must be at most 1 characters")
    .matches(/^[^\d]+$/, "Salutation must not contain numeric characters"),

  relation: Yup.string()
    .required("Relation is required"),
  year_known: Yup.string()
    .required("Year known is required"),
  landmark: Yup.string()
    .required("Landmark is required"),
  address_type: Yup.string()
    .required("Address type is required"),
  phone_number: Yup.string()
    .nullable()
    .transform((value, originalValue) => {
      if (typeof originalValue === 'string') {
        return originalValue.replace(/\D/g, ''); // Remove all non-numeric characters
      }
      return originalValue;
    })
    .matches(/^\d{8,15}$/, {
      message: 'Phone number must be between 8 to 15 digits',
      excludeEmptyString: true,
    })
    .label("Phone number"),



  phone_country_code: Yup.string()
    .max(2, "Country code must be exact 2 digits")
    .matches(/^[0-9 ]{2,}$|^$/, "Country code must be contain only 2 digits"),

  mobile_number: Yup.string()
    .required("Mobile number is required")
    .matches(/^\d+$/, "Mobile number must contain only numeric characters")
    .min(10, "Mobile number must be exact 10 digits")
    .max(10, "Mobile number must be exact 10 digits")
    .label("Mobile number"),

  mobile_country_code: Yup.string()
    // .required("Country code is required")
    // .min(2, "Country Code must be exact 2 digits!")
    .max(2, "Country code must be exact 2 digits")
    .matches(/^[0-9 ]{2,}$|^$/, "Country code must be contain only 2 digits"),
  email: Yup.string()
    // .nullable()
    // .required("Email is required")
    // .min(2, "Email must be at least 2 characters")
    // .max(128, "Email must be at max 128 characters")
    .test(
      "email-format",
      "Email address is not in a valid format",
      (value) => !value || /^[a-zA-Z0-9._%+-]+@[a-zA-Z.-]+\.[a-zA-Z]{2,}$/.test(value)
    )
    .label("Email"),
  city: Yup.string().required("City is required"),
  taluka: Yup.string().required("Taluka is required"),
  pincode: Yup.string()
    .required("Pincode is required")
    .matches(/^[0-9]+$/, "Pincode should contain numbers only"),
  reference_type: Yup.string()
    .required("Reference type is required"),
  district: Yup.string()
    .required("District is required"),
  state: Yup.string().required("State is required"),
  country: Yup.string().required("Country is required"),

});

const yupSync = {
  async validator({ field }, value) {
    await validationSchema.validateSyncAt(field, { [field]: value });
  },
};

const ReferenceDetailsForm = ({ refreshData, id, open, closeForm, customer_id, isSalesForm, activeCustomerKey, activeReferenceKey }) => {
  const [form] = Form.useForm();
  const [allRelationType, setAllRelationType] = useState([]);
  const [referanceType, setReferanceType] = useState([]);
  const [salutationType, setSalutationType] = useState([]);
  const [allcity, setCityType] = useState([]);
  const [alltaluka, setTalukaType] = useState([]);
  const [alladdress, setAddressType] = useState([]);
  const [alldistrict, setDistrictType] = useState([]);
  const [allstate, setAllState] = useState([]);
  const [allcountry, setAllCountry] = useState([]);
  const [pincode, setPincode] = useState();
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const { encrypted_loan_id } = useParams();

  let loan_id = null;
  try {
    loan_id = decrypt(encrypted_loan_id);
  } catch (error) {
    message.error("Invalid Loan ID");
  }

  const handleInputt = (e, name) => {
    let value = e.target.value.replace(/\D/g, ''); // Remove all non-numeric characters
    value = value.slice(0, 15); // Limit input to maximum of 15 characters
    form.setFieldsValue({ [name]: value });
  };

  const handleInput = (e, field) => {
    // Allow letters, numbers, comma, forward slash, and empty spaces
    const newValue = e.target.value.replace(/[^A-Za-z0-9,/\s]/g, "");
    form.setFieldsValue({ [field]: newValue });
  };

  const handleNumberFields = (e, field) => {
    let newValue = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
    newValue = newValue.slice(0, 10); // Truncate to 10 digits
    form.setFieldsValue({ [field]: newValue });
  };

  const handleCountryCodeFields = (e, field) => {
    const newValue = e.target.value.replace(/[^\d+]/g, "");
    form.setFieldsValue({ [field]: newValue });
  };
  const getReferanceData = async () => {

    if (id) {
      try {
        const response = await getReferncetById(id);
        let {
          salutation,
          first_name,
          middle_name,
          last_name,
          // phone_country_code,
          phone_number,
          // mobile_country_code,
          relation,
          mobile_number,
          email,
          year_known,
          reference_type,
          address_line_1,
          address_line_2,
          landmark,
          city,
          address_type,
          taluka,
          district,
          pincode,
          state,
          country,


        } = response?.data;
        form.setFieldsValue({
          salutation,
          first_name,
          middle_name,
          last_name,
          // phone_country_code,
          phone_number,
          // mobile_country_code,
          relation: relation.id,
          mobile_number,
          email,
          year_known,
          reference_type,
          address_line_1,
          address_line_2,
          landmark,
          address_type: address_type?.id,
          city: city?.id,
          taluka: taluka?.id,
          district: district?.id,
          pincode: pincode?.id,
          state: state?.id,
          country: country?.id,

        });
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    }
  };

  const handlePincodeChange = async (value) => {
    const selectedPincode = pincode.find((pin) => pin.id === value);
    const pincodeName = selectedPincode ? selectedPincode.name : "";
    try {
      const response = await pincodeCodeService(pincodeName);
      if (response && response.data) {
        const {
          city,
          district,
          state,
          taluka,
          country
        } = response.data;
        form.setFieldsValue({
          city: city?.id || undefined,
          district: district?.id || undefined,
          state: state?.id || undefined,
          taluka: taluka?.id || undefined,
          country: country?.id || undefined,
        });
      }
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };



  useEffect(() => {
    const getAllRelation = async () => {
      try {
        const response = await listRelationService();
        setAllRelationType(response.data);
      }
      catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };

    const getAllPincodelist = async () => {
      try {
        const response = await getallpincode();
        setPincode(response.data);
        if (response && response.data) {
          if (response.status === 200 && response.data.success) {
            message.success("Address details updated successfully");
            refreshData();
            closeForm();
          }
        }
      } catch (error) {
        closeForm();
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };


    const getAllCity = async () => {
      try {
        const response = await listCityervice();
        setCityType(response.data);
      }
      catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };
    const getAllTaluka = async () => {
      try {
        const response = await listTalukaService();
        setTalukaType(response.data);
      }
      catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };
    const getAllAddress = async () => {
      try {
        const response = await listAddressTypeService();
        setAddressType(response.data);
      }
      catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };

    const getAllDistrict = async () => {
      try {
        const response = await listdistrictService();
        setDistrictType(response.data);
      }
      catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };

    const getAllPincode = async () => {
      try {
        const response = await listPincodeService();
        setPincode(response.data);
      }
      catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };
    const getAllState = async () => {
      try {
        const response = await listStateService();
        setAllState(response.data);
      }
      catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };

    const getAllCountry = async () => {
      try {
        const response = await listCountryService();
        setAllCountry(response.data);
      }
      catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };

    const AllReferenceType = async () => {
      try {
        // Fetch Units from the API
        const response = await listReferenceType();
        setReferanceType(response.data);
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };
    const AllSalutationType = async () => {
      try {
        // Fetch Units from the API
        const response = await listSalutationType();
        setSalutationType(response.data);
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };


    getAllPincodelist();
    getAllRelation();
    getAllCity();
    getAllTaluka();
    getAllDistrict();
    getAllPincode();
    getAllState();
    getAllCountry();
    getAllAddress();
    AllReferenceType();
    AllSalutationType();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form]);




  const handleSubmit = async (values) => {

    setLoading(true);
    if (values.phone_number) {
      values.phone_country_code = "91";
    }
    if (values.mobile_number) {
      values.mobile_country_code = "91";
    }

    try {
      if (id) {
        const response = await updateInvestmentDetailsByIdService(id, values);
        if ((response.status = 200 && response.success)) {
          message.success("Referance details successfully updated");
          if (!isSalesForm) {
            refreshData();
            closeForm();
          }
        }
      } else {
        values.loan_detail = parseInt(loan_id);
        values.customer_detail = customer_id;
        const response = await createReferenceService(values);
        if ((response.status = 201 && response.success)) {
          message.success("Referance details successfully created");
          if (typeof isSalesForm === "undefined") {
            refreshData();
            closeForm();
          }
          if (
            typeof activeReferenceKey !== "undefined" &&
            typeof activeCustomerKey !== "undefined"
          ) {
            dispatch(
              addNewCustomerReference({
                activeCustomerKey: activeCustomerKey,
                activeReferenceKey: activeReferenceKey,
                id: response?.data?.id,
              })
            );
          }
        }
      }
    }
    catch (error) {
      //  closeForm();
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    } finally {
      setLoading(false)
    }
  };




  useEffect(() => {
    if (open || id) {
      getReferanceData();

    } else {
      form.resetFields();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, open]);




  return (
    <Form
      form={form}
      onFinish={handleSubmit}
      layout="vertical"
      initialValues={{
        // salutation: "",
        first_name: "",
        middle_name: "",
        last_name: "",
        // relation: "",
        phone_number: "",
        mobile_number: "",
        email: "",
        year_known: '',
        // reference_type: '',
        // relation: '',
        // address_line_1: '',
        // address_line_2: '',
        landmark: '',
        // city:'',
        // address_type:'',
        // taluka:'',
        // district:'',
        // pincode:'',
        // state:'',
        // country:'',

      }}
    >
      <Row gutter={16}>
        <Col span={8}>
          <Form.Item name="salutation" label="Salutation" rules={[yupSync]} required>
            <Select placeholder="Please Select" allowClear showSearch filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }>
              {salutationType.map((salu) => (
                <Select.Option key={salu} value={salu}>
                  {salu}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>


        </Col>
        <Col span={8}>
          <Form.Item name="first_name" label="First Name" rules={[yupSync]} required>
            <Input placeholder="Please Enter First Name"
              onChange={(e) => handleInput(e, "first_name")}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="middle_name" label="Middle Name" rules={[yupSync]}>
            <Input placeholder="Please Enter Middle Name"

              onChange={(e) => handleInput(e, "middle_name")}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="last_name" label="Last Name" rules={[yupSync]} required>
            <Input placeholder="Please Enter Last Name"
              onChange={(e) => handleInput(e, "last_name")}
            />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item name="relation" label="Relation" rules={[yupSync]} required>

            <Select
              mode="single"
              placeholder="Please Select"
              // required={true}
              allowClear
              className="select_refernce"
              showSearch
              filterOption={(input, option) =>
                option.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0

              }>
              {allRelationType?.map((relation) => {

                return (
                  <Select.Option
                    key={relation.id}
                    value={relation.id}>
                    {relation.name}
                  </Select.Option>
                );
              })}

            </Select>
          </Form.Item>
        </Col>



        <Col span={8}>
          <Form.Item label="Phone Number">
            <Input.Group compact>
              <Form.Item name="phone_country_code" rules={[yupSync]} noStyle>
                <Input
                  className="ref_country_code_block"
                  disabled
                  defaultValue="91"
                  onChange={(e) => handleCountryCodeFields(e, "phone_country_code")}
                />
              </Form.Item>
              <Form.Item
                name="phone_number"

                noStyle
                rules={[yupSync]}
              >
                <Input
                  className="ref_mobile_number_field"
                  placeholder="Please Enter Phone Number"
                  onChange={(e) => handleInputt(e, "phone_number")}
                />
              </Form.Item>
            </Input.Group>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label="Mobile Number"
            // name="mobile_country_code"
            required
          >
            <Input.Group compact>
              <Form.Item
                name="mobile_country_code"
                rules={[yupSync]} noStyle
              >
                <Input
                  className="ref_country_code_block"
                  disabled
                  defaultValue="91"
                  onChange={(e) => handleCountryCodeFields(e, "mobile_country_code")}
                />
              </Form.Item>
              <Form.Item name="mobile_number" noStyle rules={[yupSync]}>
                <Input
                  className="ref_mobile_number_field"
                  placeholder="Enter Mobile Number"

                  onChange={(e) => handleNumberFields(e, "mobile_number")}
                />
              </Form.Item>
            </Input.Group>
          </Form.Item>
        </Col>




        <Col span={8}>
          <Form.Item name="reference_type" label="Reference Type" rules={[yupSync]} required>
            <Select placeholder="Please Select" showSearch allowClear filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }>
              {referanceType.map((referance) => (
                <Select.Option key={referance} value={referance}>
                  {referance}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

        </Col>
        <Col span={8}>
          <Form.Item name="year_known" label="Year Known" rules={[yupSync]} required>
            <Input placeholder="Please Enter Year Known"
              onChange={(e) => handleInputt(e, "year_known")}
            />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item name="email" label="Email" rules={[yupSync]}>
            <Input placeholder="Please Enter Email"
            // onChange={(e) => handleInput(e, "email")}
            />
          </Form.Item>
        </Col>



        <Col span={8}>
          <Form.Item name="address_type" label="Address Type" rules={[yupSync]} required>

            <Select
              mode="single"
              placeholder="Please Select"
              // required={true}
              allowClear
              className="select_refernce"
              showSearch
              filterOption={(input, option) =>
                option.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0

              }>
              {alladdress?.map((add) => {

                return (
                  <Select.Option
                    key={add.id}
                    value={add.id}>
                    {add.name}
                  </Select.Option>
                );
              })}

            </Select>
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item name="address_line_1" label="Address line 1" rules={[yupSync]} required>
            <Input placeholder="Please Enter Address Line 1"
              onChange={(e) => handleInput(e, "address_line_1")}
            />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item name="address_line_2" label="Address line 2" rules={[yupSync]} required>
            <Input placeholder="Please Enter Address Line 2"
              onChange={(e) => handleInput(e, "address_line_2")}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="landmark" label="Landmark" rules={[yupSync]} required>
            <Input placeholder="Please Enter Landmark"
              onChange={(e) => handleInput(e, "landmark")}
            />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item name="pincode" label="Pincode" rules={[yupSync]} required>
            <Select
              showSearch
              optionFilterProp="children"
              placeholder="Please Select"
              allowClear filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              onChange={handlePincodeChange}

            >
              {pincode?.map((pincodes, index) => (
                <Option key={pincodes.id} value={pincodes.id}>
                  {pincodes.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>


        <Col span={8}>
          <Form.Item name="city" label="City" rules={[yupSync]} required>

            <Select
              mode="single"
              placeholder="Please Select"
              // required={true}
              allowClear
              className="select_refernce"
              showSearch
              disabled
              filterOption={(input, option) =>
                option.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0

              }>
              {allcity?.map((relation) => {

                return (
                  <Select.Option
                    key={relation.id}
                    value={relation.id}>
                    {relation.name}
                  </Select.Option>
                );
              })}

            </Select>
          </Form.Item>
        </Col>


        <Col span={8}>
          <Form.Item name="taluka" label="Taluka" rules={[yupSync]} required>

            <Select
              mode="single"
              placeholder="Please Select"
              // required={true}
              allowClear
              className="select_refernce"
              showSearch
              disabled
              filterOption={(input, option) =>
                option.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0

              }>
              {alltaluka?.map((taluka) => {

                return (
                  <Select.Option
                    key={taluka.id}
                    value={taluka.id}>
                    {taluka.name}
                  </Select.Option>
                );
              })}

            </Select>
          </Form.Item>
        </Col>


        <Col span={8}>
          <Form.Item name="district" label="District" rules={[yupSync]} required>

            <Select
              mode="single"
              placeholder="Please Select"
              // required={true}
              allowClear
              className="select_refernce"
              showSearch
              disabled
              filterOption={(input, option) =>
                option.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0

              }>
              {alldistrict?.map((district) => {

                return (
                  <Select.Option
                    key={district.id}
                    value={district.id}>
                    {district.name}
                  </Select.Option>
                );
              })}

            </Select>
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item name="state" label="State" rules={[yupSync]} required>

            <Select
              mode="single"
              placeholder="Please Select"
              // required={true}
              allowClear
              className="select_refernce"
              showSearch
              filterOption={(input, option) =>
                option.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0

              }
              disabled
            >
              {allstate?.map((state) => {

                return (
                  <Select.Option
                    key={state.id}
                    value={state.id}>
                    {state.name}
                  </Select.Option>
                );
              })}

            </Select>
          </Form.Item>
        </Col>




        <Col span={8}>
          <Form.Item name="country" label="Country" rules={[yupSync]} required>

            <Select
              mode="single"
              placeholder="Please Select"
              // required={true}
              allowClear
              className="select_refernce"
              showSearch
              disabled
              filterOption={(input, option) =>
                option.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0

              }>
              {allcountry?.map((country) => {

                return (
                  <Select.Option
                    key={country.id}
                    value={country.id}>
                    {country.name}
                  </Select.Option>
                );
              })}

            </Select>
          </Form.Item>
        </Col>






      </Row>

      <Space
        direction="horizontal"
        align="center"
        className="customer_tabs_form_buttons"
      >
        {isSalesForm ? (
          <Button type="primary" htmlType="submit" loading={loading}>
            Submit
          </Button>
        ) :
          (
            <>
              <Button type="primary" htmlType="submit" loading={loading}>
                Submit
              </Button>
              <Button onClick={closeForm}>Cancel</Button>
            </>
          )}
      </Space>
    </Form>
  );
};

export default ReferenceDetailsForm;