
import React, { useEffect } from 'react';
import { Button, Col, Form, Row, Space, message } from 'antd';
import * as Yup from "yup";
import { createStrengthsOfTheCaseService, getstrngthById, updateStrengthOfcaseService } from '../services/creditAnalysisServices';
import ErrorMessage from '../../../../../utils/errorHandling/ErrorMessage';
import TextArea from 'antd/es/input/TextArea';

const validationSchema = Yup.object().shape({
    description: Yup.string().required("Description is required"),
});

const yupSync = {
    async validator({ field }, value) {
        await validationSchema.validateSyncAt(field, { [field]: value });
    },
}; 

const WeaknessForm = ({ closeForm, open, id,loan_id, refreshData }) => {
    const [form] = Form.useForm();
 

    // const handleInput = (e, field) => {
    //     const newValue = e.target.value.replace(/[^A-Za-z\s]/g, "");
    //     form.setFieldsValue({ [field]: newValue });
    // };

    const getWeaknessData = async () => {
        if (id) {
            try {
                const response = await getstrngthById(id);
                const { description } = response.data;
               
                form.setFieldsValue({ description });
            } catch (error) {
                message.error(
                    ErrorMessage(error?.response?.status, error?.response?.data?.message)
                  )
            }
        }
    };

    useEffect(() => {
        if (open) {
            getWeaknessData();
        } else {
            form.resetFields();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);
    
    const handleSubmit = async (values) => {
        try {
            if (id) {
            const response = await updateStrengthOfcaseService(id, values);
            if (response.status === 200 && response.success) {
                message.success("Risk and Mitigates updated successfully");
                refreshData();
                closeForm();
            }
        } else {
                        // Create new Strength of the Case
                        const payload = {
                            loan_detail: loan_id,
                            key_type: "Weakness",
                            description: values.description,
                        };
                        const response = await createStrengthsOfTheCaseService(payload);
                        if (response.status === 200 && response.success) {
                            message.success("Risk and Mitigates successfully created");
                            refreshData();
                            closeForm();
                        }
                    }

                }catch (error) {
            message.error(
                ErrorMessage(error?.response?.status, error?.response?.data?.message)
              )
        }
    };

    return (
        <Form
            layout="vertical"
            form={form}
            onFinish={handleSubmit}
        >
            <Row gutter={16} style={{ marginBottom: "16px" }} >
                <Col span={8}>
                    <Form.Item name="description" label="Description" rules={[yupSync]} required>
                        {/* <Input
                            placeholder="Please enter description"
                            onChange={(e) => handleInput(e, "description")}
                        /> */}
                             <TextArea
                            placeholder="Please Enter Description"
                            maxLength={1001}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Space
                direction="horizontal"
                align="center"
                style={{ display: "flex", flexDirection: "row-reverse" }}
            >
                <Button type="primary" htmlType="submit">
                    Submit
                </Button>
                <Button onClick={closeForm}>Cancel</Button>
            </Space>
        </Form>
    );
};

export default WeaknessForm;
