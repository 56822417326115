import React from 'react';
import ChatBot from './Chatbot';
import '../styles/floatingScreen.css'; // Import the CSS file

const FloatingChatButton = ({
  loan_id,
  sendJsonMessage,
  lastJsonMessage,
  readyState,
  conversationId,
  isFloatingWindowVisible,
  showChatDrawer,
}) => {
  return (
    <div>
      {isFloatingWindowVisible && (
        <div className="chat-screen-container">
          <ChatBot
            loan_id={loan_id}
            sendJsonMessage={sendJsonMessage}
            lastJsonMessage={lastJsonMessage}
            readyState={readyState}
            conversationId={conversationId}
            showChatDrawer={showChatDrawer}
          />
        </div>
      )}
    </div>
  );
};

export default FloatingChatButton;
