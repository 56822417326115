/* eslint-disable no-unused-vars */
import React from "react";
import { Card, Avatar, Button, Typography } from "antd";
import "../styles/notificationFeed.css";
import TimeDisplay from "./TimeDisplay";
import NotificationCard from "./NotificationCard";
// import { useSelector } from "react-redux";

const { Title } = Typography;

const NotificationFeed = ({
  updatedNotifications = [],
  showDrawer,
  setDropdownOpen,
}) => {



  const groupNotifications = (notifications) => {
    // Determine the correct array of messages
    const chatNewMessages = Array.isArray(notifications)
      ? notifications
      : notifications.message || [];

    const twoHours = 2 * 60 * 60 * 1000;
    const now = new Date();

    // First, sort the messages by timestamp in descending order
    const sortedMessages = chatNewMessages.sort((a, b) => {
      const timestampA =
        a.time_stamp || a.created_at || (a.message && a.message.time_stamp);
      const timestampB =
        b.time_stamp || b.created_at || (b.message && b.message.time_stamp);
      return new Date(timestampB) - new Date(timestampA);
    });

    return sortedMessages.reduce(
      (acc, notification) => {
        const timestamp =
          notification.time_stamp ||
          notification.created_at ||
          (notification.message && notification.message.time_stamp);

        if (timestamp) {
          const messageDate = new Date(timestamp);
          const isRecent = now - messageDate < twoHours;

          if (isRecent) {
            acc.recent.push(notification);
          } else {
            acc.earlier.push(notification);
          }
        }

        return acc;
      },
      { recent: [], earlier: [] }
    );
  };

  const { recent, earlier } = groupNotifications(updatedNotifications);



  return (
    <div style={{ maxWidth: "100%", margin: "0 auto", padding: 16 }}>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginBottom: 16,
        }}
      >
        <Button onClick={showDrawer}>View All</Button>
      </div>

      {recent.length > 0 && (
        <div style={{ marginBottom: 32, width: "100%" }}>
          <Title
            level={5}
            style={{
              fontSize: 14,
              fontWeight: 400,
              color: "#666",
              marginBottom: 16,
            }}
          >
            Recent
          </Title>
          {recent.map((notification, index) => (
            <NotificationCard
              key={`${notification.loan_detail_id}-${index}`}
              notification={notification}
            />
          ))}
        </div>
      )}

      {earlier.length > 0 && (
        <div style={{ position: "relative" }}>
          <Title
            level={5}
            style={{
              fontSize: 14,
              fontWeight: 400,
              color: "#666",
              marginBottom: 16,
            }}
          >
            Earlier
          </Title>
          {earlier.map((notification, index) => (
            <NotificationCard
              key={`${notification.loan_detail_id}-${index}`}
              notification={notification}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default NotificationFeed;
