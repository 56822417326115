/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { Col, Form, Input, Radio, Row } from "antd";
import ICICIQuestion from "./ICICIQuestion";
import "../styles/ICICIQuestionForm.css"; // Add a CSS file for better styling management

const ICICIQuestionForm = ({
  item,
  index,
  showRemark,
  handleRadioChange,
  selectedValue,
  questionRemark,
  setQuestionRemark,
  insurance_id,
  text,
}) => {
  const [form] = Form.useForm();
  useEffect(() => {
    if (item.sub_questions && insurance_id) {
      const initialValues = {};
      item.sub_questions.forEach((subItem) => {
        initialValues[`radio_${subItem.id}`] = subItem.answer === "no" ? 0 : 1;
        if(subItem.icici_code === "MIIQEOSR" && subItem.remark ){
          setQuestionRemark(subItem.remark)
        }
      });
      form.setFieldsValue(initialValues);
    }
  }, [item.sub_questions,insurance_id, form]);

  const renderSubQuestion = (subItem, subIndex) => {
    const checkDisorder = subItem?.question?.includes(
      "Any other disorder not mentioned above"
    );
    const quesOccupation = subItem?.question?.includes(
      "Is your occupation associated with any specific hazard"
    );
  
    if (quesOccupation || subItem.question === item.question) {
      return null;
    }
  
    const subQuestionLabel =
      index === 2 || index === 4
        ? `${String.fromCharCode(97 + subIndex)}) `
        : "";
  
    return (
      <div
        key={`${item.id}_${subItem.id}_${subIndex}`}
        className="sub-question-container"
      >
        <p className="insurance-sub-question-heading">
          {subQuestionLabel} {subItem.question}
        </p>
  
        {text === "personal" &&
          (index !== 2 || (index === 2 && subIndex === 2)) && (
            <Form.Item
              name={`radio_${subItem.id}`}
              rules={[{ required: true, message: "Please select an option" }]}
            >
              <Radio.Group
                onChange={(e) => {
                  handleRadioChange(subItem.id, e);
                }}
              >
                <Row gutter={16}>
                  <Col span={12}>
                    <Radio value={1}>Yes</Radio>
                  </Col>
                  <Col span={12}>
                    <Radio value={0}>No</Radio>
                  </Col>
                </Row>
              </Radio.Group>
            </Form.Item>
          )}
  
        {checkDisorder && form.getFieldValue(`radio_${subItem.id}`) === 1 && (
          <div>
            <Input
              type="text"
              placeholder="Remark"
              className="remark-input"
              value={questionRemark}
              onChange={(e) => setQuestionRemark(e.target.value)}
            />
          </div>
        )}
      </div>
    );
  };
  

  return (
    <Form form={form} className="icici-question-form">
      <div key={item.id} className="main-question-container">
        {index === 5 && (
          <p className="female-customers-note">Only for Female Customers,</p>
        )}

        <ICICIQuestion
          selectedValue={selectedValue}
          index={index}
          item={item}
          insurance_id={insurance_id}
          questionRemark={questionRemark}
          text={text}
          handleRadioChange={handleRadioChange}
        />

        {text === "personal" && item.sub_questions?.map(renderSubQuestion)}
      </div>
    </Form>
  );
};

export default ICICIQuestionForm;
