import { message, Row, Col, Typography, Skeleton, Drawer } from 'antd';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import "../styles/incomeDetails.css";
import { decrypt } from '../../../../../utils/cryptoUtils/cryptoUtils';
import ErrorMessage from '../../../../../utils/errorHandling/ErrorMessage';
import { LTVCalculationsByLoanId } from '../services/incomeDetailsServices';
import EditButton from '../../../../../utils/editButton/EditButton';
import Header from '../../../../layout/views/Header';
import LTVCalculationForm from './LTVCalculationForm';
import { useSelector } from 'react-redux';
import ConditionalRender from '../../../../../utils/authorization/AuthorizeComponent';
import { UpOutlined, DownOutlined } from "@ant-design/icons";
import CustomNoData from '../../../../../utils/noDataIcon/CustomNoData';
const { Text } = Typography;

const LTVCalculation = ({ refreshTableData, refreshLTVData }) => {
    const { encrypted_loan_id } = useParams();
    const [getLTVCalculation, setLTVCalculation] = useState([]);
    const [loading, setLoading] = useState(true); // State to track loading
    const [selectedRecord, setSelectedRecord] = useState();
    const [open, setOpen] = useState(false);
    const [refreshData, setRefreshData] = useState(false);
    const { user_data } = useSelector((state) => state.user);
    const [collapseSections, setCollapseSections] = useState(true);
    let loan_id = null;

    try {
        loan_id = decrypt(encrypted_loan_id);
    } catch (error) {
        message.error("Invalid Loan ID");
    }
    const showDrawer = () => {
        setOpen(true);
    };
    const onClose = () => {
        setOpen(false);
        setSelectedRecord(null);
    };
    const toggleRefreshTableData = () => {
        setRefreshData((prev) => !prev);
    };


    useEffect(() => {
        const fetchLTVCalculation = async () => {
            try {
                const response = await LTVCalculationsByLoanId(loan_id);
                setLTVCalculation(response.data);
            } catch (error) {
                message.error(
                    ErrorMessage(error?.response?.status, error?.response?.data?.message)
                );
            } finally {
                setLoading(false); // Stop loading regardless of success or error
            }
        };

        fetchLTVCalculation();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refreshTableData, refreshData, loan_id, refreshLTVData]);

    const renderEditButton = (showDrawer, id,) => (
        <div className="tab_form_sub_edit" onClick={() => {
            setSelectedRecord(id);
            showDrawer();
        }}>
            <EditButton />
        </div>
    );

    const IndianNumberFormat = (value) => {
        return new Intl.NumberFormat('en-IN').format(value);
    };

    return (
        <div className="income_calculations_container">
            <Drawer
                title={
                    <Header
                        title={selectedRecord ? "Edit" : "Add"}
                        onClose={onClose}
                        name="LTV Calculations"
                    />
                }
                width={720}
                onClose={onClose}
                open={open}
                closable={false}
            >
                <LTVCalculationForm
                    refreshData={toggleRefreshTableData}
                    id={selectedRecord && selectedRecord}
                    open={open}
                    closeForm={onClose}
                />
            </Drawer>
            <div className="ltv_calculation_heading_text">LTV Calculations
                <div style={{ display: 'flex', gap: "10px" }}>
                    {getLTVCalculation && Object.keys(getLTVCalculation)?.length > 0 && (
                        <ConditionalRender
                            userPermissions={user_data?.permissions}
                            requiredPermissions={["EDITLTV1"]}
                        >
                            {renderEditButton(showDrawer, getLTVCalculation?.id)}
                        </ConditionalRender>
                    )}
                    {collapseSections ? (
                        <UpOutlined
                            onClick={() => setCollapseSections(!collapseSections)}
                        />
                    ) : (
                        <DownOutlined
                            onClick={() => setCollapseSections(!collapseSections)}
                        />
                    )}
                </div>
            </div>
            {collapseSections && (
                <div className="ltv-content">
                    {loading ? (
                        // Display Skeleton loader when loading
                        <Skeleton active paragraph={{ rows: 10 }} />
                    ) : getLTVCalculation && Object.keys(getLTVCalculation)?.length > 0 ? (
                        <>
                            <Row className="ltv-row">
                                <Col span={10}>
                                    <Text>Loan Amount</Text>
                                </Col>
                                <Col>
                                    <Text>: {getLTVCalculation?.approved_amount ? `₹ ${IndianNumberFormat(getLTVCalculation.approved_amount)}` : '-'}</Text>
                                </Col>
                            </Row>

                            <Row className="ltv-row">
                                <Col span={10}>
                                    <Text>Appraised Obligations</Text>
                                </Col>
                                <Col>
                                    <Text>: {getLTVCalculation?.appraised_obligations ? `₹ ${IndianNumberFormat(getLTVCalculation.appraised_obligations)}` : '-'}</Text>
                                </Col>
                            </Row>

                            <Row className="ltv-row">
                                <Col span={10}>
                                    <Text>Tenure applied</Text>
                                </Col>
                                <Col>
                                    <Text>: {`${getLTVCalculation?.approved_tenure || '-'}`} months</Text>
                                </Col>
                            </Row>

                            <Row className="ltv-row">
                                <Col span={10}>
                                    <Text>Rate Of Interest</Text>
                                </Col>
                                <Col>
                                    <Text>: {` ${getLTVCalculation?.approved_roi}%` || '-'}</Text>
                                </Col>
                            </Row>

                            <Row className="ltv-row">
                                <Col span={10}>
                                    <Text>Value of the property(₹ In lacs)</Text>
                                </Col>
                                <Col>
                                    <Text>: {getLTVCalculation?.property_value ? `₹ ${IndianNumberFormat(getLTVCalculation.property_value)}` : '-'}</Text>
                                </Col>
                            </Row>

                            <Row className="ltv-row">
                                <Col span={10}>
                                    <Text>Total Income</Text>
                                </Col>
                                <Col>
                                    <Text>: {getLTVCalculation?.total_income ? `₹ ${IndianNumberFormat(getLTVCalculation.total_income)}` : '-'}</Text>
                                </Col>
                            </Row>

                            <Row className="ltv-row">
                                <Col span={10}>
                                    <Text>Appraised Monthly Income</Text>
                                </Col>
                                <Col>
                                    <Text>: {getLTVCalculation?.appraised_monthly_income ? `₹ ${IndianNumberFormat(getLTVCalculation.appraised_monthly_income)}` : '-'}</Text>
                                </Col>
                            </Row>

                            <Row className="ltv-row">
                                <Col span={10}>
                                    <Text>Max EMI</Text>
                                </Col>
                                <Col>
                                    <Text>: {getLTVCalculation?.max_emi ? `₹ ${IndianNumberFormat(getLTVCalculation?.max_emi)}` : '-'}</Text>
                                </Col>
                            </Row>
                            <Row className="ltv-row">
                                <Col span={10}>
                                    <Text>EMI Factor</Text>
                                </Col>
                                <Col>
                                    <Text>: {getLTVCalculation?.emi_factor ? `₹ ${IndianNumberFormat(getLTVCalculation.emi_factor)}` : '-'}</Text>
                                </Col>
                            </Row>

                            <Row className="ltv-row">
                                <Col span={10}>
                                    <Text>Eligibility (In ₹)</Text>
                                </Col>
                                <Col>
                                    <Text>: {getLTVCalculation?.eligibility ? `₹ ${IndianNumberFormat(getLTVCalculation.eligibility)}` : '-'}</Text>

                                </Col>
                            </Row>

                            <Row className="ltv-row">
                                <Col span={10}>
                                    <Text>EMI</Text>
                                </Col>
                                <Col>
                                    <Text>: {getLTVCalculation?.emi ? `₹ ${IndianNumberFormat(getLTVCalculation.emi)}` : '-'}</Text>
                                </Col>
                            </Row>

                            <Row className="ltv-row">
                                <Col span={10}>
                                    <Text>Actual FOIR (including present loan)</Text>
                                </Col>
                                <Col>
                                    <Text>: {` ${getLTVCalculation?.foir || '-'}`}</Text>
                                </Col>
                            </Row>

                            <Row className="ltv-row actual-ltv-row">
                                <Col span={10}>
                                    <Text strong>Actual LTV</Text>
                                </Col>
                                <Col>
                                    <Text strong>: {`${getLTVCalculation?.actual_ltv}%` || '-'}</Text>
                                </Col>
                            </Row>
                        </>
                    ) : (
                        <div className="no_data_found_message">
                            <CustomNoData />
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default LTVCalculation;
