import { axiosRequest } from "../../../utils/api/axiosRequest";

export const getLoanAllNotification = (pageNumber) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.get(
          `/api/v1/communication/messages/notifications/?page=${pageNumber}`
        );
        return resolve(response.data);
      } catch (error) {
        return reject(error);
      }
    });
  };