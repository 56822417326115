import BasicDetails from "../modules/loanApplication/loanTabDetails/basicDetails/views/BasicDetails";
import PropertyDetails from "../modules/loanApplication/loanTabDetails/propertyDetails/views/PropertyDetails";
import VehicleDetails from "../modules/loanApplication/loanTabDetails/vehicleDetails/views/VehicleDetails";
import VideoAudioGallary from "../modules/loanApplication/loanTabDetails/videoAudioGallary/views/VideoAudioGallaryDetails";
import FeeDetails from "../modules/loanApplication/loanTabDetails/feeDetails/views/FeeDetails";

import LoanInsurance from "../modules/loanApplication/loanTabDetails/loanandInsurance/views/LoanInsurance";
import IncomeEvalution from "../modules/loanApplication/loanTabDetails/IncomeEvaluation/views/IncomeEvaluation";
import CreaditAnalysis from "../modules/loanApplication/loanTabDetails/creaditAnalysis/views/CreaditAnalysis";
import DisbursementTranche from "../modules/loanApplication/loanTabDetails/disbursementTranche/views/DisbursementTranche";
import MiscellaneousDetails from "../modules/loanApplication/loanTabDetails/miscellaneousDetails/views/ MiscellaneousDetails";

import OTCPDD from "../modules/loanApplication/loanTabDetails/operations/OTCPDD/views/OTCPDD";
import ChequeAndNEFT from "../modules/loanApplication/loanTabDetails/operations/ChequesAndNEFT/views/ChequeAndNEFT";
import PaymentDetail from "../modules/loanApplication/loanTabDetails/operations/PaymentDetail/views/PaymentDetail";
import PDC from "../modules/loanApplication/loanTabDetails/operations/PDC/views/PDC";

import PersonalDetails from "../modules/loanApplication/customerTabDetails/personalDetails/views/Personaldetails";
import AddressDetails from "../modules/loanApplication/customerTabDetails/addressDetails/views/AddressDetails";
import DocumentDetails from "../modules/loanApplication/customerTabDetails/documentDetails/views/DocumentDetails";
import BureauDetails from "../modules/loanApplication/customerTabDetails/bureauDetails/views/BureauDetails";
import FraudCrimeCheckDetails from "../modules/loanApplication/customerTabDetails/fraudCrimeCheckDetails/views/FraudCrimeCheckDetails";
import EmployementDetails from "../modules/loanApplication/customerTabDetails/employmentDetails/views/EmploymentDetails";
import BankAccountDetails from "../modules/loanApplication/customerTabDetails/bankAccountDetails/views/BankAccountDetails";
import InvestmentDetails from "../modules/loanApplication/customerTabDetails/investmentdetails/views/InvestmentDetails";
import ExistingLoanDetails from "../modules/loanApplication/customerTabDetails/existingLoansDetails/views/ExistingLoanDetails";
import ReferenceDetails from "../modules/loanApplication/customerTabDetails/referenceDetails/views/ReferenceDetails";
import DependentDetails from "../modules/loanApplication/customerTabDetails/dependentDetails/views/DependentDetails";
import ExistingVehicleDetails from "../modules/loanApplication/customerTabDetails/existingVehicleDetails/views/ExistingVehicleDetails";
import TeleVerificationCallDetails from "../modules/loanApplication/customerTabDetails/teleVerificationCallDetails/views/TeleVerificationCallDetails";
import KarzaDetails from "../modules/loanApplication/customerTabDetails/karzaDetails/views/karzaDetails";

import IncomeNonCashForm from "../modules/loanApplication/loanTabDetails/IncomeEvaluation/views/IncomeNonCashForm";
import IncomeCashForm from "../modules/loanApplication/loanTabDetails/IncomeEvaluation/views/IncomeCashForm";
import IncomeMultiplierForm from "../modules/loanApplication/loanTabDetails/IncomeEvaluation/views/IncomeMultiplierForm";
import NormalIncomeAndIncomeEstimateForm from "../modules/loanApplication/loanTabDetails/IncomeEvaluation/views/NormalIncomeAndIncomeEstimateForm";
import EmiEuiliserForm from "../modules/loanApplication/loanTabDetails/IncomeEvaluation/views/EmiEuiliserForm";
import PureRentalForm from "../modules/loanApplication/loanTabDetails/IncomeEvaluation/views/PureRentalForm";

import IncomeNonCashView from "../modules/loanApplication/loanTabDetails/IncomeEvaluation/views/IncomeNonCashView";
import IncomeCashView from "../modules/loanApplication/loanTabDetails/IncomeEvaluation/views/IncomeCashView";
import IncomeMultiplierView from "../modules/loanApplication/loanTabDetails/IncomeEvaluation/views/IncomeMultiplierView";
import IncomeEstimateView from "../modules/loanApplication/loanTabDetails/IncomeEvaluation/views/IncomeEstimateView";
import PureRentalView from "../modules/loanApplication/loanTabDetails/IncomeEvaluation/views/PureRentalView";
import CersaiEditForm from "../modules/loanApplication/loanTabDetails/operations/Cersai/SecurityInterest/views/CersaiEditForm"
import CashNonCashForm from "../modules/loanApplication/loanTabDetails/IncomeEvaluation/views/CashNonCashForm";
import Nach from "../modules/loanApplication/nachDetails/views/NachDetails"


export const loanTabComponents = {
    BasicDetails,
    PropertyDetails,
    VehicleDetails,
    VideoAudioGallary,
    FeeDetails,
    LoanInsurance,
    IncomeEvalution,
    CreaditAnalysis,
    DisbursementTranche,
    MiscellaneousDetails,
    OTCPDD,
    ChequeAndNEFT,
    PaymentDetail,
    PDC,
    CersaiEditForm,
    Nach,
    PersonalDetails,
    AddressDetails,
    DocumentDetails,
    BureauDetails,
    FraudCrimeCheckDetails,
    EmployementDetails,
    BankAccountDetails,
    InvestmentDetails,
    ExistingLoanDetails,
    ReferenceDetails,
    DependentDetails,
    ExistingVehicleDetails,
    TeleVerificationCallDetails,
    IncomeNonCashForm,
    IncomeCashForm,
    IncomeMultiplierForm,
    NormalIncomeAndIncomeEstimateForm,
    EmiEuiliserForm,
    PureRentalForm,
    IncomeNonCashView,
    IncomeCashView,
    IncomeMultiplierView,
    IncomeEstimateView,
    PureRentalView,
    KarzaDetails,
    CashNonCashForm

};