import React, { useState, useEffect } from 'react';
import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Space,
  message,
  Select
} from "antd";
import '../styles/televerification.css'
import { IoCallOutline } from "react-icons/io5";
import { useSelector } from "react-redux";
import { getCustomerMobileByIdService, createCallService, getApplicantListByIdService, getCustomerMobileNumberByIdService } from "../services/televerificationDetailsService";
import ErrorMessage from '../../../../../utils/errorHandling/ErrorMessage';
import { decrypt } from '../../../../../utils/cryptoUtils/cryptoUtils';
import { useParams } from 'react-router-dom';
import * as Yup from "yup";

const validationSchema = Yup.object().shape({

  customer_detail: Yup.string()
    .required("Asignee Name is required") 
    .label("Asignee Name"),
  from_country_code: Yup.string()
    .max(2, "Country code must be exact 2 digits")
    .matches(/^[0-9 ]{2,}$|^$/, "Country code must be contain only 2 digits"),
  from_mobile: Yup.string()
    .required("Mobile number is required")
    .matches(/^\d+$/, "Mobile number must contain only numeric characters")
    .min(10, "Mobile number must be exact 10 digits")
    .max(10, "Mobile number must be exact 10 digits")
    .label("Mobile number"), 

  to_country_code: Yup.string()
    .max(2, "Country code must be exact 2 digits")
    .matches(/^[0-9 ]{2,}$|^$/, "Country code must be contain only 2 digits"),

});

const yupSync = {
  async validator({ field }, value) {
    await validationSchema.validateSyncAt(field, { [field]: value });
  },
};

const TeleVerificationCallDetailsForm = ({ refreshData, open, closeForm, id, customer_id }) => {
  const [form] = Form.useForm();
  const { user_data } = useSelector((state) => state.user);
  const { encrypted_loan_id } = useParams();
  const [customerName, setCustomerName] = useState(null);
  const [, setCustomerId] = useState(null);


  let loan_id = null;
  try {
    loan_id = decrypt(encrypted_loan_id);
  } catch (error) {
    message.error("Invalid Loan ID");
  }

  useEffect(() => {
    if (user_data?.mobile) {
      form.setFieldsValue({
        from_mobile: user_data?.mobile,
        customer_detail: user_data?.name,
      });
    }
  }, [user_data, form, open]);


  useEffect(() => {
    const getCustomerById = async () => {
      if (customer_id) {
        try {
          const response = await getCustomerMobileByIdService(customer_id);
          console.log("first")
          form.setFieldsValue({
            to_mobile: response?.data?.mobile_number,
            reference: `${response?.data?.first_name} ${response?.data?.middle_name} ${response?.data?.last_name}`.trim()
          });
        } catch (error) {
          message.error(
            ErrorMessage(error?.response?.status, error?.response?.data?.message)
          );
        }
      }
    };
    getCustomerById();
  }, [customer_id, form]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const customerResponse = await getApplicantListByIdService(loan_id);
        const customerNames = customerResponse.data.customer_list.map(
          (customer) => ({
            id: customer.id,
            name: customer.name,
          })
        );
        setCustomerName(customerNames);
        if (customerNames.length > 0) {
          setCustomerId(customerNames[0].id);
        }
      } catch (error) {
        console.error("Error fetching customer data:", error);
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCustomerChange = async (value) => {
    setCustomerId(value);
    if (!value) {
      form.setFieldsValue({
        from_mobile: undefined,
        other_mobile_number: undefined,
      });
      return;
    }
    try {
      const customermobileresponse = await getCustomerMobileNumberByIdService(
        value
      );
      const numbers = customermobileresponse.data;
      form.setFieldsValue({
        from_mobile: numbers[0],
        other_mobile_number: undefined,
      });
    } catch (error) {
      message.error("Failed to fetch mobile numbers");
    }
  };

  const handleSubmit = async (values) => {
    console.log("138", values)
    try {
      values.loan_detail = parseInt(loan_id);
      values.customer_detail = customer_id;
      // console.log(values);
      const response = await createCallService(values);
      if ((response.status = 200 && response.success)) {
        message.success("Call Successfully initiated");
        refreshData();
        closeForm();
      }
    }
    catch (error) {
      // closeForm();
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  const handleNumberFields = (e, field) => {
    let newValue = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
    newValue = newValue.slice(0, 10); // Truncate to 10 digits
    form.setFieldsValue({ [field]: newValue });
  };
  return (
    <>
      <Form
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
      >
        <Row gutter={16} >
          <Col span={8}>
            <Form.Item
              name="customer_detail"
              label="Agent"
              rules={[yupSync]}
              required
            >
              {/* <Input
                                disabled
                                placeholder="Please Enter Agent Name"
                            /> */}

              <Select
                mode="single"
                placeholder="Please select"
                allowClear
                className="select_field"
                showSearch
                onChange={handleCustomerChange}
                filterOption={(input, option) =>
                  option.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {customerName?.map((cust) => (
                  <Select.Option key={cust.id} value={cust.id}>
                    {cust.name}
                  </Select.Option>
                ))}
              </Select>

            </Form.Item>
          </Col>


          <Col span={8}>
            <Form.Item
              label="From (Caller)"
              required
            >
              <Input.Group compact>
                <Form.Item
                  name="from_country_code"
                  rules={[yupSync]}
                  noStyle
                >
                  <Input
                    className='tele_country_code_block'
                    disabled
                    defaultValue="91"
                  />
                </Form.Item>
                <Form.Item
                  name="from_mobile"
                  rules={[yupSync]}
                  noStyle
                >
                  <Input
                    className='tele_mobile_number_field'
                    placeholder="Please Enter Mobile Number"
                    onChange={(e) => handleNumberFields(e, "from_mobile")}
                  />
                </Form.Item>
              </Input.Group>
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              name="reference"
              label="Reference"
              // rules={[yupSync]}
              required
            >
              <Input
                disabled
                placeholder="Please Enter Reference Name"
              />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              label="To (Callee)"
              required
            >
              <Input.Group compact>
                <Form.Item
                  // name="to_mobile"
                  //rules={[yupSync]}
                   noStyle
                >
                  <Input
                    className='tele_country_code_block'
                    disabled
                    defaultValue="91"
                  />
                </Form.Item>
                <Form.Item
                  name="to_mobile"
                  // rules={[yupSync]}
                  noStyle

                >
                  <Input
                    className='tele_mobile_number_field'
                    disabled
                    placeholder="Please Enter Mobile Number"
                    onChange={(e) => handleNumberFields(e, "customer_detail")}
                  />
                </Form.Item>
              </Input.Group>
            </Form.Item>
          </Col>
        
          <Col className='tele_col'>
            <Button type="primary" htmlType="submit">
              <div className='tele_div_icon'>
                <IoCallOutline className='tele_div_icon_two' />
                <span>Call</span>
              </div>
            </Button>
          </Col>
        </Row>
        <Space
          direction="horizontal"
          align="center"
          className='tele_button_submit'
        >
            <Button type="primary" htmlType="submit">
                        Submit
                    </Button>
          <Button onClick={closeForm}>Cancel</Button>
        </Space>
      </Form>
    </>
  );
}

export default TeleVerificationCallDetailsForm;
