import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Upload,
} from "antd";
import RViewerJS from "viewerjs-react";
import { UploadOutlined, DeleteOutlined } from "@ant-design/icons";

import React from "react";

import * as Yup from "yup";
import IndianNumberFormat from "../../../../../utils/indianNumberFormat/IndianNumberFormat";
const { Option } = Select;

const validationSchema = Yup.object().shape({
  insured_customer_name: Yup.string().required(
    "Insured Customer Name is required"
  ),
  branch_address: Yup.string().required("Branch Address is required"),
  property_type: Yup.string().required("Property Type is required"),
  occupation: Yup.string().required("Occupation is required"),
  city: Yup.string().required("City is required"),
  state: Yup.string().required("State is required"),
  pincode: Yup.string().required("Pincode is required"),
  salutation: Yup.string().required("Salutation is required"),
  mobile: Yup.string().required("Mobile is required"),
  gender: Yup.string().required("Gender is required"),
  email: Yup.string().required("Email is required"),
  address_line_1: Yup.string()
    .required("Address Line 1 is required")
    .max(225, "Address Line 1 cannot exceed 225 characters"),
  address_line_2: Yup.string()
    .required("Address Line 2 is required")
    .max(225, "Address Line 2 cannot exceed 225 characters"),
  nationality: Yup.string().required("Nationality is required"),
  date_of_birth: Yup.date().required("Date Of Birth is required"),
  pan_no: Yup.string().required("PAN Number is required"),
  height: Yup.string()
    .required("Height is required")
    .matches(/^\d+$/, "Height must contain only numbers"),
  weight: Yup.string()
    .required("Weight is required")
    .matches(/^\d+$/, "Weight must contain only numbers"),
  loan_amount: Yup.number().required("Loan Amount is required"),
  loan_tenure: Yup.number().required("Tenure of loan is required"),
  policy_term: Yup.string()
    .required("Policy Term is required")
    .test(
      "max-value",
      "Policy Term can not be more than 360",
      (value) => parseFloat(value) <= 360
    ), // Ensures the number is <= 360

  sum_assured: Yup.string().required("Sum Assured is required"),
  total_premium_incl_gst: Yup.string().required(
    "Total Premium Inclusive Of GST is required"
  ),
  emi_amount: Yup.string().required("EMI Amount is required"),
  monthly_income: Yup.string().required("Monthly income is required"),
  age_proof: Yup.string().required("Age Proof is required"),
  nominee_insured_customer_name: Yup.string().required(
    "Customer Name is required"
  ),
  nominee_nationality: Yup.string().required("Nationality is required"),
  nominee_email: Yup.string().required("Email is required"),
  nominee_mobile: Yup.string()
    .required("Mobile Number is required")
    .matches(/^\d{10}$/, "Nominee Contact Number must be exactly 10 digits"),
  nominee_date_of_birth: Yup.date()
    .max(new Date(), "Date of Birth cannot be a today's date or future date")
    .required("Date Of Birth is required"),
  nominee_gender: Yup.string().required("Gender is required"),
  nominee_address_line_1: Yup.string()
    .required("Address Line 1 is required")
    .max(225, "Address Line 1 cannot exceed 225 characters"),
  nominee_address_line_2: Yup.string()
    .required("Address Line 2 is required")
    .max(225, "Address Line 2 cannot exceed 225 characters"),
  relation_with_assured_person: Yup.string().required("Relation is required"),
  nominee_city: Yup.string().required("City is required"),
  nominee_state: Yup.string().required("State is required"),
  nominee_pincode: Yup.string().required("Pincode is required"),
  nominee_relation: Yup.string().required("Relation is required"),
  pan_number: Yup.string().required("PAN Number is required"),
  premium_amount: Yup.string().required("Total Premium Amount is required"),
  applicant_type: Yup.string().required("Applicant Type is required"),
  nominee_applicant_type: Yup.string().required(
    "Nominee Applicant Type is required"
  ),
  nominee_occupation: Yup.string().required("Nominee Occupation is required"),
  appointee_name: Yup.string().required("Appointee Name is required"),
  appointee_date_of_birth: Yup.date()
    .max(new Date(), "Date of Birth cannot be today's date or a future date")
    .required("Appointee Date Of Birth is required"),
  appointee_gender: Yup.string().required("Appointee Gender is required"),
  appointee_contact_number: Yup.string()
    .required("Appointee Contact Number is required")
    .matches(/^\d{10}$/, "Appointee Contact Number must be exactly 10 digits"),
  apointee_relationship_with_life_to_be_assured: Yup.string().required(
    "Appointee Relationship is required"
  ),
});

const yupSync = {
  async validator({ field }, value) {
    await validationSchema.validateSyncAt(field, { [`${field}`]: value });
  },
};

const HDFCCoverage = ({
  loanData,
  handleNumberFields,
  filterOption,
  propertyType,
  fileList,
  handleUploadChange,
  handleBeforeUpload,
  image,
  existingImages,
  setDeleteUploadPicture,
  setOpenUploadModal,
  pdf_image,
  setDeletePicture,
  openUploadModal,
  confirmDelete,
  openModal,
  deletePropertyPictures,
  setOpenModal,
}) => {
  return (
    <>
      <div className="drawer-insurance-space">
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item label="Loan Amount (₹)" required>
              <Input.Group compact>
                <Form.Item noStyle>
                  <Input
                    className="hdfc_country_code_block"
                    disabled
                    defaultValue="₹"
                  />
                </Form.Item>
                <Form.Item name="loan_amount" noStyle required>
                  <Input
                    className="hdfc_country_code_input"
                    placeholder={`${loanData.approved_amount}`}
                    value={`${loanData.approved_amount}`}
                    disabled
                  />
                </Form.Item>
              </Input.Group>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="loan_tenure"
              label="Tenure of loan (in months)"
              required
            >
              <Input placeholder={`${loanData.approved_tenure}`} disabled />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="policy_term"
              label="Policy Term (in months)"
              rules={[yupSync]}
              required
            >
              <Input
                onChange={(e) => handleNumberFields(e, "policy_term")}
                placeholder="Enter policy term"
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16} className="hdfccoberage_row_input">
          <Col span={8}>
            <Form.Item label="Sum Assured (₹)" required>
              <Input.Group compact>
                <Form.Item noStyle>
                  <Input
                    className="hdfc_country_code_block"
                    disabled
                    defaultValue="₹"
                  />
                </Form.Item>
                <Form.Item
                  name="sum_assured"
                  rules={[yupSync]}
                  noStyle
                  required
                >
                  <Input
                    className="hdfc_country_code_input"
                    placeholder="Please Enter Sum Assured"
                    onChange={(e) => handleNumberFields(e, "sum_assured")}
                  />
                </Form.Item>
              </Input.Group>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Total Premium Amount (Including GST)" required>
              <Input.Group compact>
                <Form.Item noStyle>
                  <Input
                    className="hdfc_country_code_block"
                    disabled
                    defaultValue="₹"
                  />
                </Form.Item>
                <Form.Item
                  name="premium_amount"
                  rules={[yupSync]}
                  noStyle
                  required
                >
                  <Input
                    className="hdfc_country_code_input"
                    placeholder="Please Enter Total Premium Amount"
                    onChange={(e) => handleNumberFields(e, "premium_amount")}
                  />
                </Form.Item>
              </Input.Group>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="pan_number"
              label="Pan Number"
              rules={[yupSync]}
              required
            >
              <Input placeholder="Enter pan number" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16} className="hdfccoberage_row_input">
          <Col span={8}>
            <Form.Item
              name="property_type"
              label="Property Type"
              rules={[yupSync]}
              required
            >
              <Select
                placeholder="Select Property Type"
                showSearch
                optionFilterProp="children"
                filterOption={filterOption}
                allowClear
              >
                {propertyType?.map((locations, index) => (
                  <Option key={locations.id} value={locations.id}>
                    {locations.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="monthly_income"
              label="Monthly Income"
            >
              <Input
                placeholder={`${IndianNumberFormat(
                  loanData?.appraised_monthly_income
                )}`}
                value={`${loanData.appraised_monthly_income}`}
                disabled
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="emi_amount"
              label="EMI Amount"
              rules={[yupSync]}
              required
            >
              <Input
                placeholder="Please Enter EMI amount"
                onChange={(e) => handleNumberFields(e, "emi_amount")}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16} className="hdfccoberage_row_input">
          <Col span={24}>
            <Form.Item name="documents" label="Documents">
              <Upload
                single
                fileList={fileList}
                onChange={handleUploadChange}
                beforeUpload={handleBeforeUpload}
                showUploadList={false}
              >
                <Button
                  type="primary"
                  icon={<UploadOutlined />}
                  className="seletc_hdfc"
                >
                  Upload Document
                </Button>
              </Upload>
            </Form.Item>
          </Col>
        </Row>

        <div className="hdfccoberage_image">
          <div className="hdfccoberage_image_firts">
            {image?.map((picture, index) => (
              <div key={index} className="hdfccoberage_picture_div">
                <RViewerJS>
                  <img
                    src={picture.file_path}
                    alt=""
                    className="hdfccoberage_aplicatin_thumb"
                  />
                </RViewerJS>
                <div
                  className="hdfccoberage_delete"
                  onClick={() => {
                    setDeleteUploadPicture(picture);
                    setOpenModal(true);
                  }}
                >
                  <DeleteOutlined />
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="image-gallery">
          {existingImages?.map((picture, index) => (
            <div key={index} className="image-container">
              {picture.file_type === "application/pdf" ? (
                <img
                  src={pdf_image}
                  width="100px"
                  height="100px"
                  className="hdfccoberage_aplicatin_pdf"
                  alt="PDF Preview"
                />
              ) : (
                <RViewerJS
                  options={{
                    url: (image) => image.getAttribute("data-original"),
                  }}
                >
                  <img
                    src={picture.thumb_document}
                    data-original={picture.document}
                    alt="Preview"
                    className="hdfccoberage_aplicatin_thumb"
                  />
                </RViewerJS>
              )}

              <div
                className="delete-icon"
                onClick={() => {
                  setDeletePicture(picture);
                  setOpenUploadModal(true);
                }}
              >
                <DeleteOutlined />
              </div>
            </div>
          ))}
        </div>
      </div>

      <Modal
        title="Confirm Delete"
        open={openUploadModal}
        onOk={deletePropertyPictures}
        onCancel={() => {
          setOpenUploadModal(false);
          setDeleteUploadPicture(null);
        }}
        okType="danger"
      >
        <p>{`Are you sure you want to delete this upload image?`}</p>
      </Modal>
      <Modal
        title="Confirm Delete"
        open={openModal}
        onOk={confirmDelete}
        onCancel={() => {
          setOpenModal(false);
          setDeletePicture(null);
        }}
        okType="danger"
      >
        <p>Are you sure you want to delete?</p>
      </Modal>
    </>
  );
};

export default HDFCCoverage;
