import { Button, Col, Divider, Form, Row, Select, Space, message } from "antd";
import TextArea from "antd/es/input/TextArea";
import React, { useEffect, useState } from "react";
import { PlusOutlined, DeleteOutlined } from "@ant-design/icons";
import { useParams } from "react-router-dom";
import { decrypt } from "../../../../utils/cryptoUtils/cryptoUtils";
import ErrorMessage from "../../../../utils/errorHandling/ErrorMessage";
import {
  createSendBackQuery,
  getAllSubSection,
  getAllUsers,
  getCustomerListByLoanIdService,
} from "../services/sendBackService";
import * as Yup from "yup";
import "../styles/forwoardForm.css"

const validationSchema = Yup.object().shape({
  assign_to: Yup.string().required("Assign to is required"),
  stage: Yup.string().required("Stage is required"),
  customer_detail: Yup.string().required("Customer is required"),
});

const yupSync = {
  async validator({ field }, value) {
    await validationSchema.validateSyncAt(field, { [field]: value });
  },
};

const SendBackForm = ({
  closeForm,
  stage_code,
  form,
  toggleTableRefreshData,
  setSubmitStageBar
}) => {
  const { encrypted_loan_id } = useParams();
  const [subSections, setSubSections] = useState(null);
  const [users, setUsers] = useState(null);
  const [loading, setLoading] = useState(false);
  const [customerList, setCustomerList] = useState([]);

  let loan_id = null;
  try {
    loan_id = decrypt(encrypted_loan_id);
  } catch (error) {
    message.error("Invalid Loan ID");
  }
  const stage_id = stage_code?.id;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const usersresponse = await getAllUsers(loan_id);
        setUsers(usersresponse.data);
        const response = await getAllSubSection(stage_id);
        setSubSections(response.data);

      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };
    const getCustomerList = async () => {
      try {
        const response = await getCustomerListByLoanIdService(loan_id);
        setCustomerList(response.data.customer_list);
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };
    getCustomerList();
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, toggleTableRefreshData]);

  const handleSubmit = async (values) => {
    setLoading(true);
    try {
      const payload = {
        loan_detail: loan_id,
        loan_stage: stage_code?.id,
        // stage: stage_code?.stage_code,
        ...values,
      };
      const response = await createSendBackQuery(payload);
      if ((response.status = 200 && response.success)) {
        message.success("Query send back successfully");
        setSubmitStageBar();
        closeForm();
        form.resetFields();
        toggleTableRefreshData();
      }
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    } finally {
      setLoading(false)
    }
  };
  useEffect(() => {

    form.resetFields(['stage']);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stage_code]);

  return (
    <Form
      layout="vertical"
      form={form}
      onFinish={handleSubmit}
      initialValues={{
        stage: stage_code?.stage_code,
        queries: [{}]
      }} // Initialize Form.List with one empty object
    >
      <div
        className="forworad_container"
      >
        <div >
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="assign_to"
                label="Assign To"
                rules={[yupSync]}
                required

              >
                <Select

                  mode="single"
                  placeholder="Please select"
                  required={true}
                  allowClear

                  showSearch
                  disabled={true}
                  filterOption={(input, option) =>
                    option?.children
                      ?.toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {users?.map((user) => (
                    <Select.Option key={user.id} value={user.id}>
                      {user?.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="stage"
                label="Stage"
                rules={[yupSync]}
                required

              >
                <Select
                  mode="single"
                  placeholder="Please select"
                  required={true}
                  allowClear

                  showSearch
                  disabled={true}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Select.Option
                    key={stage_code.stage_code}
                    value={stage_code.stage_code}
                  >
                    {stage_code?.stage}
                  </Select.Option>

                </Select>
              </Form.Item>
            </Col>
            {stage_code?.stage === "PD" && (
              <Col span={12}>
                <Form.Item
                  name="customer_detail"
                  label="Customer"
                  required
                  rules={[yupSync]}

                >
                  <Select
                    placeholder="Please Select"
                    showSearch
                    allowClear

                  >
                    {customerList
                      // Filter out the "Co-Applicant" option
                      .map((type) => (
                        <Select.Option key={type.id} value={type.id}>
                          {type.name}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
            )}
          </Row>

          <Form.List name="queries">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, fieldKey, ...restField }, index) => (
                  <div
                    key={key}

                  >
                    {index > 0 && (
                      <Button
                        onClick={() => remove(name)}
                        icon={<DeleteOutlined />}
                        className="delete_button_sendForm"
                      />
                    )}
                    <div >
                      <Row gutter={16}>
                        <Col span={12}>
                          <Form.Item
                            {...restField}
                            name={[name, "sub_section"]}
                            fieldKey={[fieldKey, "sub_section"]}
                            label="Sub Section"
                            rules={[
                              {
                                required: true,
                                message: "Sub section is required",
                              },
                            ]}
                            required
                          >
                            <Select
                              mode="single"
                              placeholder="Please select"
                              required={true}
                              allowClear

                              showSearch
                              filterOption={(input, option) =>
                                option?.children
                                  ?.toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              {subSections?.map((subsection) => (
                                <Select.Option
                                  key={subsection.id}
                                  value={subsection.code}
                                >
                                  {subsection?.display_name}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            {...restField}
                            name={[name, "query"]}
                            fieldKey={[fieldKey, "query"]}
                            label="Write Your Query"
                            // sonChange={(e) => handleInput(e, "query",fieldKey)}
                            rules={[
                              { required: true, message: "Query is required" },
                              {
                                min: 2,
                                message:
                                  "Query must be between 2 and 1000 characters",
                              },
                              {
                                max: 1000,
                                message:
                                  "Query must be between 2 and 1000 characters",
                              },
                            ]}
                            required
                          >
                            <TextArea placeholder="Enter Query" />
                          </Form.Item>
                        </Col>
                      </Row>
                    </div>
                  </div>
                ))}
                <Button
                  onClick={() => add()}
                  icon={<PlusOutlined />}

                >
                  Add
                </Button>
              </>
            )}
          </Form.List>
        </div>
      </div>
      <Divider />
      <Space
        direction="horizontal"
        align="center"
        className="button_forword_form"

      >
        <Button type="primary" htmlType="submit" loading={loading}>
          Submit
        </Button>
        <Button onClick={closeForm}>Cancel</Button>
      </Space>
    </Form>
  );
};

export default SendBackForm;
