import React, { useEffect, useState } from "react";
import { getBasicDetailsByIdService } from "../services/basicServices";
import "../styles/basicDetails.css";
import { Drawer, message } from "antd";
import Header from "../../../../layout/views/Header";
import BasicDetailsForm from "./BasicDetailsForm";
import "../../../customerTabDetails/style/customerStyle.css";
import Loading from "../../../../../utils/loading/Loading";
import ErrorMessage from "../../../../../utils/errorHandling/ErrorMessage";
import IndianNumberFormat from "../../../../../utils/indianNumberFormat/IndianNumberFormat";
import isModifiedAfterCreated from "../../../../../utils/history/date";
import { useParams } from "react-router-dom";
import HistoryTable from "../../../history/views/HistoryTable";
import ConditionalRender from "../../../../../utils/authorization/AuthorizeComponent";
import { useSelector } from "react-redux";
import "../../../../commonCss/commonStyle.css";
import EditButton from "../../../../../utils/editButton/EditButton";
import { decrypt } from "../../../../../utils/cryptoUtils/cryptoUtils";
import CustomNoData from "../../../../../utils/noDataIcon/CustomNoData";

const BasicDetails = ({ onRefresh }) => {
  const { encrypted_loan_id } = useParams();
  const [basicData, setBasicData] = useState(null);
  const [open, setOpen] = useState(false);
  const [createdById, setCreatedById] = useState("");
  const [refreshData, setRefreshData] = useState(false);
  const [loading, setLoading] = useState(true);
  const { user_data } = useSelector((state) => state.user);
  const toggleRefreshData = () => {
    setRefreshData((prev) => !prev);
  };

  let loan_id = null;
  try {
    loan_id = decrypt(encrypted_loan_id);
  } catch (error) {
    message.error("Invalid Loan ID");
  }

  useEffect(() => {
    if (loan_id) {
      const fetchData = async () => {
        try {
          const data = await getBasicDetailsByIdService(loan_id);
          setBasicData(data.data);
          setCreatedById(data.data?.id);
          setLoading(false);
        } catch (error) {
          setLoading(false);
          message.error(
            ErrorMessage(error?.response?.status, error?.response?.data?.message)
          );
        }
      };
      fetchData();
    } else {
      setLoading(false); // Prevent infinite loading if loan_id is null
    }
  }, [loan_id, refreshData]);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const fieldNameMap = {
    "application_number": "Application Number",
    "loan_account_number": "Loan Account Number",
    "requested_amount": "Requested Amount",
    "requested_tenure": "Requested Tenure  (In Year)",
    "scheme": "Scheme",
    "loan_type": "Loan Type",
    "loan_stage": "Loan Stage",
    // "approved_amount": "Approved Amount",
    // "approved_tenure": "Approved Tenure   (In Year)",
    // "approved_roi": "Approved ROI",
    "bd_modified_at": "Business Modified Date",
    "location": "Location",
    // "loan_purpose": "Loan Purpose",
    "emi_due_date": "EMI Due Date",
    "processing_fee": "Processing Fee",
    "interest_type": "Interest Type",
    "product": "Product",
    "product_end_use": "End Use",
    "expected_roi": "Expected Roi"
  };

  return (
    <>
      <div>
        <div className="fade-in">
          {loading ? (
            <Loading loading={loading} />
          ) : basicData ? (
            <>
              <div className="basic_edit_container fade-in">
                {basicData?.created_at &&
                  basicData?.modified_at &&
                  isModifiedAfterCreated(
                    basicData?.created_at,
                    basicData?.modified_at
                  ) && (
                    <HistoryTable
                      id={(basicData?.id)}
                      bench_id={"871f36a22c08d5b1"}
                      fieldNameMap={fieldNameMap}
                    />
                  )}
                <ConditionalRender
                  userPermissions={user_data?.permissions}
                  requiredPermissions={["EDITLOAN"]}
                >
                  <div
                    className="tab_form_sub_edit edit_button"
                    onClick={showDrawer}
                  >
                    <EditButton />
                  </div>
                </ConditionalRender>
              </div>
              <div className="Basic_details_main_container">
                <Drawer
                  title={
                    <Header title="Edit" onClose={onClose} name="Basic Details" />
                  }
                  width={970}
                  onClose={onClose}
                  open={open}
                  closable={false}
                  refreshData={toggleRefreshData}
                >
                  <BasicDetailsForm
                    open={open}
                    closeForm={onClose}
                    id={createdById}
                    refreshData={toggleRefreshData}
                    onRefresh={onRefresh}
                  />
                </Drawer>

                <div className="Basic_basic_card">
                  <div className="basic_basic_card_content">
                    <div className="basic_basic_details_label">Application Number</div>
                    <div className="basic_basic_details_data">
                      {basicData?.application_number || '-'}
                    </div>
                  </div>
                  <div className="basic_basic_card_content">
                    <div className="basic_basic_details_label">Loan Account Number</div>
                    <div className="basic_basic_details_data">
                      {basicData.loan_account_number || '-'}
                    </div>
                  </div>
                  <div className="basic_basic_card_content">
                    <div className="basic_basic_details_label">Loan Type</div>
                    <div className="basic_basic_details_data">{basicData?.loan_type?.display_name || '-'}</div>
                  </div>
                  <div className="basic_basic_basic_card_content">
                    <div className="basic_basic_details_label">Loan Status</div>
                    <div className="basic_basic_details_data">{basicData?.loan_status?.display_name || '-'}</div>
                  </div>
                </div>

                <div className="Basic_basic_card">
                  <div className="basic_basic_card_content">
                    <div className="basic_basic_details_label">Location</div>
                    <div className="basic_basic_details_data">
                      {basicData?.location?.display_name || '-'}
                    </div>
                    

                  </div>
                  <div className="basic_basic_card_content">
                    <div className="basic_basic_details_label">Spoc Location</div>
                    <div className="basic_basic_details_data">
                      {basicData?.spoc_location?.display_name || '-'}
                    </div>
                  </div>
                
                  <div className="basic_basic_card_content">
                    <div className="basic_basic_details_label">Requested Tenure (In Month)</div>
                    <div className="basic_basic_details_data">{IndianNumberFormat(basicData?.requested_tenure || '-')} </div>
                  </div>

                  <div className="basic_basic_basic_card_content">
                    <div className="basic_basic_details_label">End Use</div>
                    <div className="basic_basic_details_data">
                      {basicData?.product_end_use?.display_name || '-'}
                    </div>
                  </div>
                </div>

                <div className="Basic_basic_card">
                  <div className="basic_basic_card_content">
                    <div className="basic_basic_details_label">Scheme</div>
                    <div className="basic_basic_details_data">
                      {basicData?.scheme?.display_name || '-'}</div>
                  </div>

                  <div className="basic_basic_card_content">
                    <div className="basic_basic_details_label">Requested Amount</div>
                    <div className="basic_basic_details_data">₹ {IndianNumberFormat(basicData?.requested_amount || '-')}
                    </div>
                  </div>

                    <div className="basic_basic_card_content">
                    <div className="basic_basic_details_label">Product</div>
                    <div className="basic_basic_details_data">
                      {basicData?.product?.display_name || '-'}
                    </div>
                  </div>


                  <div className="basic_basic_basic_card_content">
                    <div className="basic_basic_details_label">Expected ROI</div>
                    <div className="basic_basic_details_data">
                      {basicData?.expected_roi ? `${basicData.expected_roi} %` : '-'}
                    </div>
                  </div>
                </div>


                <div className="Basic_basic_card">
                <div className="basic_basic_basic_card_div_content">
                    <div className="basic_basic_details_label">EMI Due Date</div>
                    <div className="basic_basic_details_data">
                      {basicData?.emi_due_date || '-'}
                    </div>
                  </div>
                  <div className="basic_basic_basic_card_div_content">
                    <div className="basic_basic_details_label">Interest Type</div>
                    <div className="basic_basic_details_data">
                      {basicData?.interest_type || '-'}
                    </div>
                  </div>
                  <div className="basic_basic_basic_card_div_content">
                    <div className="basic_basic_details_label">Clss</div>
                    <div className="basic_basic_details_data">
                      {basicData?.clss ? 'true' : 'false'}
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) :
            (
              <div className="no_data_found_message">
                <CustomNoData />
              </div>
            )}
        </div>
      </div>
    </>
  );
};

export default BasicDetails;
