import { axiosRequest } from "../../../../../utils/api/axiosRequest";

export const getBasicDetailsByIdService = (loan_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/loan/loan-detail/${loan_id}`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const updateBasicDetailsByIdService = (id, data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.patch(`/api/v1/loan/loan-detail/${id}`, data);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}



export const getListSchemeService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`api/v1/master/scheme/list`);  
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}



export const listLoanPurposeService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/master/loan-purpose/list`);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const listLoanStatusService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/loan/loan-status/list`);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};


export const listLoanTypeService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/loan/loan-type/list`);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};
export const listLocationService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/master/locations/list`);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const getvendorstageByIdService = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/loan/initiate-stage/vendor-stage-history/${id}`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}