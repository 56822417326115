
import { axiosRequest } from "../../../../../utils/api/axiosRequest";

export const getDocumentDetailsByIdService = (loan_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/customer/document-detail/document-detail/${loan_id}`);
      return resolve(response.data)            
    } catch (error) {
      return reject(error)
    }
  })
}

 
export const listDocumentTypeService = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/master/document-category/kyc-category-type/${id}`);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const listCategoryTypeService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/master/document-category/list`);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const uploadDocumentServiceById = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.post(`api/v1/customer/document-detail/`, data);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const getDocumentDataServiceById = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.patch(`/api/v1/customer/document-detail/${id}`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const editDocumentServiceById = (id, data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.patch(`api/v1/customer/document-detail/${id}`, data);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}


export const deleteDocumentServiceById = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.post(`/api/v1/customer/document-detail/document-detail-category-delete/`, data);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}
