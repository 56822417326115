import React, { useState, useEffect } from 'react';
import { getFeeDetailsByIdService } from '../service/feeDetailsService';
import { message } from "antd";
import '../styles/feeDetails.css';
import { useParams } from "react-router-dom";
import { decrypt } from "../../../../../utils/cryptoUtils/cryptoUtils";
import ErrorMessage from "../../../../../utils/errorHandling/ErrorMessage";
import Loading from "../../../../../utils/loading/Loading";
import CustomNoData from '../../../../../utils/noDataIcon/CustomNoData';

const FeeDetails = () => {
  const { encrypted_loan_id } = useParams();
  const [feeData, setFeeData] = useState(null);
  const [loading, setLoading] = useState(true);

  let loan_id = null;
  try {
    loan_id = decrypt(encrypted_loan_id);
  } catch (error) {
    message.error("Invalid Loan ID");
  }


  useEffect(() => {
    if (loan_id) {
      const fetchData = async () => {
        try {
          const data = await getFeeDetailsByIdService(loan_id);
          setFeeData(data?.data);
          setLoading(false);
        } catch (error) {
          setLoading(false);
          message.error(
            ErrorMessage(error?.response?.status, error?.response?.data?.message)
          );
        }
      };
      fetchData();
    }
    else {
      setLoading(false);
    }
  }, [loan_id]);


  return (
    <>
      <div>
        <div className="fade-in">
          {loading ? (
            <Loading loading={loading} />
          ) : feeData && Object.keys(feeData).length === 0 ? (
            <div className="no_data_found_message">
              <CustomNoData />
            </div>
          ) :
            (
              <>

                <div className="Basic_basic_card">
                  <div className="basic_basic_card_content">
                    <div className="basic_basic_details_label">Type Of IMD</div>
                    <div className="basic_basic_details_data">
                      {feeData?.charges?.[0]?.charges_type?.display_name || '-'}
                    </div>
                  </div>
                  <div className="basic_basic_card_content">
                    <div className="basic_basic_details_label">Instrument Date</div>
                    <div className="basic_basic_details_data">
                      {feeData?.instrument_date || '-'}
                    </div>
                  </div>
                  <div className="basic_basic_card_content">
                    <div className="basic_basic_details_label">Status</div>
                    <div className="basic_basic_details_data">{feeData?.reciept_status || '-'}</div>
                  </div>
                  <div className="basic_basic_basic_card_content">
                    <div className="basic_basic_details_label">Non-Refundable (Commitment Fee)</div>
                    <div className="basic_basic_details_data">-</div>
                  </div>

                </div>
                <div className="Basic_basic_card">
                  <div className="basic_basic_card_content">
                    <div className="basic_basic_details_label">Bank Name</div>
                    <div className="basic_basic_details_data">
                      {feeData?.bank?.display_name || '-'}
                    </div>
                  </div>
                  <div className="basic_basic_card_content">
                    <div className="basic_basic_details_label">Batch ID</div>
                    <div className="basic_basic_details_data">
                      {feeData?.bank_branch?.ifsc || '-'}
                    </div>
                  </div>
                  {/* <div className="basic_basic_card_content">
                    <div className="basic_basic_details_label">Status</div>
                    <div className="basic_basic_details_data">{feeData?.reciept_status || '-'}</div>
                  </div> */}
                  <div className="basic_basic_basic_card_content">
                    <div className="basic_basic_details_label">Cheque/DD/UTR Number</div>
                    <div className="basic_basic_details_data">{feeData?.utr_cheque_number || '-'}</div>
                  </div>
                  <div className="basic_basic_card_content">
                    <div className="basic_basic_details_label">Branch Name</div>
                    <div className="basic_basic_details_data">
                      {feeData?.bank_branch?.display_name || '-'}
                    </div>
                  </div>
                </div>
                <div className="Basic_basic_card">

                  <div className="basic_basic_card_content">
                    <div className="basic_basic_details_label">Account Number</div>
                    <div className="basic_basic_details_data">
                      {feeData?.account_number || '-'}
                    </div>
                  </div>
                  {/* <div className="basic_basic_card_content">
                    <div className="basic_basic_details_label">Status</div>
                    <div className="basic_basic_details_data">{feeData?.reciept_status || '-'}</div>
                  </div> */}
                  <div className="basic_basic_basic_card_content">
                    <div className="basic_basic_details_label">Deposit Bank Name</div>
                    <div className="basic_basic_details_data"> {feeData?.deposit_account?.display_name || '-'}</div>
                  </div>

                </div>



              </>
            )}
        </div>
      </div>
    </>
  );
}

export default FeeDetails;
