import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Space,
  message,
  DatePicker,
  Select,
} from "antd";
import React, { useEffect, useState } from "react";

import {
  createPartnerService,
  getPartnerDetailsyIdService,
  listRelationType,
  updatePertnerDetailsByIdService,
} from "../services/partnerservices";
import ErrorMessage from "../../../../../utils/errorHandling/ErrorMessage";
import dayjs from "dayjs";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { addNewCustomerPartner } from "../../../../../redux/salesFormSlice";
import { getPersonaByIdService } from "../../personalDetails/services/personalDetailsServices";


const validationSchema = Yup.object().shape({
  first_name: Yup.string().required("First name is required"),
  middle_name: Yup.string().required("Middle Name is required"),
  last_name: Yup.string().required("Last Name is required"),
  relationship: Yup.string().required("Relation is required"),
  date_of_birth: Yup.date()
    .required("Date of birth is required")
    .test(
      "valid-age",
      "Customer must be at least 18 years old",
      function (value) {
        const givenDate = dayjs(value); // Convert the given date to dayjs object for comparison
        const currentDate = dayjs(); // Get the current date using dayjs
        const eighteenYearsAgo = currentDate.subtract(18, "year"); // Calculate the date 18 years ago

        return givenDate.isValid() && givenDate.isBefore(eighteenYearsAgo);
      }
    )
    .test(
      "not-future-date",
      "Date of birth cannot be a future date",
      function (value) {
        return value ? dayjs(value).isBefore(dayjs()) : true;
      }
    )
    .label("Date Of Birth"),

  mobile_country_code: Yup.string()
    // .min(2, "Country Code must be exact 2 digits")
    .max(2, "Country code must be exact 2 digits")
    .matches(/^[0-9 ]{2,}$|^$/, "Country code must be contain only 2 digits"),
  mobile_number: Yup.string()
    .required("Mobile number is required")
    .matches(/^[0-9]+$/, "Mobile number must contain only digits")
    .min(10, "Mobile number must be exact 10 digits")
    .max(10, "Mobile number must be exact 10 digits")
    .label("Mobile number"),
});

const yupSync = {
  async validator({ field }, value) {
    await validationSchema.validateSyncAt(field, { [field]: value });
  },
};

const PartnerDetailsForm = ({ open, closeForm, refreshData, id, customer_id ,activePartnerKey ,activeCustomer ,isSalesForm }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch()
  const [companyId, setCompanyId] = useState(null);

  // const [, setcomponyList] = useState([]);
  const [relationType, setRelationType] = useState([]);


  useEffect(() => {
    const getPersonalData = async () => {
      const response = await getPersonaByIdService(customer_id);
      setCompanyId(response?.data?.company_detail)

    }

    getPersonalData()
  }, [customer_id]);


  const getPartnerDetailsById = async () => {

    if (id) {
      try {
        const response = await getPartnerDetailsyIdService(id);
        let {
          first_name,
          middle_name,
          last_name,
          date_of_birth,
          relationship,
          mobile_number,

          // company_detail,
        } = response?.data;
        date_of_birth = dayjs(date_of_birth, "YYYY-MM-DD");

        form?.setFieldsValue({
          first_name,
          middle_name,
          last_name,
          date_of_birth,
          relationship,
          mobile_number,
          // company_detail: company_detail?.id,
          // company_detail: company_name?.id,
        });
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    }
  };

  const handleNumberFields = (e, field) => {
    let newValue = e.target.value.replace(/\D/g, "");
    newValue = newValue.slice(0, 10);
    form.setFieldsValue({ [field]: newValue });
  };

  const getAllRelationType = async () => {
    try {
      const response = await listRelationType();
      setRelationType(response.data);
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };



  const disabledDate = (current) => {
    // Disable dates after today
    return current && current > dayjs().endOf("day");
  };

  const handleSubmit = async (values) => {
    try {
      values.date_of_birth = values.date_of_birth.format("YYYY-MM-DD");
      if (values.mobile_number) {
        values.mobile_country_code = "91";
      }

      if (id) {
        const response = await updatePertnerDetailsByIdService(id, values);
        if (response && response?.data) {
          if (response?.status === 200 && response?.success) {
            message.success("Partner details updated successfully");
            if (typeof isSalesForm === "undefined") {
              refreshData();
              closeForm();
            }
          }
        }
      } else {
        values.company_detail =  companyId;
        const response = await createPartnerService(values);
        if (response?.status === 200 && response?.success) {
          message.success("Partner details successfully created");
          if (
            typeof activePartnerKey !== "undefined" &&
            typeof activeCustomer !== "undefined"
          ) {
            dispatch(
              addNewCustomerPartner({
                activeCustomerKey: activeCustomer,
                activePartnerKey: activePartnerKey,
                id: response?.data?.id,
              })
            );
          }
          setCompanyId(null)
          if (typeof isSalesForm === "undefined") {
            refreshData();
            closeForm();
          }
        }
      }
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };
  const handleInput = (e, field) => {
    const newValue = e.target.value.replace(/[^A-Za-z\s]/g, "");
    form.setFieldsValue({ [field]: newValue });
  };

  useEffect(() => {
    if (id) {
      getPartnerDetailsById();
    } else {
      form?.resetFields();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, id, form]);

  useEffect(() => {
    if (open) {
      getAllRelationType();
    } else {
      form?.resetFields();
    }   
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, id, form]);

  return (
    <div>
      <Form
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
        initialValues={{
          first_name: "",
          middle_name: "",
          last_name: "",
          // date_of_birth: "",
          relationship: null,
          company_detail: "",
          mobile_number: "",
          
        }}
      >
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              name="first_name"
              label="First Name"
              rules={[yupSync]}
              required
            >
              <Input
                placeholder="Please First Name"
                onChange={(e) => handleInput(e, "first_name")}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="middle_name"
              label="Middle Name"
              rules={[yupSync]}
              required
            >
              <Input
                placeholder="Please Enter Middle Name"
                onChange={(e) => handleInput(e, "middle_name")}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="last_name"
              label="Last Name"
              rules={[yupSync]}
              required
            >
              <Input
                placeholder="Please Enter Last Name"
                onChange={(e) => handleInput(e, "last_name")}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              name="date_of_birth"
              label="Date Of Birth"
              rules={[yupSync]}
              required
            >
              <DatePicker
                format="DD-MM-YYYY"
                className="partner_salect_feilad"
                placeholder="Select Date"
                disabledDate={disabledDate}
              />
            </Form.Item>
          </Col>

        <Col span={8}>
          <Form.Item name="relationship" label="Relation" rules={[yupSync]} required>
            <Select placeholder="Please Select" showSearch allowClear>
              {relationType.map((type) => (
                <Select.Option key={type} value={type}>
                  {type}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

          <Col span={8}>
            <Form.Item label={<span>Mobile Number</span>} required>
              <Input.Group compact>
                <Form.Item name="mobile_country_code" noStyle rules={[yupSync]}>
                  <Input defaultValue="91" disabled style={{
                      width: "20%",
                      textAlign: "center",
                      background: "#f1f1f1",
                    }} />
                </Form.Item>
                <Form.Item name="mobile_number" rules={[yupSync]} noStyle>
                  <Input
                   style={{ width: "80%" }}
                    placeholder="Please Enter Mobile Number"
                    onChange={(e) => handleNumberFields(e, "mobile_number")}
                  />
                </Form.Item>
              </Input.Group>
            </Form.Item>
          </Col>
        </Row>

        <Space
          direction="Horizontal"
          align="center"
          className="customer_tabs_form_buttons"
        >
          <Button type="primary" htmlType="submit">
            Save
          </Button>
          <Button onClick={closeForm}>Cancel</Button>
        </Space>
      </Form>
    </div>
  );
};

export default PartnerDetailsForm;
