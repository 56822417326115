import { axiosRequest } from "../../../../utils/api/axiosRequest";
export const getAllAgentService = (page, limit, searchQuery) => {
  return new Promise(async (resolve, reject) => {
    try {
      page = page ? page : 1;
      limit = limit ? limit : 10;

      let path = `/api/v1/master/agent/?page=${page}&limit=${limit}`;
      if (searchQuery.length > 0) {

        path = `/api/v1/master/agent/?search=${searchQuery}&page=${page}&limit=${limit}`;
      }
      const response = await axiosRequest.get(path);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};


export const getAgentByIdService = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/master/agent/${id}`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const createAgentService = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.post("/api/v1/master/agent/", data);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const updateAgentByIdService = (id, data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.patch(`/api/v1/master/agent/${id}`, data);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}


export const deleteAgentByIdService = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.delete(`/api/v1/master/agent/${id}`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const listUserService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get('/api/v1/user/get-user-list');
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};
export const listAgencyService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/master/agency/list`);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const listAgencyTypeService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/master/agency-type/list`);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};


export const fetchAgenciesByType = (code) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/master/agency/agency-list-by-agency-type?code=${code}`);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};


export const listLocationService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get('/api/v1/master/locations/list');
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};