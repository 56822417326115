import React from "react";
import "../styles/overAllStatus.css";

const OverAllStatus = () => {
  return (
    <div className="overall_main_container">
      <div className="overall_status">

        <div className="overall">Overall Status :</div>
        <div className="dedupe_overall_status">Positive</div>

      </div>
      <div className="remark_div">
        <div className="dedupe_remark">Remark :</div>
        <div className="no_match_found">No Match Found.</div>
      </div>
    </div>
  );
};

export default OverAllStatus;
