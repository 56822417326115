import React, { useState, useEffect } from "react";
import "../styles/rcu.css";
import {
  Drawer,
  Form,
  Select,
  Space,
  Button,
  message,
  Modal,
  Table,
} from "antd";
import { UpOutlined, EditOutlined, FileOutlined } from "@ant-design/icons";
import Rcustatusform from "./rcustatusform";
import Conclusions from "./conclusions";
import Completeremark from "./completeremark";
import Header from "../../layout/views/Header";
import Propertypicturesdocuments from "./propertypicturesdocuments";
import {
  patchDedupService,
  deleteDeviationServicebyId,
  getRCUIdbyloanId,
  getRCUdetails,
} from "../services/rcuservices";
import { useParams } from "react-router-dom";
import { decrypt } from "../../../utils/cryptoUtils/cryptoUtils";
import Loading from "../../../utils/loading/Loading";
import pdf from "../assets/Pdf.png"
import RViewerJS from "viewerjs-react";
import EditButton from "../../../utils/editButton/EditButton";
import pdf_image from "../assets/Pdf.png"
import LoanSummaryBlock from "../../../utils/loanSummaryBlock/LoanSummaryBlock";
import CommonDecisionDetails from "../../technicalValuation/detailsTab/decisionDetails/views/CommonDecisionDetails";
import ErrorMessage from "../../../utils/errorHandling/ErrorMessage";

const { Option } = Select;

const Rcu = () => {
  const [isRemarkVisible, setIsRemarkVisible] = useState(true);
  const [isConclusionVisible, setIsConclusionVisible] = useState(true);
  const [isRcuVisible, setIsRcuVisible] = useState(true);
  const [isCustomersVisible, setIsCustomersVisible] = useState(true);
  const [open, setOpen] = useState(false);
  const [, setOpenDeviation] = useState(false);
  const [openConclusions, setOpenConclusions] = useState(false);
  const [openCompleteRemark, setOpenCompleteRemark] = useState(false);
  const [refreshData, setRefreshData] = useState(false);
  const [picture, setPicture] = useState(true);
  const [propertyPictures, setPropertyPictures] = useState();
  const [data, setData] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [, setDeleteValue] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [dedupStatusCheck, setDedupStatusCheck] = useState("");
  const [editingCustomerId, setEditingCustomerId] = useState("");
  const [rcuData, setRCUData] = useState("");
  const [, setRcuid] = useState("")
  const [loanid, setLoanid] = useState("");
  const { encrypted_loan_id, encrypted_stage_id } = useParams();
  const [loading, setLoading] = useState(false);
  const [loadingContent, setLoadingContent] = useState(false);
  const [manualDeviationForm] = Form.useForm(); // Initialize form instance


  useEffect(() => {
    try {
      setLoanid(decrypt(encrypted_loan_id));
    } catch (error) {
      message.error("Invalid Loan ID");
    }
  }, [encrypted_loan_id]);


  let stage_id = null;
  try {
    stage_id = decrypt(encrypted_stage_id);
  } catch (error) {
    message.error("Invalid Loan ID");
  }

  useEffect(() => {
    const getRCUById = async () => {
      try {
        setLoading(true);
        const response = await getRCUIdbyloanId(stage_id);
        if (response.status === 200 && response.success) {
          setData(response.data);
          setRcuid(response.data.rcu_data.id)
          setLoading(false);
        }
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };

    const getRCUData = async () => {
      try {
        setLoadingContent(true)
        const response = await getRCUdetails(stage_id);
        if (response.status === 200 && response.success) {
          setRCUData(response.data);
          setLoadingContent(false)
        }
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };

    if (loanid) {
      getRCUById();
      getRCUData();
    }
  }, [loanid, stage_id, refreshData]);

  const toggleRemarkVisibility = () => setIsRemarkVisible(!isRemarkVisible);
  const toggleConclusionVisibility = () =>
    setIsConclusionVisible(!isConclusionVisible);
  const toggleRcuVisibility = () => setIsRcuVisible(!isRcuVisible);
  const toggleCustomersVisibility = () =>
    setIsCustomersVisible(!isCustomersVisible);
  // const toggleBasicDetailVisibility = () =>
  //   setIsBasicDetailVisible(!isBasicDetailVisible);
  const togglepicturesVisibility = () => setPicture(!picture);
  const toggleRefreshData = () => setRefreshData((prev) => !prev);

  const showDrawer = () => setOpen(true);
  const showConclusionDrawer = () => setOpenConclusions(true);
  const showPropertyPicturesDrawer = () => setPropertyPictures(true);
  const showCompleteRemarkDrawer = () => setOpenCompleteRemark(true);

  const onClose = () => {
    manualDeviationForm.resetFields();
    setOpen(false);
    setOpenCompleteRemark(false);
    setPropertyPictures(false);
    setOpenConclusions(false);
    setOpenDeviation(false);
    setSelectedUser("");
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setDeleteValue(null);
  };

  const handleChange = (value) => setDedupStatusCheck(value);

  const handleSave = async (customerId) => {
    try {
      const response = await patchDedupService(customerId, {
        dedup_check: dedupStatusCheck,
      });
      if (response.status === 200 && response.success) {
        message.success("Dedup status successfully updated");
        setEditingCustomerId(null);
        toggleRefreshData();
      }
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  const handleEditClick = (customerId) => {
    setEditingCustomerId(customerId);
    setDedupStatusCheck("");
  };

  const handleCancelEdit = () => setEditingCustomerId(null);

  const deleteDeviationById = async () => {
    try {
      const response = await deleteDeviationServicebyId(selectedUser);
      if (response.status === 200 && response.success) {
        message.success("Deviation deleted successfully");
        setIsModalOpen(false);
        toggleRefreshData();
      }
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };
  const DeleteModalFooter = ({ onCancel }) => (
    <>
      <Button onClick={onCancel}>Cancel</Button>
      <Button type="primary" danger onClick={deleteDeviationById}>
        Delete
      </Button>
    </>
  );


  const columns = [
    {
      title: "Customer Name",
      dataIndex: "customerName",
      key: "customerName",
      width: 150,
      render: (_, record) =>
        record.customer_detail?.name ? (
          <div className="value wrap-text">{record.customer_detail.name}</div>
        ) : (
          <div className="value">-</div>
        ),
    },
    {

      title: "Residence Address",
      dataIndex: "residenceAddress",
      width: 250,
      key: "residenceAddress",
      render: (_, record) => (
        <div className="value wrap-text">
          {record.customer_detail?.address_details
            ?.filter((detail) => detail.address_type.name === "Permanent Address")
            .map((detail) => detail.address_detail) || "-"}
        </div>
      ),
    },
    {
      title: "Office Address",
      dataIndex: "officeAddress",
      key: "officeAddress",
      width: 250,
      render: (_, record) => (
        <div className="value wrap-text">
          {record.customer_detail?.address_details
            ?.filter((detail) => detail.address_type.name === "Employment Address")
            .map((detail) => detail.address_detail) || "-"}
        </div>
      ),
    },
    {
      title: "Dedupe Check",
      dataIndex: "dedupeCheck",
      width: 200,
      key: "dedupeCheck",
      render: (_, record) =>
        editingCustomerId === record.customer_detail.id ? (
          <Form.Item name="rcu_status">
            <Select
              value={dedupStatusCheck}
              onChange={handleChange}
              className="rcu_input_view_details_select_option"
            >
              <Option value="Yes">Yes</Option>
              <Option value="No">No</Option>
            </Select>
          </Form.Item>
        ) : (
          <div className="rcu_input_view_details_dedup_deading">
            {record.customer_detail?.dedup_check ? "Yes" : "No"}
          </div>
        ),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: 150,
      render: (_, record) =>
        editingCustomerId === record.customer_detail.id ? (
          <div className="rcu_customer_edit_section">
            <p
              className="rcu_customer_save"
              onClick={() => handleSave(record.customer_detail.id)}
            >
              Save
            </p>
            <p
              className="rcu_customer_cancel"
              onClick={handleCancelEdit}
            >
              Cancel
            </p>
          </div>
        ) : (
          <EditOutlined
            className="rcu_input_view_edit_button"
            onClick={() => handleEditClick(record.customer_detail.id)}
          />
        ),
    },
  ];

  return (
    <div>
      {loading ? (
        <div className="rcu_input_view_loading">
          <Loading className="rcu_input_view_loading_size" loading={loading} />
        </div>
      ) :
        (<div>
          <div className="main_technical_report">
            <div>
              <p className="loan_summary_rcu_valuation_report">RCU</p>
            </div>

          </div>
          <LoanSummaryBlock data={rcuData} attachments={rcuData.initiate_stage_attachment_detail} typeCheck="RCU" />
        </div>)}

      {loadingContent ? (
        <div className="rcu_input_view_loading">
          <Loading className="rcu_input_view_loading_size" loading={loading} />
        </div>
      ) : (
        <div>
          <div>

            <div className="rcu_views_main_container" >
              <div className="valuation_details">

                <Space direction="horizontal" align="center" className="legal_input_space">
                  <div className="rcu_views_details_heading">Details</div>
                </Space>
                <div className="rcu_buttons_container">
                  <UpOutlined
                    className={`up-outlined ${isCustomersVisible ? "up-outlined-visible" : "up-outlined-hidden"}`}
                    onClick={toggleCustomersVisibility}
                  />
                </div>
              </div>
              {isCustomersVisible && (
                <div className="customers_content">


                  <Table
                    dataSource={data?.rcu_data?.rcu_customer_detail || []}
                    rowKey={(record) => record.customer_detail?.id}
                    columns={columns}
                    pagination={false}
                    tableLayout="fixed"
                  />
                </div>
              )}
            </div>

          </div>

          <div className="rcu_views_main_container">
            <div className="valuation_details">
              <div className="valuation_details_blue">RCU Status</div>
              <div className="rcu_buttons_container">
                <div onClick={() => { showDrawer(); }} className="rcu_view_button_edit">
                  <EditButton className="img" />
                </div>
                <Drawer
                  title={<Header title="RCU Status" onClose={onClose} />}
                  width={970}
                  refreshData={toggleRefreshData}
                  onClose={onClose}
                  open={open}
                  body-Style={{ paddingBottom: 80 }}
                  closable={false}
                  loanid={loanid}
                >
                  <Rcustatusform
                    refreshData={toggleRefreshData}
                    open={open}
                    closeForm={onClose}
                    loanid={loanid}
                    stage_id={stage_id}
                  />
                </Drawer>
                <UpOutlined
                  className={`up-outlined ${isRcuVisible ? "up-outlined-visible" : "up-outlined-hidden"
                    }`}
                  onClick={toggleRcuVisibility}
                />
              </div>
            </div>

            {isRcuVisible && (
              <div className="rcu_content">
                <div className="rcu_view_gray_table_heading">
                  <div className="rcu_view_table_heading_label">SR.No</div>
                  <div className="rcu_view_table_heading_label">Document</div>
                  <div className="rcu_view_table_heading_label" >Images</div>
                  <div className="rcu_view_table_heading_label">Remarks</div>
                  <div className="rcu_view_table_heading_label">Status</div>
                </div>
                {data?.rcu_data?.rcu_docs?.map((datas, index, array) => (
                  <div
                    className={`rcu_value_property_status ${index < array.length - 1 ? "rcu_value_property_status-border" : ""}`}
                    key={index}
                  >
                    <div className="rcu_value_property_heading"
                    >{index + 1}</div>
                    <div className="rcu_value_property_heading">
                      {datas ? (
                        <div className="value">
                          {datas?.document_detail?.document_type?.dispaly_name || datas?.document_type}
                        </div>
                      ) : (
                        <div className="value">-</div>
                      )}
                    </div>
                    <div className="rcu_value_property_heading">
                      {/* Conditionally render based on file extension */}
                      {datas?.document_detail?.document_path || datas?.image_path ? (
                        <div className="img_document_two">
                          {(() => {
                            const url = datas?.document_detail?.document_path || datas?.image_path;
                            const parts = url.split('/');
                            const fileName = parts.pop().split('?')[0];
                            const fileExtension = fileName.split('.').pop().toLowerCase();

                            if (['png', 'jpg', 'jpeg'].includes(fileExtension)) {
                              return (
                                <>
                                  <RViewerJS>
                                    <img
                                      src={url}
                                      height={45}
                                      width={45}
                                      className="rcu_input_view_status_image"
                                      alt={`Document: ${fileName}`}

                                    />
                                  </RViewerJS>
                                </>
                              );
                            } else if (fileExtension === 'pdf') {
                              return (
                                <>
                                  <img
                                    src={pdf}
                                    alt="PDF"
                                    height={45}
                                    width={45}

                                    className="rcu_input_view_status_image"

                                    onClick={() => window.open(url, "_blank")}
                                  />
                                </>
                              );
                            } else {
                              return (
                                <>
                                  <FileOutlined
                                    className="rcu_input_view_file_name"
                                    title={fileName}
                                  />
                                </>
                              );
                            }
                          })()}
                        </div>
                      ) : (
                        <div className="value">-</div>
                      )}
                    </div>

                    <div className="rcu_value_property_heading">
                      {datas ? (
                        <div className="value">{datas.remark}</div>
                      ) : (
                        <div className="value">-</div>
                      )}
                    </div>
                    <div className="rcu_value_property_heading">
                      <p className="rcu_status_green">{datas.status}</p>
                    </div>
                  </div>
                ))}
              </div>
            )}

            <div></div>
            <div></div>
          </div>

          <div className="legal_manual_deviation_container">
          <CommonDecisionDetails 
          stageId={stage_id}
          type="rcu"
          
          />
          </div>

          <div className="rcu_views_main_container">
            <div className="valuation_details">
              <div className="valuation_details_blue">Conclusions</div>

              <div className="rcu_buttons_container">

                <div onClick={() => {
                  showConclusionDrawer();
                }}
                  className="rcu_view_button_edit"
                >
                  <EditButton />

                </div>
                <Drawer
                  title={<Header title="Conclusions" onClose={onClose} />}
                  width={970}
                  refreshData={toggleRefreshData}
                  onClose={onClose}
                  open={openConclusions}
                  body-Style={{ paddingBottom: 80 }}
                  closable={false}
                  loanid={loanid}
                >
                  <Conclusions
                    refreshData={toggleRefreshData}
                    open={openConclusions}
                    closeForm={onClose}
                    loanid={loanid}
                    stage_id={stage_id}
                  />
                </Drawer>
                <UpOutlined
                  className={`up-outlined ${isConclusionVisible ? "up-outlined-visible" : "up-outlined-hidden"
                    }`}
                  onClick={toggleConclusionVisibility}
                />
              </div>
            </div>

            {isConclusionVisible && (
              <div className="document_details_second_heading">
                <div className="valuation_details_second_data">
                  Overall Status <span className="rcu_input_view_red_color">*</span>
                </div>
                <div
                  className="valuation_details_second_data rcu_input_view_overall_status"

                >
                  {data?.rcu_data?.overall_status}
                </div>
                <br />
                <div className="valuation_details_second_data">
                  Remarks /Observation (please enter NA if none)
                </div>
                <div
                  className="valuation_details_second_data rcu_input_view_remark"

                >
                  {data?.rcu_data?.overall_remark}
                </div>
              </div>
            )}
          </div>

          <div className="rcu_views_main_container">
            <div className="document_details">
              <div className="valuation_details_blue" >Complete Remark</div>

              <div className="rcu_buttons_container">
                <div onClick={() => {
                  showCompleteRemarkDrawer();
                }}
                  className="rcu_view_button_edit">
                  <EditButton />
                </div>
                <Drawer
                  title={<Header title="Complete Remark" onClose={onClose} />}
                  width={970}
                  refreshData={toggleRefreshData}
                  onClose={onClose}
                  open={openCompleteRemark}
                  body-Style={{ paddingBottom: 80 }}
                  closable={false}
                  loanid={loanid}
                >
                  <Completeremark
                    refreshData={toggleRefreshData}
                    open={openCompleteRemark}
                    closeForm={onClose}
                    loanid={loanid}
                    stage_id={stage_id}
                  />
                </Drawer>
                <UpOutlined
                  className={`up-outlined ${isRemarkVisible ? "up-outlined-visible" : "up-outlined-hidden"
                    }`}
                  onClick={toggleRemarkVisibility}
                />
              </div>
            </div>

            {isRemarkVisible && (
              <div className="document_details_second_heading">
                <div
                  className="rcu_input_view_remark"
                >
                  Complete Remark:
                  <br />
                  {data?.rcu_data?.completion_remark}
                </div>
              </div>
            )}
          </div>



          <div className="rcu_views_main_container">
            <div className="document_details">
              <div className="valuation_details_blue">
                Property Pictures and Other Documents
              </div>
              <div className="rcu_buttons_container">
                <div onClick={() => {
                  showPropertyPicturesDrawer();
                }}
                  className="rcu_view_button_edit"
                >
                  <EditButton />
                </div>
                <Drawer
                  title={
                    <Header
                      title="Property Pictures and Other Documents"
                      onClose={onClose}
                    />
                  }
                  width={970}
                  refreshData={toggleRefreshData}
                  onClose={onClose}
                  open={propertyPictures}
                  body-Style={{ paddingBottom: 80 }}
                  closable={false}
                  loanid={loanid}
                >
                  <Propertypicturesdocuments
                    refreshData={toggleRefreshData}
                    open={propertyPictures}
                    closeForm={onClose}
                    loanid={loanid}
                    stage_id={stage_id}
                  />
                </Drawer>
                <UpOutlined
                  className={`up-outlined ${picture ? "up-outlined-visible" : "up-outlined-hidden"
                    }`}
                  onClick={togglepicturesVisibility}
                />
              </div>
            </div>

            {picture && (
              <div className="rcu_input_view_property_image">
                <div className="document_images">
                  {data?.rcu_data?.rcu_document_upload?.map((datas, index) => {
                    const url = datas.document_path || "";
                    const parts = url.split('/');
                    const fileNameWithParams = parts.pop();
                    const fileName = fileNameWithParams.split('?')[0]; // Remove query parameters if present

                    return (
                      <div key={index}
                        className="rcu_input_view_document_image"
                      >
                        {datas.file_type === "application/pdf" ? (
                          <img
                            src={pdf_image} // Replace `pdf_image` with your PDF icon source
                            alt="PDF Preview"
                            className="image rcu_input_view_document_file"
                            onClick={() => window.open(url, "_blank")}
                          />
                        ) : (
                          <RViewerJS>
                            <img
                              className="rcu_input_view_document_file"
                              src={url}
                              alt={fileName}
                            />
                          </RViewerJS>
                        )}
                        <p className="rcu_filename" >{fileName}</p>
                      </div>
                    );
                  })}
                </div>
              </div>

            )}
          </div>
        </div>
      )}
      <Modal
        open={isModalOpen}
        onCancel={handleCancel}
        footer={<DeleteModalFooter onCancel={handleCancel} />}
      >
        <p className="modal-paragraph">{`Are you sure you want to delete`}</p>
      </Modal>
    </div>

  );
};

export default Rcu;
