import React, { useState } from "react";
import {
  Layout,
  Row,
  Col,
  Typography,
  Calendar,
  Card,
  Table,
  Select,
} from "antd";
import MeetingTaskSwitcher from "./MeetingTaskSwitcher";
import DashBoardLiteImageSlider from "./DashBoardLiteImageSlider";
import headerImage from "../assets/rafiki.png";
import "../style/DashBoardCalender.css";
import "../style/dashboardTaskAssignUI.css";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { navigateWithEncryptedId } from "../../../utils/cryptoUtils/cryptoUtils";
import { useNavigate } from "react-router-dom";

const { Header, Content } = Layout;
const { Title, Text } = Typography;

const TaskTable = ({ assignedTask }) => {
  const navigate = useNavigate();
  const columns = [
    {
      title: "Details",
      dataIndex: "details",
      key: "details",
      responsive: ["xs", "sm", "md", "lg"],
      render: (text, record) => {
        const stage = record.stage;
        const stageStatus = record.stage_status;
        return (
          <div
            className="routeToLoanDetail"
            onClick={() => {
              const targetPath =
                stage === "Lead" && stageStatus === "Initiated"
                  ? "/sales"
                  : "/loandetails";

              const data = { name: "loan", key: "creditApplications1" };
              const newData = { name: "sales", key: "salesLeads1" };
              const passdata = targetPath === "/loandetails" ? data : newData;

              navigateWithEncryptedId(
                navigate,
                targetPath,
                record?.loan_id.toString(),
                passdata
              );
            }}
          >
            <strong>{stage}</strong> {stageStatus} for #
            <strong>{record?.lan_or_app_number}</strong>
          </div>
        );
      },
    },
    {
      title: "Pending Since",
      dataIndex: "pendingSince",
      key: "pendingSince",
      responsive: ["xs", "sm", "md", "lg"],
      render: (text, record) => {
        const createdAt = dayjs(record.created_at);
        const now = dayjs();
        const daysPending = now.diff(createdAt, "day");
        const minutesPending = now.diff(createdAt, "minute");

        let color;
        if (daysPending === 0) color = "green";
        else if (daysPending === 1) color = "yellow";
        else color = "red";

        const hours = Math.floor(minutesPending / 60);
        const remainingMinutes = minutesPending % 60;

        return (
          <div className="pending-since">
            {daysPending > 0 ? (
              <Text style={{ color }}>{daysPending} days </Text>
            ) : (
              <Text style={{ color }}>
                {hours} hours {remainingMinutes} minutes
              </Text>
            )}
            <Text style={{ color: "grey" }}>
              ({createdAt.format("DD-MM-YYYY")})
            </Text>
          </div>
        );
      },
    },
  ];

  return (
    <div className="dashboard_task_table">
      <Table
        columns={columns}
        dataSource={assignedTask}
        pagination={false}
        scroll={{ x: 600, y: 300 }}
        style={{ width: "99%" }}
      />
    </div>
  );
};

const ScheduleList = ({ selectedDate }) => {
  return <MeetingTaskSwitcher selectedDate={selectedDate} />;
};

const DashboardTaskAssignUI = ({ assignedTask }) => {
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const { user_data } = useSelector((state) => state.user);

  const handleDateSelect = (date) => {
    setSelectedDate(date);
  };

  const handleMonthChange = (newMonth) => {
    setSelectedDate(selectedDate.month(newMonth));
  };

  const handleYearChange = (newYear) => {
    setSelectedDate(selectedDate.year(newYear));
  };

  return (
    <Layout className="layout">
      <Header className="header">
        <div>
          <Title level={3} className="title">
            Welcome Back,{" "}
            <Text strong className="text">
              {user_data.name}
            </Text>
          </Title>
          <Text className="sub_text">Here's what's going on today.</Text>
        </div>

        <img src={headerImage} alt="Illustration" className="dashboard_image" />
      </Header>

      <Content className="dashboard_content">
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={8}>
            <Card
              title="Today's Schedule"
              headStyle={{
                color: "rgb(39, 101, 143)",
                fontFamily: "Poppins",
                fontSize: "20px",
                fontWeight: 400,
              }}
              bodyStyle={{
                padding: "0 10px",
              }}
              style={{ marginBottom: "16px" }}
            >
              <div className="dashboard_card_div">
                <Calendar
                  fullscreen={false}
                  onSelect={handleDateSelect}
                  headerRender={({ value, onChange }) => {
                    const start = 1900;
                    const end = 2100;
                    const monthOptions = Array.from({ length: 12 }, (_, i) => ({
                      value: i,
                      label: dayjs().month(i).format("MMM"),
                    }));
                    const yearOptions = Array.from(
                      { length: end - start + 1 },
                      (_, i) => ({
                        value: start + i,
                        label: start + i,
                      })
                    );

                    return (
                      <div className="dashboard_calender">
                        <div>
                          <Select
                            value={selectedDate?.year()}
                            style={{ width: 80, marginRight: 8 }}
                            onChange={handleYearChange}
                            options={yearOptions}
                          />
                          <Select
                            value={selectedDate?.month()}
                            style={{ width: 80 }}
                            onChange={handleMonthChange}
                            options={monthOptions}
                          />
                        </div>
                      </div>
                    );
                  }}
                />
              </div>
            </Card>

            <Card className="dashboard_card">
              <ScheduleList selectedDate={selectedDate} />
            </Card>
          </Col>

          <Col xs={24} sm={24} md={16}>
          <DashBoardLiteImageSlider />

            {/* <Card >
            </Card> */}
            <Card
              title="Assigned Tasks"
              headStyle={{
                color: "rgb(39, 101, 143)",
                fontFamily: "Poppins",
                fontSize: "20px",
                fontWeight: 400,
              }}
              bodyStyle={{
                padding: 0,
              }}
              style={{
                marginTop: "16px",
                height:"44%",
              }}
            >
              <TaskTable assignedTask={assignedTask} />
            </Card>
          </Col>
        </Row>
      </Content>
    </Layout>
  );
};

export default DashboardTaskAssignUI;
