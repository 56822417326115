import React from "react";
import '../styles/messageBubble.css'
const MessageBubble = ({ children, isActionMessage, isCurrentUser, msg }) => {

  return (
    <div
      style={{
        position: "relative",
        width: 'fit-content',
        maxWidth: "70%",
        marginLeft: isCurrentUser && !isActionMessage ? "15px" : "5px",
        marginRight: isCurrentUser && !isActionMessage ? "10px" : "10px",
        fontSize: "28px",
      }}
    >
      {!isActionMessage && !isCurrentUser && (
        <div
          style={{
            position: "absolute",
            left: "-8px",
            top: "18px",
            width: "12px",
            height: "12px",
            clipPath: "polygon(100% 0%, 0% 100%, 100% 100%)",
            backgroundColor: "rgba(98, 116, 137, 255)",
          }}
        />
      )}

      <div
        style={{
          padding: "0px 7px 7px 9px",
          borderRadius: "4px",
          backgroundColor: isActionMessage ? 'white' : isCurrentUser ? "white" : "rgba(98,116,137,255)",
          border: !isCurrentUser && !isActionMessage ? "0px solid #252568" : "none",
          textAlign: isActionMessage ? "center" : "left",
          display: "flex",
          alignItems: !isCurrentUser ? "right" : "left",
          color: isCurrentUser ? "black" : "white",
          fontSize: "10px",
          height: 'auto',
          fontFamily: "Open Sans",
          boxShadow: !isActionMessage ? "0px 4px 6px rgba(0, 0, 0, 0.2)" : "none",
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default MessageBubble;
